import { Box, Chip, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { FaArrowCircleRight } from "react-icons/fa";
import { SoftButton } from "../../../theme/StyledElement";

const ViewPage = ({ data, onBackClick }) => {
    // Columns for the vehicle details
    const vehicleColumns = [
        { field: 'registrationNumber', headerName: 'Vehicle Reg No', flex: 1 },
        { field: 'vehicleMakeId', headerName: 'Vehicle Make', flex: 1 },
        { field: 'vehicleModelId', headerName: 'Vehicle Segment', flex: 1 },
        { field: 'serviceEstimateNumber', headerName: 'Service Estimate Number', flex: 1 },
        {
            field: 'status', headerName: 'Status', flex: 1,
            renderCell: (params) => {
                let background = '';
                let statusText;

                switch (params.value) {
                    case 1:
                        statusText = 'Open';
                        background = '#ff0000';
                        break;
                    case 2:
                        statusText = 'Jc Created';
                        background = '#1b8c0a';
                        break;
                    default:
                        statusText = '-';
                        background = 'gray';
                }

                return (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '8px',
                                        color: "#ffffd6 !important"
                                    }}
                                />
                                {statusText}
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            backgroundColor: background,
                            color: '#fff', // Ensure text contrast is readable
                            width: "150px",
                        }}
                    />
                );
            },
        },
        { field: 'customerName', headerName: 'Customer Name', flex: 1 },
        { field: 'customerMobileNumber', headerName: 'Customer Mobile Number', flex: 1 },
    ];

    // Columns for the labour schedules
    const labourColumns = [
        { field: 'laborCode', headerName: 'LaborCode', width: 150 },
        { field: 'laborDescription', headerName: 'Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'rate', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discountAmount', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'laborTotal', headerName: 'Total Amount', width: 150 },
    ];

    //Osl Columns for the labour schedules
    const oslLabourColumns = [
        { field: 'laborCode', headerName: 'LaborCode', width: 150 },
        { field: 'laborDescription', headerName: 'Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'rate', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discountAmount', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'laborTotal', headerName: 'Total Amount', width: 150 },
    ];

    //parts Columns for the labour schedules
    const partsColumns = [
        { field: 'partNo', headerName: 'PartNo', width: 150 },
        { field: 'partDescription', headerName: 'Part Description', width: 200 },
        { field: 'requestedQuantity', headerName: 'Quantity', width: 100 },
        { field: 'rate', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discountAmount', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'partTotal', headerName: 'Total Amount', width: 150 },
    ];

    // Rows for the vehicle details DataGrid
    const vehicleRows = data ? [{ id: data.id, ...data }] : [];

    // Rows for the labour schedules DataGrid
    const labourRows = data.labourEstimate ? data.labourEstimate.map((item, index) => ({ id: index, ...item })) : [];
    const oslLabourRows = data.oslLabourEstimate ? data.oslLabourEstimate.map((item, index) => ({ id: index, ...item })) : [];
    const partsRows = data.partEstimate ? data.partEstimate.map((item, index) => ({ id: index, ...item })) : [];

    // Calculate grand total for laborTotal
    const grandTotal = labourRows.reduce((total, row) => total + parseFloat(row.laborTotal || 0), 0);
    const oslGrandTotal = oslLabourRows.reduce((total, row) => total + parseFloat(row.laborTotal || 0), 0);
    const partsTotal = partsRows.reduce((total, row) => total + parseFloat(row.partTotal || 0), 0);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                <SoftButton variant="contained" sx={{ width: "200px" }} onClick={onBackClick}>
                    Service Estimate <FaArrowCircleRight style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                </SoftButton>
            </Box>
            <Grid>
                <Box sx={{ height: 'auto', width: '100%' }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Vehicle Details</Typography>
                    <DataGrid rows={vehicleRows} columns={vehicleColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },

                        }}
                    />
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Labour Schedules</Typography>
                    <DataGrid rows={labourRows} columns={labourColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {grandTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Osl Labour scheduless</Typography>
                    <DataGrid rows={oslLabourRows} columns={oslLabourColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {oslGrandTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Parts Details</Typography>
                    <DataGrid rows={partsRows} columns={partsColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {partsTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>

        </Box>
    );
}

export default ViewPage;
