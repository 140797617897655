import { Box, Chip, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { MdEdit } from 'react-icons/md';

const ItemMasterDatagrid = ({ data, onEdit }) => {
  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList =menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'itemCode',
        headerName: 'Item Code',
        flex: 1,
      },
      {
        field: 'itemName',
        headerName: 'Item Name',
        flex: 1,
      },
      {
        field: 'companies',
        headerName: 'Company',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width:"90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(params.row)}
              style={{ padding: '5px', fontSize: '1rem', color: '#5d6294' }}
              sx={{ '&:hover': { backgroundColor: '#e0e0e0' }}}
            >
              <MdEdit />
          </IconButton>
          </Tooltip>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const rows = data.map((row, index) => ({ id: index, ...row }));

  const handleEdit = (rowData) => {
    // Pass the selected row data to the parent component
    onEdit(rowData);
  };

  return (
    <Box sx={{ marginTop: '30px', display: 'inline-block', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        getRowId={(row) => row.id}
        pagination
        editable={false}
        sx={{
          '& .MuiDataGrid-cell': {
             fontSize: '12px',
              fontWeight:'500'
           },
           '& .MuiDataGrid-columnHeaderTitleContainer': {
             fontSize: '14px',
             fontWeight:'700'
           },
           '& .MuiDataGrid-cell:focus': {
             outline: 'none',
           },
           '& .MuiDataGrid-row.Mui-selected': {
             outline: 'none',
           },
           '& .MuiDataGrid-row.Mui-selected:focus-within': {
             outline: 'none',
           },
           '& .MuiTablePagination-toolbar': {
             display: 'none',
           },
           // Sticky column styling
           '& .sticky-action-column': {
             position: 'sticky',
             right: 0,
             backgroundColor: 'white',
             zIndex: 1,
           },
           '& .sticky-action-column-header': {
             position: 'sticky',
             right: 0,
             backgroundColor: '#f6f6f6',
             zIndex: 2,
           },
           '& .MuiDataGrid-columnHeaders': {
             position: 'sticky',
             top: 0,
             zIndex: 3,
             backgroundColor: '#f6f6f6',
           },
            // Apply horizontal scrolling for screen widths <= 1920px
            '@media (max-width: 1920px)': {
             '& .MuiDataGrid-root': {
               overflowX: 'auto',
             },
           },
           
         }}
      />
    </Box>
  );
};

export default ItemMasterDatagrid;
