import { Box, Drawer, Toolbar } from '@mui/material';
import React from 'react';
import ExpandMenuList from './ExpandMenuList';
import MiniBar from "./MiniBar";

function Sidebar({ isOpen }) {
    let drawerWidth = isOpen ? 215 : 70;

    return (
        <Drawer
            variant='permanent'
            sx={{
                width: `${drawerWidth}px`,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', background: '#11164b', border: 0 },
            }}
        >
            <Toolbar />
            <Box sx={{ height: '100%', display: 'flex' }}>
                {isOpen ? <ExpandMenuList isOpen={isOpen} /> : <MiniBar />}
            </Box>
        </Drawer>
    );
}

export default Sidebar;
