import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Tooltip, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as User } from '../../assets/images/accounts.svg';
import { ReactComponent as Calendar } from '../../assets/images/Calendar_Active.svg';
import { ReactComponent as Dashboard } from '../../assets/images/Dashboard.svg';
import { ReactComponent as Item } from '../../assets/images/item master_active.svg';
import { ReactComponent as Log } from '../../assets/images/log_Active.svg';
import { ReactComponent as FitMaster, ReactComponent as Master } from '../../assets/images/Master_Active.svg';
import { ReactComponent as Receipts } from '../../assets/images/Messages_Active.svg';
import { ReactComponent as Call } from '../../assets/images/Notification_Active.svg';
import { ReactComponent as Report } from '../../assets/images/Settings_Active.svg';
import { ReactComponent as Transaction } from '../../assets/images/transactions_Active.svg';
import { ReactComponent as Menus } from '../../assets/images/receipt.svg';
import { ReactComponent as Stock } from '../../assets/images/stock.svg';
import { ReactComponent as Sale } from '../../assets/images/sales.svg';
import { ReactComponent as Purchase } from '../../assets/images/purchase.svg';
import { ReactComponent as Bulk } from '../../assets/images/bulkUpload.svg';

// Active icons
import { ReactComponent as UserActive } from '../../assets/images/accounts.svg';
import { ReactComponent as CalendarActive } from '../../assets/images/Calendar_Active.svg';
import { ReactComponent as DashboardActive } from '../../assets/images/Dashboard.svg';
import { ReactComponent as ItemActive } from '../../assets/images/item master_active.svg';
import { ReactComponent as LogActive } from '../../assets/images/log_Active.svg';
import { ReactComponent as FitMasterActive, ReactComponent as MasterActive } from '../../assets/images/Master_Active.svg';
import { ReactComponent as ReceiptsActive } from '../../assets/images/Messages_Active.svg';
import { ReactComponent as CallActive } from '../../assets/images/Notification_Active.svg';
import { ReactComponent as ReportActive } from '../../assets/images/Settings_Active.svg';
import { ReactComponent as TransactionActive } from '../../assets/images/transactions_Active.svg';
import { ReactComponent as MenuActive } from '../../assets/images/receipt.svg';
import { ReactComponent as StockActive } from '../../assets/images/stock.svg';
import { ReactComponent as SaleActive } from '../../assets/images/sales.svg';
import { ReactComponent as PurchaseActive } from '../../assets/images/purchase.svg';
import { ReactComponent as BulkActive } from '../../assets/images/bulkUpload.svg';


const iconMapping = {
  Dashboard,
  User,
  Master,
  Transaction,
  Receipts,
  Calendar,
  Report,
  Call,
  Item,
  Log,
  FitMaster,
  Menus,
  Stock,
  Sale,
  Purchase,
  Bulk
};

const activeIconMapping = {
  DashboardActive,
  UserActive,
  MasterActive,
  TransactionActive,
  ReceiptsActive,
  CalendarActive,
  ReportActive,
  CallActive,
  ItemActive,
  LogActive,
  FitMasterActive,
  MenuActive,
  StockActive,
  SaleActive,
  PurchaseActive,
  BulkActive
};

const MiniBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuItems, setSubmenuItems] = useState([]);
  const [currentMenuIndex, setCurrentMenuIndex] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null); // Track the active menu index
  const navigate = useNavigate();
  const theme = useTheme();
  const menuList = JSON.parse(localStorage.getItem("menuList"));

  const handleClick = (event, subMenu, path, index) => {
    setActiveMenuIndex(index); // Set the active menu index
    if (subMenu) {
      setAnchorEl(event.currentTarget);
      setSubmenuItems(subMenu);
      setCurrentMenuIndex(index);
      // Navigate to the main path if it exists
      if (path) {
        navigate(path);
      }
    } else if (path) {
      navigate(path);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubmenuItems([]);
    setCurrentMenuIndex(null);
  };

  const handleSubMenuClick = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <Box sx={{ width: '100%', height: '100%', padding: "0px 0px", background: 'transparent', backgroundColor: theme.sidebar.background }}>
      {menuList.map((menu, index) => {
        const Icon = activeMenuIndex === index ? activeIconMapping[menu.activeIcon] : iconMapping[menu.icon];
        return (
          <div key={index}>
            <Box
              button
              sx={{
                '&:hover': {
                  backgroundImage: 'linear-gradient(to right, #E96B20, rgba(255,0,0,0))',
                },
                backgroundImage: currentMenuIndex === index ? 'linear-gradient(to right, #E96B20, rgba(255,0,0,0))' : 'inherit',
              }}
            >
              <Tooltip title={menu.title} placement="right">
                <IconButton onClick={(event) => handleClick(event, menu.submenu, menu.path, index)}>
                  {Icon ? <Icon /> : null}
                  {menu.submenu && (
                    <Box ml={1} sx={{ marginLeft: "1px", marginTop: "5px" }}>
                      {currentMenuIndex === index ? <ExpandLess sx={{ color: '#d9d9d9' }} /> : <ExpandMore sx={{ color: '#d9d9d9' }} />}
                    </Box>
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        );
      })}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiList-padding': {
            padding: '0px'
          }
        }}

      >
        {submenuItems.map((subItem, subIndex) => (
          <MenuItem
            key={subIndex}
            sx={{
              backgroundColor: "#11164b",
              color: "#d9d9d9",
              '&:hover': {
                backgroundColor: '#312345',
                borderLeft: '4px solid #E96B20',
              },
              '& .MuiListItemIcon-root': {
                color: 'inherit',
              },
              '& .MuiMenu-list': {
                paddingTop: "1px !important",
                paddingBottom: "1px !important",
              }

            }}
            onClick={() => handleSubMenuClick(subItem.path)}
          >
            {subItem.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default MiniBar;
