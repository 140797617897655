import React, { useState,useRef, useEffect } from 'react';
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import DynamicTable from './TableDynamic';
import { useGetJobCardDetailsMutation,useCreatePartIssueMutation } from '../../../store/slice/ApiSlice';
import { useNavigate,useParams } from 'react-router-dom';
const EditSpareIssue = ({id,setCurrentTab,customerstate}) => {
  const navigate=useNavigate()
  const [getData]=useGetJobCardDetailsMutation()

  const [createPartIssue, { isSuccess, }] = useCreatePartIssueMutation(); // Prepare the add item mutation
  // searchKey, offset, limit
  const [rows, setRows] = useState([]);
  useEffect(()=>{
    const fetchdata=async()=>{
      if(id){
        let response=await getData({id:id,customer_state:customerstate})
       if(response.data&&response.data.requestSuccessful&&response?.data?.data){
        setRows(response?.data?.data)
       }
      }
    }
    fetchdata()
    
  },[id])

  const {
    register,
    handleSubmit,
    reset,
    setValue, control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
    },
  });

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const[ headers,setHeaders ]= useState([
    'Parts Code',
    'Description',
    'Hsn Code',
    'Repair Type',
    'Requested Qty',
    'Available Stock Qty',
    'Issued Qty',
    'Rate',
    'Cost',
    'MRP',
    'CGST',
    'SGST',
    'IGST',
    'Discount Amount',
    'Total Amount',
  ])

  const onSubmit = async (data) => {

    const formData = dynamicTableData.filter(item=>item["Issued Qty"]&& item["Issued Qty"]>0).map((obj) => ({
      transaction_id: id,
      indent_id:obj.indent_id,
      item_id: obj.item_id,
      item_code: obj["Parts Code"],
      item_name: obj.Description,
      quantity: parseFloat(obj["Issued Qty"]), // Convert to number if needed
      rate: parseFloat(obj.Rate), // Convert to number if needed
      cost: parseFloat(obj.Cost), // Convert to number if needed
      mrp: parseFloat(obj.MRP), // Convert to number if needed
      discount:  isNaN(parseFloat(obj["Discount Amount"])) ? 0 :parseFloat(obj["Discount Amount"]) , // Convert to number if needed
      cgst: obj.CGST,
      sgst: obj.SGST,
      igst: obj.IGST,
      total: parseFloat(obj["Total Amount"]), // Convert to number if needed
      repair_type:obj["Repair Type"]
    }));

    let response = await createPartIssue(formData)
    if (response.data && response.data.requestSuccessful) {
    
      let  message = response.data.message;
        setDataStatusConfirm('Success');
        reset();
        setDynamicTableData([]);
        setGrandTotal(0);
        setRows([])
      setSnackBarMessage(message);
      setCurrentTab(1)

    } else {
      let message;
        setDataStatusConfirm('Error');
        // message = response.error.data.validationErrors;
      
      setSnackBarMessage(response.error.data.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  

  const handleDataSubmit = (data) => {
    setDynamicTableData(data);
    calculateGrandTotal(data);
  };

  const calculateGrandTotal = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row['Total Amount'] || 0), 0);
    setGrandTotal(total);
  };

  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
    <Grid item xs={4}>

    <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'right' }}>
            Grand Total: {grandTotal.toFixed(2)}
          </Typography>
    </Grid>
     
          </Grid>

        
          <DynamicTable headers={headers} onSubmit={handleDataSubmit} 
          rows={rows} setRows={setRows}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <SoftButton type="submit" variant="contained" color="primary">
              {'Submit'}
            </SoftButton>
          </Box>
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default EditSpareIssue;
