import { createTheme } from "@mui/material";

const colors = {
  white: "#FFFFFF",
  primary: "#001a2c",
  dark: "#0a1128",
  light: "#336fcc",
  black: "#000",
  secondary: "#6E759F",
  grey: "#9e9e9e",
  dividerBg: "#F6F8FB",
  blue: "#0077D6",
  green: "#76ff03",
  gray: "rgba(0, 0, 0, 0.12)",
  secondaryGrey: "#9e9e9e",
  disabled: "#cccccc",
  lightBlue: "rgb(40, 150, 235)",
  whitesmoke: "#f5f5f5",
  skyBlue: "#B7E9F7",
  lightGray: "hsl(199, 18%, 76%)",
  darkGray: "red",
  sideBarDark:'#11164b',
  headerDark:'#252526',
  icon:"#213F99",
  shadows: {
    cardSm:
      "0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)",
    sidebar:
      "2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)",
    paper:
      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
    cardShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    dropdown:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
};

export function themeCreator({themeMode, fontFamily, }) {
  //const themeMode = themeMode;
  const default_theme = createTheme({
    palette: {
      mode: themeMode,
     
      primary: {
        dark: '#01013a',
        light:"#00406c",
        main:"#11164b",
        contrastText:'#fff'
      },
      secondary: {
        dark: "#ff6d00",
        light:"#ff9e00",
        main: "#ff8500",
        contrastText:"#fff",
      },
      success:{
          dark: "#25a244",
          light:"#ffc971",
          main: "#27ae60",
          contrastText: "#fff",
      },
      background: {
        paper: themeMode === "light" ? "#fff" : "#252526",
        default: themeMode === "light" ? "#f6f6f6" : "#1E1E1E",
      },
      divider: themeMode === "light" ? "rgba(0,0,0,0.12)" : "#bddcfc21",
      text:{
        primary: themeMode === "light" ? '#213F99' : '#fff',
        secondary:themeMode === "light" ? '#6c6c6c' : '#fff',
      },

    
    },
    // treeView:{
   icon: {
        iconColor: colors.icon
      },
    text: {
      highlightColor: colors.blue,
      fontSize: {
        xl: "12.5px",
        lg: "11.5px",
        md: "10px",
        sm: "7px",
        xs: "7px",
      },
      fontWeight: {
        s: 600,
        xs: 500,
      },
      color: {
        secondary: "#6e6d6d",
        contrastText: "#fff",
        gray: colors.gray,
      },
      contrastText: "#fff",
      lineHeight: 2.5,
    },
    table: {
      header: {
        text: {
        },
        backgroundColor: colors.primary,
      },
      dataRowHighlightColor: colors.gray,
    },
    sidebar: {
      width: "220px",
      color:  '#fff',
      background:colors.sideBarDark,
      padding: "0px 12px",
    },
    header:{
      // background:colors.headerDark,
      backgroundColor:'#fff'
    },
    selected:{
      background:'#333333'
    },
    subheader: {
      fontSize: {
        xl: "40px",
        l: "30px",
        m: "20px",
      },
    },
    borderRadius: {
      primary: "20px",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      }
    },
    typography: {
      fontFamily: `inter`,
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontWeight: 600,
      },
      h3: {
        fontWeight: 600,
      },
      body1: {
        fontSize: 14,
        fontWeight: 500,
        // color:"#11164b",
        color:"#7870AC",
      },
      body2: {
        fontSize: 14,
        fontWeight:700,
        // color:"#11164b",
        color:"#7870AC",
      },
    },
    //transitions
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 600,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
      }},
     // base components
    
  });
  
    return default_theme
}

