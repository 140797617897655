import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,Autocomplete,Popper,Grid,Typography
} from "@mui/material";

import StyledSnackBar from '../../../Utils/StyledSnackBar';
import { useGetAllRepairTypeMutation } from "../../../store/slice/ApiSlice";
const TableDynamic = ({ headers, onSubmit,rows,setRows }) => {
  const [open, setOpen] = useState(false);
  const [getAllRepairTypeData, { data }] = useGetAllRepairTypeMutation();
  const [message,setMessage]=useState("")
 useEffect(()=>{
getAllRepairTypeData()
 },[])
  const handleInputChange = (rowIndex, header, value) => {
    const row = rows[rowIndex];

    if (header === "Issued Qty") {
      if (parseInt(value) > row["Available Stock Qty"] ||parseInt(value)>row["Requested Qty"] ||parseInt(value)<=0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setOpen(true);
        setMessage("Issued Quantity Cannot be more than Available Quantity or Requested Quantity and Should be greater than 0")
        setTimeout(() => {
          setOpen(false);
          setMessage("")
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
    if (header === "Discount Amount") {
      if (parseInt(value)<=0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setOpen(true);
        setMessage(`${header} should be greater than zero`)
        setTimeout(() => {
          setOpen(false);
          setMessage("")
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
    

    let updatedRows = [...rows];
     const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        const totalbeforetax1=parseFloat(item["Rate"])*parseFloat(value)-parseFloat(item["Discount Amount"]||0) ||0
        const totalbeforetax2=parseFloat(item["Issued Qty"])*parseFloat(value)-parseFloat(item["Discount Amount"]||0) ||0
        const totalbeforetax3=parseFloat(item["Issued Qty"])*parseFloat(item["Rate"])-parseFloat(value||0) ||0

      const taxpercentage=parseFloat(item["CGST"])+parseFloat(item["SGST"])+parseFloat(item["IGST"])
        if(header=="Issued Qty"&&item["Rate"]){
          return({
            ...item,
            [header]:value,
            "Total Amount":(totalbeforetax1+parseFloat((totalbeforetax1*taxpercentage)/100)).toFixed(2)
          })
        }
        else if(header=="Rate"&&item["Issued Qty"]){
          return({
            ...item,
            [header]:value,
            "Total Amount":(totalbeforetax2+parseFloat((totalbeforetax2*taxpercentage)/100)).toFixed(2)
          })
        }
        else if(header=="Discount Amount"&&item["Rate"]&&item["Issued Qty"]){
          return({
            ...item,
            [header]:value,
            "Total Amount":(totalbeforetax3+parseFloat((totalbeforetax3*taxpercentage)/100)).toFixed(2)
          })
        }
        else{
        return(
          {...item,[header]:value}
        )
      }
      }
      else{
        return item
      }
      });
     
    setRows(initSelect);
    onSubmit(initSelect); // Send updated rows back to the parent

  };

  const handlerepairtypechange=(rowIndex, header, value)=>{
    if(value==null){
      return
    }
    const updatedRows = [...rows];
    
    const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        return(
          {...item,[header]:value?.id}
        )
      }
      else{
        return item
      }
    })

    setRows(initSelect);
    onSubmit(initSelect); 

  }

  return (
    <Box sx={{ marginTop: "30px", display: "inline-block", width: "100%" }}>
  <Grid container spacing={2}>
    {/* Body Rows */}
    {rows.map((row, rowIndex) => (
      <Grid item xs={12} key={row.id}>
        <Grid container spacing={2} sx={{mb:"20px"}}>
          {headers.map((header, colIndex) => (
            <Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST"   ? 0.5 :  header=="MRP" || header=="Cost"  ? 0.6:0.9} key={colIndex}>
              {header === "Repair Type" ? (
                <Autocomplete 
                  disablePortal
                  value={row[header]}
                  onChange={(_, newValue) => handlerepairtypechange(rowIndex, header, newValue)} // Handle change for row and header
                  options={data ? data?.repairTypeData : []} // Replace with your data source
                  getOptionLabel={(option) => option.repairTypeName} // Map the correct option label
                  renderOption={(props, option) => (
                    <Box key={option.id} component="li" {...props}>
                      {option.repairTypeName}
                    </Box>
                  )}
                  // PopperComponent={(popperProps) => (
                  //   <Popper {...popperProps} style={{ width: '200px' }} />
                  // )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      size="small"
                      label="Repair Type"
                    />
                  )}
                />
              ) : header === "Issued Qty" ? (
                <TextField fullWidth
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: Math.min(row["Available Stock Qty"], row["Requested Qty"]),
                    },
                    readOnly:row["Available Stock Qty"] === 0
                  }}
                  value={row[header]||""}
                  onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                  variant="standard"
                  size="small"
                  label="Issued Qty"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+"||e.key===".") {
                      e.preventDefault()
                    }
                  }}
            
                />
              ) : header=="Discount Amount"? 
              (
                <TextField fullWidth
                onBlur={()=>{
                  if (row["Rate"]-row["Discount Amount"]<row["Cost"]) {
                    // If the entered value exceeds the available stock quantity, show the message and stop further updates
                    setOpen(true);
                    setMessage(`(Rate-${header}) should be greater than Cost`)
                    row["Rate"]=""
                    row["Discount Amount"]=""
                    setTimeout(() => {
                      setOpen(false);
                      setMessage("")
                    }, 3000);
                    return; // Exit the function early, preventing state update
                  }
                
              }}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      // max: Math.min(row["Available Stock Qty"], row["Requested Qty"]),
                    },
                    readOnly:row["Available Stock Qty"] === 0
                  }}
                  value={row[header]||""}
                  onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                  variant="standard"
                  size="small"
                  label={header}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+"||e.key===".") {
                      e.preventDefault()
                    }
                  }}
            
                />
              ) :
             header=="Rate" ? 
              (
                <TextField fullWidth onBlur={()=>{
                    if (row["Rate"]<row["Cost"]) {
                      // If the entered value exceeds the available stock quantity, show the message and stop further updates
                      setOpen(true);
                      setMessage(`${header} should be greater than Cost`)
                      row["Rate"]=""
                      setTimeout(() => {
                        setOpen(false);
                        setMessage("")
                      }, 3000);
                      return; // Exit the function early, preventing state update
                    }
                  
                }}
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: row["Cost"],step:"any"
                      // max: Math.min(row["Available Stock Qty"], row["Requested Qty"]),
                    },
                    readOnly:row["Available Stock Qty"] === 0
                  }}
                  value={row[header]||""}
                  onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                  variant="standard"
                  size="small"
                  label={header}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                      e.preventDefault()
                    }
                  }}
            
                />
              )
             : (
                <TextField focused={header=="Total Amount"&&row[header]}
                  value={row[header]}
                  onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                  variant="standard"
                  size="small"
                  label={header}
                  InputProps={{
                    readOnly:  true 
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    ))}
  </Grid>

  {open && (
    <StyledSnackBar
      open={true}
      message={message}
      status={"Error"}
    />
  )}
</Box>


  );
};

export default TableDynamic;
