import React from 'react';
import webpage from "../../../src/assets/images/work_Inprogress.svg";

const Accounts = () => {
    return (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          width: '100%', 
          height: '100vh'
      }}>
          <img src={webpage} alt="webpage under construction" />
      </div>
      )
    }
export default Accounts