import { Button, Autocomplete, Box, Chip, Divider, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useVehicleUploadsDataMutation, useGetVehicleValidateMutation } from '../../store/slice/ApiSlice';
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import { UploadFile } from "@mui/icons-material";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";
import { SiTicktick } from "react-icons/si";
import { CgCloseR } from "react-icons/cg";

const VehicleUploads = () => {
    const { handleSubmit } = useForm();
    const [VehicleUploadsData] = useVehicleUploadsDataMutation();
    const [VehicleValidateUploads, { data: VehicleValidateGrid, isSuccess: VehicleValidateSuccess }] = useGetVehicleValidateMutation();
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [FailedData, setFailedData] = useState([]);
    const [successData, setSuccessData] = useState([]);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile?.name || "");
    };

    const onSubmit = async () => {
        if (!file) {
            setSnackBarMessage('Please upload an Excel file.');
            setDataStatusConfirm("Error");
            setOpen(true);
            return;
        }
        const formData = new FormData();
        formData.append("vehiclexls", file);

        const response = await VehicleValidateUploads(formData);

        let message;
        if (response && response.data) {
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                if (response.data.message === "Data Validated Successfully") {
                    setOpenPopup(true);
                }
                setFailedData(response?.data?.exceptionData)
                setSuccessData(response?.data?.successData)
            }
        } else {
            message = response?.error?.data?.message || 'Unknown error';
            setDataStatusConfirm("Error");
        }

        setSnackBarMessage(message);
        setOpen(true);
        setTimeout(() => setOpen(false), 3000);
    };


    const handleClosePopup = () => {
        setOpenPopup(false);
        setFile(null);
        setFileName("");
        setFailedData([]);

        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const handleSubmitvalidate = async () => {
        if (!file) {
            setSnackBarMessage("No file selected.");
            setDataStatusConfirm("Error");
            setOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append("vehiclexls", file);

        const response = await VehicleUploadsData(formData);

        if (response?.data) {
            setSnackBarMessage("File uploaded successfully.");
            setDataStatusConfirm("Success");
            setOpenPopup(false)
        } else {
            setSnackBarMessage("File upload failed.");
            setDataStatusConfirm("Error");
        }

        setOpen(true);
        setTimeout(() => setOpen(false), 3000);
        handleClosePopup();
    };
    const columns = useMemo(() => {

        const baseColumns = [
            {
                field: 'customer',
                headerName: 'Customer',
                flex: 1,
            },
            {
                field: 'registrationNumber',
                headerName: 'RegistrationNumber',
                flex: 1,
            },
            {
                field: 'message',
                headerName: 'Message',
                flex: 1,
            },

            {
                field: 'Validate',
                headerName: 'Validate',
                flex: 1,
                renderCell: (params) => (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '8px',
                                        color: "#ffffd6 !important"
                                    }}
                                />
                                Failed
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            backgroundColor: "#d70f0f",
                            color: '#fff', // Ensure text contrast is readable
                            width: "150px",
                        }}
                    />
                ),
            },
        ];

        return baseColumns;
    }, []);

    useEffect(() => {
        if (VehicleValidateSuccess) {
            setTotalItems(VehicleValidateGrid?.totalItems)
        }
    }, [offset, limit, VehicleValidateSuccess]);



    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };

    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };
    return (
        <Box>
            <StyledBreadcrumb mainDiv="Bulk Uploads" subDiv="VehicleUploads" />
            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", height: "auto" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", columnGap: "20px", alignItems: "flex-start" }}>
                        <Typography sx={{ alignSelf: "center", fontSize: "16px !important" }}>Vehicle Details Bulk Uploads:</Typography>
                        <Box>
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<UploadFile />}
                                sx={{ marginTop: "10px" }}
                            >
                                Upload file
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                            <Typography sx={{ paddingLeft: '10px' }}>{fileName}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", padding: "10px" }}>
                            {!openPopup && (<SoftButton type="button" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                                Validate
                            </SoftButton>
                            )}
                            {openPopup && (
                                <>
                                    <SoftButton variant="outlined" color="secondary" onClick={handleClosePopup} sx={{ marginRight: "20px" }}>
                                        Cancel
                                    </SoftButton>
                                    <SoftButton type="button" variant="contained" color="primary" onClick={handleSubmitvalidate}>
                                        Submit
                                    </SoftButton>
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", columnGap: "20px", alignItems: "flex-start" }}>
                        <Box>
                            <Typography sx={{ alignSelf: "center", fontSize: "16px !important", color: "red" }}>Guidelines *:</Typography>
                            <Box sx={{ display: "flex" }}>
                                <Typography sx={{ alignSelf: "center", fontSize: "16px !important", paddingRight: "20px" }}>Click here to download Vehicle Master BulkUploads Template</Typography>
                                <SoftButton variant="contained" color="primary" href={`${process.env.PUBLIC_URL}/Vehicle.xlsx`} download="Vehicle.xlsx">
                                    Download
                                </SoftButton>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Divider sx={{ mb: .8, marginTop: "30px" }}></Divider>
                <Box sx={{ marginTop: "30px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            rowGap: "10px",
                            columnGap: "30px",
                            '@media (max-width: 700px)': {
                                flexDirection: "column",
                                columnGap: "10px",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                backgroundColor: "#11164b",
                                borderRadius: "5px",
                                padding: "10px",
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column",
                                    columnGap: "5px",
                                    padding: "5px",
                                    width: "100%",
                                },
                            }}
                        >
                            <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "#d9d9d9 !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Total Records: {VehicleValidateGrid?.totalItems}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "1px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "10px",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column",
                                        paddingLeft: "5px",
                                        width: "100%",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Show:
                                </Typography>
                                <Select
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{
                                        marginLeft: 1,
                                        '& .MuiSelect-select': {
                                            paddingTop: "1px !important",
                                            paddingBottom: "1px !important",
                                        },
                                        '@media (max-width: 700px)': {
                                            marginLeft: 0,
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width: "auto",
                                background: "#f6f6f6",
                                borderRadius: "5px",
                                padding: "10px",
                                display: "flex", flexDirection: "row",
                                '@media (max-width: 700px)': {
                                    width: "100%",
                                    marginTop: "10px",
                                },
                            }}
                        >
                            <Box sx={{ alignSelf: "center", marginRight: "30px" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "green !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Success Records <SiTicktick style={{ color: "green", fontSize: '1.1rem', alignItems: "center", cursor: 'pointer', marginLeft: '2px' }} />:  {successData?.length}
                                </Typography>
                            </Box>
                            <Box sx={{ alignSelf: "center" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "red !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Failed Records <CgCloseR style={{ color: "red", fontSize: '1.2rem', alignItems: "center", cursor: 'pointer', marginLeft: '2px' }} />:  {FailedData?.length}
                                </Typography>
                            </Box>
                        </Box>

                    </Box>
                    <ItemGroupDatagrid data={FailedData || []} columns={columns} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                        <ToggleButtonGroup
                            exclusive
                            sx={{
                                display: 'flex', alignItems: 'center',

                                '& .MuiToggleButtonGroup-middleButton': {
                                    marginRight: "0px !important",
                                    borderLeft: "1px solid #e5e5e5"
                                },
                                '& .MuiToggleButtonGroup-root': {
                                    border: "1px solid #e5e5e5",
                                },
                            }}
                        >
                            <ToggleButton
                                value="previous"
                                onClick={handlePrevious}
                                disabled={offset === 0}
                            >
                                Previous
                            </ToggleButton>

                            {Array.from({ length: totalPages }, (_, index) => {
                                const page = index + 1;
                                const isFirstThreePages = page <= 3;
                                const isLastThreePages = page > totalPages - 3;
                                const isCurrentPage = offset / limit === index;

                                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                    return (
                                        <ToggleButton
                                            key={page}
                                            value={`page-${page}`}
                                            onClick={() => handlePageClick(page)}
                                            sx={{ margin: '0 5px' }}
                                            selected={isCurrentPage}
                                        >
                                            {page}
                                        </ToggleButton>
                                    );
                                }

                                if (page === 4 && !isFirstThreePages) {
                                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                if (page === totalPages - 3 && !isLastThreePages) {
                                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                return null;
                            })}
                            <ToggleButton
                                value="next"
                                onClick={handleNext}
                                disabled={offset + limit >= totalItems}
                            >
                                Next
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                </Box>

            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
};


export default VehicleUploads