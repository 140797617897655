import { Box, Chip, IconButton, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiFillPrinter } from "react-icons/ai";
import { FaSearchPlus } from "react-icons/fa";
import { IoSearchSharp, IoTicketSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useAllGatePassMutation, useLazyGatePassPdfQuery, useSubmitGatePassMutation } from '../../../store/slice/ApiSlice';
import { StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const GatePass = () => {
    const { formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {

        }
    });
    const navigate = useNavigate();
    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const pathname = window.location.pathname;

    const [getGatePassDatagrid, { data: GatePassDatagrid }] = useAllGatePassMutation();
    const [SubmitGatePass, { isSuccess: submitGatePassSuccess }] = useSubmitGatePassMutation();
    const [getGatePassPdfDetails] = useLazyGatePassPdfQuery();

    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [backDropOpen, setBackDropOpen] = useState(false);  //null
    const [gatePassData, setGatePassData] = useState(false);

    useEffect(() => {
        fetchItems(offset, searchValue);
        setBackDropOpen(true)
    }, [offset, limit, submitGatePassSuccess, gatePassData]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey,
        };
        const response = await getGatePassDatagrid(payload);
        if (response?.data?.requestSuccessful === true) {
            setBackDropOpen(false)
        }
        setTotalItems(response?.data?.GatePassJobCardsData?.totalItems);
    };

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };
    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };
    const columns = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        const hasUpdatePermission = buttonList.split(',').includes("3");
        const baseColumns = [
            {
                field: 'reg_no',
                headerName: 'Registration No',
                flex: 1,
            },
            {
                field: 'job_card_no',
                headerName: 'JobCard No',
                flex: 1,
            },
            {
                field: 'customer_name',
                headerName: 'Customer Name',
                flex: 1,
            },
            {
                field: 'odometer',
                headerName: 'Odometer',
                flex: 1,
            },
            {
                field: 'status_value',
                headerName: 'Status',
                flex: 1,
                renderCell: (params) => {
                    let background = '';

                    switch (params.value) {
                        case 'Open':
                            background = '#ff0000';
                            break;
                        case 'Ready For Billing':
                            background = '#1b8c0a';
                            break;
                        case 'Billing':
                            background = '#1b8c0a';
                            break;
                        case 'Deliverd':
                            background = '#1b8c0a';
                            break;
                        case 'Work In Progress':
                            background = '#e7c018';
                            break;
                        default:
                            background = 'gray';
                    }

                    return (
                        <Chip
                            label={
                                <>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '8px',
                                            color: "#ffffd6 !important"
                                        }}
                                    />
                                    {params.value}
                                </>
                            }
                            style={{
                                textTransform: 'capitalize',
                                backgroundColor: background,
                                color: '#fff', // Ensure text contrast is readable
                                width: "150px",
                            }}
                        />
                    );
                },
            },

        ];
        if (hasUpdatePermission) {
            baseColumns.push({
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    <>
                        {params?.row?.status === 4 && (
                            <Tooltip title="Click Here For GatePass">
                                <>
                                    <IoTicketSharp onClick={() => handleGatePassClick(params.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
                                </>
                            </Tooltip>
                        )}

                        <Tooltip title="View">
                            <>
                                <FaSearchPlus onClick={() => handleViewClick(params.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
                            </>
                        </Tooltip>

                        {params?.row?.status === 5 && (
                            <Tooltip title="Pdf">
                                <span>
                                    <AiFillPrinter onClick={() => handlePdfClick(params?.row)} style={{ cursor: 'pointer', fontSize: '1.2rem', marginRight: '10px' }} />
                                </span>
                            </Tooltip>
                        )}
                    </>
                ),
                cellClassName: 'sticky-action-column',
                headerClassName: 'sticky-action-column-header',
            });
        }

        return baseColumns;
    }, [menuList, pathname]);

    const handleViewClick = (rowData) => {
        navigate('/gatePass/gatePassViewPage', { state: { rowData } });
    };

    const handlePdfClick = async (rowData) => {
        const id = rowData.job_card_no;
        try {
            const pdfBlob = await getGatePassPdfDetails(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `JobCard${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const handleGatePassClick = async (rowData) => {
        try {
            const payload = {
                id: rowData.id,
                jobCardNo: rowData.job_card_no,
                status: 5,
                subStatus: rowData?.sub_status,
            };
            const response = await SubmitGatePass(payload);

            if (response && response.data) {
                let message;
                if (response.data.validationErrors) {
                    message = response.data.validationErrors;
                    setDataStatusConfirm("Error");
                } else {
                    message = response.data.message;
                    setDataStatusConfirm("Success");
                    setGatePassData(true)
                    // handlePdfClick(rowData)
                }
                setSnackBarMessage(message);
            } else {
                let message;
                if (response?.error?.data?.validationErrors) {
                    setDataStatusConfirm("Error");
                    message = response.error.data.validationErrors;
                } else {
                    setDataStatusConfirm("Success");
                    message = response?.error?.data.message;
                }
                setSnackBarMessage(message);
            }
        } catch (error) {
            console.error("Transaction update failed", error);
        }
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };
    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Transactions"
                subDiv="GatePass"
            ></StyledBreadcrumb>
            <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            rowGap: "10px",
                            columnGap: "30px",
                            '@media (max-width: 700px)': {
                                flexDirection: "column",
                                columnGap: "10px",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                backgroundColor: "#11164b",
                                borderRadius: "5px",
                                padding: "10px",
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column",
                                    columnGap: "5px",
                                    padding: "5px",
                                    width: "100%",
                                },
                            }}
                        >
                            <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "#d9d9d9 !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Total Records: {GatePassDatagrid?.GatePassJobCardsData?.totalItems}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "1px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "10px",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column",
                                        paddingLeft: "5px",
                                        width: "100%",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Show:
                                </Typography>
                                <Select
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{
                                        marginLeft: 1,
                                        '& .MuiSelect-select': {
                                            paddingTop: "1px !important",
                                            paddingBottom: "1px !important",
                                        },
                                        '@media (max-width: 700px)': {
                                            marginLeft: 0,
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    width: "100%",
                                    marginTop: "10px",
                                },
                            }}
                        >
                            <TextField
                                variant="standard"
                                label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSearchClick}>
                                                <IoSearchSharp />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "auto",
                                    '@media (max-width: 700px)': {
                                        width: "100%",
                                    },
                                }}
                            />
                        </Box>
                    </Box>

                    <ItemGroupDatagrid data={GatePassDatagrid?.GatePassJobCardsData?.data || []} columns={columns} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                        <ToggleButtonGroup
                            exclusive
                            sx={{
                                display: 'flex', alignItems: 'center',

                                '& .MuiToggleButtonGroup-middleButton': {
                                    marginRight: "0px !important",
                                    borderLeft: "1px solid #e5e5e5"
                                },
                                '& .MuiToggleButtonGroup-root': {
                                    border: "1px solid #e5e5e5",
                                },
                            }}
                        >
                            <ToggleButton
                                value="previous"
                                onClick={handlePrevious}
                                disabled={offset === 0}
                            >
                                Previous
                            </ToggleButton>

                            {Array.from({ length: totalPages }, (_, index) => {
                                const page = index + 1;
                                const isFirstThreePages = page <= 3;
                                const isLastThreePages = page > totalPages - 3;
                                const isCurrentPage = offset / limit === index;

                                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                    return (
                                        <ToggleButton
                                            key={page}
                                            value={`page-${page}`}
                                            onClick={() => handlePageClick(page)}
                                            sx={{ margin: '0 5px' }}
                                            selected={isCurrentPage}
                                        >
                                            {page}
                                        </ToggleButton>
                                    );
                                }

                                if (page === 4 && !isFirstThreePages) {
                                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                if (page === totalPages - 3 && !isLastThreePages) {
                                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                return null;
                            })}
                            <ToggleButton
                                value="next"
                                onClick={handleNext}
                                disabled={offset + limit >= totalItems}
                            >
                                Next
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                </Box>
            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={backDropOpen}
            >
                <ScaleLoader color="#11164b" />
            </Backdrop>
        </Box>
    )
}
export default GatePass