import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useEditDsaAgentMutation, useGetAllBankQuery, useGetAllPincodeMutation, useGetCompanyQuery, useGetDsaAgentDataMutation, useGetDsaAgentDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const DsaAgent = () => {
    const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
        defaultValues: {
            dsaCode: "",
            status: 0,
            dsaManagedBy: 0,
        }
    });

    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const pathname = window.location.pathname;

    const [addDsaAgentData, { isSuccess: DsaAgentSuccess }] = useGetDsaAgentDataMutation();
    const [getDsaAgentDatagrid, { data: DsaAgentDatagrid }] = useGetDsaAgentDatagridMutation();
    const [editDsaAgentData, { isSuccess: editDsaAgentSuccess }] = useEditDsaAgentMutation();
    const [getAllPincodeData] = useGetAllPincodeMutation();
    const { data: itemCompanyData } = useGetCompanyQuery();
    const { data: BankData } = useGetAllBankQuery();

    const [status, setStatus] = useState(1);
    const [dsaManagedBy, setDsaManagedBy] = useState(0);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [currentTab, setCurrentTab] = useState(1);
    const [isCreating, setIsCreating] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const companies = itemCompanyData?.companyData || [];
    const banks = BankData || []

    useEffect(() => {
        fetchItems(offset, searchValue);
    }, [offset, limit, DsaAgentSuccess, editDsaAgentSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey,
        };
        const response = await getDsaAgentDatagrid(payload);
        setTotalItems(response?.data?.DsaAgentsData?.totalItems);
    };

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };
    const onSubmit = async (data) => {
        const formData = {
            ...data,
            status: status ? 1 : 0,
            dsaManagedBy: dsaManagedBy ? 1 : 0,
            state: selectedState,
            city: selectedCity,
            pincode: data.pincode,
            companyId: data.companyId,
            bankName: data.bankName.bankName,

        };
        let response;
        if (isEditing) {
            response = await editDsaAgentData({ id: currentItemId, ...formData });
            if (response && response.data.requestSuccessful === true) {
                setIsEditing(false);
                setCurrentItemId(null);
            }
        } else {
            response = await addDsaAgentData(formData);
        }
        if (response && response.data) {

            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                resetForm();
                setValue('companyId', []);
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                resetForm();
                setValue('companyId', []);
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);

    };

    const resetForm = () => {
        reset({
            dsaCode: "",
            dsaName: "",
            email: "",
            whatsapp: "",
            address1: "",
            address2: "",
            mobileNumber: "",
            alternativeMobileNumber: "",
            pincode: "",
            branchName: "",
            accountNumber: "",
            ifscCode: "",
            panNo: "",
            bankCity: "",
            sourceOfTheDSA: "",
        });

        setIsEditing(false);
        setStatus(0);
        setSelectedCity("")
        setSelectedState("")
        setDsaManagedBy(0);
        setCurrentTab(1);
    };


    const handleStatusChange = (event) => {
        setStatus(event.target.checked ? 1 : 0);
    };
    const handledsaManagedByChange = (event) => {
        setDsaManagedBy(event.target.checked ? 1 : 0);
    };

    const handlePincodeChange = async (event) => {
        let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
        if (pincode.length === 6) {
            const payLoad = {
                pinCode: pincode,
            };
            const response = await getAllPincodeData(payLoad);
            if (response?.data?.requestSuccessful) {
                setSelectedState(response.data.pincodeData.state);
                setSelectedCity(response.data.pincodeData.city);
            }
        }
    };


    const handleEditClick = (row) => {
        setValue('dsaCode', row.dsaCode);
        setValue('dsaName', row.dsaName);
        setValue('email', row.email);
        setValue('whatsapp', row.whatsapp);
        setValue('address1', row.address1);
        setValue('address2', row.address2);
        setValue('mobileNumber', row.mobileNumber);
        setValue('alternativeMobileNumber', row.alternativeMobileNumber);
        setValue('pincode', row.pincode);
        setValue('branchName', row.branchName);
        setValue('accountNumber', row.accountNumber);
        setValue('ifscCode', row.ifscCode);
        setValue('panNo', row.panNo);
        setValue('bankCity', row.bankCity);
        setValue('sourceOfTheDSA', row.sourceOfTheDSA);

        const selectedCompanyNames = row.companies.split(',').map(companyName => companyName.trim());
        const selectedCompanies = companies.filter(company => selectedCompanyNames.includes(company.name));
        setValue('companyId', selectedCompanies);

        setSelectedState(row.state);
        setSelectedCity(row.city);

        setStatus(row.status ? 1 : 0);
        setDsaManagedBy(row.dsaManagedBy ? 1 : 0);

        const selectedBank = banks?.BankData?.find(bank => bank.bankName === row.bankName) || null;
        setValue('bankName', selectedBank);

        setIsEditing(true);
        setCurrentTab(0);
        setCurrentItemId(row.id);
        clearErrors(['dsaCode', 'panNo', 'bankCity', 'dsaManagedBy', 'dsaName', 'pincode', 'bankName', 'branchName', 'accountNumber', 'ifscCode', 'email', 'whatsapp', 'address1', 'address2', 'mobileNumber', 'alternativeMobileNumber']);
    };

    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };
    const columns = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        const hasUpdatePermission = buttonList.split(',').includes("3");
        const baseColumns = [
            {
                field: 'dsaCode',
                headerName: 'Dsa Code',
                flex: 1,
            },
            {
                field: 'dsaName',
                headerName: 'Dsa Name',
                flex: 1,
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
            },
            {
                field: 'mobileNumber',
                headerName: 'Mobile Number',
                flex: 1,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params) => (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                                        marginRight: '8px',
                                    }}
                                />
                                {params.value ? 'Active' : 'Inactive'}
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            width: "90px",
                        }}
                    />
                ),
            },
        ];

        if (hasUpdatePermission) {
            baseColumns.push({
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() => handleEditClick(params.row)}
                            style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
                            sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
                        >
                            <MdEdit />
                        </IconButton>
                    </Tooltip>
                ),
            });
        }

        return baseColumns;
    }, [menuList, pathname]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            setIsCreating(true);
        } else {
            setIsCreating(false);
            resetForm();
        }
        setCurrentTab(newValue);
    };

    // Filter out selected companies from options
    const selectedCompanies = useWatch({ name: 'companyId', control });
    const filteredOptions = companies.filter(
        (company) => !selectedCompanies?.some((selectedCompany) => selectedCompany.id === company.id)
    );

    const hasCreatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("1");
    }, [menuList, pathname]);

    const hasUpdatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("3");
    }, [menuList, pathname]);

    const handleCancel = () => {
        reset({
            dsaCode: "",
            dsaName: "",
            email: "",
            whatsapp: "",
            address1: "",
            address2: "",
            mobileNumber: "",
            alternativeMobileNumber: "",
            pincode: "",
            branchName: "",
            accountNumber: "",
            ifscCode: "",
            panNo: "",
            bankCity: "",
            sourceOfTheDSA: "",
        })
        setStatus(1);
        setDsaManagedBy(0);
        setIsEditing(false);
    };

    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Master"
                subDiv="DSA Agent"
            ></StyledBreadcrumb>

            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                    <Tabs value={currentTab} onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        sx={{
                          '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
                          maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
                          overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
                        }}
                    >
                        {hasCreatePermission ? (
                            <Tab label="Create Dsa Agent" />
                        ) : (isEditing && !hasCreatePermission) ? (
                            <Tab label="Update Dsa Agent" />
                        ) : null}
                        {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Dsa Agent " />)}

                    </Tabs>
                </Box>
                {currentTab === 0 && (
                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('dsaCode', { required: 'dsaCode is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Dsa Code <span style={{ color: '#D32F2F' }}>*</span></span>}

                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.dsaCode}
                                        helperText={errors.dsaCode ? errors.dsaCode.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('dsaName', { required: 'dsaName is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Dsa Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.dsaName}
                                        helperText={errors.dsaName ? errors.dsaName.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('email', {
                                        required: 'email is required',
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                        fullWidth variant="standard"
                                        label={<span>Email <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('whatsapp', {
                                            required: 'Whatsapp number is required',
                                            maxLength: {
                                                value: 10,
                                                message: 'Whatsapp number cannot exceed 10 digits'
                                            }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        label={<span>Whatsapp <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.whatsapp}
                                        type="number"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.whatsapp ? errors.whatsapp.message : ''}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 10) {
                                                e.target.value = value.slice(0, 10);
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 10,
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('address1', { required: 'address1 is required' })}
                                        fullWidth variant="standard"
                                        label={<span>address1 <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.address1}
                                        helperText={errors.address1 ? errors.address1.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('address2', { required: 'address1 is required' })}
                                        fullWidth variant="standard"
                                        label={<span>address2 <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.address2}
                                        helperText={errors.address2 ? errors.address2.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('pincode', {
                                            required: 'Pincode is required',
                                            minLength: { value: 6, message: 'Pincode must be 6 digits' },
                                            maxLength: { value: 6, message: 'Pincode must be 6 digits' }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        type="number"
                                        label={<span>Pincode <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.pincode}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.pincode ? errors.pincode.message : ''}
                                        onChange={handlePincodeChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label={<span>State <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        value={selectedState}
                                        InputLabelProps={selectedState ? { shrink: true } : {}}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label={<span>City <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        value={selectedCity}
                                        InputLabelProps={selectedCity ? { shrink: true } : {}}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('mobileNumber', {
                                            required: 'Mobile Number is required',
                                            maxLength: {
                                                value: 10,
                                                message: 'Mobile Number cannot exceed 10 digits'
                                            }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        label={<span>Mobile Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.mobileNumber}
                                        inputProps={{
                                            maxLength: 10,
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                        type="number"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.mobileNumber ? errors.mobileNumber.message : ''}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 10) {
                                                e.target.value = value.slice(0, 10);
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('alternativeMobileNumber', {
                                            required: 'Alternative Mobile Number is required',
                                            maxLength: {
                                                value: 10,
                                                message: 'Alternative Mobile Number cannot exceed 10 digits'
                                            }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        label={<span>Alternative Mobile Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.alternativeMobileNumber}
                                        inputProps={{
                                            maxLength: 10,
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                        type="number"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.alternativeMobileNumber ? errors.alternativeMobileNumber.message : ''}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 10) {
                                                e.target.value = value.slice(0, 10);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="bankName"
                                        control={control}
                                        rules={{ required: 'Bank is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={banks?.BankData || []}
                                                getOptionLabel={(option) => option.bankName || ''}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Bank <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        error={!!errors.bankName}
                                                        helperText={errors.bankName ? errors.bankName.message : ''}
                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, value) => field.onChange(value)}
                                            />
                                        )}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('branchName', { required: 'branchName is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Branch Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.branchName}
                                        helperText={errors.branchName ? errors.branchName.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('accountNumber', { required: 'accountNumber is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Account Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.accountNumber}
                                        type='number'
                                        helperText={errors.accountNumber ? errors.accountNumber.message : ''}
                                        onInput={handleInputChange} />
                                </Grid><Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('ifscCode', { required: 'ifscCode is required' })}
                                        fullWidth variant="standard"
                                        label={<span>IFSC Code <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.ifscCode}
                                        helperText={errors.ifscCode ? errors.ifscCode.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('panNo', { required: 'panNo is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Pan No <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.panNo}
                                        helperText={errors.panNo ? errors.panNo.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('bankCity', { required: 'bankCity is required' })}
                                        fullWidth variant="standard" l
                                        label={<span>Bank City <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.bankCity}
                                        helperText={errors.bankCity ? errors.bankCity.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('sourceOfTheDSA', { required: 'sourceOfTheDSA is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Source Of The DSA <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.sourceOfTheDSA}
                                        helperText={errors.sourceOfTheDSA ? errors.sourceOfTheDSA.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="companyId"
                                        control={control}
                                        rules={{ required: 'Company is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                multiple
                                                options={filteredOptions}
                                                getOptionLabel={(option) => option.name || ''}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Company <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        error={!!errors.companyId}
                                                        sx={{
                                                            '& .MuiChip-root': { height: "25px" }
                                                        }}
                                                        helperText={errors.companyId ? errors.companyId.message : ''}
                                                    />
                                                )}
                                                value={field.value || []}
                                                onChange={(event, value) => field.onChange(value)}
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                                        <Box>
                                            <FormLabel>DSA Managed By</FormLabel>
                                        </Box>
                                        <Box>
                                            <Switch checked={dsaManagedBy === 1} onChange={handledsaManagedByChange} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                                        <Box>
                                            <FormLabel>Status</FormLabel>
                                        </Box>
                                        <Box>
                                            <Switch checked={status === 1} onChange={handleStatusChange} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end",flexWrap:"wrap" }}>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                            <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                                                Cancel
                                            </SoftButton>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                            <SoftButton type="submit" variant="contained" color="primary" >
                                                {isEditing ? 'Update' : 'Add'}
                                            </SoftButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>


                        </form>
                    </StyledPaperShadow>
                )}
                {currentTab === 1 && (
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                flexWrap: "wrap",
                                rowGap: "10px",
                                columnGap: "30px",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column",
                                    columnGap: "10px",
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "10px",
                                    backgroundColor: "#11164b",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    width: "auto",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column",
                                        columnGap: "5px",
                                        padding: "5px",
                                        width: "100%",
                                    },
                                }}
                            >
                                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                    <Typography
                                        sx={{
                                            alignSelf: "center",
                                            color: "#d9d9d9 !important",
                                            fontSize: "1rem",
                                            '@media (max-width: 700px)': {
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    >
                                        Total Records: {DsaAgentDatagrid?.DsaAgentsData?.totalItems}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: "1px",
                                        backgroundColor: "#ffffff",
                                        paddingLeft: "10px",
                                        '@media (max-width: 700px)': {
                                            flexDirection: "column",
                                            paddingLeft: "5px",
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            alignSelf: "center",
                                            fontSize: "1rem",
                                            '@media (max-width: 700px)': {
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    >
                                        Show:
                                    </Typography>
                                    <Select
                                        value={limit}
                                        onChange={handleLimitChange}
                                        sx={{
                                            marginLeft: 1,
                                            '& .MuiSelect-select': {
                                                paddingTop: "1px !important",
                                                paddingBottom: "1px !important",
                                            },
                                            '@media (max-width: 700px)': {
                                                marginLeft: 0,
                                                width: "100%",
                                            },
                                        }}
                                    >
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: "auto",
                                    '@media (max-width: 700px)': {
                                        width: "100%",
                                        marginTop: "10px",
                                    },
                                }}
                            >
                                <TextField
                                    variant="standard"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={handleSearchClick}>
                                                    <IoSearchSharp />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        width: "auto",
                                        '@media (max-width: 700px)': {
                                            width: "100%",
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <ItemGroupDatagrid data={DsaAgentDatagrid?.DsaAgentsData?.data || []} columns={columns} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                            <ToggleButtonGroup
                                exclusive
                                sx={{
                                    display: 'flex', alignItems: 'center',

                                    '& .MuiToggleButtonGroup-middleButton': {
                                        marginRight: "0px !important",
                                        borderLeft: "1px solid #e5e5e5"
                                    },
                                    '& .MuiToggleButtonGroup-root': {
                                        border: "1px solid #e5e5e5",
                                    },
                                }}
                            >
                                <ToggleButton
                                    value="previous"
                                    onClick={handlePrevious}
                                    disabled={offset === 0}
                                >
                                    Previous
                                </ToggleButton>

                                {Array.from({ length: totalPages }, (_, index) => {
                                    const page = index + 1;
                                    const isFirstThreePages = page <= 3;
                                    const isLastThreePages = page > totalPages - 3;
                                    const isCurrentPage = offset / limit === index;

                                    if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                        return (
                                            <ToggleButton
                                                key={page}
                                                value={`page-${page}`}
                                                onClick={() => handlePageClick(page)}
                                                sx={{ margin: '0 5px' }}
                                                selected={isCurrentPage}
                                            >
                                                {page}
                                            </ToggleButton>
                                        );
                                    }

                                    if (page === 4 && !isFirstThreePages) {
                                        return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                    }

                                    if (page === totalPages - 3 && !isLastThreePages) {
                                        return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                    }

                                    return null;
                                })}
                                <ToggleButton
                                    value="next"
                                    onClick={handleNext}
                                    disabled={offset + limit >= totalItems}
                                >
                                    Next
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                    </Box>

                )}
                {open && (
                    <StyledSnackBar
                        open={true}
                        message={snackBarMessage}
                        status={dataStatusConfirm}
                    />
                )}
            </StyledPaperShadow>

        </Box>

    )
}
export default DsaAgent