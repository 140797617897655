import { Box, Grid, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useVehicleUploadsDataMutation } from '../../store/slice/ApiSlice';
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import { UploadFile } from "@mui/icons-material";

const VehicleUploads = () => {
    const { handleSubmit } = useForm();
    const [VehicleUploadsData] = useVehicleUploadsDataMutation();
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [itemHide, setitemHide] = useState(true);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile?.name || "");
    };

    const onSubmit = async () => {
        if (!file) {
            setSnackBarMessage('Please upload an Excel file.');
            setDataStatusConfirm("Error");
            setOpen(true);
            return;
        }
        setitemHide(false)
        const formData = new FormData();
        formData.append("file", file);

        const response = await VehicleUploadsData(formData);

        let message;
        if (response && response.data) {
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                setFileName("")
                setitemHide(true)
            }
        } else {
            message = response?.error?.data?.message || 'Unknown error';
            setDataStatusConfirm("Error");
        }

        setSnackBarMessage(message);
        setOpen(true);
        setTimeout(() => setOpen(false), 3000);
    };

    return (
        <Box>
            <StyledBreadcrumb mainDiv="Bulk Uploads" subDiv="VehicleUploads" />
            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", height: "400px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", columnGap: "20px", alignItems: "flex-start" }}>
                        <Typography sx={{ alignSelf: "center", fontSize: "16px !important" }}>Vehicle Details Bulk Uploads:</Typography>
                        <Box>
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<UploadFile />}
                                sx={{ marginTop: "10px" }}
                            >
                                Upload file
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                            <Typography sx={{ paddingLeft: '10px' }}>{fileName}</Typography>
                        </Box>
                        {itemHide && (<Box sx={{ display: "flex", justifyContent: "flex-start", padding: "10px" }}>
                            <Button type="button" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                                Submit
                            </Button>
                        </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", columnGap: "20px", alignItems: "flex-start" }}>
                        <Box>
                            <Typography sx={{ alignSelf: "center", fontSize: "16px !important", color: "red" }}>Guidelines *:</Typography>
                            <Box sx={{ display: "flex" }}>
                                <Typography sx={{ alignSelf: "center", fontSize: "16px !important", paddingRight: "20px" }}>Click here to download Vehicle Master BulkUploads Template</Typography>
                                <Button variant="contained" color="primary"
                                    href={`${process.env.PUBLIC_URL}/LabourMaster.xlsx`}
                                    download="LabourMaster.xlsx"
                                >
                                    Download
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
};


export default VehicleUploads