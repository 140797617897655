import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,Autocomplete,Popper,Grid
} from "@mui/material";

import StyledSnackBar from '../../../Utils/StyledSnackBar';
import { useGetAllRepairTypeMutation } from "../../../store/slice/ApiSlice";
const TableDynamic = ({ headers, onSubmit,rows,setRows }) => {
  const [open, setOpen] = useState(false);
  const [getAllRepairTypeData, { data }] = useGetAllRepairTypeMutation();

 useEffect(()=>{
getAllRepairTypeData()
 },[])
  const handleInputChange = (rowIndex, header, value) => {
    
    const row = rows[rowIndex];

    if (header === "Return Qty") {
      if (parseInt(value)>row["Available Qty"] ||parseInt(value)<0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
  

    let updatedRows = [...rows];
     const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        if(header=="Return Qty"&&item["Cost"]){
          return({
            ...item,
            [header]:value,
            "Total Amount":parseFloat(item["Cost"])*parseFloat(value) ||0
          })
        }
        else{
        return(
          {...item,[header]:value}
        )
      }
      }
      else{
        return item
      }
      });
     
    setRows(initSelect);
    onSubmit(initSelect); // Send updated rows back to the parent

  };

  const handlerepairtypechange=(rowIndex, header, value)=>{
    if(value==null){
      return
    }
    const updatedRows = [...rows];
    
    const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        return(
          {...item,[header]:value?.id}
        )
      }
      else{
        return item
      }
    })

    setRows(initSelect);
    onSubmit(initSelect); 

  }

  return (
    <Box sx={{ marginTop: "10px", maxHeight: "380px", overflowY: "auto" }}>
  {rows.map((row, rowIndex) => (
    <Grid container spacing={2} key={row.id} sx={{ mb: "30px", alignItems: "center" }}>
      
      {headers.map((header, colIndex) => (
        <Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST" || header=="Available Qty" || header=="Cost" || header=="Parts Code" ? 1 :2} key={colIndex}>
          {header === "Return Qty" ? (
            <TextField
              label="Return Qty"
              required
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                  max: row["Available Qty"],
                },
              }}
              value={row[header] || ""}
              onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
              variant="standard"
              size="small"
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "inherit",
                },
                "& input.Mui-disabled": {
                  backgroundColor: "#F0F0F0",
                },
              }}
            />
          ) : (
            <TextField focused={header=="Total Amount"}
              label={header}
              value={row[header] || ""}
              onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
              variant="standard"
              size="small"
              InputProps={{
                readOnly:  true
              }}
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "inherit",
                },
                "& input.Mui-disabled": {
                  backgroundColor: "#F0F0F0",
                },
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  ))}

  {/* Snackbar for Validation Message */}
  {open && (
    <StyledSnackBar open={true} message="Return Quantity Cannot be more than Issued Quantity and should be greater than 0" status="Error" />
  )}
</Box>

  );
};

export default TableDynamic;
