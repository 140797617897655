import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

function IsPublicLayout() {
  return (
    <Box sx={{margin:'auto', background:'#f7f7f7', height:'100vh', overflow:'hidden'}}>
        <Outlet />
    </Box>
    
  )
}

export default IsPublicLayout