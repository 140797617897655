import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,Autocomplete,Popper,Grid,Typography
} from "@mui/material";

import StyledSnackBar from '../../../Utils/StyledSnackBar';
import { useGetAllRepairTypeMutation } from "../../../store/slice/ApiSlice";
const TableDynamic = ({ headers, onSubmit,rows,setRows }) => {
  const [open, setOpen] = useState(false);
  const [getAllRepairTypeData, { data }] = useGetAllRepairTypeMutation();

 useEffect(()=>{
getAllRepairTypeData()
 },[])
  const handleInputChange = (rowIndex, header, value) => {
    
    const row = rows[rowIndex];

    if (header === "Issued Qty" && row["MRP"]) {
      if (parseInt(value) > row["Available Stock Qty"] ||parseInt(value)>row["Requested Qty"] ||parseInt(value)<0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
  

    let updatedRows = [...rows];
     const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        if(header=="Issued Qty"&&item["MRP"]){
          return({
            ...item,
            [header]:value,
            "Total Amount":parseFloat(item["MRP"])*parseFloat(value) ||0
          })
        }
        else{
        return(
          {...item,[header]:value}
        )
      }
      }
      else{
        return item
      }
      });
     
    setRows(initSelect);
    onSubmit(initSelect); // Send updated rows back to the parent

  };

  const handlerepairtypechange=(rowIndex, header, value)=>{
    if(value==null){
      return
    }
    const updatedRows = [...rows];
    
    const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        return(
          {...item,[header]:value?.id}
        )
      }
      else{
        return item
      }
    })

    setRows(initSelect);
    onSubmit(initSelect); 

  }

  return (
    <Box sx={{ marginTop: "30px", display: "inline-block", width: "100%" }}>
  <Grid container spacing={2}>
    {/* Body Rows */}
    {rows.map((row, rowIndex) => (
      <Grid item xs={12} key={row.id}>
        <Grid container spacing={2} sx={{mb:"20px"}}>
          {headers.map((header, colIndex) => (
            <Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST"   || header=="Rate" || header=="MRP" || header=="Cost" ? 1 :2} key={colIndex}>
              {header === "Repair Type" ? (
                <Autocomplete
                  value={row[header]}
                  onChange={(_, newValue) => handlerepairtypechange(rowIndex, header, newValue)} // Handle change for row and header
                  options={data ? data?.repairTypeData : []} // Replace with your data source
                  getOptionLabel={(option) => option.repairTypeName} // Map the correct option label
                  renderOption={(props, option) => (
                    <Box key={option.id} component="li" {...props}>
                      {option.repairTypeName}
                    </Box>
                  )}
                  PopperComponent={(popperProps) => (
                    <Popper {...popperProps} style={{ width: '200px' }} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      size="small"
                      label="Repair Type"
                    />
                  )}
                />
              ) : header === "Issued Qty" ? (
                <TextField fullWidth
                  required
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: Math.min(row["Available Stock Qty"], row["Requested Qty"]),
                    },
                    readOnly:row["Available Stock Qty"] === 0
                  }}
                  value={row[header]||""}
                  onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                  variant="standard"
                  size="small"
                  label="Issued Qty"
                  
                />
              ) : (
                <TextField focused={header=="Total Amount"}
                  required={header !== "Discount Amount"}
                  value={row[header]}
                  onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                  variant="standard"
                  size="small"
                  label={header}
                  InputProps={{
                    readOnly: header!=="Discount Amount" && header!="Rate" ? true :false
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    ))}
  </Grid>

  {open && (
    <StyledSnackBar
      open={true}
      message={"Issued Quantity Cannot be more than Available Quantity or Requested Quantity and Should be greater than 0"}
      status={"Error"}
    />
  )}
</Box>


  );
};

export default TableDynamic;
