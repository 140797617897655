import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField, Autocomplete, Popper, Grid,IconButton,CardMedia,Typography
} from "@mui/material";
import { SoftButton } from "../../../theme/StyledElement";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useGetsearchPartsDetailsMutation, useGetCounterSalepartMutation } from '../../../store/slice/ApiSlice';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import MdDelete from "../../../../src/assets/images/del.svg";
import { StyledPaperShadow } from "../../../theme/StyledElement";
import { MdAdd, MdEdit, } from 'react-icons/md';
const DynamicTable = ({ headers, onSubmit, vendorState, vendorCodeRef, rows, setRows }) => {
  const [rowIdCounter, setRowIdCounter] = useState(1);
  const [partsOptionData, setPartsOptionData] = useState([]);
  const [searchPartsData, { data: SearchParts, isLoading: loading }] = useGetsearchPartsDetailsMutation();
  const [getAllPartsData, { data: PartsDetails }] = useGetCounterSalepartMutation();
  const [open, setOpen] = useState(false);
  const [mesaage,setMessage]=useState("")

  const addRow = () => {
    const newRow = { id: rowIdCounter, itemid: 0 };
    headers.forEach((header) => (newRow[header] = ""));
    setRows((prevRows) => [...prevRows, newRow]);
    setRowIdCounter((prev) => prev + 1);
  };

  const handleInputChange = (rowIndex, header, value) => {
    const row = rows[rowIndex];
    if (header === "Return Qty") {
      if (parseInt(value) > row["Issued Qty"] ||parseInt(value)<=0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setMessage("Return Quantity should be less than  Issued Quantity and Should be greater than 0")
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
    
    const updatedRows = [...rows];
    const initSelect =  updatedRows.map((item,index) => {
        if(index==rowIndex){
          if(header=="Return Qty"){
            if(value){
                const totalbeforetax=(parseFloat(updatedRows[rowIndex]["Rate"]) * parseFloat(value))-parseFloat(updatedRows[rowIndex]["Discount Amount"]||0)
                const taxpercentage=parseFloat(updatedRows[rowIndex]["CGST"])+parseFloat(updatedRows[rowIndex]["SGST"])+parseFloat(updatedRows[rowIndex]["IGST"])
                return({
                    ...item,
                    [header]:value,
                    "Total Amount":(totalbeforetax+parseFloat((totalbeforetax*taxpercentage)/100)).toFixed(2)
                  })
            }
              else{
                return({
                    ...item,
                    [header]:value,
                    "Total Amount":0
                  })
              }
            
          }
          else{
          return(
            {...item,[header]:value}
          )
        }
        }
        else{
          return item
        }
        });

   
   
    setRows(initSelect);
    onSubmit(initSelect); // Send updated rows back to the parent

  };



  return (
    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "0px", marginLeft: "10px" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}> Parts details</Typography>
        
    </Box>
    <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto" }}>
      {rows.map((row, rowIndex) => (
        <Grid container spacing={2} key={row.id} sx={{ marginBottom: "10px", alignItems: "center" }}>

         

          {/* Other Fields as TextFields */}
          {headers.map((header, colIndex) => (
            header=="Return Qty" ?
            (<Grid item xs={1} key={colIndex}>
              <TextField type={"number"}
                label={header}
                value={row[header] }
                onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                required={true}
                size="small"
                fullWidth
                variant="standard"
                InputProps={{
                  
                  readOnly:row["Issued Qty"] === 0 || !row["Issued Qty"] ? true :false
                }}
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+"||e.key===".") {
                    e.preventDefault()
                  }
                }}
              />
            </Grid>
            ) 
            :
             (<Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST" ? 0.5 :1.1} key={colIndex}>
              <TextField focused={header=="Total Amount"}
                label={header}
                value={row[header]}
                onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                size="small"
                fullWidth
                variant="standard"
                InputProps={{
                  
                    readOnly:true
                  }}
              />
            </Grid>
            )
          ))}

          
        </Grid>
      ))}

     
      {open && (
        <StyledSnackBar open={true} message={mesaage} status={"Error"} />
      )}
    </Box>
    </StyledPaperShadow>

  );
};

export default DynamicTable;
