import {
  AppBar,
  Box,
  Button,
  FormLabel,
  Paper,
  styled
} from "@mui/material";

import { alpha } from '@mui/material/styles';


export const FullHeightBoxLayout = styled(Box)(({ theme }) => ({
  height: "100%",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
}));

export const StyledMainLayout = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const StyledPageLayout = styled(Box)(({ theme }) => ({
  padding: "20px 20px",
}));

//App bar
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  //boxShadow:'0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1)',
  color: "#000",
  background:
    theme.palette.mode === "light"
      ? alpha(theme.palette.background.paper, 1)
      : '#000',
  zIndex: theme.zIndex.drawer + 1,
  backdropFilter: "blur(5px)",
}));

export const SoftButton = styled(Button)(({ theme }) => ({
  borderRadius: '2px',
  width: '120px',
  height: '40px',
  background: '#E96B20', // Default background color
  color: '#FFFFFF', // Default text color, adjust as per your design
  '&.wait': {
    cursor: 'wait'
  },
  "&.primary": {
    background: alpha(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.contrastText,
  },
  "&.secondary": {
    background: alpha(theme.palette.secondary.main, 0.8),
    color: theme.palette.secondary.contrastText,
  },
  "&.info": {
    background: alpha(theme.palette.info.main, 0.8),
    color: theme.palette.info.contrastText,
  },
  "&.warning": {
    background: alpha(theme.palette.warning.main, 0.8),
    color: theme.palette.warning.contrastText,
  },
  "&.success": {
    background: alpha(theme.palette.success.main, 0.8),
    color: theme.palette.success.contrastText,
  },
  "&.error": {
    background: alpha(theme.palette.error.main, 0.8),
    color: theme.palette.error.contrastText,
  },
  '&.success-soft': {
    background: alpha(theme.palette.success.main, 0.16),
    color: theme.palette.success.main,
  },
  '&.error-soft': {
    background: alpha(theme.palette.error.main, 0.16),
    color: theme.palette.error.main,
  },
  "&.map-btn": {
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
  },
  pointerEvents: 'all',
  transition: 'background-color 0.3s ease', // Add smooth transition for background color change
  '&:hover': {
    background: '#11164b', // Background color when hovering
  },
}));


export const StyledFormControlLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: 3,
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
  "&.Mui-focused": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.grey[800]
        : theme.palette.grey[400],
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: ".6rem",
  },
}));

export const StyledPaperShadow = styled(Paper)(({ theme }) => ({
  boxShadow:
    theme.palette.mode === "light"
      ? "0 4px 25px #a8b4d01a !important"
      : "0 3px 3rem #0000000b",
  border:
    theme.palette.mode === "light"
      ? "1px solid #e5e5e5"
      : "1px solid #bddcfc21",
  padding: "10px",
  display: "flex",
  alignItems: "end",
  borderRadius: "5px",
  "&.loginWrapper": {
    background: alpha(theme.palette.background.paper, 0.8),
    display: "flex",
    alignItems: "baseline",
  },
}));


