import { Alert, Box, CardMedia, Collapse, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import image from '../../../src/assets/images/bg.png';
import company from "../../../src/assets/images/companylogo.png";
import { useResetPasswordMutation } from '../../store/slice/ApiSlice';
import { SoftButton, StyledFormControlLabel } from "../../theme/StyledElement";
const ActivationLink = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [validation, setValidation] = useState(false);
  const [lower, setLower] = useState(false);
  const [upper, setUpper] = useState(false);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);
  const [noSpace, setNoSpace] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [ResetPasswordData] = useResetPasswordMutation();


  let params = window.location.href;
  let url = new URL(params);
  let token = url.searchParams.get("token");
  let userId = url.searchParams.get("userId");


  var lowerCaseLetters = new RegExp(/[a-z]/);
  var upperCaseLetters = new RegExp(/[A-Z]/);
  var numbers = new RegExp(/[0-9]/);
  var space = new RegExp(/\s/);
  var specialChar = new RegExp(/[!@#$%^&*]/);
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      name: null,
      password: null,
      captcha: null,
    },
  });
  const passwordValidate = (password) => {
    if (password.length > 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
    if (lowerCaseLetters.test(password)) {
      setLower(true);
    } else {
      setLower(false);
    }

    if (upperCaseLetters.test(password)) {
      setUpper(true);
    } else {
      setUpper(false);
    }

    if (numbers.test(password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }
    if (!space.test(password)) {
      setNoSpace(true);
    } else {
      setNoSpace(false);
    } if (specialChar.test(password)) {
      setSpecial(true);
    } else {
      setSpecial(false);
    }

  }

  const onSubmit = async (data) => {
    setValidation(false);
    const payload = {
      token: token,
      confirmPassword: data.confirmPassword,
      userId: userId
    }
    const response = await ResetPasswordData(payload)
    if (response && response.data.requestSuccessful === true) {
      navigate("/");
    }
  };
  const showpassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor={theme.palette.background.default}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'blue',
      }}
    >
      <Box
        width={380}
        height={480}
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"

      >
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-evenly" }}>
          <CardMedia src={company} component={'img'} sx={{ height: 'auto', width: 'auto' }} />
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: "100%", margin: "10px" }}
        >
          <Alert icon={false} color="info" sx={{ justifyContent: "space-evenly", marginBottom: "10px" }}>
            Enter your Password.
          </Alert>
          <Stack direction="column" spacing={2} >
            <StyledFormControlLabel>
              <TextField
                size="small"
                variant="standard"
                label="Password"
                error={errors.password && true}
                {...register("password", {
                  required: {
                    value: true,
                    message: "Please enter New Password ",
                  },
                  minLength: {
                    value: 5,
                    message: "Password cannot less than 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                    message: "Invalid Password",
                  },
                })}
                onChange={(e) => { setValue("password", e.target.value); passwordValidate(e.target.value) }}
                helperText={errors.password && errors.password.message}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showpassword} size="small">
                        {showPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </StyledFormControlLabel>

            <StyledFormControlLabel>

              <TextField
                size="small"
                variant="standard"
                label="Confirm Password"
                error={errors.confirmPassword && true}
                {...register("confirmPassword", {
                  required: {
                    value: true,
                    message: "Please enter Password",
                  },


                  validate: {
                    emailAvailable: async (fieldValue) => {
                      var result = "";
                      if (fieldValue === getValues("password")) {
                        result = "Valid Password";
                      } else {
                        result = "InValid Password";
                      }

                      return (
                        result !== "InValid Password" ||
                        "Password does not match"
                      );
                    },
                  },
                })}
                onChange={(e) =>
                  setValue("confirmPassword", e.target.value)
                }
                helperText={
                  errors.confirmPassword && errors.confirmPassword.message
                }
                type={showPassword ? "text" : "password"}
                id="ConfirmPassword"
                autoComplete="confirm-password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={showpassword}
                        size="small"
                        sx={{ padding: 0 }}
                      >
                        {showPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </StyledFormControlLabel>
            <Collapse in={validation}>
              <Typography variant="caption" sx={{ width: '100%', color: lower ? theme.palette.success.main : theme.palette.error.main }} component={'p'}  >* At least one lowercase</Typography>
              <Typography variant="caption" sx={{ width: '100%', color: upper ? theme.palette.success.main : theme.palette.error.main }} component={'p'}  >* At least one uppercase</Typography>
              <Typography variant="caption" sx={{ width: '100%', color: number ? theme.palette.success.main : theme.palette.error.main }} component={'p'}  >* At least one number</Typography>
              <Typography variant="caption" sx={{ width: '100%', color: special ? theme.palette.success.main : theme.palette.error.main }} component={'p'}  >* Special character</Typography>
              <Typography variant="caption" sx={{ width: '100%', color: noSpace ? theme.palette.success.main : theme.palette.error.main }} component={'p'}  >* No spacing</Typography>
            </Collapse>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <SoftButton
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel
            </SoftButton>
            <SoftButton
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              sx={{ marginLeft: "20px" }}
            >
              Submit
            </SoftButton>

          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ActivationLink