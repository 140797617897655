import React, { useState, useEffect,useRef,useMemo } from "react";
import { Typography, Box, Grid, TextField,Tab,Tabs,Select,MenuItem,IconButton } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { StyledPaperShadow, SoftButton } from "../../../theme/StyledElement";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import { useNavigate } from 'react-router-dom';
import { useGetCounterSaleMutation,useGetCounterSalePdfMutation } from "../../../store/slice/ApiSlice";
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import 'html2canvas'; // Import html2canvas
import { IoSearchSharp } from "react-icons/io5";
import InputAdornment from '@mui/material/InputAdornment';
import CreateCounterSale from "./CreateCounterSale";
import kilogo from "../../../assets/images/kilogo.png"
import mytvslogo from "../../../assets/images/tvslogo.png"


const CounterSale = () => {
      

  const [getCountersale,{ data }] = useGetCounterSaleMutation({ refetchOnMountOrArgChange: true });
  const [getCounterSalePdfData,{ data:pdfData }] = useGetCounterSalePdfMutation({ refetchOnMountOrArgChange: true });

  const [limit,setLimit] = useState(25); // Number of records per page
  const [offset, setOffset] = useState(0); // Current page offset
  const [currentTab, setCurrentTab] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  

  useEffect(() => {
    if(currentTab==1){
      fetchItems(offset, searchValue);
    }
}, [offset, limit,currentTab]);

const fetchItems = async (newOffset, searchKey) => {
    const payload = {
        limit,
        offset: newOffset,
        searchKey,
    };
    const response = await getCountersale(payload);
    if(response?.data?.requestSuccessful){
      setTotalItems(response?.data?.data?.length);

    } 
};


  const handleNext = () => {
    const newOffset = offset + limit;
    if (newOffset < totalItems) {
      setOffset(newOffset);
    }
  };

  const handlePrevious = () => {
    const newOffset = offset - limit;
    if (newOffset >= 0) {
      setOffset(newOffset);
    }
  };



  const handleEditClick = async(row) => {
   const response =await getCounterSalePdfData({id:row.id})
   if(response?.data?.requestSuccessful){

   const outletdetails=response.data.outlet_details
   const grndetails=response.data.data[0]
   const partsdetails=response.data.data[0].countersale_parts
   
   function numberToWordsIndian(num) {
    if (num === 0) return 'zero';

    const belowTwenty = [
        'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
        'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const tens = [
        'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    const scales = [
        '', 'thousand', 'lakh', 'crore'
    ];

    function helper(n) {
        if (n === 0) return '';
        if (n < 20) return belowTwenty[n - 1] + ' ';
        if (n < 100) return tens[Math.floor(n / 10) - 2] + ' ' + helper(n % 10);
        if (n < 1000) return belowTwenty[Math.floor(n / 100) - 1] + ' hundred ' + (n % 100 !== 0 ? 'and ' : '') + helper(n % 100);
    }

    function convertWholeNumber(n) {
        let word = '';
        let scaleIndex = 0;

        while (n > 0) {
            let chunk;
            if (scaleIndex === 0) {
                // First segment (thousands), handle 3 digits
                chunk = n % 1000;
                n = Math.floor(n / 1000);
            } else {
                // Lakhs and Crores, handle 2 digits
                chunk = n % 100;
                n = Math.floor(n / 100);
            }

            if (chunk !== 0) {
                word = helper(chunk) + scales[scaleIndex] + ' ' + word;
            }

            scaleIndex++;
        }

        return word.trim();
    }

    // Separate the rupees and paisa
    const rupees = Math.floor(num);
    const paisa = Math.round((num - rupees) * 100);

    let result = '';
    if (rupees > 0) {
        result += convertWholeNumber(rupees) + ' rupees ';
    }

    if (paisa > 0) {
        result += 'and ' + convertWholeNumber(paisa) + ' paisa';
    }

    return result.trim();
}


const amountInWords = numberToWordsIndian(Math.round(parseFloat(grndetails.pdf_total)));


    const doc = new jsPDF();
    const pageHeight = 841.89;
    const htmlstring=`
    <div style="color: black;font-family:Times New Roman;text-align:justify;line-height:1.2;letter-spacing: 0.01px;">
    <div style="display: flex;flex-direction: row;justify-content:space-between;align-item:flex-start;">
    <div style="flex:1">
    <img src="${kilogo}" style="width: 120px; height: auto;"/>
    </div>
    <div style="flex:5;margin-top:20px">
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_name}
    </p>
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_address1}
    </p>
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_address2}
    </p>
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_city}
    </p>
    </div>
    <div style="flex:4;display: flex; justify-content: flex-end;margin-top:20px">
    <img src="${mytvslogo}" style="width: 150px; height: 40px;"/>
    </div>
    </div>
    <div style="border-bottom:1px solid black;">
    <p style="color:#aa2e2b; font-size:22px;font-weight:bold;line-height:0.8;text-align:center">
    COUNTER SALE RECEIPT
    </p>
    </div>
    <div style="display: flex; justify-content: space-between; gap: 20px;">
    <div style="flex: 1;">
        <div style="display: flex; align-items: flex-start; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">Pan</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;"></span>
        </div>
        <div style="display: flex; align-items: flex-start; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">GSTIN</span>
            <span style="margin: 0 10px;">:</span>
            <div style="flex: 2">
            <p style="text-align: left;margin:0;">${outletdetails.outlet_gst}</p>
            </div>
        </div>
        <div style="display: flex; align-items: flex-start; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">CIN</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;"></span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">IRN No</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">0</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">IRN Date</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;"></span>
        </div>
    </div>
    <div style="flex: 1;">
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">Invoice Number</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${row.invoice_number}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">Bill DATE</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${row.createdAt}</span>
        </div>
    </div>
</div>
    <table style="border-collapse: collapse;margin-top:10px; width: 100%;">
    <tr>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;" colspan="2">
    Billing To
    </td>
    </tr>
    <tr>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
    Cust Code
    </td>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
    ${grndetails.customer_code}
    </td>
    </tr>
    <tr>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
    Cust Name
    </td>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
    ${grndetails.customer_name}
    </td>
    </tr>
    <tr>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
    Cust GSTIN
    </td>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
       ${grndetails.customer_gstin}
    </td>
    </tr>
    <tr>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
    Billing Address
    </td>
<td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">
${grndetails.customer_address}
</td>
    </tr>
    </table>
    <table style="border-collapse: collapse;margin-top:10px; width: 100%;">
    <tr>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">SNO</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">CODE</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">NAME</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">QTY</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">Taxable Value</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">DISC</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">Taxbl Val After Disc</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">CGST%</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">CGST</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">SGST%</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">SGST</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">IGST%</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">IGST</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">AMOUNT</td>
    </tr>
    ${partsdetails.map((item, index) => `
      <tr>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${index + 1}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.item_code}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;max-width:150pt;">${item.item_description}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.quantity}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.rate }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: right; color: black;">${item.discount}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${parseFloat(item.rate)-parseFloat(item.discount) }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.cgst }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.cgst_amount }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.sgst }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.sgst_amount }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.igst }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.igst_amount }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: right; color: black;">${item.total}</td>
      </tr>
      `).join('')}
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="3">SUB TOTAL</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${grndetails.total_quantity}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;"></td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;"></td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${parseFloat(grndetails.total_rate)-parseFloat(grndetails.total_discount)}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;"></td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${grndetails.total_cgst}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;"></td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${grndetails.total_sgst}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;"></td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${grndetails.total_igst}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.pdf_total}</td>
    </tr>
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="13">Total GST</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${parseFloat(grndetails.total_cgst)+parseFloat(grndetails.total_sgst)+parseFloat(grndetails.total_igst)}</td>
    </tr>
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="13">ROUND OFF</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${(Math.round(parseFloat(grndetails.pdf_total))-parseFloat(grndetails.pdf_total)).toFixed(2)}</td>
    </tr>
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="13">TOTAL</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${Math.round(parseFloat(grndetails.pdf_total))}</td>
    </tr>
</table>
<div style="display: flex; justify-content:flex-start;">
<p style="font-weight: bold;margin-right:10pt">
AMOUNT IN WORDS: 
</P>
<p style="font-weight:700">
Rupees ${amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)} only
</p>
</div>
<div>
<p>
E & O.E
</p>
<p>
I hereby accept to pay the above mentioned amount by Cash / DD before taking delivery of the vehicle.
</p>
<p>
Certified that goods covered by this bill suffered taxes at our hand/already. Pl turnover for the condition of sale. Goods once sold will not taken back.
</p>
</div>
<div style="margin-top:50pt">
<p style="text-align:right;font-weight:700">
For  ${outletdetails.outlet_name}
</p>
</div>
<div style="display: flex; justify-content:space-between;margin-top:40pt">
<div style="border-top: 2px solid #a2a0a0;padding-right:30pt">
<p style="font-weight:700;margin:0">
Customer Signature   
</p>
</div>
<div style="border-top: 2px solid #a2a0a0;padding-left:30pt">
<p style="font-weight:700;margin:0">
Authorized Signature
</p>
</div>
</div>
<div style="position:absolute; top:${pageHeight+50}pt;border-top:1px solid black;width:100%">
<p style="text-align:center;margin:0">
Regd. Off. No. 10, Jawahar Road, Madurai – 625 002
</p>
<p style="text-align:center;margin:0">
Mail id: enquiry@tvs.in, Website: www.tvsautomobilesolutions.com
</p>
<p style="text-align:center;margin:0">
ki Mobility Solutions Private Limited (formerly Peninsula Auto Parts Private Limited)
</p>
</div>
</div>
`

    doc.html(htmlstring, {
        callback: function (pdf) {
            pdf.save(`${row.invoice_number}.pdf`);
        },
        x: 10,
        y: 0,
        width: 210, // Width in mm
        windowWidth: 800, // Adjusts the window width for rendering
        margin: [0, 0, 0, 0], // Top, Left, Bottom, Right margins
        autoPaging: 'text',
        // html2canvas: { scale: 0.2 }
    });
  }
  else{

  }
  };

const handleTabChange = (event, newValue) => {
  setCurrentTab(newValue);
};

const hasCreatePermission = useMemo(() => {
  const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
  const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
  return buttonList.split(',').includes("1");
}, [menuList, pathname]);

const hasUpdatePermission = useMemo(() => {
  const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
  const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
  return buttonList.split(',').includes("3");
}, [menuList, pathname]);

const handleLimitChange = (event) => {
  setLimit(event.target.value);
  setOffset(0); // Reset offset when changing the limit
};
const handleSearchClick = () => {
  setOffset(0);
  fetchItems(0, searchValue);
};
  return (
    <>
    <Box>
      <StyledBreadcrumb mainDiv="Parts" subDiv="Counter Sale" />
      <StyledPaperShadow
        elevation={0}
        sx={{ padding: "1px 20px 20px 20px", display: "inline-block", width: "100%" }}
      >

<Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                    <Tabs value={currentTab} onChange={handleTabChange}
                        sx={{ '&  .MuiTabs-indicator': { backgroundColor: "#e96b20" } }}
                    >
                        {hasCreatePermission ? (
                            <Tab label="Create Counter Sale" />
                        ) : (isEditing && !hasCreatePermission) ? (
                            <Tab label="Update Counter Sale" />
                        ) : null}

                        {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Counter Sale" />)}

                    </Tabs>
                </Box>

                {currentTab === 0 && (
                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                     <CreateCounterSale setCurrentTab={setCurrentTab} />
                    </StyledPaperShadow>
                  )}

{currentTab === 1 && ( <Box>
  <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
                                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                    <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {totalItems}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                                    <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                                    <Select
                                        value={limit}
                                        onChange={handleLimitChange}
                                        sx={{
                                            marginLeft: 1,

                                            '& .MuiSelect-select': {
                                                paddingTop: "1px !important",
                                                paddingBottom: "1px !important"
                                            },
                                        }}

                                    >
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <TextField
                                variant="standard"
                                label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSearchClick}>
                                                <IoSearchSharp />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Box>
          { data?.data?.length > 0 && (
            <ItemGroupDatagrid
              data={data?.data} 
              columns={[...Object.keys(data?.data[0]).filter((item)=>item!="id").map((key) => ({
                field: key,
                headerName:key=="createdAt" ? "Invoice Date": key.replace(/_/g, ' ')  
                  .replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase(),
                  flex:1,
                  minWidth: 100,
              })),
              {
                field: 'Action',
                headerName: 'Action',
                minWidth: 100,
                flex:1,
                renderCell: (params) => (
                    <>
                      <Tooltip title="Print">  
                      <PrintIcon
                       onClick={() => handleEditClick(params.row)} 
                       style={{ fontSize: '1.1rem', cursor: 'pointer', marginRight: '10px' }} />
                       </Tooltip>
          
                    </>
                  ),
              }
            ]}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <SoftButton variant="contained" color="primary" onClick={handlePrevious} disabled={offset === 0}>
              Previous
            </SoftButton>
            <SoftButton
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={offset + limit >= totalItems}
            >
              Next
            </SoftButton>
          </Box>
        </Box>)}
      </StyledPaperShadow>
    </Box>
    </>
  );
};

export default CounterSale;
