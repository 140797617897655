import { Box, CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import { NewStore } from './store/NewStore';
import ThemeProviderWrapper from './theme/themeProvider';
// import 'antd/dist/antd.css';

let persistor = persistStore(NewStore);
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  // <React.StrictMode>
    <Provider store={NewStore}>
    <PersistGate loading={null} persistor={persistor}>
    <ThemeProviderWrapper>
      <Box sx={{ display: "flex", padding:"0px 0px"}} >
      <CssBaseline />
    <Router>
    <App />
    </Router>
    </Box>
      </ThemeProviderWrapper>
      </PersistGate>
    </Provider>
  // </React.StrictMode>,
);

