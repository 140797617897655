import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { IoSearchSharp } from "react-icons/io5";
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import {
  useLazyReceiptsDownloadQuery,
  useGetAllReceiptsDatagridMutation,
  useGetAllReceiptsMutation,
  useGetCustomerMutation,
  useGetJobCardMutation,
} from "../../store/slice/ApiSlice";
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";
import Tooltip from "@mui/material/Tooltip";
import { FaFilePdf } from "react-icons/fa6";

const Receipts = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addReceiptData, { isSuccess: ReceiptSuccess }] =
    useGetAllReceiptsMutation();
  const [getReceiptDatagrid, { data: ReceiptDatagrid }] =
    useGetAllReceiptsDatagridMutation();

  const [searchCustomerData] = useGetCustomerMutation();
  const [searchJobCardData] = useGetJobCardMutation();
  const [getPdfDetails] = useLazyReceiptsDownloadQuery();

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [isCreating, setIsCreating] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [optionData, setOptionData] = useState([]);
  const [optionJcData, setOptionJcData] = useState([]);
  const [billTypeOptions, setBillTypeOptions] = useState([]);
  const [customer, setCustomer] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [dateOpen1, setDateOpen1] = useState(false);
  const [dateOpen2, setDateOpen2] = useState(false);
  const [paymentMode, setPaymentMode] = useState("Cash");
  const [modeOfPay, setModeOfPay] = useState("");
  const [receiptType, setReceiptType] = useState("");
  const [inputValue, setInputValue] = useState('');
  const [allData, setAllData] = useState({
    outlet_id: "",
    transactionId: "",
    customerId: "",
    customerCode: ""
  });

  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, ReceiptSuccess, modeOfPay, receiptType]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey,
      billType: modeOfPay,
      receiptType,
    };
    const response = await getReceiptDatagrid(payload);
    setTotalItems(response?.data?.ReceiptData?.totalItems);
  };

  const modeOfPaymentValue = useWatch({ control, name: "modeOfPayment" });
  const amountValue = useWatch({ control, name: "amount" });

  useEffect(() => {
    if (modeOfPaymentValue?.value === "Cheque") {
      setPaymentMode("Cheque");
    } else if (modeOfPaymentValue?.value === "Cash") {
      setPaymentMode("Cash");
    } else if (modeOfPaymentValue?.value === "Upi") {
      setPaymentMode("Upi");
    } else {
      setPaymentMode(false);
    }
  }, [modeOfPaymentValue]);

  const receiptTypeValue = useWatch({ control, name: "receiptType" });

  useEffect(() => {
    if (receiptTypeValue?.value === "Customer") {
      setInputValue("");
      setOptionJcData([]);
      setBillTypeOptions([{ label: "Advance", value: "Advance" }]);
      setCustomer(false);
    } else if (receiptTypeValue?.value === "Jobcard") {
      setValue("customerCode", []);
      setOptionData([]);
      setBillTypeOptions([
        { label: "Advance", value: "Advance" },
        { label: "Cash", value: "Cash" },
      ]);
      setCustomer(true);
    } else {
      setBillTypeOptions([]);
    }
  }, [receiptTypeValue, setValue]);

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0);
  };

  const onSubmit = async (data) => {
    const formData = {
      outletId: allData?.outlet_id ? allData?.outlet_id : data.customerCode?.outletId,
      receiptType: data.receiptType?.value,
      billType: data.billType?.value,
      customerId: customer ? null : data.customerCode?.id || null,
      customerCode: customer ? null : data.customerCode?.customerCode || null,
      transactionId: allData?.transactionId || null,
      jcNumber: inputValue ? inputValue : null,
      amount: data.amount,
      refNo: data.refNo,
      refDate: data.refDate ? dayjs(data.refDate).format("YYYY-MM-DD") : null,
      remarks: data.remarks || "",
      modeOfPayment: data.modeOfPayment?.value || "",
      drawnOn: data.drawnOn ? dayjs(data.drawnOn).format("YYYY-MM-DD") : null,
      chequeDraftDate: data.chequeDraftDate
        ? dayjs(data.chequeDraftDate).format("YYYY-MM-DD")
        : null,
      chequeDraftNumber: data.chequeDraftNumber || "",
      transactionNumber: data.transactionNumber || "",
    };
    let response = await addReceiptData(formData);
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        resetForm();
        setValue("jobCardNo", []);
        setValue("companyId", []);
        setInputValue("");
        setOptionJcData([]);
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        reset();
        setCurrentTab(1);
        setValue("companyId", []);
        setValue("jobCardNo", []);
        setInputValue("");
        setOptionJcData([]);
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const resetForm = () => {
    reset({});
    setCurrentTab(1);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  const handlePdfClick = async (rowData) => {
    const id = rowData.id;
    try {
      const pdfBlob = await getPdfDetails(id);

      if (pdfBlob?.data !== undefined) {
        const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `Reciepts${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(pdfUrl);
        setDataStatusConfirm("Success");
        const message = "pdf is downloaded";
        setSnackBarMessage(message);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "doc_no",
        headerName: "Document No",
        flex: 1,
      },
      {
        field: "receipt_type",
        headerName: "Receipt Type",
        flex: 1,
      },
      {
        field: "bill_type",
        headerName: "Bill Type",
        flex: 1,
      },
      {
        field: "customer_code",
        headerName: "Customer Code",
        flex: 1,
        renderCell: (params) => (
          <span>{params.value !== null ? params.value : '-'}</span>
        )
      },
      {
        field: "jc_number",
        headerName: "Jobcard Number",
        flex: 1,
        renderCell: (params) => (
          <span>{params.value !== null ? params.value : '-'}</span>
        )
      },
      {
        field: "mode_of_payment",
        headerName: "Mode of Payment",
        flex: 1,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (params) => (
          <>
            <Tooltip title="Pdf">
              <>
                <FaFilePdf
                  onClick={() => handlePdfClick(params.row)}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.1rem",
                    marginRight: "10px",
                  }}
                />
              </>
            </Tooltip>
          </>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleTabChange = (event, newValue) => {
    setCustomer(false);
    if (newValue === 0) {
      setIsCreating(true);
      setOptionData([]);
    } else {
      setIsCreating(false);
      setInputValue("");
      setOptionJcData([]);
      resetForm();
    }
    setCurrentTab(newValue);
  };

  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(
      (item) =>
        item.path === pathname ||
        item.submenu?.some((sub) => sub.path === pathname)
    );
    const buttonList =
      menu?.submenu?.find((sub) => sub.path === pathname)?.buttons || "";
    return buttonList.split(",").includes("1");
  }, [menuList, pathname]);

  const modeOfPaymentOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
    { label: "Upi", value: "Upi" },
  ];

  const receiptTypeOptions = [
    { label: "Customer", value: "Customer" },
    { label: "Jobcard", value: "Jobcard" },
  ];

  const handleSearch = async (customerCode, isOptionSelected = false) => {
    if (isOptionSelected) return;

    if (customerCode.length <= 2) {
      setOptionData([]);
      setValue("customerCode", "");
    }

    if (customerCode.length >= 3) {
      const payload = { customerCode };
      const response = await searchCustomerData(payload);

      if (response?.data?.requestSuccessful) {
        setOptionData(response.data.data);
      }
    }
  };

  const handleJcSearch = async (jobCardNo, isOptionSelected = false) => {
    if (isOptionSelected && jobCardNo === "" ? setValue("amount", '') : '') return;
    if (jobCardNo.length <= 4) {
      setOptionJcData([]);
      setValue("jobCardNo", "");
      setValue("amount", '');
    }

    if (jobCardNo.length >= 5) {
      const payload = { jobCardNo };
      const response = await searchJobCardData(payload);

      if (response?.data?.requestSuccessful) {
        if (response?.data?.requestSuccessful) {
          setValue("jobCardNo", "");
          setOptionJcData(response.data.data);
        }
      }
    }
  };

  const Cancelled = () => {
    reset({
      receiptType: "",
      billType: "",
      customerCode: "",
      jobCardNo: "",
      amount: "",
      refNo: "",
      refDate: "",
      remarks: "",
      modeOfPayment: "",
      drawnOn: "",
      chequeDraftDate: "",
      chequeDraftNumber: "",
      transactionNumber: "",

    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <StyledBreadcrumb
          mainDiv="Receipts"
          subDiv={currentTab === 0 ? "Create Receipts" : "Receipts ViewPage"}
        ></StyledBreadcrumb>

        <StyledPaperShadow
          elevation={0}
          sx={{ display: "inline-block", width: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px 0px",
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              sx={{ "&  .MuiTabs-indicator": { backgroundColor: "#e96b20" } }}
            >
              {hasCreatePermission ? (
                <Tab label="Create Receipts" />
              ) : !hasCreatePermission ? (
                <Tab label="Update Receipts" />
              ) : null}
              {hasCreatePermission && <Tab label="Receipts" />}
            </Tabs>
          </Box>
          {currentTab === 0 && (
            <StyledPaperShadow
              elevation={0}
              sx={{ display: "inline-block", width: "100%" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Controller
                      name="receiptType"
                      control={control}
                      rules={{ required: "receiptType is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={receiptTypeOptions}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) => field.onChange(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Receipt Type"
                              variant="standard"
                              error={!!errors.receiptType}
                              helperText={
                                errors.receiptType
                                  ? "Receipt Type is required"
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Controller
                      name="billType"
                      control={control}
                      rules={{ required: "billType is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={billTypeOptions}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) => field.onChange(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Bill Type"
                              variant="standard"
                              error={!!errors.billType}
                              helperText={
                                errors.billType ? "Bill Type is required" : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {!customer ? (
                    <Grid item xs={3}>
                      <Controller
                        name="customerCode"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            freeSolo
                            options={optionData}
                            getOptionLabel={(option) =>
                              option.customerCode || ""
                            }
                            filterOptions={(options, state) => {
                              const searchTerm = state.inputValue.toLowerCase();
                              return options.filter(
                                (option) =>
                                  option.customerCode
                                    .toLowerCase()
                                    .includes(searchTerm) ||
                                  option.address
                                    .toLowerCase()
                                    .includes(searchTerm)
                              );
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                field.onChange(value);
                              }
                            }}
                            value={field.value}
                            renderOption={(props, option) => (
                              <Box
                                key={option.customerCode}
                                component="li"
                                {...props}
                              >
                                {option.customerCode} | {option.address}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Customer Code"
                                variant="standard"
                              />
                            )}
                            onInputChange={(event, newValue) => {
                              handleSearch(
                                newValue,
                                event?.type === "change" ? false : true
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : null}

                  {customer ? (<Grid item xs={3}>
                    <Controller
                      name="jobCardNo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          options={optionJcData}
                          getOptionLabel={(option) => option?.job_card_no || ""}
                          filterOptions={(options, state) => {
                            const searchTerm = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.job_card_no.toLowerCase().includes(searchTerm)
                            );
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              field.onChange(value.job_card_no);
                              setValue("amount", value.billing !== null ? value.billing.total_amount : "");
                              setAllData({ outlet_id: value.outlet_id, transactionId: value.id })
                              setInputValue(value.job_card_no);
                            }
                          }}
                          value={optionJcData.find(option => option.job_card_no === field.value) || null}
                          inputValue={inputValue}
                          onInputChange={(event, newValue) => {
                            setInputValue(newValue);
                            handleJcSearch(newValue, event?.type === "change" ? false : true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              label="JobCardNo"
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>) : null}



                  <Grid item xs={3}>
                    <TextField
                      {...register("amount", {
                        required: "amount is required",
                      })}
                      fullWidth
                      variant="standard"
                      label="Amount"
                      error={!!errors.amount}
                      helperText={errors.amount ? errors.amount.message : ""}
                      InputLabelProps={{
                        shrink: amountValue || isFocused
                      }}
                      onInput={handleInputChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}

                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      {...register("refNo")}
                      fullWidth
                      variant="standard"
                      label="referenceNo"
                      onInput={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="refDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Reference Date"
                          open={dateOpen}
                          onClose={() => setDateOpen(false)}
                          slotProps={{
                            textField: {
                              variant: "standard",
                              fullWidth: true,
                              readOnly: true,
                              onClick: () => setDateOpen(true),
                            },
                            openPickerButton: {
                              onClick: () => setDateOpen(true),
                            },
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          format="DD/MM/YYYY"
                          value={field.value || null}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      {...register("remarks")}
                      fullWidth
                      variant="standard"
                      label="Remarks"
                      onInput={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="modeOfPayment"
                      control={control}
                      rules={{ required: "mode Of Payment is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={modeOfPaymentOptions}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) => field.onChange(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Mode of Payment"
                              variant="standard"
                              error={!!errors.modeOfPayment}
                              helperText={
                                errors.modeOfPayment
                                  ? "mode Of Payment is required"
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {paymentMode === "Cheque" && (
                    <>
                      <Grid item xs={3}>
                        <Controller
                          name="drawnOn"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label="DrawnOn Date"
                              open={dateOpen1}
                              onClose={() => setDateOpen1(false)}
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  fullWidth: true,
                                  readOnly: true,
                                  onClick: () => setDateOpen1(true),
                                },
                                openPickerButton: {
                                  onClick: () => setDateOpen1(true),
                                },
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              format="DD/MM/YYYY"
                              value={field.value || null}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          name="chequeDraftDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label="ChequeDraft Date"
                              open={dateOpen2}
                              onClose={() => setDateOpen2(false)}
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  fullWidth: true,
                                  readOnly: true,
                                  onClick: () => setDateOpen2(true),
                                },
                                openPickerButton: {
                                  onClick: () => setDateOpen2(true),
                                },
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              format="DD/MM/YYYY"
                              value={field.value || null}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          {...register("chequeDraftNumber")}
                          fullWidth
                          variant="standard"
                          label="ChequeDraft Number"
                          onInput={handleInputChange}
                        />
                      </Grid>
                    </>
                  )}
                  {paymentMode === "Upi" && (
                    <Grid item xs={3}>
                      <TextField
                        {...register("transactionNumber")}
                        fullWidth
                        variant="standard"
                        label="Transaction Number"
                        onInput={handleInputChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                        <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{background:"red !important"}} >
                          Cancel
                        </SoftButton>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                        <SoftButton type="submit" variant="contained" color="primary" >
                          Add
                        </SoftButton>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </form>
            </StyledPaperShadow>
          )}
          {currentTab === 1 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#11164b",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <Typography sx={{ color: "#d9d9d9" }}>
                      Total Records: {ReceiptDatagrid?.ReceiptData?.totalItems}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                    <Select
                      value={limit}
                      onChange={handleLimitChange}
                      sx={{
                        marginLeft: 1,
                        "& .MuiSelect-select": {
                          paddingTop: "1px !important",
                          paddingBottom: "1px !important",
                        },
                      }}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Select
                      value={modeOfPay}
                      onChange={(e) => setModeOfPay(e.target.value)}
                      displayEmpty
                      sx={{
                        marginLeft: 1,
                        width: 150,
                        "& .MuiSelect-select": {
                          paddingTop: "1px !important",
                          paddingBottom: "1px !important",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>Select Bill Type</MenuItem>
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"Advance"}>Advance</MenuItem>
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Select
                      value={receiptType}
                      onChange={(e) => setReceiptType(e.target.value)}
                      displayEmpty
                      sx={{
                        marginLeft: 1,
                        width: 180,
                        "& .MuiSelect-select": {
                          paddingTop: "1px !important",
                          paddingBottom: "1px !important",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>Select Receipt Type</MenuItem>
                      <MenuItem value={"Customer"}>Customer</MenuItem>
                      <MenuItem value={"Jobcard"}>Jobcard</MenuItem>
                    </Select>
                  </Box>

                  {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "1px",
                        paddingLeft: "10px",
                        marginTop: "2px"
                      }}
                      onClick={fetchItems}
                    >
                      <SoftButton
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </SoftButton>
                    </Box> */}

                </Box>

                <TextField
                  variant="standard"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSearchClick}>
                          <IoSearchSharp />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <ItemGroupDatagrid
                data={ReceiptDatagrid?.ReceiptData?.data || []}
                columns={columns}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <ToggleButtonGroup
                  exclusive
                  sx={{
                    display: "flex",
                    alignItems: "center",

                    "& .MuiToggleButtonGroup-middleButton": {
                      marginRight: "0px !important",
                      borderLeft: "1px solid #e5e5e5",
                    },
                    "& .MuiToggleButtonGroup-root": {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                >
                  <ToggleButton
                    value="previous"
                    onClick={handlePrevious}
                    disabled={offset === 0}
                  >
                    Previous
                  </ToggleButton>

                  {Array.from({ length: totalPages }, (_, index) => {
                    const page = index + 1;
                    const isFirstThreePages = page <= 3;
                    const isLastThreePages = page > totalPages - 3;
                    const isCurrentPage = offset / limit === index;

                    if (
                      isFirstThreePages ||
                      isLastThreePages ||
                      isCurrentPage
                    ) {
                      return (
                        <ToggleButton
                          key={page}
                          value={`page-${page}`}
                          onClick={() => handlePageClick(page)}
                          sx={{ margin: "0 5px" }}
                          selected={isCurrentPage}
                        >
                          {page}
                        </ToggleButton>
                      );
                    }

                    if (page === 4 && !isFirstThreePages) {
                      return (
                        <Typography
                          key="dots-before"
                          sx={{ margin: "0 5px", userSelect: "none" }}
                        >
                          ...
                        </Typography>
                      );
                    }

                    if (page === totalPages - 3 && !isLastThreePages) {
                      return (
                        <Typography
                          key="dots-after"
                          sx={{ margin: "0 5px", userSelect: "none" }}
                        >
                          ...
                        </Typography>
                      );
                    }

                    return null;
                  })}
                  <ToggleButton
                    value="next"
                    onClick={handleNext}
                    disabled={offset + limit >= totalItems}
                  >
                    Next
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          )}
          {open && (
            <StyledSnackBar
              open={true}
              message={snackBarMessage}
              status={dataStatusConfirm}
            />
          )}
        </StyledPaperShadow>
      </Box>
    </LocalizationProvider>
  );
};

export default Receipts;
