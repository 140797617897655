import { Autocomplete, Box, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { FaArrowCircleRight, FaSearchPlus } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { GiSpanner } from "react-icons/gi";
import { IoMdInformationCircle } from "react-icons/io";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import { MdAdd, MdEdit, } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import check from "../../../../src/assets/images/check.svg";
import MdDelete from "../../../../src/assets/images/del.svg";
import { useEditJobCardMutation, useGetAjcInsuranceMutation, useGetAllDsaAgentMutation, useGetAllPartsDetailsMutation, useGetAllRepairTypeMutation, useGetAllServiceTypeMutation, useGetAllSourceMutation, useGetAllVechileDetailsMutation, useGetAllVedorMutation, useGetJobCardDataMutation, useGetJobCardDatagridMutation, useGetLabourDetailsMutation, useGetOpenServiceEstimateMutation, useGetOslLabourDetailsMutation, useGetOtdFailureQuery, useGetTransactionSubQuery, useGetsearchLabourDetailsMutation, useGetsearchOslLabourDetailsMutation, useGetsearchPartsDetailsMutation, useLazyJobCardPdfQuery, useSubmitOpenEstimateMutation, useSubmitSourceMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const CreateJobcard = () => {
  const { register, handleSubmit, reset, setValue, setError, formState: { errors, isValid }, clearErrors, control, getValues, trigger } = useForm({
    defaultValues: {
      registrationNumber: "",
      documentType: { label: '', value: '' },
      labor: [{
        additionalMargin: 0,
        discountAmount: 0
      }],

    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;
  const navigate = useNavigate();

  const [addJobCardData, { isSuccess: JobCardSuccess }] = useGetJobCardDataMutation();
  const [getJobCardDatagrid, { data: JobCardDatagrid }] = useGetJobCardDatagridMutation();
  const [editJobCardData, { isSuccess: editJobCardSuccess }] = useEditJobCardMutation();

  const [getAllVehicleData,] = useGetAllVechileDetailsMutation();
  const [getAllsourceData, { data: sourceData }] = useGetAllSourceMutation();
  const [submitSource, { data: submitSourceData }] = useSubmitSourceMutation();
  const [getAllServiceTypeData, { data: serviceTypeData }] = useGetAllServiceTypeMutation();
  const [getAllRepairTypeData, { data: repairTypeData }] = useGetAllRepairTypeMutation();
  const [getAllVendorData, { data: allVendorData }] = useGetAllVedorMutation();
  const [getAllDsaData, { data: allDsaAgentData }] = useGetAllDsaAgentMutation();

  const { data: allOtdFailureData } = useGetOtdFailureQuery();
  const { data: allTransactionData } = useGetTransactionSubQuery();
  const [getAllOpenEstimateData, { data: OpenEstimates }] = useGetOpenServiceEstimateMutation();
  const [getAllLabourDetailsData, { data: LabourDetails }] = useGetLabourDetailsMutation();
  const [searchLabourData] = useGetsearchLabourDetailsMutation();
  const [getAllOslLabourDetailsData, { data: OslLabourDetails }] = useGetOslLabourDetailsMutation();
  const [searchOslLabourData] = useGetsearchOslLabourDetailsMutation();
  const [getAllPartsData, { data: PartsDetails }] = useGetAllPartsDetailsMutation();
  const [searchPartsData] = useGetsearchPartsDetailsMutation();
  const [getJobCardPdfDetails] = useLazyJobCardPdfQuery();
  const [SubmitOpenServiceEstimate, { data: SubmitEstimateData, isSuccess: EstimateSuccess }] = useSubmitOpenEstimateMutation();
  const [getAjcInsurance] = useGetAjcInsuranceMutation();
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [isCreating, setIsCreating] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [VehicleIdData, setVehicleIdData] = useState('');
  const [paidByStatus, setpaidByStatus] = useState(1);
  const sources = sourceData?.sourceData || [];
  const sourceType = submitSourceData?.sourceTypes || [];
  const serviceTypes = serviceTypeData?.serviceTypeData || [];
  const repairTypes = repairTypeData?.repairTypeData || [];
  const vendors = allVendorData?.vendorData || [];
  const TransactionSubStatus = allTransactionData?.TransactionSubstatusData || [];
  const OTDFailureReasons = allOtdFailureData?.OTDFailureReasonsData || [];
  const DsaAgents = allDsaAgentData?.DsaAgentsData || [];
  const OpenEstimateData = OpenEstimates?.OpenEstimatesData || [];

  const [optionData, setOptionData] = useState([]);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [oslOptionData, setOslOptionData] = useState([]);
  const [OslcurrentRowIndex, setOslCurrentRowIndex] = useState(null);
  const [partsOptionData, setPartsOptionData] = useState([]);
  const [PartsCurrentRowIndex, setPartsCurrentRowIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [registrationNo, setRegistrationNumber] = useState("");
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [dateOpen1, setDateOpen1] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedSourceType, setSelectedSourceType] = useState(null);

  useEffect(() => {
    getAllsourceData();
    getAllServiceTypeData();
    getAllRepairTypeData();
    getAllVendorData();
    getAllDsaData();
  }, [])

  const inputRef = useRef(null);
  const oslInputRef = useRef(null);

  const handleOpenPopup = () => {
    setOpenPopup(true);
    getAllOpenEstimateData()
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleServiceEstimateChange = (event, value) => {
    if (value) {
      setCustomerCode(value.customerCode);
      setRegistrationNumber(value.registrationNumber);
    } else {
      setCustomerCode("");
      setRegistrationNumber("");
    }
  };

  const handlePopupSubmit = async () => {
    const selectedServiceEstimate = getValues("openServiceEstimate");
    handleClosePopup();
    setBackDropOpen(true)
    if (selectedServiceEstimate) {
      const payload = {
        selectedServiceEstimate
      };

      try {
        const response = await SubmitOpenServiceEstimate(payload);

        if (response && response?.data?.requestSuccessful === true) {
          setCurrentTab(0);
          setValue('registrationNumber', response.data.EstimateData.registrationNumber);
          setBackDropOpen(false)
        }
      } catch (error) {
        console.error("Error submitting service estimate:", error);
      }
    } else {
      console.error("No service estimate selected");
    }

    reset({
      openServiceEstimate: null,
    });
    setCustomerCode("");
    setRegistrationNumber("");
  };

  const updateFormData = async () => {
    const EstimateData = SubmitEstimateData?.EstimateData;
    if (EstimateData !== undefined) {
      // Initialize the form data object
      const formData = {
        registrationNumber: EstimateData.registrationNumber,
        odometer: EstimateData.odometer,
        documentType: EstimateData.jobType === "SQRT"
          ? { label: 'RJC-Regular Job Card', value: 'RJC' }
          : { label: 'AJC-Accident Job Card', value: 'AJC' },
        serviceTypeId: serviceTypes?.find(serviceType => serviceType.serviceTypeName === EstimateData.serviceType) || null,
        repairTypeId: repairTypes?.find(repairType => repairType.repairTypeName === EstimateData.repairType) || null,
        sourceId: sources?.find(source => source.sourceName === EstimateData.source) || null,
        labor: [],
        oslLabor: [],
        parts: []
      };

      // Labor data
      EstimateData?.labourEstimate.forEach((labor, index) => {
        const repairType = repairTypes.find(rt => rt.id === labor.repairTypeId) || repairTypes[0];
        const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;

        formData.labor.push({
          laborCode: { laborCode: labor.laborCode, id: labor.laborId },
          laborDescription: labor.laborDescription,
          repairType: repairType,
          quantity: labor.quantity,
          rate: labor.rate,
          additionalMargin: labor.additionalMargin,
          discountAmount: labor.discountAmount,
          singleAmount: labor.singleAmount,
          sgst: labor.sgst,
          cgst: labor.cgst,
          igst: labor.igst,
          laborTotal: labor.laborTotal,
          sacCode: labor.sacCode,
          taxPercentage: taxPercentage
        });
      });

      // OSL data
      EstimateData?.oslLabourEstimate.forEach((labor, index) => {
        const supplier = vendors.find(rt => rt.id === labor.vendorId) || vendors[0];
        const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;

        formData.oslLabor.push({
          supplierCode: supplier,
          laborCode: { laborCode: labor.laborCode, id: labor.laborId },
          laborDescription: labor.laborDescription,
          quantity: labor.quantity,
          rate: labor.rate,
          additionalMargin: labor.additionalMargin,
          discountAmount: labor.discountAmount,
          sgst: labor.sgst,
          cgst: labor.cgst,
          igst: labor.igst,
          laborTotal: labor.laborTotal,
          sacCode: labor.sacCode,
          taxPercentage: taxPercentage
        });
      });

      // Parts data
      EstimateData?.partEstimate.forEach((part, index) => {
        const taxPercentage = part.igst !== 0 ? part.igst : part.sgst + part.cgst;
        formData.parts.push({
          partNo: { itemCode: part.partNo, id: part.partId },
          partDescription: part.partDescription,
          hsnCode: part.hsnCode,
          rate: part.rate,
          requestedQuantity: part.requestedQuantity,
          receivedQuantity: part.received_quantity,
          returnQuantity: part.return_quantity,
          sgst: part.sgst,
          cgst: part.cgst,
          igst: part.igst,
          partTotal: part.partTotal,
          taxPercentage: taxPercentage
        });
      });

      // Use reset to update all values in one go
      reset(formData);

      await trigger(['sourceId', 'sourceTypeId']);
      const selectedSourceData = sources?.find(source => source.sourceName === EstimateData.source) || null;
      if (selectedSourceData) {
        setSelectedSource(selectedSourceData);
        const payload = { sourceId: selectedSourceData.id };
        const sourceResponse = await submitSource(payload);

        if (sourceResponse?.data?.requestSuccessful) {
          const sourceType = sourceResponse?.data?.sourceTypes;
          const selectedSourceType = sourceType?.find(sourceType => sourceType.sourceTypeName === EstimateData.sourceType);

          if (selectedSourceType) {
            setSelectedSourceType(selectedSourceType);
            reset({ ...formData, sourceTypeId: selectedSourceType });
            await trigger('sourceTypeId');
          }
        } else {
          console.log('Failed to fetch source types');
        }
      }

      handleVehicleData(EstimateData?.registrationNumber);
    }
  };


  // Trigger the data handling when the data arrives
  useEffect(() => {
    updateFormData();
  }, [EstimateSuccess]);


  //labour details
  const { fields, append, remove } = useFieldArray({
    control,
    name: "labor",
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ laborCode: "", laborDescription: "", quantity: "", rate: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", laborTotal: "", });

    }

  }, [fields, append]);

  //OSL labour details
  const { fields: oslFields, append: appendOsl, remove: removeOsl } = useFieldArray({
    control,
    name: "oslLabor",
  });

  useEffect(() => {
    if (oslFields.length === 0) {
      appendOsl({ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" });
    }
  }, [oslFields, appendOsl]);

  //Parts details
  const { fields: partsFields, append: appendParts, remove: removeParts } = useFieldArray({
    control,
    name: "parts",
  });

  useEffect(() => {
    if (partsFields.length === 0) {
      appendParts({ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", partTotal: "" });
    }
  }, [partsFields, appendParts]);

  useEffect(() => {
    if (vendors.length > 0) {
      setValue(`oslLabor[${0}].supplierCode`, vendors[0])
    }

  }, [vendors])

  const handleSearch = async (laborCode, index) => {
    if (laborCode <= 2) {
      setOptionData([]);
      setValue(`labor[${index}].laborDescription`, '');
    }
    if (laborCode.length >= 3) {
      const payload = {
        laborCode,
      };
      const response = await searchLabourData(payload);
      if (response.data.requestSuccessful) {
        setOptionData(response?.data?.labourSearchData);
        setCurrentRowIndex(index);
      }
    }
  };

  const handleSearchOsl = async (laborCode, index) => {
    if (laborCode <= 2) {
      setOslOptionData([]);
      setValue(`oslLabor[${index}].laborDescription`, '');
    }
    if (laborCode.length >= 3) {
      const payload = {
        laborCode,
      };
      const response = await searchOslLabourData(payload);
      if (response.data.requestSuccessful) {
        setOslOptionData(response?.data?.oslLabourSearchData);
        setOslCurrentRowIndex(index);
      }
    }
  };

  const handleSearchParts = async (itemCode, index) => {
    if (itemCode <= 2) {
      setPartsOptionData([]);
      setValue(`parts[${index}].partDescription`, '');
    }
    if (itemCode.length >= 3) {
      const payload = {
        itemCode,
      };
      const response = await searchPartsData(payload);
      if (response.data.requestSuccessful) {
        setPartsOptionData(response?.data?.itemSearchData);
        setPartsCurrentRowIndex(index);
      }
    }
  };

  useEffect(() => {
    fetchItems(offset, searchValue);
    setBackDropOpen(true)
  }, [offset, limit, JobCardSuccess, editJobCardSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey
    };
    const response = await getJobCardDatagrid(payload);
    if (response?.data?.requestSuccessful === true) {
      setBackDropOpen(false)
    }
    setTotalItems(response?.data?.JobCardData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };

  useEffect(() => {
    if (LabourDetails?.requestSuccessful === true && LabourDetails.laborScheduleData.length > 0 && currentRowIndex !== null) {
      const { sacCode, laborDescription, sgst, cgst, igst, singleAmount, taxPercentage } = LabourDetails.laborScheduleData[0];
      setValue(`labor[${currentRowIndex}].laborDescription`, laborDescription);
      setValue(`labor[${currentRowIndex}].cgst`, cgst);
      setValue(`labor[${currentRowIndex}].sgst`, sgst);
      setValue(`labor[${currentRowIndex}].igst`, igst);
      setValue(`labor[${currentRowIndex}].singleAmount`, singleAmount);
      setValue(`labor[${currentRowIndex}].sacCode`, sacCode);
      setValue(`labor[${currentRowIndex}].taxPercentage`, taxPercentage);
      setValue(`labor[${currentRowIndex}].quantity`, 1);

      const rate = (1) * singleAmount;
      setValue(`labor[${currentRowIndex}].rate`, rate);
      const labourTotal = ((rate * taxPercentage) / 100) + rate;
      setValue(`labor[${currentRowIndex}].laborTotal`, labourTotal);

    }
    else if (LabourDetails?.requestSuccessful === false) {
      setDataStatusConfirm("Error");
      let message = LabourDetails?.validationErrors;
      setSnackBarMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [LabourDetails, currentRowIndex, setValue]);

  useEffect(() => {
    if (OslLabourDetails?.requestSuccessful === true && OslLabourDetails.laborScheduleData.length > 0 && OslcurrentRowIndex !== null) {
      const { sacCode, taxPercentage, laborDescription, sgst, cgst, igst } = OslLabourDetails.laborScheduleData[0];
      setValue(`oslLabor[${OslcurrentRowIndex}].laborDescription`, laborDescription);
      setValue(`oslLabor[${OslcurrentRowIndex}].cgst`, cgst);
      setValue(`oslLabor[${OslcurrentRowIndex}].sgst`, sgst);
      setValue(`oslLabor[${OslcurrentRowIndex}].igst`, igst);
      setValue(`oslLabor[${OslcurrentRowIndex}].quantity`, 1);
      setValue(`oslLabor[${OslcurrentRowIndex}].sacCode`, sacCode);
      setValue(`oslLabor[${OslcurrentRowIndex}].taxPercentage`, taxPercentage);
    }
    else if (OslLabourDetails?.requestSuccessful === false) {
      setDataStatusConfirm("Error");
      let message = OslLabourDetails?.validationErrors;
      setSnackBarMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [OslLabourDetails, OslcurrentRowIndex, setValue]);

  useEffect(() => {
    if (PartsDetails?.requestSuccessful && PartsDetails.itemsData.length > 0 && PartsCurrentRowIndex !== null) {
      const { taxPercentage, itemName, hsnCode, sgst, cgst, igst, list } = PartsDetails.itemsData[0];
      setValue(`parts[${PartsCurrentRowIndex}].partDescription`, itemName);
      setValue(`parts[${PartsCurrentRowIndex}].cgst`, cgst);
      setValue(`parts[${PartsCurrentRowIndex}].hsnCode`, hsnCode);
      setValue(`parts[${PartsCurrentRowIndex}].sgst`, sgst);
      setValue(`parts[${PartsCurrentRowIndex}].igst`, igst);
      setValue(`parts[${PartsCurrentRowIndex}].rate`, list);
      setValue(`parts[${PartsCurrentRowIndex}].requestedQuantity`, 1);
      setValue(`parts[${PartsCurrentRowIndex}].taxPercentage`, taxPercentage);
    }
    else if (PartsDetails?.requestSuccessful === false) {
      setDataStatusConfirm("Error");
      let message = PartsDetails?.validationErrors;
      setSnackBarMessage(message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [PartsDetails, PartsCurrentRowIndex, setValue]);

  //calculations for labourschedule
  const handleChange = (index) => {
    const quantity = parseFloat(getValues(`labor[${index}].quantity`)) || 1;
    const additionalMargin = parseFloat(getValues(`labor[${index}].additionalMargin`)) || 0;
    const discountAmount = parseFloat(getValues(`labor[${index}].discountAmount`)) || 0;
    const amount = parseFloat(getValues(`labor[${index}].singleAmount`)) || 0;
    const depreciation = parseFloat(getValues(`labor[${index}].depreciation`)) || 0;
    const tax = parseFloat(getValues(`labor[${index}].taxPercentage`)) || 0;

    let rate = quantity * amount;
    if (additionalMargin) {
      rate += additionalMargin;
    }

    if (discountAmount >= rate) {
      setError(`labor[${index}].discountAmount`, {
        type: 'manual',
        message: 'Discount amount cannot be greater than total rate'
      });
    } else {
      clearErrors(`labor[${index}].discountAmount`);
      rate -= discountAmount;
    }

    if (depreciation >= 0) {
      const cusAmount = (((quantity * amount) + additionalMargin - discountAmount) * depreciation / 100)
      setValue(`labor[${index}].customerAmount`, parseFloat(cusAmount));

      const insAmount = (((quantity * amount) + additionalMargin - discountAmount) - cusAmount)
      setValue(`labor[${index}].insuranceAmount`, parseFloat(insAmount));
    }
    const labourTotal = (((rate * tax) / 100) + rate).toFixed(2);
    setValue(`labor[${index}].laborTotal`, parseFloat(labourTotal));
  };


  const watchFields = useWatch({ control, name: "oslLabor" });

  const memoizedOslFields = useMemo(() => oslFields, [oslFields]);
  const memoizedWatchFields = useMemo(() => JSON.stringify(watchFields), [watchFields]);

  //calculations for oslLabourschedule
  useEffect(() => {
    memoizedOslFields.forEach((field, index) => {
      if (watchFields && watchFields[index]) {
        const quantity = parseFloat(watchFields[index]?.quantity) || 0;
        const rate = parseFloat(watchFields[index]?.rate) || 0;
        const depreciation = parseFloat(watchFields[index]?.depreciation) || 0;
        const oslTax = parseFloat(watchFields[index]?.taxPercentage) || 0;

        let newTotal = quantity * rate;

        const additionalMargin = parseFloat(watchFields[index]?.additionalMargin) || 0;
        const discountAmount = parseFloat(watchFields[index]?.discountAmount) || 0;

        if (additionalMargin) {
          newTotal += additionalMargin;
        }

        if (discountAmount) {
          if (discountAmount >= newTotal) {
            setError(`oslLabor[${index}].discountAmount`, {
              type: 'manual',
              message: 'please enter value less than rate'
            });
          } else {
            clearErrors(`oslLabor[${index}].discountAmount`);
            newTotal -= discountAmount;
          }
        }

        if (depreciation >= 0) {
          const totalWithMarginAndDiscount = (quantity * rate) + additionalMargin - discountAmount;
          const cusAmount = (totalWithMarginAndDiscount * depreciation) / 100;
          setValue(`oslLabor[${index}].customerAmount`, parseFloat(cusAmount.toFixed(2)));
          const insAmount = totalWithMarginAndDiscount - cusAmount;
          setValue(`oslLabor[${index}].insuranceAmount`, parseFloat(insAmount.toFixed(2)));
        }

        const labourTotal = (((newTotal * oslTax) / 100) + newTotal).toFixed(2);
        setValue(`oslLabor[${index}].laborTotal`, parseFloat(labourTotal));
      }
    });
  }, [memoizedWatchFields, memoizedOslFields, setValue, setError, clearErrors]);

  const watchPartsFields = useWatch({ control, name: "parts" });

  useEffect(() => {
    partsFields.forEach((field, index) => {
      // Check if watchFields[index] is defined
      if (watchPartsFields && watchPartsFields[index]) {
        const quantity = parseFloat(watchPartsFields[index]?.requestedQuantity) || 0;
        const rate = parseFloat(watchPartsFields[index]?.rate) || 0;
        const PartsTax = parseFloat(watchPartsFields[index]?.taxPercentage) || 0;

        let newTotal = quantity * rate;

        const additionalMargin = parseFloat(watchPartsFields[index]?.additionalMargin) || 0;
        const discountAmount = parseFloat(watchPartsFields[index]?.discountAmount) || 0;

        // Add additionalMargin if provided
        if (additionalMargin) {
          newTotal += additionalMargin;
        }

        // Subtract discountAmount if provided
        if (discountAmount) {
          if (discountAmount >= newTotal) {
            setError(`parts[${index}].discountAmount`, {
              type: 'manual',
              message: 'please enter value less than rate'
            });
          } else {
            clearErrors(`parts[${index}].discountAmount`);
            newTotal -= discountAmount;
          }
        }

        const labourTotal = (((newTotal * PartsTax) / 100) + newTotal).toFixed(2);
        setValue(`parts[${index}].partTotal`, parseFloat(labourTotal));
      }
    });
  }, [JSON.stringify(watchPartsFields), setValue, setError, clearErrors, partsFields]);

  const onSubmit = async (data) => {
    const laborEstimateWithIds = data.labor.map(item => ({
      ...item,
      laborCode: item.laborCode.laborCode,
      laborId: item.laborCode.id,
      repairType: item.repairType || repairTypes[0],
    }));
    const PartsNoWithIds = data.parts.map(item => ({
      ...item,
      partNo: item.partNo.itemCode,
      partId: item.partNo.id,
    }));
    const oslLaborEstimateWithIds = data.oslLabor.map(item => ({
      ...item,
      laborCode: item.laborCode.laborCode,
      laborId: item.laborCode.id,
      supplierCode: item.supplierCode || vendors[0]
    }));

    const formData = {

      ...data,
      customerId: VehicleIdData.customeId,
      vehicleId: VehicleIdData.vehicleId,
      customerStatus: VehicleIdData.customerStatus,
      sourceId: data?.sourceId.id,
      sourceTypeId: data?.sourceTypeId.id,
      serviceTypeId: data?.serviceTypeId.id,
      repairTypeId: data?.repairTypeId.id,
      documentType: data?.documentType.value,
      labor: laborEstimateWithIds,
      parts: PartsNoWithIds,
      oslLabor: oslLaborEstimateWithIds,
      serviceEstimateId: SubmitEstimateData?.EstimateData?.id,
      serviceEstimateNumber: SubmitEstimateData?.EstimateData?.serviceEstimateNumber,
      expectedWorkCompletion: dayjs(data?.expectedWorkCompletion).format('YYYY-MM-DD HH:mm:ss'),
      customerArrivedDate: dayjs(data?.customerArrivedDate).format('YYYY-MM-DD HH:mm:ss'),
      paidByStatus: paidByStatus,
    };

    let response;
    if (isEditing) {
      response = await editJobCardData({ id: currentItemId, status: statusId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
        setCurrentItemId(null);
        setStatusId(null)
      }
    } else {
      response = await addJobCardData(formData);
    }
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        resetForm();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const resetForm = () => {
    reset({
      registrationNumber: "",
      odometer: "",

      pincode: "",
      labor: [{ laborCode: "", repairType: "", laborDescription: "", quantity: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" }],
      oslLabor: [{ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" }],
      parts: [{ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", partTotal: "" }]
    });

    setIsEditing(false);
    setCurrentTab(1);
    setSelectedSourceType(null)
    setSelectedSource(null)
  };

  const handleRegisterNumberChange = async (event) => {
    const regNumber = event.target.value.toUpperCase().slice(0, 10);
    setValue('registrationNumber', regNumber);

    if (regNumber.length === 10) {
      await handleVehicleData(regNumber);
    }
  };
  const handleVehicleData = async (regNumber) => {
    const payLoad = {
      registrationNumber: regNumber
    };
    const response = await getAllVehicleData(payLoad);
    if (response?.data?.requestSuccessful) {
      setVehicleIdData(response.data.vehicleData[0]);
      if (response.data.vehicleData[0]) {
        setDataStatusConfirm("Success");
        let message = response.data.vehicleData[0].message;
        setSnackBarMessage(message);
      } else {
        setDataStatusConfirm("Error");
        let message = response.data.vehicleData[0].message;
        setSnackBarMessage(message);
      }

      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  };

  const handleEditClick = async (row) => {

    handleVehicleData(row.reg_no)
    setpaidByStatus(row.paid_by_status)
    setValue('registrationNumber', row.reg_no);
    setValue('odometer', row.odometer);
    setValue('customerVoice', row.customer_voice);
    setValue('serviceEngineerRemarks', row.service_engineer_remarks);
    setValue('serviceAdvice', row.service_advice);
    setValue('TransactionSubStatusReason', row.sub_status_reason);

    const workEndDate = row.work_end_date_time ? dayjs(row.work_end_date_time, "DD-MM-YYYY HH:mm:ss") : null;
    const customerArrivedDate = row.customer_arrived_date ? dayjs(row.customer_arrived_date, "DD-MM-YYYY HH:mm:ss") : null;
    setValue('expectedWorkCompletion', workEndDate);
    setValue('customerArrivedDate', customerArrivedDate);

    const selecteddocumentType = documentTypeOptions.find(option => option.value === row.document_type);
    setValue('documentType', selecteddocumentType || null);

    const selectedSourceData = sources?.find(source => source.id === row.source);
    if (selectedSourceData) {
      setSelectedSource(selectedSourceData);
      setValue('sourceId', selectedSourceData);

      const payload = { sourceId: selectedSourceData.id };
      const sourceResponse = await submitSource(payload);

      if (sourceResponse?.data?.requestSuccessful) {
        const sourceType = sourceResponse?.data?.sourceTypes;
        const selectedSourceType = sourceType?.find(sourceType => sourceType.id === row.source_type);

        if (selectedSourceType) {
          setSelectedSourceType(selectedSourceType);
          setValue('sourceTypeId', selectedSourceType);
        }
      } else {
        console.log('Failed to fetch model data');
      }
    }

    const selectedServiceType = serviceTypes?.find(serviceType => serviceType.id === row.service_type);
    setValue('serviceTypeId', selectedServiceType || null);

    const selectedRepairType = repairTypes?.find(repairType => repairType.id === row.repair_type);
    setValue('repairTypeId', selectedRepairType || null);

    const selectedOtdFailureReason = OTDFailureReasons?.find(OtdFailure => OtdFailure.reason === row.otd_reason);
    setValue('OtdFailureReason', selectedOtdFailureReason || null);

    const selectedDsaAgent = DsaAgents?.find(dsaAgent => dsaAgent.dsaCode === row.dsa_agent);
    setValue('dsaAgent', selectedDsaAgent || null);

    const selectedTransactionSubStatus = TransactionSubStatus?.find(subStatus => subStatus.title === row.sub_status);
    setValue('TransactionSubStatus', selectedTransactionSubStatus || null);


    row?.schedules?.forEach((labor, index) => {
      if (fields.length < row.schedules.length) {
        append({
          laborCode: "",
          laborDescription: "",
          quantity: "",
          rate: "",
          additionalMargin: 0,
          discountAmount: 0,
          sgst: "",
          cgst: "",
          igst: "",
          laborTotal: "",
          repairType: "",
          depreciation: "",
          customerAmount: "",
          insuranceAmount: "",
        });
      }
      setValue(`labor[${index}].laborCode`, { laborCode: labor.rot_code, id: labor.rot_id });
      setValue(`labor[${index}].laborDescription`, labor.description);
      const repairType = repairTypes.find(rt => rt.id === labor.repairTypeId) || repairTypes[0];
      setValue(`labor[${index}].repairType`, repairType);
      setValue(`labor[${index}].quantity`, labor.quantity);
      setValue(`labor[${index}].rate`, labor.amount);
      setValue(`labor[${index}].additionalMargin`, labor.additionalMargin);
      setValue(`labor[${index}].discountAmount`, labor.discount_percentage);
      setValue(`labor[${index}].singleAmount`, labor.singleAmount);
      setValue(`labor[${index}].sgst`, labor.sgst);
      setValue(`labor[${index}].cgst`, labor.cgst);
      setValue(`labor[${index}].igst`, labor.igst);
      setValue(`labor[${index}].laborTotal`, labor.laborTotal);
      setValue(`labor[${index}].sacCode`, labor.sacCode);
      const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;
      setValue(`labor[${index}].taxPercentage`, taxPercentage);
      setValue(`labor[${index}].depreciation`, labor.depreciation_per);
      setValue(`labor[${index}].customerAmount`, labor.customer_amount);
      setValue(`labor[${index}].insuranceAmount`, labor.insurance_amount);

    });

    row?.oslSchedules?.forEach((labor, index) => {
      if (oslFields.length < row.oslSchedules.length) {
        appendOsl({
          supplierCode: "",
          laborCode: "",
          laborDescription: "",
          quantity: "",
          additionalMargin: 0,
          discountAmount: 0,
          sgst: "",
          cgst: "",
          igst: "",
          laborTotal: "",
          rate: "",
          depreciation: "",
          customerAmount: "",
          insuranceAmount: "",

        })
      }
      const supplier = vendors.find(rt => rt.id === labor.vendorId) || vendors[0]
      setValue(`oslLabor[${index}].supplierCode`, supplier);
      setValue(`oslLabor[${index}].laborCode`, { laborCode: labor.rot_code, id: labor.rot_id });
      setValue(`oslLabor[${index}].laborDescription`, labor.description);
      setValue(`oslLabor[${index}].quantity`, labor.quantity);
      setValue(`oslLabor[${index}].rate`, labor.amount);
      setValue(`oslLabor[${index}].additionalMargin`, labor.additionalMargin);
      setValue(`oslLabor[${index}].discountAmount`, labor.discount_percentage);
      setValue(`oslLabor[${index}].sgst`, labor.sgst);
      setValue(`oslLabor[${index}].cgst`, labor.cgst);
      setValue(`oslLabor[${index}].igst`, labor.igst);
      setValue(`oslLabor[${index}].laborTotal`, labor.laborTotal);
      setValue(`oslLabor[${index}].sacCode`, labor.sacCode);
      const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;
      setValue(`oslLabor[${index}].taxPercentage`, taxPercentage);
      setValue(`oslLabor[${index}].depreciation`, labor.depreciation_per);
      setValue(`oslLabor[${index}].customerAmount`, labor.customer_amount);
      setValue(`oslLabor[${index}].insuranceAmount`, labor.insurance_amount);

    });

    row.partsIndent.forEach((labor, index) => {
      if (partsFields.length < row.partsIndent.length) {
        appendParts({
          partNo: "",
          partDescription: "",
          hsnCode: "",
          quantity: "",
          rate: "",
          additionalMargin: 0,
          discountAmount: 0,
          sgst: "",
          cgst: "",
          igst: "",
          partTotal: ""
        })
      }

      setValue(`parts[${index}].partNo`, { itemCode: labor.item_code, id: labor.item_id });
      setValue(`parts[${index}].partDescription`, labor.item_name);
      setValue(`parts[${index}].hsnCode`, labor.hsn_code);
      setValue(`parts[${index}].rate`, labor.amount);
      setValue(`parts[${index}].requestedQuantity`, labor.request_quantity);
      setValue(`parts[${index}].receivedQuantity`, labor.received_quantity);
      setValue(`parts[${index}].returnQuantity`, labor.return_quantity);
      setValue(`parts[${index}].sgst`, labor.sgst);
      setValue(`parts[${index}].cgst`, labor.cgst);
      setValue(`parts[${index}].igst`, labor.igst);
      setValue(`parts[${index}].partTotal`, labor.part_total);
      const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;
      setValue(`parts[${index}].taxPercentage`, taxPercentage);
    });

    setIsEditing(true);
    setCurrentTab(0);
    setCurrentItemId(row.id);
    setStatusId(row.status);
    clearErrors(['pincode', 'odometer', 'customerVoice', 'registrationNumber', 'serviceEngineerRemarks']);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };
  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'document_type',
        headerName: 'Document Type',
        flex: 1,
      },
      {
        field: 'reg_no',
        headerName: 'Registration Number',
        flex: 1,
      },
      {
        field: 'job_card_no',
        headerName: 'Job CardNo',
        flex: 1,
      },
      {
        field: 'customer_name',
        headerName: 'Customer Name',
        flex: 1,
      },
      {
        field: 'status_value',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
          let background = '';

          switch (params.value) {
            case 'Open':
              background = '#ff0000';
              break;
            case 'Ready For Billing':
              background = '#1b8c0a';
              break;
            case 'Work In Progress':
              background = '#e7c018';
              break;
            case 'Billing':
              background = '#1b8c0a';
              break;
            default:
              background = 'gray';
          }

          return (
            <Chip
              label={
                <>
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '8px',
                      color: "#ffffd6 !important"
                    }}
                  />
                  {params.value}
                </>
              }
              style={{
                textTransform: 'capitalize',
                backgroundColor: background,
                color: '#fff', // Ensure text contrast is readable
                width: "150px",
              }}
            />
          );
        },
      }

    ];
    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <div style={{ display: 'flex' }}>
            <Tooltip title="Edit">
              <span>
                <MdEdit onClick={() => handleEditClick(params?.row)} style={{ fontSize: '1.1rem', cursor: 'pointer', marginRight: '10px' }} />
              </span>
            </Tooltip>
            {params?.row?.document_type === "AJC" && (
              <Tooltip title="Insurance">
                <span>
                  <IoMdInformationCircle onClick={() => handleInsuranceClick(params?.row)} style={{ cursor: 'pointer', fontSize: '1.3rem', marginRight: '10px' }} />
                </span>
              </Tooltip>
            )}
            <Tooltip title="Mechanic Mapping">
              <span>
                <GiSpanner onClick={() => handleMechanicClick(params?.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
              </span>
            </Tooltip>
            <Tooltip title="View">
              <span>
                <FaSearchPlus onClick={() => handleViewClick(params?.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
              </span>
            </Tooltip>
            <Tooltip title="Pdf">
              <span>
                <FaFilePdf onClick={() => handlePdfClick(params?.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
              </span>
            </Tooltip>
          </div>
        ),
        cellClassName: 'sticky-action-column',
        headerClassName: 'sticky-action-column-header',
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsCreating(true);
    } else {
      setIsCreating(false);
      resetForm();
    }
    setCurrentTab(newValue);
  };

  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const documentTypeOptions = [
    { label: 'RJC-Regular Job Card', value: 'RJC' },
    { label: 'AJC-Accident Job Card', value: 'AJC' },
  ];
  const handleViewClick = (rowData) => {
    navigate('/createJobcard/jobCardViewPage', { state: { rowData } });
  };
  const handleInsuranceClick = async (rowData) => {
    const payload = {
      transactionId: rowData.id
    }
    let response = await getAjcInsurance(payload)
    if (response && response.data.requestSuccessful === true) {
      const insuranceData = response.data.InsuranceData
      navigate('/createJobcard/insurance', { state: { insuranceData } });
    }

    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        reset();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        reset();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const handleMechanicClick = (rowData) => {
    navigate('/createJobcard/mechanicMapping', { state: { rowData } });
  };
  const selectedInputType = useWatch({
    control,
    name: 'documentType'
  });

  const showInputValues = useMemo(() => {
    return selectedInputType?.value === "AJC";
  }, [selectedInputType]);

  const selectedSourceData = useWatch({
    control,
    name: 'sourceTypeId'
  });

  const showDsaValues = useMemo(() => {
    return selectedSourceData?.sourceTypeName === "DSA";
  }, [selectedSourceData]);

  const handlePdfClick = async (rowData) => {
    const id = rowData.id;
    try {
      const pdfBlob = await getJobCardPdfDetails(id)

      if (pdfBlob?.data !== undefined) {
        const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `JobCard${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(pdfUrl);
        setDataStatusConfirm("Success");
        const message = "pdf is downloaded";
        setSnackBarMessage(message);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error fetching PDF details:', error);
    }

  };
  const onBackClick = () => {
    setCurrentTab(1)
    resetForm();
  };

  const Cancelled = () => {
    setCurrentTab(1)
    resetForm();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <StyledBreadcrumb
          mainDiv="Transaction"
          subDiv="Create Jobcard"
        ></StyledBreadcrumb>
        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
            <Tabs value={currentTab} onChange={handleTabChange}
              sx={{ '&  .MuiTabs-indicator': { backgroundColor: "#e96b20" } }}
            >
              {hasCreatePermission ? (
                <Tab label="Create Jobcard" />
              ) : (isEditing && !hasCreatePermission) ? (
                <Tab label="Update Jobcard" />
              ) : null}
              {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Jobcard" />)}

            </Tabs>
          </Box>
          {currentTab === 0 && (
            <Box>
              {!isEditing && (
                <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                  <SoftButton variant="contained" sx={{ width: "200px" }} onClick={onBackClick}>
                    Jobcard <FaArrowCircleRight style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                  </SoftButton>
                </Box>
              )}
              <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        {...register('registrationNumber', {
                          required: 'Registration Number is required',
                          maxLength: {
                            value: 10,
                            message: 'Registration Number should not exceed 10 characters'
                          },
                          pattern: {
                            value: /^[A-Z0-9]*$/,
                            message: 'Registration Number should only contain uppercase letters and numbers'
                          }
                        })}
                        fullWidth
                        variant="standard"
                        label="Registration Number"
                        InputLabelProps={isEditing ? { shrink: true } : {}}
                        error={!!errors.registrationNumber}
                        helperText={errors.registrationNumber ? errors.registrationNumber.message : ''}
                        onChange={handleRegisterNumberChange}
                        inputProps={{ maxLength: 10, readOnly: true, }}
                        onInput={handleInputChange}

                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="documentType"
                        control={control}
                        rules={{ required: 'documentType is required' }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={documentTypeOptions}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => field.onChange(value)}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            disabled
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Document Type"
                                variant="standard"
                                error={!!errors.documentType}
                                helperText={errors.documentType ? "Document Type is required" : ""}

                              />
                            )}
                            value={field.value || null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="expectedWorkCompletion"
                        control={control}
                        rules={{ required: 'Expected Work Completion Date is required' }} // Add validation rule
                        render={({ field }) => (
                          <DateTimePicker
                            label="Expected Work Date/Time"
                            open={dateOpen}
                            onClose={() => setDateOpen(false)}
                            slotProps={{
                              textField: {
                                variant: 'standard',
                                fullWidth: true,
                                readOnly: true,
                                onClick: () => setDateOpen(true),
                                error: !!errors.expectedWorkCompletion,
                                helperText: errors.expectedWorkCompletion ? errors.expectedWorkCompletion.message : ''
                              },
                              openPickerButton: {
                                onClick: () => setDateOpen(true)
                              }
                            }}
                            format='DD/MM/YYYY HH:mm A'
                            minDateTime={dayjs()}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                              // Add helper text for validation
                              />
                            )}
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Controller
                        name="customerArrivedDate"
                        control={control}
                        rules={{ required: 'Customer Arrived Date is required' }} // Add validation rule
                        render={({ field }) => (
                          <DateTimePicker
                            label="Customer Arrived Date"
                            open={dateOpen1}
                            onClose={() => setDateOpen1(false)}
                            slotProps={{
                              textField: {
                                variant: 'standard',
                                fullWidth: true,
                                readOnly: true,
                                onClick: () => setDateOpen1(true),
                                error: !!errors.customerArrivedDate,
                                helperText: errors.customerArrivedDate ? errors.customerArrivedDate.message : ''
                              },
                              openPickerButton: {
                                onClick: () => setDateOpen1(true)
                              }
                            }}
                            format='DD/MM/YYYY HH:mm A'
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                              />
                            )}
                            {...field}
                          />
                        )}
                      />
                    </Grid>


                    <Grid item xs={3}>
                      <TextField {...register('odometer', { required: 'odometer is required' })}
                        fullWidth variant="standard" label="Odometer"
                        type='number'
                        InputLabelProps={isEditing ? { shrink: true } : {}}
                        error={!!errors.odometer}
                        helperText={errors.odometer ? errors.odometer.message : ''}
                        onInput={handleInputChange}
                        inputProps={{
                          min: 0,
                          pattern: "[0-9]*",
                          inputMode: "numeric"
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="repairTypeId"
                        control={control}
                        rules={{ required: 'repairType is required' }}
                        render={({ field }) => (
                          <Autocomplete
                            options={repairTypes}
                            getOptionLabel={(option) => option.repairTypeName}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Repair Type"
                                variant="standard"
                                InputLabelProps={isEditing ? { shrink: true } : {}}
                                error={!!errors.repairTypeId}
                                helperText={errors.repairTypeId ? errors.repairTypeId.message : ''}
                              />
                            )}
                            value={field.value || null}
                            onChange={(event, value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="serviceTypeId"
                        control={control}
                        rules={{ required: 'serviceType is required' }}
                        render={({ field }) => (
                          <Autocomplete
                            options={serviceTypes}
                            getOptionLabel={(option) => option.serviceTypeName}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Service Type"
                                variant="standard"
                                InputLabelProps={isEditing ? { shrink: true } : {}}
                                error={!!errors.serviceTypeId}
                                helperText={errors.serviceTypeId ? errors.serviceTypeId.message : ''}
                              />
                            )}
                            value={field.value || null}
                            onChange={(event, value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Controller
                        name="sourceId"
                        control={control}
                        rules={{ required: 'sourceId is required' }}
                        render={({ field }) => (
                          <Autocomplete
                            options={sources}
                            getOptionLabel={(option) => option.sourceName}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Source"
                                variant="standard"
                                error={!!errors.sourceId}
                                helperText={errors.sourceId ? errors.sourceId.message : ''}
                              />
                            )}
                            value={selectedSource}
                            onChange={(event, value, reason) => {
                              if (value !== selectedSource) {
                                setSelectedSourceType(null);
                                setValue('sourceTypeId', null);
                              }

                              setSelectedSource(value);
                              field.onChange(value ? value : null);
                              setValue('sourceId', value);

                              if (value) {
                                const payload = { sourceId: value.id };
                                submitSource(payload);
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="sourceTypeId"
                        control={control}
                        rules={{ required: ' Source Type is required' }}
                        render={({ field }) => (
                          <Autocomplete
                            options={sourceType}
                            getOptionLabel={(option) => option.sourceTypeName}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Source Type"
                                variant="standard"
                                InputLabelProps={isEditing ? { shrink: true } : {}}
                                error={!!errors.sourceTypeId}
                                helperText={errors.sourceTypeId ? errors.sourceTypeId.message : ''}
                              />
                            )}
                            value={selectedSourceType}
                            onChange={(event, value) => {
                              setSelectedSourceType(value);
                              setValue('sourceTypeId', value);
                              field.onChange(value ? value : null);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="OtdFailureReason"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={OTDFailureReasons}
                            getOptionLabel={(option) => option.reason}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="OTD Failure Reason"
                                variant="standard"
                                InputLabelProps={isEditing ? { shrink: true } : {}}
                              />
                            )}
                            value={field.value || null}
                            onChange={(event, value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                    {showDsaValues && (
                      <Grid item xs={3}>
                        <Controller
                          name="dsaAgent"
                          control={control}
                          rules={{ required: 'dsaAgent is required' }}
                          render={({ field }) => (
                            <Autocomplete
                              options={DsaAgents}
                              getOptionLabel={(option) => option.dsaCode}
                              isOptionEqualToValue={(option, value) => (option.id === value.id)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="DSA Agent"
                                  variant="standard"
                                  InputLabelProps={isEditing ? { shrink: true } : {}}
                                  error={!!errors.dsaAgent}
                                  helperText={errors.dsaAgent ? errors.dsaAgent.message : ''}
                                />
                              )}
                              value={field.value || null}
                              onChange={(event, value) => field.onChange(value)}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <Controller
                        name="TransactionSubStatus"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            options={TransactionSubStatus}
                            getOptionLabel={(option) => option.title}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Transaction Substatus"
                                variant="standard"
                                InputLabelProps={isEditing ? { shrink: true } : {}}
                              />
                            )}
                            value={field.value || null}
                            onChange={(event, value) => field.onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        {...register('TransactionSubStatusReason')}
                        fullWidth
                        variant="standard"
                        label="Transaction SubStatusReason"
                        InputLabelProps={isEditing ? { shrink: true } : {}}
                        onInput={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        {...register('customerVoice')}
                        fullWidth
                        variant="standard"
                        label="Customer Voice"
                        InputLabelProps={isEditing ? { shrink: true } : {}}
                        error={!!errors.customerVoice}
                        helperText={errors.customerVoice ? errors.customerVoice.message : ''}
                        onInput={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        {...register('serviceEngineerRemarks')}
                        fullWidth
                        variant="standard"
                        label="Service Engineer Remarks"
                        InputLabelProps={isEditing ? { shrink: true } : {}}
                        error={!!errors.serviceEngineerRemarks}
                        helperText={errors.serviceEngineerRemarks ? errors.serviceEngineerRemarks.message : ''}
                        onInput={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        {...register('serviceAdvice')}
                        fullWidth
                        variant="standard"
                        label="Service Advice"
                        InputLabelProps={isEditing ? { shrink: true } : {}}
                        error={!!errors.serviceAdvice}
                        helperText={errors.serviceAdvice ? errors.serviceAdvice.message : ''}
                        onInput={handleInputChange}
                      />
                    </Grid>
                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "50px", marginLeft: "10px" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}> Labour details</Typography>
                        <SoftButton sx={{ width: "200px" }} onClick={() => append({ laborCode: "", repairType: "", laborDescription: "", quantity: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" })} startIcon={<MdAdd />}>
                          Add Labour Details
                        </SoftButton>
                      </Box>

                      <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
                        {fields.map((item, index) => (
                          <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px" }}>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`labor[${index}].laborCode`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    freeSolo
                                    options={optionData}
                                    getOptionLabel={(option) => option.laborCode || ''}
                                    filterOptions={(options, state) => {
                                      const searchTerm = state.inputValue.toLowerCase();
                                      return options.filter(
                                        (option) =>
                                          option.laborCode.toLowerCase().includes(searchTerm) ||
                                          option.laborDescription.toLowerCase().includes(searchTerm)
                                      );
                                    }}
                                    onChange={async (event, value) => {
                                      field.onChange(value);
                                      const payload = {
                                        laborCode: value ? value.laborCode : "",
                                        modelSegment: VehicleIdData ? VehicleIdData.modelSegment : "",
                                        customerState: VehicleIdData ? VehicleIdData.customerState : "",
                                      };
                                      await getAllLabourDetailsData(payload);
                                    }}
                                    value={field.value}
                                    renderOption={(props, option) => (
                                      <Box
                                        key={option.laborCode}
                                        component="li"
                                        {...props}
                                      >
                                        {option.laborCode} | {option.laborDescription}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        label="Labour Code"
                                        variant="standard"
                                      />
                                    )}
                                    onInputChange={(e, value) => {
                                      handleSearch(value, index);
                                    }}
                                  />
                                )}
                              />

                            </Grid>

                            <Grid item xs={2}>
                              <Controller
                                name={`labor[${index}].laborDescription`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Labour Description"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Controller
                                name={`labor[${index}].repairType`}
                                control={control}
                                defaultValue={repairTypes[0]}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    options={repairTypes}
                                    getOptionLabel={(option) => option.repairTypeName}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(_, data) => field.onChange(data)}
                                    value={field.value || repairTypes[0]}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        label="Repair Type"
                                        variant="standard"
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Controller
                                name={`labor[${index}].quantity`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="Quantity"
                                    variant="standard"
                                    type="number"
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      handleChange(index);
                                    }}
                                    inputProps={{
                                      min: 0,
                                      pattern: "[0-9]*",
                                      inputMode: "numeric"
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`labor[${index}].rate`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label="Rate"
                                  variant="standard"
                                  type="number"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                  disabled
                                />}
                              />
                            </Grid>
                            <Grid item xs={1.5} sx={{ display: 'none' }}>
                              <Controller
                                name={`labor[${index}].singleAmount`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="Single Amount"
                                    variant="standard"
                                    type="hidden"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5} sx={{ display: 'none' }}>
                              <Controller
                                name={`labor[${index}].taxPercentage`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="taxPercentage"
                                    variant="standard"
                                    type="hidden"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5} sx={{ display: 'none' }}>
                              <Controller
                                name={`labor[${index}].sacCode`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="SacCode"
                                    variant="standard"
                                    type="hidden"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`labor[${index}].additionalMargin`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label=" Additional Margin"
                                  variant="standard"
                                  type="number"
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    handleChange(index);
                                  }}
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                />}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`labor[${index}].discountAmount`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="Discount Amount"
                                    variant="standard"
                                    type="number"
                                    error={!!error}
                                    helperText={error ? error.message : ''}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      handleChange(index);
                                    }}
                                    inputProps={{
                                      min: 0,
                                      pattern: "[0-9]*",
                                      inputMode: "numeric"
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <IconButton onClick={() => remove(index)}>
                                  <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                  <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                </IconButton>
                              </Box>
                            </Grid>
                            <Grid sx={{ margin: "20px 0px", width: "100%", marginLeft: "16px" }}>
                              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                {showInputValues && (
                                  <Box sx={{ display: "flex", flexDirection: "row", columnGap: "35px" }}>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`labor[${index}].depreciation`}
                                        control={control}
                                        rules={{
                                          required: inputRef.current?.value === "" ? false : 'Depreciation is required',
                                          min: {
                                            value: 0,
                                            message: 'Depreciation cannot be less than 0'
                                          },
                                          max: {
                                            value: 100,
                                            message: 'Depreciation cannot be more than 100'
                                          },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label="Depreciation%"
                                            variant="standard"
                                            type="number"
                                            error={!!error}
                                            helperText={error ? error.message : ''}
                                            onChange={(e) => {
                                              const value = parseFloat(e.target.value);
                                              field.onChange(e.target.value);
                                              handleChange(index);
                                            }}
                                            inputProps={{
                                              min: 0,
                                              max: 100,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />

                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`labor[${index}].customerAmount`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label="Cus Amount"
                                            variant="standard"
                                            type="number"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`labor[${index}].insuranceAmount`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label="Ins Amount"
                                            variant="standard"
                                            type="number"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Box>
                                )}
                                <Box sx={{ width: "100%", backgroundColor: "#eef3ff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", columnGap: "30px" }} >

                                    <Grid item xs={2}>
                                      <Controller
                                        name={`labor[${index}].sgst`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label=" SGST%"
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Controller
                                        name={`labor[${index}].cgst`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label=" CGST%"
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={2}>
                                      <Controller
                                        name={`labor[${index}].igst`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label=" IGST%"
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Box>
                                  <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                    <Box>
                                      <Typography>Labour Total</Typography>
                                    </Box>
                                    <Grid item xs={2} sx={{ display: "flex", flexDirection: "row" }}>

                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Controller
                                          name={`labor[${index}].laborTotal`}
                                          control={control}
                                          variant="standard"
                                          render={({ field }) => <TextField {...field} fullWidth size='small'
                                            type="number"
                                            variant="standard"
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                            disabled
                                            sx={{
                                              '& .MuiInputBase-input': {
                                                textAlign: 'center',
                                                fontWeight: 700,
                                              }
                                            }}
                                          />}
                                        />

                                      </Box>

                                    </Grid>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}

                      </Box>
                    </StyledPaperShadow>

                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "50px", marginLeft: "10px" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Osl Labour details</Typography>
                        <SoftButton sx={{ width: "200px" }} onClick={() => appendOsl({ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" })} startIcon={<MdAdd />}>
                          Add Osl Labour
                        </SoftButton>
                      </Box>

                      <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
                        {oslFields.map((item, index) => (
                          <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px" }}>
                            <Grid item xs={2}>
                              <Controller
                                name={`oslLabor[${index}].laborCode`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    freeSolo
                                    options={oslOptionData}
                                    onChange={async (event, value) => {
                                      field.onChange(value);
                                      const payload = {
                                        laborCode: value ? value.laborCode : "",
                                        modelSegment: VehicleIdData ? VehicleIdData.modelSegment : "",
                                        customerState: VehicleIdData ? VehicleIdData.customerState : "",
                                      };
                                      await getAllOslLabourDetailsData(payload);
                                    }}
                                    value={field.value}
                                    getOptionLabel={(option) => option.laborCode || ''}
                                    renderOption={(props, option) => (
                                      <Box
                                        key={option.laborCode}
                                        component="li"
                                        {...props}
                                      >
                                        {option.laborCode} | {option.laborDescription}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        label="Labour Code"
                                        variant="standard"
                                        inputRef={oslInputRef}
                                      />
                                    )}
                                    onInputChange={(e, value) => {
                                      handleSearchOsl(value, index); // Pass index here
                                    }}
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={2}>
                              <Controller
                                name={`oslLabor[${index}].laborDescription`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Labour Description"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`oslLabor[${index}].supplierCode`}
                                control={control}
                                defaultValue={vendors[0]}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    options={vendors}
                                    getOptionLabel={(option) => option.vendorCode}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(_, data) => field.onChange(data)}
                                    value={field.value || vendors[0]}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        label="Supplier Code"
                                        variant="standard"
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Controller
                                name={`oslLabor[${index}].quantity`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label=" Quantity"
                                  variant="standard"
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                />}
                              />
                            </Grid>

                            <Grid item xs={1.5}>
                              <Controller
                                name={`oslLabor[${index}].rate`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label="Rate"
                                  variant="standard"
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}

                                />}
                              />
                            </Grid>
                            <Grid item xs={1.5} sx={{ display: 'none' }}>
                              <Controller
                                name={`oslLabor[${index}].sacCode`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="SacCode"
                                    variant="standard"
                                    type="hidden"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5} sx={{ display: 'none' }}>
                              <Controller
                                name={`oslLabor[${index}].taxPercentage`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="taxPercentage"
                                    variant="standard"
                                    type="hidden"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`oslLabor[${index}].additionalMargin`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label=" Additional Margin"
                                  variant="standard"
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                />}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`oslLabor[${index}].discountAmount`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="Discount Amount"
                                    variant="standard"
                                    type="number"
                                    error={!!error}
                                    helperText={error ? error.message : ''}
                                    inputProps={{
                                      min: 0,
                                      pattern: "[0-9]*",
                                      inputMode: "numeric"
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <IconButton onClick={() => removeOsl(index)}>
                                  <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                  <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                </IconButton>
                              </Box>
                            </Grid>
                            <Grid sx={{ margin: "20px 0px", width: "100%", marginLeft: "16px" }}>
                              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                {showInputValues && (
                                  <Box sx={{ display: "flex", flexDirection: "row", columnGap: "35px" }}>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`oslLabor[${index}].depreciation`}
                                        control={control}
                                        rules={{
                                          required: oslInputRef.current?.value === "" ? false : 'Depreciation is required',
                                          min: {
                                            value: 0,
                                            message: 'Depreciation cannot be less than 0'
                                          },
                                          max: {
                                            value: 100,
                                            message: 'Depreciation cannot be more than 100'
                                          },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label="Depreciation%"
                                            variant="standard"
                                            type="number"
                                            error={!!error}
                                            helperText={error ? error.message : ''}
                                            inputProps={{
                                              min: 0,
                                              max: 100,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />

                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`oslLabor[${index}].customerAmount`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label="Cus Amount"
                                            variant="standard"
                                            type="number"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`oslLabor[${index}].insuranceAmount`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label="Ins Amount"
                                            variant="standard"
                                            type="number"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Box>
                                )}
                                <Box sx={{ width: "100%", backgroundColor: "#eef3ff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", columnGap: "30px" }} >

                                    <Grid item xs={3}>
                                      <Controller
                                        name={`oslLabor[${index}].sgst`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label=" SGST%"
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`oslLabor[${index}].cgst`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label=" CGST%"
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`oslLabor[${index}].igst`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size='small'
                                            label=" IGST%"
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Box>
                                  <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                    <Box>
                                      <Typography>Osl Labour Total</Typography>
                                    </Box>
                                    <Grid item xs={2} sx={{ display: "flex", flexDirection: "row" }}>

                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Controller
                                          name={`oslLabor[${index}].laborTotal`}
                                          control={control}
                                          variant="standard"
                                          render={({ field }) => <TextField {...field} fullWidth size='small'
                                            variant="standard"
                                            type="number"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            inputProps={{
                                              min: 0,
                                              pattern: "[0-9]*",
                                              inputMode: "numeric"
                                            }}
                                            disabled
                                            sx={{
                                              '& .MuiInputBase-input': {
                                                textAlign: 'center',
                                                fontWeight: 700,
                                              }
                                            }}

                                          />}
                                        />

                                      </Box>

                                    </Grid>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}

                      </Box>
                    </StyledPaperShadow>

                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "50px", marginLeft: "10px" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Parts details</Typography>
                        <SoftButton sx={{ width: "200px" }} onClick={() => appendParts({ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: 0, discountAmount: 0, sgst: "", cgst: "", igst: "", partTotal: "" })} startIcon={<MdAdd />}>
                          Add Parts Details
                        </SoftButton>
                      </Box>

                      <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
                        {partsFields.map((item, index) => (
                          <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px" }}>

                            <Grid item xs={1.5}>
                              <Controller
                                name={`parts[${index}].partNo`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    freeSolo
                                    options={partsOptionData}
                                    onChange={async (event, value) => {
                                      field.onChange(value);
                                      const payload = {
                                        itemCode: value ? value.itemCode : "",
                                        modelSegment: VehicleIdData ? VehicleIdData.modelSegment : "",
                                        customerState: VehicleIdData ? VehicleIdData.customerState : "",
                                      };
                                      await getAllPartsData(payload);
                                    }}
                                    value={field.value}
                                    getOptionLabel={(option) => option.itemCode || ''}
                                    filterOptions={(options, state) => {
                                      const searchTerm = state.inputValue.toLowerCase();
                                      return options.filter(
                                        (option) =>
                                          option.itemCode.toLowerCase().includes(searchTerm) ||
                                          option.itemName.toLowerCase().includes(searchTerm)
                                      );
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        key={option.itemCode}
                                        component="li"
                                        {...props}
                                      >
                                        {option.itemCode} | {option.itemName}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        label="part No"
                                        variant="standard"
                                      />
                                    )}
                                    onInputChange={(e, value) => {
                                      handleSearchParts(value, index);
                                    }}
                                  />
                                )}
                              />

                            </Grid>

                            <Grid item xs={2}>
                              <Controller
                                name={`parts[${index}].partDescription`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Parts Name"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`parts[${index}].hsnCode`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label="Hsn Code"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Grid>


                            <Grid item xs={1.5}>
                              <Controller
                                name={`parts[${index}].requestedQuantity`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label="Request Quantity"
                                  variant="standard"
                                  type="number"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                />}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`parts[${index}].receivedQuantity`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label="Received Quantity"
                                  variant="standard"
                                  type="number"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                />}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`parts[${index}].returnQuantity`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="Return Quantity"
                                    variant="standard"
                                    type="number"
                                    error={!!error}
                                    helperText={error ? error.message : ''}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    inputProps={{
                                      min: 0,
                                      pattern: "[0-9]*",
                                      inputMode: "numeric"
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5} sx={{ display: 'none' }}>
                              <Controller
                                name={`parts[${index}].taxPercentage`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    label="taxPercentage"
                                    variant="standard"
                                    type="hidden"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <Controller
                                name={`parts[${index}].rate`}
                                control={control}
                                variant="standard"
                                render={({ field }) => <TextField {...field} fullWidth size='small'
                                  label="Rate"
                                  variant="standard"
                                  type="number"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  inputProps={{
                                    min: 0,
                                    pattern: "[0-9]*",
                                    inputMode: "numeric"
                                  }}
                                />}
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <IconButton onClick={() => removeParts(index)}>
                                  <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px", }} />
                                  <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                </IconButton>
                              </Box>
                            </Grid>
                            <Grid sx={{ margin: "20px 0px", backgroundColor: "#eef3ff", width: "100%", marginLeft: "16px" }}>
                              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Box sx={{ display: "flex", columnGap: "30px" }} >

                                  <Grid item xs={1.5}>
                                    <Controller
                                      name={`parts[${index}].sgst`}
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          fullWidth
                                          size='small'
                                          label=" SGST%"
                                          variant="standard"
                                          type="number"
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          inputProps={{
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={1.5}>
                                    <Controller
                                      name={`parts[${index}].cgst`}
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          fullWidth
                                          size='small'
                                          label=" CGST%"
                                          variant="standard"
                                          type="number"
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          inputProps={{
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={1.5}>
                                    <Controller
                                      name={`parts[${index}].igst`}
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          fullWidth
                                          size='small'
                                          label=" IGST%"
                                          variant="standard"
                                          type="number"
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          inputProps={{
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                  <Box>
                                    <Typography>Parts Total</Typography>
                                  </Box>
                                  <Grid item xs={2} sx={{ display: "flex", flexDirection: "row" }}>

                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                      <Controller
                                        name={`parts[${index}].partTotal`}
                                        control={control}
                                        variant="standard"
                                        render={({ field }) => <TextField {...field} fullWidth size='small'
                                          variant="standard"
                                          type="number"
                                          inputProps={{
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                          }}
                                          disabled
                                          sx={{
                                            '& .MuiInputBase-input': {
                                              textAlign: 'center',
                                              fontWeight: 700,
                                            }
                                          }}
                                        />}
                                      />

                                    </Box>

                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}

                      </Box>
                    </StyledPaperShadow>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                          <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{background:"red !important"}} >
                            Cancel
                          </SoftButton>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                          <SoftButton type="submit" variant="contained" color="primary" >
                            {isEditing ? 'Update' : 'Submit'}
                          </SoftButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </StyledPaperShadow>
            </Box>
          )}
          {currentTab === 1 && (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
                  <Box sx={{ alignSelf: "center", margin: "5px" }}>
                    <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {JobCardDatagrid?.JobCardData?.totalItems}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                    <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                    <Select
                      value={limit}
                      onChange={handleLimitChange}
                      sx={{
                        marginLeft: 1,

                        '& .MuiSelect-select': {
                          paddingTop: "1px !important",
                          paddingBottom: "1px !important"
                        },
                      }}

                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ marginRight: "30px" }}>
                    <SoftButton
                      variant="contained"
                      color="primary"
                      sx={{ width: "220px" }}
                      onClick={handleOpenPopup}
                    >
                      Open Service Estimate
                    </SoftButton>
                  </Box>
                  <Box>
                    <TextField
                      variant="standard"
                      label="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={handleSearchClick}>
                              <IoSearchSharp />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

              </Box>
              <ItemGroupDatagrid data={JobCardDatagrid?.JobCardData?.data || []} columns={columns} />
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                <ToggleButtonGroup
                  exclusive
                  sx={{
                    display: 'flex', alignItems: 'center',

                    '& .MuiToggleButtonGroup-middleButton': {
                      marginRight: "0px !important",
                      borderLeft: "1px solid #e5e5e5"
                    },
                    '& .MuiToggleButtonGroup-root': {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                >
                  <ToggleButton
                    value="previous"
                    onClick={handlePrevious}
                    disabled={offset === 0}
                  >
                    Previous
                  </ToggleButton>

                  {Array.from({ length: totalPages }, (_, index) => {
                    const page = index + 1;
                    const isFirstThreePages = page <= 3;
                    const isLastThreePages = page > totalPages - 3;
                    const isCurrentPage = offset / limit === index;

                    if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                      return (
                        <ToggleButton
                          key={page}
                          value={`page-${page}`}
                          onClick={() => handlePageClick(page)}
                          sx={{ margin: '0 5px' }}
                          selected={isCurrentPage}
                        >
                          {page}
                        </ToggleButton>
                      );
                    }

                    if (page === 4 && !isFirstThreePages) {
                      return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                    }

                    if (page === totalPages - 3 && !isLastThreePages) {
                      return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                    }

                    return null;
                  })}
                  <ToggleButton
                    value="next"
                    onClick={handleNext}
                    disabled={offset + limit >= totalItems}
                  >
                    Next
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

            </Box>
          )}
          {open && (
            <StyledSnackBar
              open={true}
              message={snackBarMessage}
              status={dataStatusConfirm}
            />
          )}
        </StyledPaperShadow>


        <Dialog open={openPopup} onClose={handleClosePopup} >
          <DialogTitle sx={{ width: "600px", textAlign: "center", backgroundColor: "#f6f6f6" }}>
            Service Estimate
            <IconButton
              aria-label="close"
              onClick={handleClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ justifyContent: "center", paddingTop: "20px !important" }}>
              <Grid item xs={9}>
                <Controller
                  name="openServiceEstimate"
                  control={control}
                  rules={{ required: 'ServiceEstimate is required' }}
                  render={({ field }) => (
                    <Autocomplete
                      options={OpenEstimateData}
                      getOptionLabel={(option) => option.serviceEstimate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select ServiceEstimate"
                          variant="standard"
                          InputLabelProps={isEditing ? { shrink: true } : {}}
                          error={!!errors.openServiceEstimate}
                          helperText={errors.openServiceEstimate ? errors.openServiceEstimate.message : ''}
                        />
                      )}
                      value={field.value || null}
                      onChange={(event, value) => {
                        field.onChange(value);
                        handleServiceEstimateChange(event, value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Customer Code"
                  value={customerCode}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Vehicle RegNo"
                  value={registrationNo}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <SoftButton
              variant="contained"
              color="primary"
              onClick={handlePopupSubmit}
            >
              Submit
            </SoftButton>

          </DialogActions>
        </Dialog>
        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={backDropOpen}
        >
          <ScaleLoader color="#11164b" />
        </Backdrop>
      </Box>
    </LocalizationProvider>
  )
}

export default CreateJobcard