import { Autocomplete, Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useAllSubMenuDataMutation, useGetroleIdMutation, useMenuListByRoleMutation, useSubmitSubMenuSettingMutation } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";

const RoleSubMenuSettings = () => {
    const {handleSubmit, control, formState: { errors }, setValue} = useForm({
        defaultValues: {},
    });
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);

    const [getRoles, { data: getRolesData }] = useGetroleIdMutation();
    const [getSubMenuList, { data: getSubMenuListData }] = useAllSubMenuDataMutation();
    const [menuList] = useMenuListByRoleMutation();
    const [SubmitRoleSettingData,] = useSubmitSubMenuSettingMutation();

    const roleData = getRolesData?.menuList || [];
    const SubMenuList = getSubMenuListData?.data || [];

    useEffect(() => {
        getRoles();
        getSubMenuList();
    }, []);


    const onSubmit = async (data) => {
        const roleSubMenuSetting = SubMenuList?.filter((item, index) => data[`assign-${item.id}`])
            .map((menuItem, index) => {
                const buttonOperations = [];
                if (data[`Create-${menuItem.id}`]) buttonOperations.push(1);
                if (data[`Read-${menuItem.id}`]) buttonOperations.push(2);
                if (data[`Update-${menuItem.id}`]) buttonOperations.push(3);
                if (data[`Delete-${menuItem.id}`]) buttonOperations.push(4);

                return {

                    SubMenu: menuItem.id,
                    button_operation: buttonOperations.join(','),
                    status: true,
                };
            });

        const formData = {
            role: data.role,
            roleSubMenuSetting,
        };

        let response = await SubmitRoleSettingData(formData)
        if (response && response.data) {
            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                // reset();
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                // reset();
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };
    const selectedInputType = useWatch({
        control,
        name: 'role',
    });
    useEffect(() => {
        const fetchMenuList = async () => {
          if (selectedInputType !== undefined) {
            try {
              const payload = { roleId: selectedInputType?.id };
              const response = await menuList(payload);
              const menuResponse = response?.data?.menuList || [];

              SubMenuList.forEach((menuItem) => {
                setValue(`assign-${menuItem.id}`, false); 
                ['Create', 'Read', 'Update', 'Delete'].forEach((operation) => {
                  setValue(`${operation}-${menuItem.id}`, false); 
                });
              });
              
              menuResponse.forEach((menuItem) => {
                const matchedMenu = SubMenuList?.find((menu) => menu?.title === menuItem?.title);
                if (matchedMenu) {
                  setValue(`assign-${matchedMenu.id}`, true);
      
                  const buttonOperations = menuItem.buttons.split(',').map(Number);
                  buttonOperations.forEach((operation) => {
                    if (operation === 1) setValue(`Create-${matchedMenu.id}`, true);
                    if (operation === 2) setValue(`Read-${matchedMenu.id}`, true);
                    if (operation === 3) setValue(`Update-${matchedMenu.id}`, true);
                    if (operation === 4) setValue(`Delete-${matchedMenu.id}`, true);
                  });
                }
      
                // Handle submenus if they exist
                if (menuItem.submenu) {
                  menuItem.submenu.forEach((submenuItem) => {
                    const matchedSubMenu = SubMenuList?.find((subMenu) => subMenu?.title === submenuItem?.title);
                    if (matchedSubMenu) {
                      setValue(`assign-${matchedSubMenu.id}`, true);
      
                      const subButtonOperations = submenuItem.buttons.split(',').map(Number);
                      subButtonOperations.forEach((operation) => {
                        if (operation === 1) setValue(`Create-${matchedSubMenu.id}`, true);
                        if (operation === 2) setValue(`Read-${matchedSubMenu.id}`, true);
                        if (operation === 3) setValue(`Update-${matchedSubMenu.id}`, true);
                        if (operation === 4) setValue(`Delete-${matchedSubMenu.id}`, true);
                      });
                    }
                  });
                }
              });
            } catch (error) {
              console.error("Error fetching menu list:", error);
            }
          }
        };
      
        fetchMenuList();
      }, [selectedInputType, SubMenuList, setValue]);
      



    return (
        <Box>
            <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ marginBottom: '50px' }}>
                        {/* Role Selection */}
                        <Grid item xs={3}>
                            <Controller
                                name="role"
                                control={control}
                                rules={{ required: 'Role is required' }}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={roleData || []}
                                        getOptionLabel={(option) => option.roleName || ''}
                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Roles"
                                                variant="standard"
                                                error={!!errors.role}
                                                helperText={errors.role ? errors.role.message : ''}
                                            />
                                        )}
                                        value={field.value || null}
                                        onChange={(event, value) => field.onChange(value)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ mb: 1.5, margin: "50px 5px" }}></Divider>
                    <Grid container spacing={2}>
                        {SubMenuList?.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="SubMenu"
                                        value={item.title}
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Box>Button Operation</Box>
                                    <FormGroup row>
                                        {['Create', 'Read', 'Update', 'Delete'].map((operation) => (
                                            <FormControlLabel
                                                key={operation}
                                                control={
                                                    <Controller
                                                        name={`${operation}-${item.id}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value || false}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={operation}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={4} sx={{ alignSelf: "center", marginTop: "20px" }}>
                                    <Controller
                                        name={`assign-${item.id}`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                label="Assign"
                                                control={<Switch {...field} checked={field.value || false} />}

                                            />
                                        )}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                                <SoftButton type="submit" variant="contained" color="primary">
                                    Submit
                                </SoftButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
};
export default RoleSubMenuSettings