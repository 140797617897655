import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useEditOutletMutation, useGetAllBankQuery, useGetAllPincodeMutation, useGetCompanyQuery, useGetOutletDataMutation, useGetOutletDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const Oulet = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
    defaultValues: {
      outletCode: "",
      outletName: "",
      status: 0,
    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addOutletData, { isSuccess: OutletDataSuccess }] = useGetOutletDataMutation();
  const [getOutletGrid, { data: OutletGrid }] = useGetOutletDatagridMutation();
  const [editOutletData, { isSuccess: editoutletSuccess }] = useEditOutletMutation();
  const { data: itemCompanyData } = useGetCompanyQuery();
  const [getAllPincodeData] = useGetAllPincodeMutation();
  const { data: BankData } = useGetAllBankQuery();

  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [isCreating, setIsCreating] = useState(true);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const banks = BankData || []
  const companies = itemCompanyData?.companyData

  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, OutletDataSuccess, editoutletSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey,
    };
    const response = await getOutletGrid(payload);
    setTotalItems(response?.data?.OutletData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
      state: selectedState,
      city: selectedCity,
      pincode: data.pincode,
      bankName: data.bankName.bankName,
      companyId: selectedCompany,
    };

    let response;
    if (isEditing) {
      response = await editOutletData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
      }
    } else {
      response = await addOutletData(formData);
    }

    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        resetForm();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);

  };

  const resetForm = () => {
    reset({
      outletCode: "",
      outletName: "",
      oracleCashCustomerCode: "",
      oracleLocation: "",
      email: "",
      phoneNumber: "",
      gstin: "",
      oracleSiteCode: "",
      address1: "",
      address2: "",
      contactPhoneNumber: "",
      contactEmail: "",
      contactPerson: "",
      outletSegment: "",
      googleRatingLink: "",
      bankAccount: "",
      typeofAccount: "",
      branch: "",
      micrCode: "",
      ifscCode: "",
      pincode: "",
      companies: "",
      companyId: ""
    });
    setIsEditing(false);
    setStatus(0);
    setCurrentTab(1);
    setSelectedCompany(null);
    setSelectedCity("")
    setSelectedState("")
  };


  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };

  const handleEditClick = (row) => {

    setValue('outletCode', row.outletCode);
    setValue('outletName', row.outletName);
    setValue('oracleSiteCode', row.oracleSiteCode);
    setValue('oracleCashCustomerCode', row.oracleCashCustomerCode);
    setValue('oracleLocation', row.oracleLocation);
    setValue('gstIn', row.gstIn);
    setValue('email', row.email);
    setValue('phoneNumber', row.phoneNumber);
    setValue('contactPhoneNumber', row.contactPhoneNumber);
    setValue('contactEmail', row.contactEmail);
    setValue('address1', row.address1);
    setValue('address2', row.address2);
    setValue('contactPerson', row.contactPerson);
    setValue('outletSegment', row.outletSegment);
    setValue('latitude', row.latitude);
    setValue('longitude', row.longitude);
    setValue('bridgeId', row.bridgeId);
    setValue('googleRatingLink', row.googleRatingLink);
    setValue('bankAccount', row.bankAccount);
    setValue('typeofAccount', row.typeofAccount);
    setValue('branch', row.branch);
    setValue('micrCode', row.micrCode);
    setValue('ifscCode', row.ifscCode);
    setValue('pincode', row.pincode);
    setValue('maxPartDiscountPercentage', row.maxPartDiscountPercentage);
    setValue('maxLabourDiscountPercentage', row.maxLabourDiscountPercentage);

    setSelectedState(row.state);
    setSelectedCity(row.city);

    const selectedCompany = companies?.find(company => company.name === row.companyName) || null;
    setSelectedCompany(selectedCompany);

    const selectedBank = banks?.BankData?.find(bank => bank.bankName === row.bankName) || null;
    setValue('bankName', selectedBank);

    setStatus(row.status ? 1 : 0);
    setCurrentTab(0);
    setIsEditing(true);
    setCurrentItemId(row.id);
    clearErrors(['googleRatingLink', 'oracleCashCustomerCode', 'bankAccount', 'typeofAccount', 'branch', 'micrCode', 'ifscCode', 'address2', 'contactPerson', 'outletSegment', 'latitude', 'longitude', 'bridgeId', 'outletCode', 'outletName', 'oracleSiteCode', 'oracleLocation', 'gstIn', 'email', 'phoneNumber', 'contactPhoneNumber', 'contactEmail', 'address1']);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };
  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'outletCode',
        headerName: 'Outlet Code',
        flex: 1,
      },
      {
        field: 'outletName',
        headerName: 'Outlet Name',
        flex: 1,
      },
      {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params.row)}
              style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
              sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
            >
              <MdEdit />
            </IconButton>
          </Tooltip>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const handlePincodeChange = async (event) => {
    let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
    if (pincode.length === 6) {
      const payLoad = {
        pinCode: pincode,
      };
      const response = await getAllPincodeData(payLoad);
      if (response?.data?.requestSuccessful) {
        setSelectedState(response.data.pincodeData.state);
        setSelectedCity(response.data.pincodeData.city);
      }
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsCreating(true);
    } else {
      setIsCreating(false);
      resetForm();
    }
    setCurrentTab(newValue);
  };


  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const handleCancel = () => {
    reset({
      outletCode: "",
      outletName: "",
      oracleCashCustomerCode: "",
      oracleLocation: "",
      email: "",
      phoneNumber: "",
      gstin: "",
      oracleSiteCode: "",
      address1: "",
      address2: "",
      contactPhoneNumber: "",
      contactEmail: "",
      contactPerson: "",
      outletSegment: "",
      googleRatingLink: "",
      bankAccount: "",
      typeofAccount: "",
      branch: "",
      micrCode: "",
      ifscCode: "",
      pincode: "",
      latitude: "",
      longitude: "",
      bridgeId: "",
      maxPartDiscountPercentage: "",
      maxLabourDiscountPercentage: "",
      gstIn: "",
      companyId: ""
    });
    setSelectedCompany(null);
    setStatus(1)
    setIsEditing(false);
  };

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Master"
        subDiv="Outlet"
      ></StyledBreadcrumb>

      <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
          <Tabs value={currentTab} onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
              maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
              overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
            }}
          >
            {hasCreatePermission ? (
              <Tab label="Create Outlet" />
            ) : (isEditing && !hasCreatePermission) ? (
              <Tab label="Update Outlet" />
            ) : null}
            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Outlet " />)}

          </Tabs>
        </Box>
        {currentTab === 0 && (
          <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('outletCode', { required: 'Outlet Code is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Outlet Code<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.outletCode}
                    helperText={errors.outletCode ? errors.outletCode.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('outletName', { required: 'Outlet Name is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Outlet Name<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.outletName}
                    helperText={errors.outletName ? errors.outletName.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('oracleSiteCode', { required: 'Oracle SiteCode is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Oracle SiteCode<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.oracleSiteCode}
                    helperText={errors.oracleSiteCode ? errors.oracleSiteCode.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('oracleCashCustomerCode', { required: 'Oracle Cash CustomerCode is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Oracle Cash CustomerCode<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.oracleCashCustomerCode}
                    helperText={errors.oracleCashCustomerCode ? errors.oracleCashCustomerCode.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('oracleLocation', { required: 'Oracle Location is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Oracle Location<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.oracleLocation}
                    helperText={errors.oracleLocation ? errors.oracleLocation.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('gstIn', { required: 'gstIn is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Gst In<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.gstIn}
                    helperText={errors.gstIn ? errors.gstIn.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Invalid email address'
                      }
                    })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Email<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('phoneNumber', {
                      required: 'Phone Number is required',
                      maxLength: {
                        value: 10,
                        message: 'Phone number cannot exceed 10 digits'
                      }
                    })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Phone Number<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.phoneNumber}
                    type="number"
                    helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value.length > 10) {
                        e.target.value = value.slice(0, 10);
                      }
                    }}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('contactPhoneNumber', {
                      required: 'Contact PhoneNumber is required',
                      maxLength: {
                        value: 10,
                        message: 'Phone number cannot exceed 10 digits'
                      }
                    })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Contact PhoneNumber<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.contactPhoneNumber}
                    helperText={errors.contactPhoneNumber ? errors.contactPhoneNumber.message : ''}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value.length > 10) {
                        e.target.value = value.slice(0, 10);
                      }
                    }}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('contactEmail', { required: 'Contact Email is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Contact Email<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.contactEmail}
                    helperText={errors.contactEmail ? errors.contactEmail.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('pincode', {
                      required: 'Pincode is required',
                      minLength: { value: 6, message: 'Pincode must be 6 digits' },
                      maxLength: { value: 6, message: 'Pincode must be 6 digits' }
                    })}
                    fullWidth
                    variant="standard"
                    type="tel"
                    label={<span>Pincode<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.pincode}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.pincode ? errors.pincode.message : ''}
                    onChange={handlePincodeChange} // Implement this function
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label={<span>State<span style={{ color: '#D32F2F' }}>*</span></span>}
                    value={selectedState}
                    InputLabelProps={selectedState ? { shrink: true } : {}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label={<span>City<span style={{ color: '#D32F2F' }}>*</span></span>}
                    value={selectedCity}
                    InputLabelProps={selectedCity ? { shrink: true } : {}}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('address1', { required: 'Address1 is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Address1<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.address1}
                    helperText={errors.address1 ? errors.address1.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('address2', { required: 'Address2 is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Address2<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.address2}
                    helperText={errors.address2 ? errors.address2.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('contactPerson', { required: 'Contact Person is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Contact Person<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.contactPerson}
                    helperText={errors.contactPerson ? errors.contactPerson.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('outletSegment', { required: 'outletSegment is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>outletSegment<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.outletSegment}
                    helperText={errors.outletSegment ? errors.outletSegment.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('latitude', { required: 'Latitude is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Latitude<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.latitude}
                    helperText={errors.latitude ? errors.latitude.message : ''}
                    onInput={handleInputChange}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('longitude', { required: 'Longitude is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Longitude<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.longitude}
                    helperText={errors.longitude ? errors.longitude.message : ''}
                    onInput={handleInputChange}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('bridgeId', { required: 'BridgeId is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>BridgeId<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.bridgeId}
                    helperText={errors.bridgeId ? errors.bridgeId.message : ''}
                    onInput={handleInputChange}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('googleRatingLink', { required: 'Google RatingLink is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Google RatingLink<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.googleRatingLink}
                    helperText={errors.googleRatingLink ? errors.googleRatingLink.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="bankName"
                    control={control}
                    rules={{ required: 'Bank is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        options={banks?.BankData || []}
                        getOptionLabel={(option) => option.bankName || ''}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Bank<span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.bankName}
                            helperText={errors.bankName ? errors.bankName.message : ''}
                          />
                        )}
                        value={field.value || null}
                        onChange={(event, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('bankAccount', { required: 'Bank Account is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Bank Account<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.bankAccount}
                    helperText={errors.bankAccount ? errors.bankAccount.message : ''}
                    onInput={handleInputChange}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('typeofAccount', { required: 'Typeof Account is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Typeof Account<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.typeofAccount}
                    helperText={errors.typeofAccount ? errors.typeofAccount.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('branch', { required: 'Branch is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Branch<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.branch}
                    helperText={errors.branch ? errors.branch.message : ''}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('micrCode', { required: 'Micr Code is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>Micr Code<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.micrCode}
                    helperText={errors.micrCode ? errors.micrCode.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('ifscCode', { required: 'IFSC Code is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>IFSC Code<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.ifscCode}
                    helperText={errors.ifscCode ? errors.ifscCode.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('maxLabourDiscountPercentage', { required: 'maxLabourDiscountPercentage is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>maxLabour Discount Percentage<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.maxLabourDiscountPercentage}
                    helperText={errors.maxLabourDiscountPercentage ? errors.maxLabourDiscountPercentage.message : ''}
                    onInput={handleInputChange}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('maxPartDiscountPercentage', { required: 'maxPartDiscountPercentage is required' })}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    fullWidth variant="standard"
                    label={<span>MaxPart Discount Percentage<span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    error={!!errors.maxPartDiscountPercentage}
                    helperText={errors.maxPartDiscountPercentage ? errors.maxPartDiscountPercentage.message : ''}
                    onInput={handleInputChange}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyId"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={companies || []}
                        value={selectedCompany || null}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(event, value) => {
                          field.onChange(value);
                          setSelectedCompany(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Company<span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.companyId}
                            helperText={errors.companyId ? 'Company is required' : ''}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                    <Box>
                      <FormLabel>Status</FormLabel>
                    </Box>
                    <Box>
                      <Switch checked={status === 1} onChange={handleStatusChange} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" ,flexWrap:"wrap"}}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                      <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                        Cancel
                      </SoftButton>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                      <SoftButton type="submit" variant="contained" color="primary" >
                        {isEditing ? 'Update' : 'Add'}
                      </SoftButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </StyledPaperShadow>
        )}
        {currentTab === 1 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                flexWrap: "wrap",
                rowGap: "10px",
                columnGap: "30px",
                '@media (max-width: 700px)': {
                  flexDirection: "column",
                  columnGap: "10px",
                }
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  backgroundColor: "#11164b",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "auto",
                  '@media (max-width: 700px)': {
                    flexDirection: "column",
                    columnGap: "5px",
                    padding: "5px",
                    width: "100%",
                  },
                }}
              >
                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                  <Typography
                    sx={{
                      alignSelf: "center",
                      color: "#d9d9d9 !important",
                      fontSize: "1rem",
                      '@media (max-width: 700px)': {
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    Total Records:{OutletGrid?.OutletData?.totalItems}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "1px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "10px",
                    '@media (max-width: 700px)': {
                      flexDirection: "column",
                      paddingLeft: "5px",
                      width: "100%",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: "center",
                      fontSize: "1rem",
                      '@media (max-width: 700px)': {
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    Show:
                  </Typography>
                  <Select
                    value={limit}
                    onChange={handleLimitChange}
                    sx={{
                      marginLeft: 1,
                      '& .MuiSelect-select': {
                        paddingTop: "1px !important",
                        paddingBottom: "1px !important",
                      },
                      '@media (max-width: 700px)': {
                        marginLeft: 0,
                        width: "100%",
                      },
                    }}
                  >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={75}>75</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "auto",
                  '@media (max-width: 700px)': {
                    width: "100%",
                    marginTop: "10px",
                  },
                }}
              >
                <TextField
                  variant="standard"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSearchClick}>
                          <IoSearchSharp />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "auto",
                    '@media (max-width: 700px)': {
                      width: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
            <ItemGroupDatagrid data={OutletGrid?.OutletData?.data || []} columns={columns} />
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
              <ToggleButtonGroup
                exclusive
                sx={{
                  display: 'flex', alignItems: 'center',

                  '& .MuiToggleButtonGroup-middleButton': {
                    marginRight: "0px !important",
                    borderLeft: "1px solid #e5e5e5"
                  },
                  '& .MuiToggleButtonGroup-root': {
                    border: "1px solid #e5e5e5",
                  },
                }}
              >
                <ToggleButton
                  value="previous"
                  onClick={handlePrevious}
                  disabled={offset === 0}
                >
                  Previous
                </ToggleButton>

                {Array.from({ length: totalPages }, (_, index) => {
                  const page = index + 1;
                  const isFirstThreePages = page <= 3;
                  const isLastThreePages = page > totalPages - 3;
                  const isCurrentPage = offset / limit === index;

                  if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                    return (
                      <ToggleButton
                        key={page}
                        value={`page-${page}`}
                        onClick={() => handlePageClick(page)}
                        sx={{ margin: '0 5px' }}
                        selected={isCurrentPage}
                      >
                        {page}
                      </ToggleButton>
                    );
                  }

                  if (page === 4 && !isFirstThreePages) {
                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  if (page === totalPages - 3 && !isLastThreePages) {
                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  return null;
                })}
                <ToggleButton
                  value="next"
                  onClick={handleNext}
                  disabled={offset + limit >= totalItems}
                >
                  Next
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

          </Box>

        )}
        {open && (
          <StyledSnackBar
            open={true}
            message={snackBarMessage}
            status={dataStatusConfirm}
          />
        )}
      </StyledPaperShadow>

    </Box>

  )
}


export default Oulet