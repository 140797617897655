import { Box } from '@mui/material'
import React, { useState } from 'react'
import Header from '../layout/Header/Header'
import Main from '../layout/Main/Main'
import Sidebar from '../layout/Sidebar/SideBar'

function Wrapper({children}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevOpen) => !prevOpen);
  };

  return (
    
    <Box sx={{width:"100%"}}>
       
        <Box sx={{
          display:'flex', 
          height:'calc(100vh - 0px)', 
          position:'relative', 
          background:'#ffffff',
          //backgroundImage:'linear-gradient(0deg, #fffffd, #fcfff0)'
          }}>
          
          <Header onToggleSidebar={handleToggleSidebar} />
          <Sidebar isOpen={isSidebarOpen} />
          <Main page={children}  isOpen={isSidebarOpen}/>
        </Box>
        
    </Box>
  )
}

export default Wrapper