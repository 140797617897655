import React, { useState, useEffect,useMemo } from "react";
import { Typography, Box, Grid, TextField,Select,MenuItem,IconButton,Tab,Tabs } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { StyledPaperShadow, SoftButton } from "../../../theme/StyledElement";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import { useNavigate } from 'react-router-dom';
import { useGetPurchaseReturnMutation } from "../../../store/slice/ApiSlice";
import Tooltip from '@mui/material/Tooltip';
import { MdEdit } from 'react-icons/md';
import { IoSearchSharp } from "react-icons/io5";
import InputAdornment from '@mui/material/InputAdornment';
import CreatePurchaseReturn from "./CreatePurchaseReturn";

const PurchaseReturn = () => {


  const [getPurchasereturn,{ data }] = useGetPurchaseReturnMutation();
 
  const [limit,setLimit] = useState(25); // Number of records per page
  const [offset, setOffset] = useState(0); // Current page offset
  const [searchValue, setSearchValue] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentTab, setCurrentTab] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;


  useEffect(() => {
      fetchItems(offset, searchValue);
    
}, [offset, limit,currentTab]);

const fetchItems = async (newOffset, searchKey) => {
    const payload = {
        limit,
        offset: newOffset,
        searchKey,
    };
    const response = await getPurchasereturn(payload);
    if(response?.data?.requestSuccessful&&response?.data?.data){
      setTotalItems(response?.data?.data?.length);

    }
};


  

  const handleNext = () => {
    const newOffset = offset + limit;
    if (newOffset < totalItems) {
      setOffset(newOffset);
    }
  };

  const handlePrevious = () => {
    const newOffset = offset - limit;
    if (newOffset >= 0) {
      setOffset(newOffset);
    }
  };

  const handleEditClick = (row) => {
    
  };
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };
  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsEditing(false);
  }
    setCurrentTab(newValue);
  };
  
  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);
  
  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);
console.log(data,"data")
  return (
    <Box>
      <StyledBreadcrumb mainDiv="Parts" subDiv="Purchase Return" />
      <StyledPaperShadow
        elevation={0}
        sx={{ padding: "1px 20px 20px 20px", display: "inline-block", width: "100%" }}
      >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                    <Tabs value={currentTab} onChange={handleTabChange}
                        sx={{ '&  .MuiTabs-indicator': { backgroundColor: "#e96b20" } }}
                    >
                        
                        { (hasCreatePermission) ? (
                            <Tab label="Create Purchase Return" />
                        ) : null}
                        {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Purchase Return" />)}
                        
                    </Tabs>
                </Box>
      
                        {currentTab === 0 && (
                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                     <CreatePurchaseReturn setCurrentTab={setCurrentTab} />
                    </StyledPaperShadow>
                  )}
     {currentTab==1&& <StyledPaperShadow
        elevation={0}
        sx={{ padding: "1px 20px 20px 20px", display: "inline-block", width: "100%" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
                                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                    <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {totalItems}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                                    <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                                    <Select
                                        value={limit}
                                        onChange={handleLimitChange}
                                        sx={{
                                            marginLeft: 1,

                                            '& .MuiSelect-select': {
                                                paddingTop: "1px !important",
                                                paddingBottom: "1px !important"
                                            },
                                        }}

                                    >
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <TextField
                                variant="standard"
                                label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSearchClick}>
                                                <IoSearchSharp />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Box>
        <Box>
          { data?.data?.length > 0 && (
            <ItemGroupDatagrid
              data={data?.data}
              columns={[...Object.keys(data?.data[0]).filter(item=>item!=="id").map((key) => ({
                field: key,
                headerName: key.replace(/_/g, ' ')  
                  .replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase(),
                minWidth: 100,
                flex:1,
                hide: key=="id" ? true : false
              }))
            ]}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <SoftButton variant="contained" color="primary" onClick={handlePrevious} disabled={offset === 0}>
              Previous
            </SoftButton>
            <SoftButton
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={offset + limit >= totalItems}
            >
              Next
            </SoftButton>
          </Box>
        </Box>
      </StyledPaperShadow>}

      </StyledPaperShadow>
    </Box>
  );
};

export default PurchaseReturn;
