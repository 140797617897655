import {
  Box,
  Button,
  CardMedia,
  Typography
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import pagenotfound from "../../assets/images/404.png";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        left: "0px",
        zIndex: "99999",
        // background: theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {location.pathname !== "/undefined" ? (
        <>
          <CardMedia component="img" src={pagenotfound} sx={{ width: "450px" }} />
          <Typography variant="h4" sx={{ alignSelf: "center" }}>
            PAGE NOT FOUND
          </Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate('/dashboard')}>
            Go Back Dashboard
          </Button>
        </>
      ) : (
        <>
          <CardMedia component="img" src={pagenotfound} sx={{ width: "450px" }} />
          <Typography variant="h4" sx={{ alignSelf: "center" }}>
            Don't access this page
          </Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate('/login')}>
            Go Back Login
          </Button>
        </>
      )}
    </Box>
  );
};

export default Home;

  