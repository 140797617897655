import { Alert, Box, CardMedia, InputAdornment, Link, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import company from "../../../src/assets/images/companylogo.png";
import image from '../../../src/assets/images/home.svg';
import lock from "../../../src/assets/images/lock.svg";
import userFace from "../../../src/assets/images/userface.svg";
import { useForgetPasswordMutation, useMenuListMutation, useSaveLoginMutation } from '../../store/slice/ApiSlice';
import { getAuth, getEmployeeCode, getLoginData, getRoleId } from "../../store/slice/ControlsSlice";
import { SoftButton, StyledFormControlLabel } from "../../theme/StyledElement";
import StyledSnackBar from "../../Utils/StyledSnackBar";

const LoginPage = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
    }
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [saveLogin] = useSaveLoginMutation();
  const [forgetPassword] = useForgetPasswordMutation();
  const [menuList] = useMenuListMutation();

  const menuListData = JSON.parse(localStorage.getItem("menuList"));
  const isAuthSuccess = useSelector((state) => state.controls.authToken);

  useEffect(() => {
    if (menuListData !== null && menuListData !== undefined && isAuthSuccess) {
      let firstPath = menuListData[0].path;
      if (!firstPath.trim() && menuListData[0].submenu && menuListData[0].submenu.length > 0) {
        firstPath = menuListData[0].submenu[0].path;
      }
      if (firstPath) {
        navigate(firstPath);
      }
    }
  }, [menuListData, isAuthSuccess]);

  const loginSubmit = async (data) => {
    const loginRes = await saveLogin(data);

    if (loginRes?.data?.resultText === 'Success') {
      setDataStatusConfirm("Success");
      setSnackBarMessage(loginRes.data.message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);

      const { token, employeeCode, roleId } = loginRes.data;
      localStorage.setItem('jwToken', token);
      localStorage.setItem('employeeCode', employeeCode);
      localStorage.setItem('roleId', roleId);
      localStorage.setItem('loginData', JSON.stringify(loginRes.data));
      dispatch(getAuth(token));
      dispatch(getEmployeeCode(employeeCode));
      dispatch(getRoleId(roleId));
      dispatch(getLoginData(loginRes.data));

      menuListNew(employeeCode, roleId);
    } else if (loginRes?.error) {
      setDataStatusConfirm("Error");
      setSnackBarMessage(loginRes.error.data.message);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  };

  const menuListNew = async (code, id) => {
    const payload = { employeeCode: code, roleId: id };
    const menusRes = await menuList(payload);

    if (menusRes.data.requestSuccessful) {
      if (menusRes.data.menuList.length > 0) {
        let firstPath = menusRes.data.menuList[0].path;
        if (!firstPath.trim() && menusRes.data.menuList[0].submenu && menusRes.data.menuList[0].submenu.length > 0) {
          firstPath = menusRes.data.menuList[0].submenu[0].path;
        }

        if (firstPath) {
          window.localStorage.setItem("menuList", JSON.stringify(menusRes.data.menuList));
          navigate(`${firstPath}`);
        }
      }
    }
  };


  const handleForgotPasswordClick = () => setShowForgotPassword(true);
  const handleGoToLoginClick = () => setShowForgotPassword(false);

  const handleSubmitForgotPassword = async (data) => {
    const payload = {
      email: data?.email
    }
    await forgetPassword(payload);

  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        width={380}
        height={440}
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"
      >
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-evenly" }}>
          <CardMedia src={company} component={'img'} sx={{ height: 'auto', width: 'auto' }} />
        </Box>
        <Box align="center" sx={{ padding: "10px", color: "#7870AC" }}>
          {!showForgotPassword ? 'Login to your account' : 'Forget Password'}
        </Box>

        {!showForgotPassword ? (
          <Box component="form" onSubmit={handleSubmit(loginSubmit)} sx={{ margin: "10px" }}>
            <StyledFormControlLabel>
              <TextField
                {...register('employeeCode', { required: 'User Name is required' })}
                fullWidth
                label="User Name"
                margin="normal"
                variant="standard"
                error={!!errors.employeeCode}
                helperText={errors.employeeCode ? errors.employeeCode.message : ''}
                InputProps={{
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={userFace} alt="user icon" style={{ background: 'none' }} />
                    </InputAdornment>
                  )
                }}
              />
            </StyledFormControlLabel>
            <StyledFormControlLabel>
              <TextField
                {...register('password', { required: 'Password is required' })}
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                variant="standard"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
                InputProps={{
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={lock} alt="lock icon" style={{ background: 'none' }} />
                    </InputAdornment>
                  )
                }}
              />
            </StyledFormControlLabel>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "10px", cursor: "pointer", marginTop: "20px" }}>
              <Box sx={{ marginTop: "10px" }}>
                <Link variant="body1" onClick={handleForgotPasswordClick} sx={{ color: theme.palette.text.primary, textDecoration: "none" }}>
                  Forgot password?
                </Link>
              </Box>
            </Box>
            <SoftButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ width: "100%" }}
            >
              Login
            </SoftButton>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit(handleSubmitForgotPassword)}>
            <Alert icon={false} color="info">
              To reset your password, enter your Email. Password reset details will be sent to your registered Email.
            </Alert>
            <Box>
              <StyledFormControlLabel>
                <TextField
                  {...register('email', { required: 'email is required' })}
                  fullWidth
                  margin="normal"
                  label="Email"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={userFace} alt="user icon" style={{ background: 'none' }} />
                      </InputAdornment>
                    )
                  }}
                />
              </StyledFormControlLabel>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", padding: "8px", justifyContent: "flex-end", cursor: "pointer" }}>
              <Link variant="body1" onClick={handleGoToLoginClick} sx={{ color: theme.palette.text.primary, textDecoration: "none" }}>
                Go to login page
              </Link>
            </Box>
            <SoftButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ width: "100%" }}
            >
              Submit
            </SoftButton>
          </Box>
        )}
        <Box sx={{ textAlign: "center", }}>{process.env.REACT_APP_VERSION}</Box>
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
};

export default LoginPage;
