import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaArrowCircleRight } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useEditVehicleMutation, useGetAllCustomerMutation, useGetAllInsuranceQuery, useGetAllMakesMutation, useGetAllModelMutation, useGetAllVaraintQuery, useGetAllVechicleColorsQuery, useGetFuelQuery, useGetVehicleDataMutation, useGetVehicleDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";


const Vehicle = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
    defaultValues: {
      registrationNumber: "",
    }
  });
  const location = useLocation();
  const customerId = location?.state?.id;
  const buttonList = location?.state?.buttonList;
  const navigate = useNavigate();

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addVehicleData, { isSuccess: VehicleSuccess }] = useGetVehicleDataMutation();
  const [getVehicleDatagrid, { data: VehicleDatagrid }] = useGetVehicleDatagridMutation();
  const [editVechicleData, { isSuccess: editVehicleSuccess }] = useEditVehicleMutation();
  const [getMakeData, { data: AllMakeData }] = useGetAllMakesMutation();

  const { data: AllVariantData } = useGetAllVaraintQuery();
  const { data: allFuel } = useGetFuelQuery();
  const [getAllCustomerData, { data: allCustomer }] = useGetAllCustomerMutation();
  const { data: allInsurance } = useGetAllInsuranceQuery();
  const { data: allVechicleColors } = useGetAllVechicleColorsQuery();
  const [getModalData, { data: getAllModalData }] = useGetAllModelMutation();
  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isCreating, setIsCreating] = useState(true);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);

  const makes = AllMakeData?.MakeData || [];
  const variants = AllVariantData?.varientData || [];
  const FuelData = allFuel?.fuelTypeData || [];
  const CustomerData = allCustomer?.customerData || [];
  const model = getAllModalData?.ModelData || [];
  const InsuranceData = allInsurance?.InsuracesData || [];
  const VechileColorData = allVechicleColors?.vehicleColorsData || [];

  useEffect(() => {
    getMakeData();
  }, [])

  useEffect(() => {
    const payload = {
      customerId: customerId || 0
    };
    getAllCustomerData(payload);
  }, [customerId]);

  useEffect(() => {
    fetchItems(offset, searchValue);
    setBackDropOpen(true)
  }, [offset, limit, VehicleSuccess, editVehicleSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      customerId,
      searchKey
    };
    const response = await getVehicleDatagrid(payload);
    if (response?.data?.requestSuccessful === true) {
      setBackDropOpen(false)
    }
    setTotalItems(response?.data?.vehicleData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };
  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
      variantId: data?.variantId.id,
      fuelType: data?.fuelTypeId.fuelTypeName,
      customerId: data?.customerId.id,
      makeId: selectedMake?.id || null,
      modelId: selectedModel?.id || null,
      color: data?.color.color,
      insuranceName: data?.insuranceName?.insuranceName,
      insuranceExpDate: data?.insuranceExpDate?.format('YYYY-MM-DD'),
    };
    let response;
    if (isEditing) {
      response = await editVechicleData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
        setCurrentItemId(null);
      }
    } else {
      response = await addVehicleData(formData);
    }
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        resetForm();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();

      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);

  };

  const resetForm = () => {
    reset({
      registrationNumber: "",
      insuranceExpDate: null,
      variantId: "",
      fuelTypeId: "",
      customerId: "",
      color: "",
      insuranceName: "",
      chassisNumber: "",
      odometer: "",
      engineNumber: "",
    });

    setIsEditing(false);
    setStatus(0);
    setSelectedMake(null);
    setSelectedModel(null);
    setCurrentTab(1);
  };


  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };

  useEffect(() => {
    if (CustomerData.length === 1) {
      setValue('customerId', CustomerData[0]);
    }
  }, [CustomerData, setValue]);

  const handleEditClick = async (row) => {
    setValue('registrationNumber', row.registrationNumber);
    setValue('odometer', row.odometer);
    setValue('chassisNumber', row.chassisNumber);
    setValue('engineNumber', row.engineNumber);

    const selectedMake = makes.find(make => make.makeName === row.make);
    setSelectedMake(selectedMake);
    setValue('makeId', selectedMake || null);

    if (selectedMake) {
      setSelectedMake(selectedMake);
      setValue('makeId', selectedMake.id);

      const payload = { makeId: selectedMake.id };
      const modelResponse = await getModalData(payload);

      if (modelResponse?.data?.requestSuccessful) {
        const models = modelResponse?.data?.ModelData;
        const selectedModel = models?.find(model => model?.modelName === row.model);

        if (selectedModel) {
          setSelectedModel(selectedModel);
          setValue('modelId', selectedModel.id);
        }
      } else {
        console.log('Failed to fetch model data');
      }
    }


    const selectedVariant = variants.find(variant => variant.varientName === row.variant);
    setValue('variantId', selectedVariant || null);

    const selectedFuelType = FuelData.find(d => d.fuelTypeName === row.fuelType) || null;
    setValue("fuelTypeId", selectedFuelType)

    const selectedCustomer = CustomerData.find(d => d.customerCode === row.customer) || null;
    setValue("customerId", selectedCustomer)

    const selectedColor = VechileColorData.find(d => d.color === row.color) || null;
    setValue("color", selectedColor)

    const selectedInsuranceName = InsuranceData.find(d => d.insuranceName === row.insuranceName) || null;
    setValue("insuranceName", selectedInsuranceName)

    const selectedInsuranceExpDate = row.insuranceExpDate ? dayjs(row.insuranceExpDate) : null;
    setValue('insuranceExpDate', selectedInsuranceExpDate);

    setStatus(row.status ? 1 : 0);
    setIsEditing(true);
    setCurrentTab(0);
    setCurrentItemId(row.id);
    clearErrors(['variantId', 'makeId', 'registrationNumber', 'odometer', 'chassisNumber', 'engineNumber', 'insuranceExpDate', 'fuelTypeId']);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };
  const columns = useMemo(() => {
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'registrationNumber',
        headerName: 'Registration Num',
        flex: 1,
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: 1,
      },
      {
        field: 'chassisNumber',
        headerName: 'Chassis Number',
        flex: 1,
      },
      {
        field: 'engineNumber',
        headerName: 'Engine Number',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params.row)}
              style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
              sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
            >
              <MdEdit />
            </IconButton>
          </Tooltip>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsCreating(true);
    } else {
      setIsCreating(false);
      resetForm();
    }
    setCurrentTab(newValue);
  };


  const hasCreatePermission = useMemo(() => {
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const onBackClick = () => {
    navigate('/customer');
  };
  const Cancelled = () => {
    reset({
      registrationNumber: "",
      insuranceExpDate: null,
      variantId: "",
      fuelTypeId: "",
      customerId: "",
      color: "",
      insuranceName: "",
      chassisNumber: "",
      odometer: "",
      engineNumber: "",

    });
    setSelectedMake(null);
    setSelectedModel(null);
    setIsEditing(false);

  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <StyledBreadcrumb
          mainDiv="Master"
          subDiv="Vehicle"
        ></StyledBreadcrumb>

        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
            <Tabs value={currentTab} onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
                maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
                overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
              }}
            >
              {hasCreatePermission ? (
                <Tab label="Create Vehicle" />
              ) : (isEditing && !hasCreatePermission) ? (
                <Tab label="Update Vehicle" />
              ) : null}

              {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Vehicle" />)}

            </Tabs>
          </Box>
          {currentTab === 0 && (
            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="customerId"
                      control={control}
                      rules={{ required: 'Customer Code is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={CustomerData}
                          getOptionLabel={(option) => option.customerCode}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Customer Code<span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.customerId}
                              helperText={errors.customerId ? errors.customerId.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      {...register('registrationNumber', {
                        required: 'Registration Number is required',
                        maxLength: {
                          value: 10,
                          message: 'Registration Number should not exceed 10 characters'
                        },
                        pattern: {
                          value: /^[A-Z0-9]*$/,
                          message: 'Registration Number should only contain uppercase letters and numbers'
                        }
                      })}
                      fullWidth variant="standard"
                      label={<span>Registration Number<span style={{ color: '#D32F2F' }}>*</span></span>}
                      InputLabelProps={isEditing ? { shrink: true } : {}}
                      error={!!errors.registrationNumber}
                      inputProps={{ maxLength: 10 }}
                      helperText={errors.registrationNumber ? errors.registrationNumber.message : ''}
                      onInput={handleInputChange}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase().slice(0, 10);
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="makeId"
                      control={control}
                      rules={{ required: 'Make is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={makes}
                          getOptionLabel={(option) => option.makeName}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Make<span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.makeId}
                              helperText={errors.makeId ? errors.makeId.message : ''}
                            />
                          )}
                          value={selectedMake}
                          onChange={(event, value, reason) => {
                            if (value !== selectedMake) {
                              setSelectedModel(null);
                              setValue('modelId', null);
                            }
                            setSelectedMake(value);
                            field.onChange(value ? value.id : null);

                            if (value) {
                              const payload = { makeId: value.id };
                              getModalData(payload);
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="modelId"
                      control={control}
                      rules={{ required: 'Model is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={model}
                          getOptionLabel={(option) => option.modelName}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Model<span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.modelId}
                              helperText={errors.modelId ? errors.modelId.message : ''}
                            />
                          )}
                          value={selectedModel}
                          onChange={(event, value) => {
                            setSelectedModel(value);
                            field.onChange(value ? value.id : null);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="variantId"
                      control={control}
                      rules={{ required: 'Variant is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={variants}
                          getOptionLabel={(option) => option.varientName}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Variant<span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.variantId}
                              helperText={errors.variantId ? errors.variantId.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="fuelTypeId"
                      control={control}
                      rules={{ required: 'fuelType is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={FuelData || []}
                          getOptionLabel={(option) => option.fuelTypeName || ''}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Fuel Type<span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.fuelTypeId}
                              helperText={errors.fuelTypeId ? errors.fuelTypeId.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField {...register('odometer', { required: 'Odometer is required' })}
                      fullWidth variant="standard"
                      label={<span> Odometer <span style={{ color: '#D32F2F' }}>*</span></span>}
                      InputLabelProps={isEditing ? { shrink: true } : {}}
                      error={!!errors.odometer}
                      type='number'
                      helperText={errors.odometer ? errors.odometer.message : ''}
                      onInput={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField {...register('chassisNumber', { required: ' chassisNumber is required' })}
                      fullWidth variant="standard"
                      label={<span>Chassis Number<span style={{ color: '#D32F2F' }}>*</span></span>}
                      InputLabelProps={isEditing ? { shrink: true } : {}}
                      error={!!errors.chassisNumber}
                      helperText={errors.chassisNumber ? errors.chassisNumber.message : ''}
                      onInput={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField {...register('engineNumber', { required: ' engineNumber is required' })}
                      fullWidth variant="standard"
                      label={<span>Engine Number<span style={{ color: '#D32F2F' }}>*</span></span>}
                      InputLabelProps={isEditing ? { shrink: true } : {}}
                      error={!!errors.engineNumber}
                      helperText={errors.engineNumber ? errors.engineNumber.message : ''}
                      onInput={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="insuranceName"
                      control={control}
                      rules={{ required: 'Insurance Provider is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={InsuranceData}
                          getOptionLabel={(option) => option.insuranceName}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Insurance Provider <span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.insuranceName}
                              helperText={errors.insuranceName ? errors.insuranceName.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="insuranceExpDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Insurance Expiry Date"
                          open={dateOpen}
                          onClose={() => setDateOpen(false)}
                          slotProps={{
                            textField: {
                              variant: 'standard',
                              fullWidth: true,
                              readOnly: true,
                              onClick: () => setDateOpen(true),
                            },
                            openPickerButton: {
                              onClick: () => setDateOpen(true)
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          format='DD/MM/YYYY'
                          value={field.value || null}
                          onChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="color"
                      control={control}
                      rules={{ required: 'Color is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={VechileColorData}
                          getOptionLabel={(option) => option.color}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<span>Color <span style={{ color: '#D32F2F' }}>*</span></span>}
                              variant="standard"
                              error={!!errors.color}
                              helperText={errors.color ? errors.color.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                      <Box>
                        <FormLabel>Status</FormLabel>
                      </Box>
                      <Box>
                        <Switch checked={status === 1} onChange={handleStatusChange} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                        <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{ background: "red !important" }} >
                          Cancel
                        </SoftButton>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                        <SoftButton type="submit" variant="contained" color="primary" >
                          {isEditing ? 'Update' : 'Add'}
                        </SoftButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>


              </form>
            </StyledPaperShadow>
          )}
          {currentTab === 1 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  flexWrap: "wrap",
                  rowGap: "10px",
                  columnGap: "30px",
                  '@media (max-width: 700px)': {
                    flexDirection: "column",
                    columnGap: "10px",
                  }
                }}
              >

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                    backgroundColor: "#11164b",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "auto",
                    '@media (max-width: 700px)': {
                      flexDirection: "column",
                      columnGap: "5px",
                      padding: "5px",
                      width: "100%",
                    },
                  }}
                >
                  <Box sx={{ alignSelf: "center", margin: "5px" }}>
                    <Typography
                      sx={{
                        alignSelf: "center",
                        color: "#d9d9d9 !important",
                        fontSize: "1rem",
                        '@media (max-width: 700px)': {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      Total Records:  {VehicleDatagrid?.vehicleData?.totalItems}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "10px",
                      '@media (max-width: 700px)': {
                        flexDirection: "column",
                        paddingLeft: "5px",
                        width: "100%",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        alignSelf: "center",
                        fontSize: "1rem",
                        '@media (max-width: 700px)': {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      Show:
                    </Typography>
                    <Select
                      value={limit}
                      onChange={handleLimitChange}
                      sx={{
                        marginLeft: 1,
                        '& .MuiSelect-select': {
                          paddingTop: "1px !important",
                          paddingBottom: "1px !important",
                        },
                        '@media (max-width: 700px)': {
                          marginLeft: 0,
                          width: "100%",
                        },
                      }}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "auto",
                    display: "flex", flexDirection: "row",
                    '@media (max-width: 700px)': {
                      width: "100%",
                      marginTop: "10px",
                    },
                  }}
                >
                  <Box sx={{ marginRight: "30px" }}>
                    <SoftButton
                      variant="contained"
                      color="primary"
                      sx={{ width: "220px" }}
                      onClick={onBackClick}
                    >
                      Customer  <FaArrowCircleRight style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                    </SoftButton>
                  </Box>
                  <Box>
                    <TextField
                      variant="standard"
                      label="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={handleSearchClick}>
                              <IoSearchSharp />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        width: "auto",
                        '@media (max-width: 700px)': {
                          width: "100%",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <ItemGroupDatagrid data={VehicleDatagrid?.vehicleData?.data || []} columns={columns} />
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                <ToggleButtonGroup
                  exclusive
                  sx={{
                    display: 'flex', alignItems: 'center',

                    '& .MuiToggleButtonGroup-middleButton': {
                      marginRight: "0px !important",
                      borderLeft: "1px solid #e5e5e5"
                    },
                    '& .MuiToggleButtonGroup-root': {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                >
                  <ToggleButton
                    value="previous"
                    onClick={handlePrevious}
                    disabled={offset === 0}
                  >
                    Previous
                  </ToggleButton>

                  {Array.from({ length: totalPages }, (_, index) => {
                    const page = index + 1;
                    const isFirstThreePages = page <= 3;
                    const isLastThreePages = page > totalPages - 3;
                    const isCurrentPage = offset / limit === index;

                    if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                      return (
                        <ToggleButton
                          key={page}
                          value={`page-${page}`}
                          onClick={() => handlePageClick(page)}
                          sx={{ margin: '0 5px' }}
                          selected={isCurrentPage}
                        >
                          {page}
                        </ToggleButton>
                      );
                    }

                    if (page === 4 && !isFirstThreePages) {
                      return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                    }

                    if (page === totalPages - 3 && !isLastThreePages) {
                      return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                    }

                    return null;
                  })}
                  <ToggleButton
                    value="next"
                    onClick={handleNext}
                    disabled={offset + limit >= totalItems}
                  >
                    Next
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

            </Box>

          )}
          {open && (
            <StyledSnackBar
              open={true}
              message={snackBarMessage}
              status={dataStatusConfirm}
            />
          )}
          <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={backDropOpen}
          >
            <ScaleLoader color="#11164b" />
          </Backdrop>
        </StyledPaperShadow>

      </Box>
    </LocalizationProvider>

  )
}

export default Vehicle