import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdDelete, MdEdit } from 'react-icons/md';
import { useDeleteInspectionCheckMutation, useEditInspectionCheckMutation, useGetInspectionCheckDataMutation, useGetInspectionCheckDatagridMutation, useGetInspectionListQuery, useGetInspectionTypeQuery, useGetSubSystemQuery } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const InspectionCheckList = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      checkListVersion: "",
      status: 0,
    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addInspectionCheckData, { isSuccess: InspectionCheckDataSuccess }] = useGetInspectionCheckDataMutation();
  const [getInspectionCheckGrid, { data: InspectionCheckGrid, }] = useGetInspectionCheckDatagridMutation();
  const [editInspectionCheckData, { isSuccess: editInspectionCheckSuccess }] = useEditInspectionCheckMutation();
  const [deleteInspectionCheckData, { isSuccess: deleteInspectionCheckSuccess }] = useDeleteInspectionCheckMutation();
  const { data: checkListTypeData } = useGetInspectionTypeQuery();
  const { data: InspectionTypeData } = useGetInspectionListQuery();
  const { data: SubSystemData } = useGetSubSystemQuery();

  const listTypeCode = checkListTypeData?.CheckListTypes;
  const inspection = InspectionTypeData?.InspectionTypes

  const subsystem = SubSystemData?.SubSystemMapdata

  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'INSPECTION_TYPE',
        headerName: 'InspectionType',
        flex: 1,
      },
      {
        field: 'CHECKLIST_TYPE_CODE',
        headerName: 'CheckListType Code',
        flex: 1,
      },
      {
        field: 'CHECKLIST_VERSION',
        headerName: 'CheckList Version',
        flex: 1,
      },
      {
        field: 'PARAM_CODE',
        headerName: 'Param Code',
        flex: 1,
      },
      {
        field: 'PARAM_NAME',
        headerName: 'Param Name',
        flex: 1,
      },
      {
        field: 'PARAM_WEIGHT',
        headerName: 'Param Weight',
        flex: 1,
      },
      {
        field: 'ACTIVE',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditClick(params.row)}>
                <MdEdit style={{ fontSize: '1.1rem', cursor: 'pointer', color: '#5d6294' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="delete">
              <IconButton
                onClick={() => handleDeleteClick(params.row)}
                style={{ padding: '5px', fontSize: '1.1rem', color: 'red' }}
                sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
              >
                <MdDelete />
              </IconButton>
            </Tooltip>
          </>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const [status, setStatus] = useState(1);
  const [valueRequired, setValueRequired] = useState(1);
  const [option, setOption] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, InspectionCheckDataSuccess, editInspectionCheckSuccess, deleteInspectionCheckSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey
    };
    const response = await getInspectionCheckGrid(payload);
    setTotalItems(response?.data?.inspectionCheckListData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };
  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
      valueRequired: valueRequired ? 1 : 0,
      optional: option ? 1 : 0,
      checkListTypeCode: data?.checkListTypeCode?.CHECKLIST_TYPE_CODE,
      inspectionType: data?.inspectionType?.VEHICLE_TYPE,
      subSystemId: data?.subSystemId?.SUBSYSTEM_ID,
    };

    let response;
    if (isEditing) {
      response = await editInspectionCheckData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
      }
    } else {
      response = await addInspectionCheckData(formData);
    }

    if (response && response.data) {

      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        reset();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        reset();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };
  const handleValueRequiredChange = (event) => {
    setValueRequired(event.target.checked ? 1 : 0);
  };
  const handleOptionalChange = (event) => {
    setOption(event.target.checked ? 1 : 0);
  };

  const handleEditClick = (row) => {
    setValue('sortOrder', row.SORT_ORDER);
    setValue('checkListVersion', row.CHECKLIST_VERSION);
    setValue('paramCode', row.PARAM_CODE);
    setValue('paramName', row.PARAM_NAME);
    setValue('paramWeight', row.PARAM_WEIGHT);
    setValue('relativeRanking', row.RELATIVE_RANKING);

    const selectedInspection = inspection?.find(inspection => inspection.VEHICLE_TYPE === row.INSPECTION_TYPE) || null;
    setValue('inspectionType', selectedInspection);

    const selectedCheck = listTypeCode?.find(check => check.CHECKLIST_TYPE_CODE === row.CHECKLIST_TYPE_CODE) || null;
    setValue('checkListTypeCode', selectedCheck);

    const selectedsubSystemId = subsystem?.find(subSystem => subSystem.SUBSYSTEM_ID === row.SUB_SYSTEM_ID) || null;
    setValue('subSystemId', selectedsubSystemId);

    setStatus(row.ACTIVE ? 1 : 0);
    setValueRequired(row.VALUE_REQUIRED ? 1 : 0);
    setOption(row.OPTIONAL ? 1 : 0);
    setIsEditing(true);
    setCurrentItemId(row.UNIQUE_PARAM_ID);
    clearErrors(['relativeRanking', 'paramWeight', 'paramName', 'paramCode', 'checkListVersion', 'sortOrder', 'checkListTypeCode', 'inspectionType', 'subSystemId',]);
  };

  const handleDeleteClick = async (row) => {
    const payload = {
      id: row.UNIQUE_PARAM_ID
    }
    let response;
    response = await deleteInspectionCheckData(payload)
    if (response && response.data) {
      setDataStatusConfirm("Success");
      setSnackBarMessage(response?.data?.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };


  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const Cancelled = () => {
    reset({
      inspectionType: "",
      checkListTypeCode: "",
      checkListVersion: "",
      paramCode: "",
      paramName: "",
      relativeRanking: "",
      paramWeight: "",
      subSystemId: "",
      sortOrder: "",
    })
    setStatus(1)
    setValueRequired(1)
    setOption(1)
    setIsEditing(false);
  }

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Fit Master"
        subDiv="Inspection CheckList"
      ></StyledBreadcrumb>

      {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (
        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="inspectionType"
                  control={control}
                  rules={{ required: 'InspectionType is required' }}
                  render={({ field }) => (
                    <Autocomplete
                      options={inspection}
                      getOptionLabel={(option) => option?.VEHICLE_TYPE}
                      isOptionEqualToValue={(option, value) => (option.VEHICLE_TYPE === value.VEHICLE_TYPE)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<span>InspectionType <span style={{ color: '#D32F2F' }}>*</span></span>}
                          variant="standard"
                          InputLabelProps={isEditing ? { shrink: true } : {}}
                          error={!!errors.inspectionType}
                          helperText={errors.inspectionType ? errors.inspectionType.message : ''}
                        />
                      )}
                      value={field.value || null}
                      onChange={(event, value) => field.onChange(value)}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="checkListTypeCode"
                  control={control}
                  rules={{ required: 'CheckListType Code is required' }}
                  render={({ field }) => (
                    <Autocomplete
                      options={listTypeCode}
                      getOptionLabel={(option) => option?.CHECKLIST_TYPE_CODE}
                      isOptionEqualToValue={(option, value) => (option.CHECKLIST_TYPE_CODE === value.CHECKLIST_TYPE_CODE)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<span>CheckListType Code <span style={{ color: '#D32F2F' }}>*</span></span>}
                          variant="standard"
                          InputLabelProps={isEditing ? { shrink: true } : {}}
                          error={!!errors.checkListTypeCode}
                          helperText={errors.checkListTypeCode ? errors.checkListTypeCode.message : ''}
                        />
                      )}
                      value={field.value || null}
                      onChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('checkListVersion', { required: 'CheckList Version is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label={<span>CheckList Version <span style={{ color: '#D32F2F' }}>*</span></span>}
                  error={!!errors.checkListVersion}
                  helperText={errors.checkListVersion ? errors.checkListVersion.message : ''}
                  onInput={handleInputChange}
                  type='number'
                  inputProps={{
                    min: 0,
                    pattern: "[0-9]*",
                    inputMode: "numeric"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('paramCode', { required: 'paramCode is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label={<span>Param Code <span style={{ color: '#D32F2F' }}>*</span></span>}
                  error={!!errors.paramCode}
                  helperText={errors.paramCode ? errors.paramCode.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('paramName', { required: 'paramName is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label={<span>Param Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                  error={!!errors.paramName}
                  helperText={errors.paramName ? errors.paramName.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('relativeRanking', { required: 'relativeRanking is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label={<span>Relative Ranking <span style={{ color: '#D32F2F' }}>*</span></span>}
                  error={!!errors.relativeRanking}
                  helperText={errors.relativeRanking ? errors.relativeRanking.message : ''}
                  onInput={handleInputChange}
                  type='number'
                  inputProps={{
                    min: 0,
                    pattern: "[0-9]*",
                    inputMode: "numeric"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('paramWeight', { required: 'paramWeight is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label={<span>Param Weight <span style={{ color: '#D32F2F' }}>*</span></span>}
                  error={!!errors.paramWeight}
                  helperText={errors.paramWeight ? errors.paramWeight.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="subSystemId"
                  control={control}
                  rules={{ required: 'subSystemId is required' }}
                  render={({ field }) => (
                    <Autocomplete
                      options={subsystem}
                      getOptionLabel={(option) => option?.SUBSYSTEM_CODE}
                      isOptionEqualToValue={(option, value) => (option.SUBSYSTEM_CODE === value.SUBSYSTEM_CODE)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<span>subSystemId <span style={{ color: '#D32F2F' }}>*</span></span>}
                          variant="standard"
                          InputLabelProps={isEditing ? { shrink: true } : {}}
                          error={!!errors.subSystemId}
                          helperText={errors.subSystemId ? errors.subSystemId.message : ''}
                        />
                      )}
                      value={field.value || null}
                      onChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('sortOrder', { required: 'sortOrder is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label={<span>Sort Order <span style={{ color: '#D32F2F' }}>*</span></span>}
                  error={!!errors.sortOrder}
                  helperText={errors.sortOrder ? errors.sortOrder.message : ''}
                  onInput={handleInputChange}
                  type='number'
                  inputProps={{
                    min: 0,
                    pattern: "[0-9]*",
                    inputMode: "numeric"
                  }}
                />
              </Grid>

              <Grid item xs={1.5}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                  <Box>
                    <FormLabel>Status</FormLabel>
                  </Box>
                  <Box>
                    <Switch checked={status === 1} onChange={handleStatusChange} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={1.5}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                  <Box>
                    <FormLabel>Value Required</FormLabel>
                  </Box>
                  <Box>
                    <Switch checked={valueRequired === 1} onChange={handleValueRequiredChange} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                  <Box>
                    <FormLabel>Optional</FormLabel>
                  </Box>
                  <Box>
                    <Switch checked={option === 1} onChange={handleOptionalChange} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{ background: "red !important" }} >
                      Cancel
                    </SoftButton>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton type="submit" variant="contained" color="primary" >
                      {isEditing ? 'Update' : 'Submit'}
                    </SoftButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </StyledPaperShadow>
      )}
      <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
        <Box>
        <Box
          sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              flexWrap: "wrap",  
              rowGap: "10px",
              columnGap: "30px",
              '@media (max-width: 700px)': {
                  flexDirection: "column",  
                  columnGap: "10px",
              }
          }}
          >

          <Box
              sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  backgroundColor: "#11164b",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "auto",
                  '@media (max-width: 700px)': {
                      flexDirection: "column", 
                      columnGap: "5px",
                      padding: "5px",
                      width: "100%",
                  },
              }}
          >
              <Box sx={{ alignSelf: "center", margin: "5px" }}>
                  <Typography
                      sx={{
                          alignSelf: "center",
                          color: "#d9d9d9 !important",
                          fontSize: "1rem",
                          '@media (max-width: 700px)': {
                              fontSize: "0.8rem",
                          },
                      }}
                  >
                      Total Records: {InspectionCheckGrid?.inspectionCheckListData?.totalItems}
                  </Typography>
              </Box>

              <Box
                  sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "10px",
                      '@media (max-width: 700px)': {
                          flexDirection: "column", 
                          paddingLeft: "5px",
                          width: "100%", 
                      },
                  }}
              >
                  <Typography
                      sx={{
                          alignSelf: "center",
                          fontSize: "1rem",
                          '@media (max-width: 700px)': {
                              fontSize: "0.8rem",
                          },
                      }}
                  >
                      Show:
                  </Typography>
                  <Select
                      value={limit}
                      onChange={handleLimitChange}
                      sx={{
                          marginLeft: 1,
                          '& .MuiSelect-select': {
                              paddingTop: "1px !important",
                              paddingBottom: "1px !important",
                          },
                          '@media (max-width: 700px)': {
                              marginLeft: 0,
                              width: "100%", 
                          },
                      }}
                  >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                  </Select>
              </Box>
          </Box>

          <Box
              sx={{
                  width: "auto",
                  '@media (max-width: 700px)': {
                      width: "100%", 
                      marginTop: "10px",
                  },
              }}
          >
              <TextField
                  variant="standard"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                              <IconButton onClick={handleSearchClick}>
                                  <IoSearchSharp />
                              </IconButton>
                          </InputAdornment>
                      ),
                  }}
                  sx={{
                      width: "auto",  
                      '@media (max-width: 700px)': {
                          width: "100%", 
                      },
                  }}
              />
          </Box>
                </Box>
          <ItemGroupDatagrid data={InspectionCheckGrid?.inspectionCheckListData?.data || []} columns={columns} />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
            <ToggleButtonGroup
              exclusive
              sx={{
                display: 'flex', alignItems: 'center',

                '& .MuiToggleButtonGroup-middleButton': {
                  marginRight: "0px !important",
                  borderLeft: "1px solid #e5e5e5"
                },
                '& .MuiToggleButtonGroup-root': {
                  border: "1px solid #e5e5e5",
                },
              }}
            >
              <ToggleButton
                value="previous"
                onClick={handlePrevious}
                disabled={offset === 0}
              >
                Previous
              </ToggleButton>

              {Array.from({ length: totalPages }, (_, index) => {
                const page = index + 1;
                const isFirstThreePages = page <= 3;
                const isLastThreePages = page > totalPages - 3;
                const isCurrentPage = offset / limit === index;

                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                  return (
                    <ToggleButton
                      key={page}
                      value={`page-${page}`}
                      onClick={() => handlePageClick(page)}
                      sx={{ margin: '0 5px' }}
                      selected={isCurrentPage}
                    >
                      {page}
                    </ToggleButton>
                  );
                }

                if (page === 4 && !isFirstThreePages) {
                  return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                }

                if (page === totalPages - 3 && !isLastThreePages) {
                  return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                }

                return null;
              })}
              <ToggleButton
                value="next"
                onClick={handleNext}
                disabled={offset + limit >= totalItems}
              >
                Next
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

        </Box>

      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
};

export default InspectionCheckList