import React, { useState } from 'react';
import { Box, TextField, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import DynamicTable from '../grn/DynamicTable';

const PurchaseOrder = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      status: 0,
      snv: '',
      venderCode: '',
      supplierInvoiceNumber: '',
      invoiceDate: '',
      invoiceAmount: '',
      eSugamPermitNumber: '',
      lrNumber: '',
      lrDate: '',
      transportName: '',
      freightCharge: '',
      miscellaneousCharge: '',
      companyId: [],
    },
  });

  const [status, setStatus] = useState(1);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  const onSubmit = async (data) => {
    if (dynamicTableData.length === 0) {
      setSnackBarMessage('Please add at least one row in the table.');
      setDataStatusConfirm('Error');
      setOpen(true);
      return;
    }

    const formData = {
      ...data,
      status: status ? 1 : 0,
      companyId: data.companyId.map((company) => company.id),
      parts: dynamicTableData, 
    };


    let response = null; 
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm('Error');
      } else {
        message = response.data.message;
        setDataStatusConfirm('Success');
        reset();
        setDynamicTableData([]); // Reset table data
        setGrandTotal(0); // Reset grand total
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm('Error');
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm('Success');
        message = response?.error?.data?.message;
        reset();
        setValue('companyId', []);
        setDynamicTableData([]); // Reset table data
        setGrandTotal(0); // Reset grand total
      }
      setSnackBarMessage(message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const headers = [
    'Parts Code',
    'Description',
    'PO Number',
    'Sup. inv qty',
    'Received Qty',
    'Rate',
    'Cost',
    'MRP',
    'Discount Amount',
    'Select Bin Location',
    'CGST',
    'SGST',
    'IGST',
    'Total Amount',
  ];

  const handleDataSubmit = (data) => {
    setDynamicTableData(data);
    calculateGrandTotal(data);
  };

  const calculateGrandTotal = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row['Total Amount'] || 0), 0);
    setGrandTotal(total);
  };

  return (
    <Box>
      <StyledBreadcrumb mainDiv="Parts" subDiv="GRN" />

      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                {...register('snv', { required: 'SNV is required' })}
                fullWidth
                variant="standard"
                label="SNV"
                error={!!errors.snv}
                helperText={errors.snv ? errors.snv.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('venderCode', { required: 'Vender Code is required' })}
                fullWidth
                variant="standard"
                label="Vender Code"
                error={!!errors.venderCode}
                helperText={errors.venderCode ? errors.venderCode.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('supplierInvoiceNumber', { required: 'Supplier Invoice Number is required' })}
                fullWidth
                variant="standard"
                label="Supplier Invoice Number"
                error={!!errors.supplierInvoiceNumber}
                helperText={errors.supplierInvoiceNumber ? errors.supplierInvoiceNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('invoiceDate', { required: 'Invoice Date is required' })}
                fullWidth
                variant="standard"
                label="Invoice Date"
                error={!!errors.invoiceDate}
                helperText={errors.invoiceDate ? errors.invoiceDate.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('invoiceAmount', { required: 'Invoice Amount is required' })}
                fullWidth
                variant="standard"
                label="Invoice Amount"
                error={!!errors.invoiceAmount}
                helperText={errors.invoiceAmount ? errors.invoiceAmount.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('eSugamPermitNumber', { required: 'E Sugam Road Permit Number is required' })}
                fullWidth
                variant="standard"
                label="E Sugam Road Permit Number"
                error={!!errors.eSugamPermitNumber}
                helperText={errors.eSugamPermitNumber ? errors.eSugamPermitNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('lrNumber', { required: 'LR Number is required' })}
                fullWidth
                variant="standard"
                label="LR Number"
                error={!!errors.lrNumber}
                helperText={errors.lrNumber ? errors.lrNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('lrDate', { required: 'LR Date is required' })}
                fullWidth
                variant="standard"
                label="LR Date"
                error={!!errors.lrDate}
                helperText={errors.lrDate ? errors.lrDate.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('transportName', { required: 'Transport Name is required' })}
                fullWidth
                variant="standard"
                label="Transport Name"
                error={!!errors.transportName}
                helperText={errors.transportName ? errors.transportName.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('freightCharge', { required: 'Freight Charge is required' })}
                fullWidth
                variant="standard"
                label="Freight Charge"
                error={!!errors.freightCharge}
                helperText={errors.freightCharge ? errors.freightCharge.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('miscellaneousCharge', { required: 'Miscellaneous Charge is required' })}
                fullWidth
                variant="standard"
                label="Miscellaneous Charge"
                error={!!errors.miscellaneousCharge}
                helperText={errors.miscellaneousCharge ? errors.miscellaneousCharge.message : ''}
              />
            </Grid>
            <Grid item xs={2}>
            </Grid>
          </Grid>

      <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'right' }}>
        Grand Total: {grandTotal.toFixed(2)}
      </Typography>

      <DynamicTable headers={headers} onSubmit={handleDataSubmit} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <SoftButton type="submit" variant="contained" color="primary">
                  {'Submit'}
                </SoftButton>
              </Box>
      </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default PurchaseOrder;
