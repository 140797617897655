import React from 'react';
import { useSelector } from "react-redux";
import { Navigate, Outlet } from 'react-router-dom';
import Wrapper from '../Wrapper';

function IsPrivateLayout() {
  const isAuthSuccess = useSelector((state) => state.controls.authToken);

    let auth =(isAuthSuccess!==undefined && isAuthSuccess!==null) ? true:false;

  return ( auth ? 
    <Wrapper>
        <Outlet/>
    </Wrapper>
    : 
    <Navigate to={'/'}/>
  )
}

export default IsPrivateLayout