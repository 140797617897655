import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaCar } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useEditCustomerMutation, useGetAllBillTypeQuery, useGetAllCustomerCategoriesQuery, useGetAllCustomerTypeQuery, useGetAllPincodeMutation, useGetAllSourceMutation, useGetCustomerDatagridMutation, useGetCustomerDataMutation, useSubmitSourceMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const Customer = () => {
    const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, trigger, control } = useForm({
        defaultValues: {
            firstName: "",
            status: 0,
        }
    });

    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const pathname = window.location.pathname;
    const navigate = useNavigate();

    const [addCustomerData, { isSuccess: CustomerDataSuccess }] = useGetCustomerDataMutation();
    const [getCustomerGrid, { data: CustomerGrid }] = useGetCustomerDatagridMutation();
    const [editCustomerData, { isSuccess: editCustomerSuccess }] = useEditCustomerMutation();
    const [getAllPincodeData] = useGetAllPincodeMutation();
    const [getAllsourceData, { data: sourceData }] = useGetAllSourceMutation();
    const [submitSource, { data: submitSourceData }] = useSubmitSourceMutation();

    const { data: billTypeData } = useGetAllBillTypeQuery();
    const { data: customerCategoriesData } = useGetAllCustomerCategoriesQuery();
    const { data: customerTypeData } = useGetAllCustomerTypeQuery();

    const [status, setStatus] = useState(1);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [currentTab, setCurrentTab] = useState(1);
    const [isCreating, setIsCreating] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [backDropOpen, setBackDropOpen] = useState(false); //null
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedSourceType, setSelectedSourceType] = useState(null);

    const sources = sourceData?.sourceData || [];
    const sourceType = submitSourceData?.sourceTypes || [];
    const billType = billTypeData?.billTypesData || [];
    const customerCategories = customerCategoriesData?.customercategoryData || [];
    const customerType = customerTypeData?.customertypesData || [];

    useEffect(() => {
        getAllsourceData()
    }, [])

    useEffect(() => {
        fetchItems(offset, searchValue);
        setBackDropOpen(true)
    }, [offset, limit, CustomerDataSuccess, editCustomerSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey,
        };
        const response = await getCustomerGrid(payload);
        if (response?.data?.requestSuccessful === true) {
            setBackDropOpen(false)
        }
        setTotalItems(response?.data?.customerData?.totalItems);
    };

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };

    const columns = useMemo(() => {

        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        const hasUpdatePermission = buttonList.split(',').includes("3");

        const baseColumns = [
            {
                field: 'customerCode',
                headerName: 'Customer Code',
                flex: 1,
            },
            {
                field: 'outletCode',
                headerName: 'Outlet Code',
                flex: 1,
            },
            {
                field: 'customerName',
                headerName: 'Customer Name',
                flex: 1,
            },
            {
                field: 'regNo',
                headerName: 'Registration Number',
                flex: 1,
            },
            {
                field: 'mobileNumber',
                headerName: 'Mobile Number',
                flex: 1,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params) => (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                                        marginRight: '8px',
                                    }}
                                />
                                {params.value ? 'Active' : 'Inactive'}
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            width: "90px",
                        }}
                    />
                ),
            },
        ];

        baseColumns.push({
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <>
                    {hasUpdatePermission && (
                        <Tooltip title="Edit">
                            <span>
                                <MdEdit onClick={() => handleEditClick(params.row)} style={{ cursor: 'pointer', marginRight: '10px', fontSize: '1.1rem' }} />
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip title="Vehicle">
                        <span>
                            <FaCar onClick={() => handleVehicleClick(params.row.id)} style={{ cursor: 'pointer', fontSize: '1.1rem' }} />
                        </span>
                    </Tooltip>
                </>
            ),
        });

        return baseColumns;
    }, [menuList, pathname]);

    const onSubmit = async (data) => {
        const formData = {
            ...data,
            status: status ? 1 : 0,
            state: selectedState,
            city: selectedCity,
            pinCode: data.pincode,
            sourceId: data?.sourceId,
            sourceTypeId: data?.sourceTypeId,
            customerCategory: data?.customerCategory.customerCategory,
            customerType: data?.customerType.customerType,
            billType: data?.billType.billType,
        };

        let response;
        if (isEditing) {
            response = await editCustomerData({ id: currentItemId, ...formData });
            if (response && response.data.requestSuccessful === true) {
                setIsEditing(false);
            }
        } else {
            response = await addCustomerData(formData);
        }

        if (response && response.data) {

            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                resetForm();
                setCurrentItemId(null);
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                resetForm();
                setCurrentItemId(null);
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };


    const resetForm = () => {
        reset({
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            mobileNumber: "",
            emailId: "",
            contactPerson: "",
            contactPersonNumber: "",
            gstinNumber: "",
            oraclAccountNumber: "",
            oraclSiteNumber: "",
            pincode: "",
            customerCategory: "",
            customerType: "",
            billType: "",
        });

        setIsEditing(false);
        setSelectedCity('')
        setSelectedState('')
        setCurrentTab(1);
        setSelectedSourceType(null)
        setSelectedSource(null)
    };


    const handleStatusChange = (event) => {
        setStatus(event.target.checked ? 1 : 0);
    };

    const handleEditClick = async (row) => {

        setValue('firstName', row.firstName);
        setValue('lastName', row.lastName);
        setValue('address1', row.address1);
        setValue('address2', row.address2);
        setValue('mobileNumber', row.mobileNumber);
        setValue('emailId', row.emailId);
        setValue('contactPerson', row.contactPerson);
        setValue('contactPersonNumber', row.contactPersonNumber);
        setValue('gstinNumber', row.gstinNumber);
        setValue('oraclAccountNumber', row.oraclAccountNumber);
        setValue('oraclSiteNumber', row.oraclSiteNumber);
        setValue('pincode', row.pinCode);

        setSelectedState(row.state);
        setSelectedCity(row.city);

        const selectedSourceData = sources?.find(source => source.sourceName === row.sourceName);
        setSelectedSource(selectedSourceData)
        setValue('sourceId', selectedSourceData || null);

        if (selectedSourceData) {
            setSelectedSourceType(selectedSourceData);
            setValue('sourceId', selectedSourceData.id);

            const payload = { sourceId: selectedSourceData.id };
            const sourceResponse = await submitSource(payload);

            if (sourceResponse?.data?.requestSuccessful) {
                const sourceType = sourceResponse?.data?.sourceTypes;
                const selectedSourceType = sourceType?.find(sourceType => sourceType.sourceTypeName === row.sourceTypeName);

                if (selectedSourceType) {
                    setSelectedSourceType(selectedSourceType);
                    setValue('sourceTypeId', selectedSourceType.id);
                }
            } else {
                console.log('Failed to fetch model data');
            }
        }

        const selectedCustomerCategory = customerCategories?.find(Category => Category.customerCategory === row.customerCategory);
        setValue('customerCategory', selectedCustomerCategory || null);

        const selectedBillType = billType?.find(bill => bill.billType === row.billType);
        setValue('billType', selectedBillType || null);

        const selectedCustomerType = customerType?.find(customer => customer.customerType === row.customerType);
        setValue('customerType', selectedCustomerType || null);

        setStatus(row.status ? 1 : 0);
        setIsEditing(true);
        setCurrentTab(0);
        setCurrentItemId(row.id);
        clearErrors(['firstName', 'pinCode', 'lastName', 'address1', 'oraclSiteNumber', 'oraclAccountNumber', 'gstinNumber', 'contactPersonNumber', 'contactPerson', 'emailId', 'mobileNumber', 'address2']);
    };

    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };


    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };
    const handleUpperCaseChange = (event) => {
        event.target.value = event.target.value.replace(/\s+/g, '').toUpperCase();
    };

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            setIsCreating(true);
        } else {
            setIsCreating(false);
            resetForm();
        }
        setCurrentTab(newValue);
    };

    const handlePincodeChange = async (event) => {
        let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
        if (pincode.length === 6) {
            const payLoad = {
                pinCode: pincode,
            };
            const response = await getAllPincodeData(payLoad);
            if (response?.data?.requestSuccessful) {
                setSelectedState(response.data.pincodeData.state);
                setSelectedCity(response.data.pincodeData.city);
                clearErrors('pincode');
            }
        }
    };

    const hasCreatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("1");
    }, [menuList, pathname]);

    const hasUpdatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("3");
    }, [menuList, pathname]);

    const handleVehicleClick = (id) => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";

        navigate('/vehicle', { state: { id, buttonList } });
    };
    const Cancelled = () => {
        reset({
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            mobileNumber: "",
            emailId: "",
            contactPerson: "",
            contactPersonNumber: "",
            gstinNumber: "",
            oraclAccountNumber: "",
            oraclSiteNumber: "",
            pincode: "",
            customerCategory: "",
            customerType: "",
            billType: "",
        });
        setSelectedCity('')
        setSelectedState('')
        setSelectedSourceType(null)
        setSelectedSource(null)
        setIsEditing(false);
    }

    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Master"
                subDiv="Customer"
            ></StyledBreadcrumb>

            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                    <Tabs value={currentTab} onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        sx={{
                          '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
                          maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
                          overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
                        }}
                    >
                        {hasCreatePermission ? (
                            <Tab label="Create Customer" />
                        ) : (isEditing && !hasCreatePermission) ? (
                            <Tab label="Update Customer" />
                        ) : null}

                        {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Customer" />)}

                    </Tabs>
                </Box>
                {currentTab === 0 && (
                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('firstName', { required: 'First Name is required' })}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        fullWidth variant="standard"
                                        label={<span>First Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName ? errors.firstName.message : ''}
                                        onInput={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('lastName', { required: 'Last Name is required' })}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        fullWidth variant="standard"
                                        label={<span>Last Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName ? errors.lastName.message : ''}
                                        onInput={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('address1', { required: 'address1 is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Address1 <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.address1}
                                        helperText={errors.address1 ? errors.address1.message : ''}
                                        multiline
                                        onInput={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('address2', { required: 'address2 is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Address2 <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.address2}
                                        helperText={errors.address2 ? errors.address2.message : ''}
                                        onInput={handleInputChange}
                                        multiline />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('pincode', {
                                            required: 'Pincode is required',
                                            minLength: { value: 6, message: 'Pincode must be 6 digits' },
                                            maxLength: { value: 6, message: 'Pincode must be 6 digits' }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        type="number"
                                        label={<span>Pincode <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.pincode}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.pincode ? errors.pincode.message : ''}
                                        onChange={handlePincodeChange} // Implement this function
                                        onInput={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="State"
                                        value={selectedState}
                                        InputLabelProps={selectedState ? { shrink: true } : {}}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="City"
                                        value={selectedCity}
                                        InputLabelProps={selectedCity ? { shrink: true } : {}}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('mobileNumber', {
                                            required: 'Mobile Number is required',
                                            validate: (value) =>
                                                value.length === 10 || 'Mobile Number must be exactly 10 digits'
                                        })}
                                        fullWidth
                                        variant="standard"
                                        label={<span>Mobile Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.mobileNumber}
                                        type="number"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.mobileNumber ? errors.mobileNumber.message : ''}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 10) {
                                                e.target.value = value.slice(0, 10);
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 10,
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                        onBlur={() => {
                                            trigger('mobileNumber'); // Trigger validation on blur
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="sourceId"
                                        control={control}
                                        rules={{ required: 'sourceId is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={sources}
                                                getOptionLabel={(option) => option.sourceName}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Source <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        error={!!errors.sourceId}
                                                        helperText={errors.sourceId ? errors.sourceId.message : ''}
                                                    />
                                                )}
                                                value={selectedSource}
                                                onChange={(event, value, reason) => {
                                                    if (value !== selectedSource) {
                                                        setSelectedSourceType(null);
                                                        setValue('sourceTypeId', null);
                                                    }
                                                    setSelectedSource(value);
                                                    field.onChange(value ? value.id : null);

                                                    if (value) {
                                                        const payload = { sourceId: value.id };
                                                        submitSource(payload);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="sourceTypeId"
                                        control={control}
                                        rules={{ required: ' Source Type is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={sourceType}
                                                getOptionLabel={(option) => option.sourceTypeName}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Source Type <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                                        error={!!errors.sourceTypeId}
                                                        helperText={errors.sourceTypeId ? errors.sourceTypeId.message : ''}
                                                    />
                                                )}
                                                value={selectedSourceType}
                                                onChange={(event, value) => {
                                                    setSelectedSourceType(value);
                                                    field.onChange(value ? value.id : null);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="customerCategory"
                                        control={control}
                                        rules={{ required: 'Customer Category is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={customerCategories}
                                                getOptionLabel={(option) => option.customerCategory}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Customer Category <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                                        error={!!errors.customerCategory}
                                                        helperText={errors.customerCategory ? errors.customerCategory.message : ''}
                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, value) => field.onChange(value)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="billType"
                                        control={control}
                                        rules={{ required: 'Bill Type is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={billType}
                                                getOptionLabel={(option) => option.billType}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Bill Type <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                                        error={!!errors.billType}
                                                        helperText={errors.billType ? errors.billType.message : ''}
                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, value) => field.onChange(value)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name="customerType"
                                        control={control}
                                        rules={{ required: 'Customer Type is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={customerType}
                                                getOptionLabel={(option) => option.customerType}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Customer Type <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                        variant="standard"
                                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                                        error={!!errors.customerType}
                                                        helperText={errors.customerType ? errors.customerType.message : ''}
                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, value) => field.onChange(value)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('emailId', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                message: 'Invalid email address'
                                            }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        label={<span>Email <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.emailId}
                                        helperText={errors.emailId ? errors.emailId.message : ''}
                                        onInput={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('contactPerson', { required: 'contact Person is required' })}
                                        fullWidth variant="standard"
                                        label={<span>Contact Person <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        error={!!errors.contactPerson}
                                        helperText={errors.contactPerson ? errors.contactPerson.message : ''}
                                        onInput={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        {...register('contactPersonNumber', {
                                            required: 'Contact Person Number is required',
                                            maxLength: {
                                                value: 10,
                                                message: 'Contact Person Number cannot exceed 10 digits'
                                            }
                                        })}
                                        fullWidth
                                        variant="standard"
                                        label={<span>Contact Person Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                        error={!!errors.contactPersonNumber}
                                        type="number"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        helperText={errors.contactPersonNumber ? errors.contactPersonNumber.message : ''}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 10) {
                                                e.target.value = value.slice(0, 10);
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 10,
                                            min: 0,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('gstinNumber')}
                                        fullWidth variant="standard" label="Gstin Number"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        onInput={handleUpperCaseChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('oraclAccountNumber')}
                                        fullWidth variant="standard" label="oracle AccountNumber"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        onInput={handleUpperCaseChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField {...register('oraclSiteNumber')}
                                        fullWidth variant="standard" label="oracle SiteNumber"
                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                        onInput={handleUpperCaseChange} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={1.5}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                                        <Box>
                                            <FormLabel>Status</FormLabel>
                                        </Box>
                                        <Box>
                                            <Switch checked={status === 1} onChange={handleStatusChange} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                            <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{ background: "red !important" }} >
                                                Cancel
                                            </SoftButton>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                            <SoftButton type="submit" variant="contained" color="primary" >
                                                {isEditing ? 'Update' : 'Add'}
                                            </SoftButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>


                        </form>
                    </StyledPaperShadow>
                )}
                {currentTab === 1 && (
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                flexWrap: "wrap",
                                rowGap: "10px",
                                columnGap: "30px",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column",
                                    columnGap: "10px",
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "10px",
                                    backgroundColor: "#11164b",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    width: "auto",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column",
                                        columnGap: "5px",
                                        padding: "5px",
                                        width: "100%",
                                    },
                                }}
                            >
                                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                    <Typography
                                        sx={{
                                            alignSelf: "center",
                                            color: "#d9d9d9 !important",
                                            fontSize: "1rem",
                                            '@media (max-width: 700px)': {
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    >
                                        Total Records: {CustomerGrid?.customerData?.totalItems}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: "1px",
                                        backgroundColor: "#ffffff",
                                        paddingLeft: "10px",
                                        '@media (max-width: 700px)': {
                                            flexDirection: "column",
                                            paddingLeft: "5px",
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            alignSelf: "center",
                                            fontSize: "1rem",
                                            '@media (max-width: 700px)': {
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    >
                                        Show:
                                    </Typography>
                                    <Select
                                        value={limit}
                                        onChange={handleLimitChange}
                                        sx={{
                                            marginLeft: 1,
                                            '& .MuiSelect-select': {
                                                paddingTop: "1px !important",
                                                paddingBottom: "1px !important",
                                            },
                                            '@media (max-width: 700px)': {
                                                marginLeft: 0,
                                                width: "100%",
                                            },
                                        }}
                                    >
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: "auto",
                                    '@media (max-width: 700px)': {
                                        width: "100%",
                                        marginTop: "10px",
                                    },
                                }}
                            >
                                <TextField
                                    variant="standard"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={handleSearchClick}>
                                                    <IoSearchSharp />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        width: "auto",
                                        '@media (max-width: 700px)': {
                                            width: "100%",
                                        },
                                    }}
                                />
                            </Box>
                        </Box>

                        <ItemGroupDatagrid data={CustomerGrid?.customerData?.data || []} columns={columns} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                            <ToggleButtonGroup
                                exclusive
                                sx={{
                                    display: 'flex', alignItems: 'center',

                                    '& .MuiToggleButtonGroup-middleButton': {
                                        marginRight: "0px !important",
                                        borderLeft: "1px solid #e5e5e5"
                                    },
                                    '& .MuiToggleButtonGroup-root': {
                                        border: "1px solid #e5e5e5",
                                    },
                                }}
                            >
                                <ToggleButton
                                    value="previous"
                                    onClick={handlePrevious}
                                    disabled={offset === 0}
                                >
                                    Previous
                                </ToggleButton>

                                {Array.from({ length: totalPages }, (_, index) => {
                                    const page = index + 1;
                                    const isFirstThreePages = page <= 3;
                                    const isLastThreePages = page > totalPages - 3;
                                    const isCurrentPage = offset / limit === index;

                                    if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                        return (
                                            <ToggleButton
                                                key={page}
                                                value={`page-${page}`}
                                                onClick={() => handlePageClick(page)}
                                                sx={{ margin: '0 5px' }}
                                                selected={isCurrentPage}
                                            >
                                                {page}
                                            </ToggleButton>
                                        );
                                    }

                                    if (page === 4 && !isFirstThreePages) {
                                        return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                    }

                                    if (page === totalPages - 3 && !isLastThreePages) {
                                        return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                    }

                                    return null;
                                })}
                                <ToggleButton
                                    value="next"
                                    onClick={handleNext}
                                    disabled={offset + limit >= totalItems}
                                >
                                    Next
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                    </Box>
                )}
                {open && (
                    <StyledSnackBar
                        open={true}
                        message={snackBarMessage}
                        status={dataStatusConfirm}
                    />
                )}
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={backDropOpen}
                >
                    <ScaleLoader color="#11164b" />
                </Backdrop>
            </StyledPaperShadow>

        </Box>

    )
}


export default Customer