import { Box, Grid, IconButton, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaFileExcel } from "react-icons/fa6";
import { IoSearchSharp } from "react-icons/io5";
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useRepairOrderStatmentExcelMutation,useRepairOrderStatmentMutation } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const { RangePicker } = DatePicker;
const RepairOrderStatement = () => {
  const { handleSubmit } = useForm({
      mode: 'onChange',
      defaultValues: {
          oemName: "",
          status: 0,
      }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [getRepairOrderStatementReportGrid, { data: RepairOrderStatementReportGrid }] = useRepairOrderStatmentMutation();
  const [getexcelExportGrid] = useRepairOrderStatmentExcelMutation();

  const columns = useMemo(() => {
      const baseColumns = [
          {
              field: 'jobcard_no',
              headerName: 'JobCard Number ',
              flex: 1,
          },
          {
              field: 'customerName',
              headerName: 'Customer Name ',
              flex: 1,
          },

          {
              field: 'mobileNumber',
              headerName: 'Customer Mobile Number',
              flex: 1,
          },
          {
              field: 'registrationNumber',
              headerName: 'Registration Number ',
              flex: 1,
          },
      ];
      return baseColumns;
  }, []);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const fetchItems = async (newOffset, searchKey, startDate, endDate) => {
      const payload = {
          limit,
          offset: newOffset,
          searchKey,
          startDate: startDate,
          endDate: endDate,
      };
      const response = await getRepairOrderStatementReportGrid(payload);
      console.log("response",response)
      const totalItems = response.data.RepairOrderData.length;
      setTotalItems(totalItems);
  };

  const onFilterCheck = () => {
      let startDate = dateRange[0] ? moment(dateRange[0].$d).format('YYYY-MM-DD') : '';
      let endDate = dateRange[1] ? moment(dateRange[1].$d).format('YYYY-MM-DD') : '';
      fetchItems(0, searchValue, startDate, endDate);
  };
  const handleExcelExport = async () => {
      let startDate = dateRange[0] ? moment(dateRange[0].$d).format('YYYY-MM-DD') : '';
      let endDate = dateRange[1] ? moment(dateRange[1].$d).format('YYYY-MM-DD') : '';

      const payload = {
          startDate: startDate,
          endDate: endDate,
      };

      try {
          const response = await getexcelExportGrid(payload);

          if (response?.data !== undefined) {
              const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `ReportOrderStatementReport.xlsx`;
              document.body.appendChild(link);
              link.click();

              document.body.removeChild(link);
              setDataStatusConfirm("Success");
          } else {
              setSnackBarMessage('Failed to download Excel file');
              setOpen(true);
              setDataStatusConfirm("Error");
          }
      } catch (error) {
          console.error('Error downloading Excel file:', error);
          setSnackBarMessage('Failed to download Excel file');
          setOpen(true);
      }
  };



  const handleSearchClick = () => {
      setOffset(0);
      fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
      const newOffset = (page - 1) * limit;
      setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
      setLimit(event.target.value);
      setOffset(0); // Reset offset when changing the limit
  };

  const handleNext = () => {
      if (offset + limit < totalItems) {
          setOffset(prevOffset => prevOffset + limit);
      }
  };

  const handlePrevious = () => {
      if (offset - limit >= 0) {
          setOffset(prevOffset => prevOffset - limit);
      }
  };

  const hasCreatePermission = useMemo(() => {
      const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
      const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
      return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
      const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
      const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
      return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  return (
      <Box>
          <StyledBreadcrumb
              mainDiv="Reports"
              subDiv="RepairOrder Statement"
          ></StyledBreadcrumb>

          {(hasCreatePermission || (hasUpdatePermission)) && (
              <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                  <form onSubmit={handleSubmit(onFilterCheck)}>
                      <Grid container spacing={2}>
                          <Grid item xs={3}>
                              <Box sx={{ marginTop: "17px" }}>
                                  <RangePicker
                                      onChange={(dates) => setDateRange(dates)}
                                      style={{ width: '100%' }}
                                      sx={{
                                          '& .ant-picker-outlined ': { marginTop: "17px" }
                                      }}
                                  />
                              </Box>
                          </Grid>
                          <Grid item xs={9}>
                              <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10PX", justifyContent: "flex-end", padding: "10px" }}>
                                  <Box>
                                      <SoftButton type="submit" variant="contained" color="primary" >
                                          Submit
                                      </SoftButton>
                                  </Box>
                                  <Box sx={{
                                      alignSelf: "flex-end",
                                      backgroundColor: "#E96B20",
                                      "&:hover": {
                                          backgroundColor: "#11164b",
                                      }

                                  }}>
                                      <Tooltip title="Download Excel">
                                          <IconButton onClick={() => handleExcelExport()} sx={{ paddingLeft: "14px !important", paddingTop: "10px !important" }}>
                                              <FaFileExcel style={{ color: "#f6f6f6", fontSize: '1.3rem', cursor: 'pointer', marginRight: '10px' }} />
                                          </IconButton>
                                      </Tooltip>
                                  </Box>
                              </Box>
                          </Grid>

                      </Grid>
                  </form>
              </StyledPaperShadow>
          )}
          <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
              <Box>
                  <Box
                      sx={{
                          display: "flex", justifyContent: "space-between", marginTop: "10px",flexWrap: "nowrap",columnGap:"30px", rowGap: "10px"
                      }}
                  >
                      <Box
                          sx={{
                              display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px", padding: "10px", width: "auto",
                              '@media (max-width: 700px)': {
                                  flexDirection: "column",
                                  columnGap: "5px",
                                  padding: "5px",
                              },
                          }}
                      >
                          <Box sx={{ alignSelf: "center", margin: "5px" }}>
                              <Typography
                                  sx={{
                                      alignSelf: "center", color: "#d9d9d9 !important", fontSize: "1rem",
                                      '@media (max-width: 700px)': {
                                          fontSize: "0.8rem",
                                      },
                                  }}
                              >
                                  Total Records: {RepairOrderStatementReportGrid?.RepairOrderData?.length}
                              </Typography>
                          </Box>
                          <Box
                              sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1px",
                                  backgroundColor: "#ffffff",
                                  paddingLeft: "10px",
                                  '@media (max-width: 700px)': {
                                      flexDirection: "column",
                                      paddingLeft: "5px",
                                  },
                              }}
                          >
                              <Typography
                                  sx={{
                                      alignSelf: "center",
                                      fontSize: "1rem",
                                      '@media (max-width: 700px)': {
                                          fontSize: "0.8rem",
                                      },
                                  }}
                              >
                                  Show:
                              </Typography>
                              <Select
                                  value={limit}
                                  onChange={handleLimitChange}
                                  sx={{
                                      marginLeft: 1,
                                      '& .MuiSelect-select': {
                                          paddingTop: "1px !important",
                                          paddingBottom: "1px !important",
                                      },
                                      '@media (max-width: 700px)': {
                                          marginLeft: 0,
                                      },
                                  }}
                              >
                                  <MenuItem value={25}>25</MenuItem>
                                  <MenuItem value={50}>50</MenuItem>
                                  <MenuItem value={75}>75</MenuItem>
                                  <MenuItem value={100}>100</MenuItem>
                              </Select>
                          </Box>
                      </Box>

                      <Box>
                          <TextField
                              variant="standard"
                              label="Search"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                              InputProps={{
                                  endAdornment: (
                                      <InputAdornment position="start">
                                          <IconButton onClick={handleSearchClick}>
                                              <IoSearchSharp />
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }}
                              sx={{
                                  '@media (max-width: 700px)': {
                                      width: "100%",
                                      marginTop: "10px",
                                  },
                              }}
                          />
                      </Box>
                  </Box>
                  <ItemGroupDatagrid data={RepairOrderStatementReportGrid?.RepairOrderData || []} columns={columns} />
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                      <ToggleButtonGroup
                          exclusive
                          sx={{
                              display: 'flex', alignItems: 'center',

                              '& .MuiToggleButtonGroup-middleButton': {
                                  marginRight: "0px !important",
                                  borderLeft: "1px solid #e5e5e5"
                              },
                              '& .MuiToggleButtonGroup-root': {
                                  border: "1px solid #e5e5e5",
                              },
                          }}
                      >
                          <ToggleButton
                              value="previous"
                              onClick={handlePrevious}
                              disabled={offset === 0}
                          >
                              Previous
                          </ToggleButton>

                          {Array.from({ length: totalPages }, (_, index) => {
                              const page = index + 1;
                              const isFirstThreePages = page <= 3;
                              const isLastThreePages = page > totalPages - 3;
                              const isCurrentPage = offset / limit === index;

                              if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                  return (
                                      <ToggleButton
                                          key={page}
                                          value={`page-${page}`}
                                          onClick={() => handlePageClick(page)}
                                          sx={{ margin: '0 5px' }}
                                          selected={isCurrentPage}
                                      >
                                          {page}
                                      </ToggleButton>
                                  );
                              }

                              if (page === 4 && !isFirstThreePages) {
                                  return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                              }

                              if (page === totalPages - 3 && !isLastThreePages) {
                                  return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                              }

                              return null;
                          })}
                          <ToggleButton
                              value="next"
                              onClick={handleNext}
                              disabled={offset + limit >= totalItems}
                          >
                              Next
                          </ToggleButton>
                      </ToggleButtonGroup>
                  </Box>

              </Box>

          </StyledPaperShadow>
          {open && (
              <StyledSnackBar
                  open={true}
                  message={snackBarMessage}
                  status={dataStatusConfirm}
              />
          )}
      </Box>
  );
};

export default RepairOrderStatement