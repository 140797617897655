import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import profilePattern from "../../../src/assets/images/profilePattern.png";
import { useMenuListMutation, useMultiUserLoginMutation } from '../../store/slice/ApiSlice';
import { getRoleId } from "../../store/slice/ControlsSlice";
import { StyledPaperShadow } from "../../theme/StyledElement";
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";

const ProfileSetting = () => {
    const { register, watch, setValue, control, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
        }
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginData = useSelector((state) => state.controls.loginData);
    const roleIdData = useSelector((state) => state.controls.roleId);

    const [multiUserLogin, { data: multiUserLoginData, }] = useMultiUserLoginMutation();
    const [menuList] = useMenuListMutation();
    const roleListData = multiUserLoginData?.roleList || [];

    useEffect(() => {
        multiUserLogin()
    }, [])

    useEffect(() => {
        if (loginData !== null && loginData !== undefined) {
            setValue('name', loginData?.employeeCode);
            setValue('roleName', loginData?.roleName);
            setValue('employeeName', loginData?.employeeName);
            setValue('outletName', loginData?.outlet?.outletName);
            setValue('outletCode', loginData?.outlet?.outletCode);
            setValue('companyName', loginData?.outlet?.companyName);
            setValue('email', loginData?.outlet?.email);
            setValue('phoneNumber', loginData?.outlet?.phoneNumber);
            setValue('city', loginData?.outlet?.city);
        }
    }, [loginData])

    useEffect(() => {
        if (roleListData.length > 0 && roleIdData) {
            const matchedRole = roleListData.find(role => role.roleId === roleIdData);
            if (matchedRole) {
                setValue('switchRole', matchedRole);
            }
        }
    }, [roleListData, roleIdData, setValue]);

    const switchRole = useWatch({ name: 'switchRole', control });

    const menuListNew = async (id) => {
        const payload = { roleId: id };
        const menusRes = await menuList(payload);

        if (menusRes.data.requestSuccessful) {
            if (menusRes?.data?.menuList.length > 0) {
                let firstPath = menusRes.data.menuList[0].path;
                if (!firstPath.trim() && menusRes.data.menuList[0].submenu && menusRes.data.menuList[0].submenu.length > 0) {
                    firstPath = menusRes.data.menuList[0].submenu[0].path;
                }

                if (firstPath) {
                    localStorage.removeItem("menuList");
                    window.localStorage.setItem("menuList", JSON.stringify(menusRes.data.menuList));
                    navigate(`${firstPath}`);
                }
            }
        }
    };
    useEffect(() => {
        if (switchRole && switchRole.roleId !== roleIdData) {
            localStorage.removeItem('roleId');
            localStorage.removeItem('menuList');
            localStorage.setItem('roleId', switchRole?.roleId);

            // Dispatch roleId
            dispatch(getRoleId(switchRole?.roleId));
            menuListNew(switchRole?.roleId);
        }
    }, [switchRole, dispatch, roleIdData]);

    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Settings"
                subDiv="Profile Settings"
            ></StyledBreadcrumb>
            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                <Box
                    sx={{
                        height: "150px",
                        background: `url(${profilePattern})`,
                        pt: 10,
                        px: 15,
                        position: "sticky",
                        top: "0px",
                        zIndex: 1,
                    }}
                ></Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: "auto", mt: -10, zIndex: 999, position: "relative"
                }}>

                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "75%", marginTop: "5px", marginLeft: "10px" }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, backgroundColor: "#d2d9eb" }}>Switch Role</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ marginBottom: "20px" }}>
                                <Controller
                                    name="switchRole"
                                    control={control}
                                    rules={{ required: 'Switch Role is required' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={roleListData || []}
                                            getOptionLabel={(option) => option.role_name || ''}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Switch Role"
                                                    variant="standard"
                                                    error={!!errors.switchRole}
                                                    helperText={errors.switchRole ? errors.switchRole.message : ''}

                                                />
                                            )}
                                            value={field.value || null}
                                            onChange={(event, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, backgroundColor: "#d2d9eb" }}>User Details</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('name')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="Name"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('name') || watch('name') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('roleName')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="RoleName"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('roleName') || watch('roleName') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('employeeName')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="EmployeeName"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('employeeName') || watch('employeeName') === '',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, backgroundColor: "#d2d9eb" }}>Outlet Details</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('outletName')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="OutletName"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('outletName') || watch('outletName') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('outletCode')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="OutletCode"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('outletCode') || watch('outletCode') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('companyName')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="CompanyName"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('companyName') || watch('companyName') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('phoneNumber')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="PhoneNumber"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('phoneNumber') || watch('phoneNumber') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('email')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="EmailId"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('email') || watch('email') === '',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register('city')}
                                    fullWidth
                                    size='small'
                                    variant="standard"
                                    label="City"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: !!watch('city') || watch('city') === '',
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </StyledPaperShadow>

                </Box>
            </StyledPaperShadow>
        </Box>
    )
}

export default ProfileSetting