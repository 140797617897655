import { Autocomplete, Box, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { useAddItemMasterMutation, useAllUomQuery, useEditItemMasterMutation, useGetAllAggregateQuery, useGetAllHsnsDataMutation, useGetAllMakesMutation, useGetAllModelMutation, useGetAllSubAggregateMutation, useGetCompanyQuery, useGetHsnQuery, useGetitemCategoryQuery, useItemGroupQuery, useItemMasterDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemMasterDatagrid from "./ItemMasterDatagrid";

const ItemMaster = () => {
  const { register, handleSubmit, reset, control, setValue, formState: { errors }, clearErrors, getValues } = useForm({
    defaultValues: {
      itemCode: "",
      itemName: "",
      itemDescription: "",
      itemgroupId: "",
      itemcategoryId: "",
      uomId: "",
      hsnId: "",
      list: null,
      cost: null,
      mrp: null,
      companyId: [],
      status: 0,
      aggregateId: null,
      subaggregateId: null,

    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [MasterDatagrid, { data: ItemMasterDataGrid }] = useItemMasterDatagridMutation();
  const [addItemMaster, { isSuccess: ItemMasterSuccess }] = useAddItemMasterMutation();
  const [editItemMaster, { isSuccess: editItemMasterSuccess }] = useEditItemMasterMutation();
  const [getSubAggregateData, { data: SubAggregateData }] = useGetAllSubAggregateMutation();
  const [getModalData, { data: getAllModalData }] = useGetAllModelMutation();
  const [getHsnTaxData] = useGetAllHsnsDataMutation();
  const [getMakeData, { data: AllMakeData }] = useGetAllMakesMutation();

  const { data: itemGroupsData } = useItemGroupQuery();
  const { data: itemCategoryData } = useGetitemCategoryQuery();
  const { data: itemCompanyData } = useGetCompanyQuery();
  const { data: itemHsnData } = useGetHsnQuery();
  const { data: itemUomData } = useAllUomQuery();
  const { data: getAllAggregateData } = useGetAllAggregateQuery();

  const [currentTab, setCurrentTab] = useState(1);
  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedAggregate, setSelectedAggregate] = useState(null);
  const [selectedSubAggregate, setSelectedSubAggregate] = useState(null);
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);
  const [selectedUom, setSelectedUom] = useState(null);
  const [selectedHsn, setSelectedHsn] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedHsnTax, setSelectedHsnTax] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getMakeData();
  }, [])

  const setSelectedValues = () => {
    if (isEditing) {
      if (SubAggregateData !== undefined && getAllModalData !== undefined) {
        const selectedSubAggregate = SubAggregateData?.subAggregates.find(subAgg => subAgg.id === selectedRowData.subaggregateId);
        setSelectedSubAggregate(selectedSubAggregate);
        setValue('subaggregateId', selectedSubAggregate);

        const selectedModel = getAllModalData?.ModelData.find(model => model.id === selectedRowData.modelId);
        setSelectedModel(selectedModel);
        setValue('modelId', selectedModel);
      }
    }

  };
  useEffect(() => {
    setSelectedValues()
  }, [SubAggregateData, getAllModalData])


  const itemGroups = itemGroupsData?.ItemGroupData || [];
  const itemCategories = itemCategoryData?.ItemCategorieData || [];
  const uoms = itemUomData?.UomData || [];
  const makes = AllMakeData?.MakeData || [];
  const hsn = itemHsnData?.hsnData || [];
  const companies = itemCompanyData?.companyData || [];
  const subAggregate = SubAggregateData?.subAggregates || [];
  const Aggregate = getAllAggregateData?.aggregateData || [];
  const model = getAllModalData?.ModelData || [];

  const selectedCompanies = useWatch({ name: 'companyId', control });
  const filteredOptions = companies.filter(
    (company) => !selectedCompanies.some((selectedCompany) => selectedCompany.id === company.id)
  );
  const [optionValue, setOptionValue] = useState([]);

  useEffect(() => {
    if (selectedAggregate !== undefined && selectedAggregate !== null) {
      const payload = {
        id: selectedAggregate.id,
      };
      getSubAggregateData(payload);
    }
  }, [selectedAggregate]);

  const fetchHsnTaxData = async (selectedHsn, setSelectedHsnTax) => {
    if (selectedHsn !== undefined && selectedHsn !== null) {
      const payload = {
        id: selectedHsn.id,
      };
      try {
        const response = await getHsnTaxData(payload);
        if (response?.data?.requestSuccessful) {
          setSelectedHsnTax(response.data.data.tax);
        }
      } catch (error) {
        console.error("Error fetching HSN tax data:", error);
      }
    }
  };

  useEffect(() => {
    fetchHsnTaxData(selectedHsn, setSelectedHsnTax);
  }, [selectedHsn]);

  useEffect(() => {
    if (selectedMake !== undefined && selectedMake !== null) {
      const payload = {
        makeId: selectedMake.id,
      };
      getModalData(payload);
    }
  }, [selectedMake])


  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, editItemMasterSuccess,]);

  useEffect(() => {
    if (ItemMasterSuccess) {
      fetchItems(offset, searchValue);
    }
  }, [ItemMasterSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey,
    };
    const response = await MasterDatagrid(payload);
    setTotalItems(response.data.itemData.totalItems);
  };
  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };


  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
      aggregateId: selectedAggregate?.id || null,
      subaggregateId: selectedSubAggregate?.id || null,
      itemgroupId: selectedItemGroup?.id || null,
      itemcategoryId: selectedItemCategory?.id || null,
      companyId: data.companyId.map(company => company.id),
      uomId: selectedUom?.id || null,
      hsnId: selectedHsn?.id || null,
      hsnCode: selectedHsn?.hsnCode || null,
      makeId: selectedMake?.id || null,
      modelId: selectedModel?.id || null,
      vehicletypeId: 1,
      taxPercentage: selectedHsnTax,
    };
    let response;
    if (isEditing) {
      response = await editItemMaster({ id: selectedRowData.id, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
      }
    } else {
      response = await addItemMaster(formData);
    }

    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response.data.message;
        resetForm();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);

  };

  const handleCancel = () => {
    reset({
      itemCode: "",
      itemName: "",
      itemDescription: "",
      itemgroupId: null,
      itemcategoryId: null,
      uomId: null,
      hsnId: null,
      taxPercentage: null,
      list: null,
      cost: null,
      mrp: null,
      status: 0,
      aggregateId: null,
      subaggregateId: null,
      companyId: [],
    });
    setIsEditing(false);
    setSelectedAggregate(null);
    setSelectedSubAggregate(null);
    setSelectedItemGroup(null);
    setSelectedItemCategory(null);
    setSelectedUom(null);
    setSelectedHsn(null);
    setSelectedHsnTax("");
    setSelectedMake(null);
    setSelectedModel(null);
    setStatus(1);
  };

  const resetForm = () => {
    reset({
      itemCode: "",
      itemName: "",
      itemDescription: "",
      itemgroupId: null,
      itemcategoryId: null,
      uomId: null,
      hsnId: null,
      list: null,
      cost: null,
      mrp: null,
      status: 0,
      aggregateId: null,
      subaggregateId: null,
      companyId: [],
    });
    setIsEditing(false);
    setSelectedAggregate(null);
    setSelectedSubAggregate(null);
    setSelectedItemGroup(null);
    setSelectedItemCategory(null);
    setSelectedUom(null);
    setSelectedHsn(null);
    setSelectedHsnTax("");
    setSelectedMake(null);
    setSelectedModel(null);
    setStatus(0);
    setCurrentTab(1);
  };

  const handleEdit = (rowData) => {
    setSelectedRowData(rowData)
    setValue('itemCode', rowData.itemCode);
    setValue('itemName', rowData.itemName);
    setValue('itemDescription', rowData.itemDescription);
    setValue('list', rowData.list);
    setValue('cost', rowData.cost);
    setValue('mrp', rowData.mrp);
    setValue('status', rowData.status);
    setSelectedHsnTax(rowData.taxPercentage);

    const selectedItemGroup = itemGroupsData?.ItemGroupData.find(group => group.id === rowData.itemgroupId);
    setSelectedItemGroup(selectedItemGroup);
    setValue('itemgroupId', selectedItemGroup);

    const selectedItemCategory = itemCategoryData?.ItemCategorieData.find(category => category.id === rowData.itemcategoryId);
    setSelectedItemCategory(selectedItemCategory);
    setValue('itemcategoryId', selectedItemCategory);

    const selectedUom = itemUomData?.UomData.find(uom => uom.id === rowData.uomId);
    setSelectedUom(selectedUom);
    setValue('uomId', selectedUom);

    const selectedHsn = itemHsnData?.hsnData.find(hsn => hsn.id === rowData.hsnId);
    setSelectedHsn(selectedHsn);
    setValue('hsnId', selectedHsn);

    const selectedMake = AllMakeData?.MakeData.find(make => make.id === rowData.makeId);
    setSelectedMake(selectedMake);
    setValue('makeId', selectedMake);

    const selectedAggregate = getAllAggregateData?.aggregateData.find(aggregate => aggregate.id === rowData.aggregateId);
    setSelectedAggregate(selectedAggregate);
    setValue('aggregateId', selectedAggregate);


    const selectedCompanyNames = rowData?.companies?.split(',')?.map(companyName => companyName.trim());
    const selectedCompanies = companies?.filter(company => selectedCompanyNames?.includes(company.name));
    setValue('companyId', selectedCompanies);

    setStatus(rowData.status ? 1 : 0);
    setIsEditing(true);
    clearErrors([
      'itemCode',
      'itemName',
      'itemDescription',
      'list',
      'cost',
      'mrp',
      'taxPercentage',
      'status',
      'itemgroupId',
      'itemcategoryId',
      'uomId',
      'hsnId',
      'makeId',
      'modelId',
      'aggregateId',
      'subaggregateId'
    ]);
    setCurrentTab(0);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsCreating(true);
    } else {
      setIsCreating(false);
      resetForm();
    }
    setCurrentTab(newValue);
  };


  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Master"
        subDiv="Item Master"
      ></StyledBreadcrumb>

      <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
          <Tabs value={currentTab} onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
              maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
              overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
            }}
          >
            {hasCreatePermission ? (
              <Tab label="Create Item Master" />
            ) : (isEditing && !hasCreatePermission) ? (
              <Tab label="Update Item Master" />
            ) : null}
            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Item Master" />)}
          </Tabs>
        </Box>

        {currentTab === 0 && (
          <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField {...register('itemCode', { required: 'Item Code is required' })}
                    fullWidth variant="standard"
                    label={<span>Item Code <span style={{ color: '#D32F2F' }}>*</span></span>}
                    InputLabelProps={isEditing && getValues('itemCode') !== "" ? { shrink: true } : {}}
                    error={!!errors.itemCode}
                    helperText={errors.itemCode ? errors.itemCode.message : ''}
                    onInput={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField {...register('itemName', { required: 'Item Name is required' })}
                    fullWidth variant="standard"
                    label={<span>Item Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.itemName}
                    helperText={errors.itemName ? errors.itemName.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField {...register('itemDescription', { required: 'Item Description is required' })}
                    fullWidth variant="standard"
                    label={<span>Item Description <span style={{ color: '#D32F2F' }}>*</span></span>}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.itemDescription}
                    multiline
                    helperText={errors.itemDescription ? errors.itemDescription.message : ''}
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="itemgroupId"
                    control={control}
                    rules={{ required: 'Item Group is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={itemGroups}
                        getOptionLabel={(option) => option.itemGroupCode}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Item Group <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.itemgroupId}
                            helperText={errors.itemgroupId ? errors.itemgroupId.message : ''}
                          />
                        )}
                        value={selectedItemGroup}
                        onChange={(event, value) => {
                          setSelectedItemGroup(value);
                          field.onChange(value ? value.id : null); // Update subaggregateId with the selected value's id
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="itemcategoryId"
                    control={control}
                    rules={{ required: 'Item Category is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={itemCategories}
                        getOptionLabel={(option) => option.itemCategorie}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Item Category <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.itemcategoryId}
                            helperText={errors.itemcategoryId ? errors.itemcategoryId.message : ''}
                          />
                        )}
                        value={selectedItemCategory}
                        onChange={(event, value) => {
                          setSelectedItemCategory(value);
                          field.onChange(value ? value.id : null);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="uomId"
                    control={control}
                    rules={{ required: 'UOM is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={uoms}
                        getOptionLabel={(option) => option.uomType}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>UOM <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.uomId}
                            helperText={errors.uomId ? errors.uomId.message : ''}
                          />
                        )}
                        value={selectedUom}
                        onChange={(event, value) => {
                          setSelectedUom(value);
                          field.onChange(value ? value.id : null);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="hsnId"
                    control={control}
                    rules={{ required: 'HsnCode is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={hsn}
                        getOptionLabel={(option) => option.hsnCode}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>HSN <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.hsnId}
                            helperText={errors.hsnId ? errors.hsnId.message : ''}
                          />
                        )}
                        value={selectedHsn}
                        onChange={(event, value) => {
                          setSelectedHsn(value);
                          field.onChange(value ? value.id : null);
                        }}

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="taxPercentage"
                    fullWidth
                    variant="standard"
                    label={<span>Tax Percentage <span style={{ color: '#D32F2F' }}>*</span></span>}
                    value={selectedHsnTax}
                    InputLabelProps={selectedHsnTax ? { shrink: true } : {}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="makeId"
                    control={control}
                    rules={{ required: 'Make is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        options={makes}
                        getOptionLabel={(option) => option.makeName}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Make <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.makeId}
                            helperText={errors.makeId ? errors.makeId.message : ''}
                          />
                        )}
                        value={selectedMake}
                        onChange={(event, value, reason) => {
                          if (value !== selectedMake) {
                            setSelectedModel(null);
                            setValue('modelId', null);
                          }
                          setSelectedMake(value);
                          field.onChange(value ? value.id : null);

                          if (value) {
                            const payload = { makeId: value.id };
                            getModalData(payload);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="modelId"
                    control={control}
                    rules={{ required: 'Model is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        options={model}
                        getOptionLabel={(option) => option.modelName}
                        isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Model <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.modelId}
                            helperText={errors.modelId ? errors.modelId.message : ''}
                          />
                        )}
                        value={selectedModel}
                        onChange={(event, value) => {
                          setSelectedModel(value);
                          field.onChange(value ? value.id : null);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="aggregateId"
                    control={control}
                    rules={{ required: 'Aggregate is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={Aggregate}
                        getOptionLabel={(option) => option.aggregateName}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Aggregate <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.aggregateId}
                            helperText={errors.aggregateId ? errors.aggregateId.message : ''}
                          />
                        )}
                        value={selectedAggregate}
                        onChange={(event, value, reason) => {
                          if (reason === 'selectOption' && isEditing) {
                            setSelectedSubAggregate(null);
                            setValue('subaggregateId', null);
                          }
                          setSelectedAggregate(value);
                          field.onChange(value ? value.id : null);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="subaggregateId"
                    control={control}
                    rules={{ required: 'Sub Aggregate is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={subAggregate}
                        getOptionLabel={(option) => option.subAggregateName}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Sub Aggregate <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.subaggregateId}
                            helperText={errors.subaggregateId ? errors.subaggregateId.message : ''}
                          />
                        )}
                        value={selectedSubAggregate}
                        onChange={(event, value) => {
                          setSelectedSubAggregate(value);
                          field.onChange(value ? value.id : null);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    {...register('list', { required: 'Price list is required', valueAsNumber: true })}
                    fullWidth
                    variant="standard"
                    label={<span>Price list <span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.list}
                    helperText={errors.list ? errors.list.message : ''}
                    inputProps={{
                      min: 0,
                      step: "any",
                      pattern: "[0-9]*\\.?[0-9]*"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    {...register('cost', { required: 'Cost is required', valueAsNumber: true })}
                    fullWidth
                    variant="standard"
                    label={<span>Cost <span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.cost}
                    helperText={errors.cost ? errors.cost.message : ''}
                    inputProps={{
                      min: 0,
                      step: "any",
                      pattern: "[0-9]*\\.?[0-9]*"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    {...register('mrp', { required: 'MRP is required', valueAsNumber: true })}
                    fullWidth
                    variant="standard"
                    label={<span>MRP <span style={{ color: '#D32F2F' }}>*</span></span>}
                    type="number"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.mrp}
                    helperText={errors.mrp ? errors.mrp.message : ''}
                    inputProps={{
                      min: 0,
                      step: "any",
                      pattern: "[0-9]*\\.?[0-9]*"
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="companyId"
                    control={control}
                    rules={{ required: 'Company  is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={filteredOptions}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Company <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.companyId}
                            sx={{
                              '& .MuiButtonBase-root-MuiChip-root': { height: "25px" }
                            }}
                            helperText={errors.companyId ? errors.companyId.message : ''}
                          />
                        )}
                        value={field.value}
                        onChange={(event, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                    <Box>
                      <FormLabel>Status</FormLabel>
                    </Box>
                    <Box>
                      <Switch checked={status === 1} onChange={handleStatusChange} />
                    </Box>
                  </Box>
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                      <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                        Cancel
                      </SoftButton>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                      <SoftButton type="submit" variant="contained" color="primary" >
                        {isEditing ? 'Update' : 'Add'}
                      </SoftButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>


            </form>
          </StyledPaperShadow>
        )}
        {currentTab === 1 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                flexWrap: "wrap",
                rowGap: "10px",
                columnGap: "30px",
                '@media (max-width: 700px)': {
                  flexDirection: "column",
                  columnGap: "10px",
                }
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  backgroundColor: "#11164b",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "auto",
                  '@media (max-width: 700px)': {
                    flexDirection: "column",
                    columnGap: "5px",
                    padding: "5px",
                    width: "100%",
                  },
                }}
              >
                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                  <Typography
                    sx={{
                      alignSelf: "center",
                      color: "#d9d9d9 !important",
                      fontSize: "1rem",
                      '@media (max-width: 700px)': {
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    Total Records: {ItemMasterDataGrid?.itemData?.totalItems}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "1px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "10px",
                    '@media (max-width: 700px)': {
                      flexDirection: "column",
                      paddingLeft: "5px",
                      width: "100%",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: "center",
                      fontSize: "1rem",
                      '@media (max-width: 700px)': {
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    Show:
                  </Typography>
                  <Select
                    value={limit}
                    onChange={handleLimitChange}
                    sx={{
                      marginLeft: 1,
                      '& .MuiSelect-select': {
                        paddingTop: "1px !important",
                        paddingBottom: "1px !important",
                      },
                      '@media (max-width: 700px)': {
                        marginLeft: 0,
                        width: "100%",
                      },
                    }}
                  >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={75}>75</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "auto",
                  '@media (max-width: 700px)': {
                    width: "100%",
                    marginTop: "10px",
                  },
                }}
              >
                <TextField
                  variant="standard"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSearchClick}>
                          <IoSearchSharp />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "auto",
                    '@media (max-width: 700px)': {
                      width: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
            <ItemMasterDatagrid onEdit={handleEdit} data={ItemMasterDataGrid?.itemData?.data || []} />
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
              <ToggleButtonGroup
                exclusive
                sx={{
                  display: 'flex', alignItems: 'center',

                  '& .MuiToggleButtonGroup-middleButton': {
                    marginRight: "0px !important",
                    borderLeft: "1px solid #e5e5e5"
                  },
                  '& .MuiToggleButtonGroup-root': {
                    border: "1px solid #e5e5e5",
                  },
                }}
              >
                <ToggleButton
                  value="previous"
                  onClick={handlePrevious}
                  disabled={offset === 0}
                >
                  Previous
                </ToggleButton>

                {Array.from({ length: totalPages }, (_, index) => {
                  const page = index + 1;
                  const isFirstThreePages = page <= 3;
                  const isLastThreePages = page > totalPages - 3;
                  const isCurrentPage = offset / limit === index;

                  if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                    return (
                      <ToggleButton
                        key={page}
                        value={`page-${page}`}
                        onClick={() => handlePageClick(page)}
                        sx={{ margin: '0 5px' }}
                        selected={isCurrentPage}
                      >
                        {page}
                      </ToggleButton>
                    );
                  }

                  if (page === 4 && !isFirstThreePages) {
                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  if (page === totalPages - 3 && !isLastThreePages) {
                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  return null;
                })}
                <ToggleButton
                  value="next"
                  onClick={handleNext}
                  disabled={offset + limit >= totalItems}
                >
                  Next
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

          </Box>

        )}
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
}

export default ItemMaster;

