import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useEditSubAggregateMutation, useGetAllAggregateQuery, useGetSubAggregateDataMutation, useGetSubAggregateDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const SubAggregate = ({ buttons }) => {
    const { register, handleSubmit, reset, setValue, control, formState: { errors }, clearErrors } = useForm({
        defaultValues: {
            subAggregateName: "",
            status: 0,
            aggregateId: null,
        }
    });

    const [addSubAggregateData, { isSuccess: SubAggregateSuccess }] = useGetSubAggregateDataMutation();
    const [getSubAggregateDatagrid, { data: SubAggregateDatagrid }] = useGetSubAggregateDatagridMutation();
    const [editSubAggregateData, { isSuccess: editSubAggregateSuccess }] = useEditSubAggregateMutation();
    const { data: getAllAggregateData } = useGetAllAggregateQuery();
    const aggregate = getAllAggregateData?.aggregateData

    const [status, setStatus] = useState(1);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        fetchItems(offset, searchValue);
    }, [offset, limit, SubAggregateSuccess, editSubAggregateSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey,
        };
        const response = await getSubAggregateDatagrid(payload);
        setTotalItems(response?.data?.subAggregateData?.totalItems);
    };

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };

    const onSubmit = async (data) => {
        const formData = {
            ...data,
            status: status ? 1 : 0,
            aggregateId: data?.aggregateId?.id || null,
        };
        let response;
        if (isEditing) {
            response = await editSubAggregateData({ id: currentItemId, ...formData });
            if (response && response.data.requestSuccessful === true) {
                setIsEditing(false);
                setCurrentItemId(null);
            }
        } else {
            response = await addSubAggregateData(formData);
        }
        if (response && response.data) {
            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                reset();
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                reset();
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);

    };

    const handleStatusChange = (event) => {
        setStatus(event.target.checked ? 1 : 0);
    };

    const handleEditClick = (row) => {
        const selectedAggregate = aggregate?.find(aggregate => aggregate.aggregateName === row.aggregateName) || null;
        setValue('aggregateId', selectedAggregate);

        setValue('subAggregateName', row.subAggregateName);
        setStatus(row.status ? 1 : 0);
        setIsEditing(true);
        setCurrentItemId(row.id);
        clearErrors(['aggregateName', 'subAggregateName']);
    };

    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };

    const columns = useMemo(() => [
        {
            field: 'aggregateName',
            headerName: 'Aggregate Name',
            flex: 1,
        },
        {
            field: 'subAggregateName',
            headerName: 'Sub Aggregate',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                <Chip
                    label={
                        <>
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                                    marginRight: '8px',
                                }}
                            />
                            {params.value ? 'Active' : 'Inactive'}
                        </>
                    }
                    style={{
                        textTransform: 'capitalize',
                        width: "90px",
                    }}
                />
            ),
        },
        ...(buttons.includes(3) ? [{
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Edit">
                    <IconButton
                        onClick={() => handleEditClick(params.row)}
                        style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
                        sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
                    >
                        <MdEdit />
                    </IconButton>
                </Tooltip>
            ),
        }] : []),
    ], [buttons]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };

    const handleCancel = () => {
        reset({
            aggregateId: "",
            subAggregateName: ""
        })
        setStatus(1);
        setIsEditing(false);
    };

    return (
        <Box>
            {(buttons.includes(1) || (buttons.includes(3) && isEditing)) && (
                <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Controller
                                    name="aggregateId"
                                    control={control}
                                    rules={{ required: 'Aggregate is required' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={aggregate}
                                            getOptionLabel={(option) => option?.aggregateName}
                                            isOptionEqualToValue={(option, value) => (option.aggregateName === value.aggregateName)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<span>Aggregate<span style={{ color: '#D32F2F' }}>*</span></span>}
                                                    variant="standard"
                                                    InputLabelProps={isEditing ? { shrink: true } : {}}
                                                    error={!!errors.aggregateId}
                                                    helperText={errors.aggregateId ? errors.aggregateId.message : ''}
                                                />
                                            )}
                                            value={field.value || null}
                                            onChange={(event, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    {...register('subAggregateName', { required: 'Sub AggregateName is required' })}
                                    InputLabelProps={isEditing ? { shrink: true } : {}}
                                    fullWidth variant="standard"
                                    label={<span>SubAggregate<span style={{ color: '#D32F2F' }}>*</span></span>}
                                    error={!!errors.subAggregateName}
                                    helperText={errors.subAggregateName ? errors.subAggregateName.message : ''}
                                    onInput={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                                    <Box>
                                        <FormLabel>Status</FormLabel>
                                    </Box>
                                    <Box>
                                        <Switch checked={status === 1} onChange={handleStatusChange} />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: "flex", justifyContent: "flex-end",flexWrap:"wrap" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                        <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                                            Cancel
                                        </SoftButton>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                        <SoftButton type="submit" variant="contained" color="primary" >
                                            {isEditing ? 'Update' : 'Add'}
                                        </SoftButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </StyledPaperShadow>
            )}
            <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            rowGap: "10px",
                            columnGap: "30px",
                            '@media (max-width: 700px)': {
                                flexDirection: "column",
                                columnGap: "10px",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                backgroundColor: "#11164b",
                                borderRadius: "5px",
                                padding: "10px",
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column",
                                    columnGap: "5px",
                                    padding: "5px",
                                    width: "100%",
                                },
                            }}
                        >
                            <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "#d9d9d9 !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Total Records: {SubAggregateDatagrid?.subAggregateData?.totalItems}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "1px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "10px",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column",
                                        paddingLeft: "5px",
                                        width: "100%",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Show:
                                </Typography>
                                <Select
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{
                                        marginLeft: 1,
                                        '& .MuiSelect-select': {
                                            paddingTop: "1px !important",
                                            paddingBottom: "1px !important",
                                        },
                                        '@media (max-width: 700px)': {
                                            marginLeft: 0,
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    width: "100%",
                                    marginTop: "10px",
                                },
                            }}
                        >
                            <TextField
                                variant="standard"
                                label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSearchClick}>
                                                <IoSearchSharp />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "auto",
                                    '@media (max-width: 700px)': {
                                        width: "100%",
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <ItemGroupDatagrid data={SubAggregateDatagrid?.subAggregateData?.data || []} columns={columns} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                        <ToggleButtonGroup
                            exclusive
                            sx={{
                                display: 'flex', alignItems: 'center',
                                '& .MuiToggleButtonGroup-middleButton': {
                                    marginRight: "0px !important",
                                    borderLeft: "1px solid #e5e5e5"
                                },
                                '& .MuiToggleButtonGroup-root': {
                                    border: "1px solid #e5e5e5",
                                },
                            }}
                        >
                            <ToggleButton
                                value="previous"
                                onClick={handlePrevious}
                                disabled={offset === 0}
                            >
                                Previous
                            </ToggleButton>

                            {Array.from({ length: totalPages }, (_, index) => {
                                const page = index + 1;
                                const isFirstThreePages = page <= 3;
                                const isLastThreePages = page > totalPages - 3;
                                const isCurrentPage = offset / limit === index;

                                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                    return (
                                        <ToggleButton
                                            key={page}
                                            value={`page-${page}`}
                                            onClick={() => handlePageClick(page)}
                                            sx={{ margin: '0 5px' }}
                                            selected={isCurrentPage}
                                        >
                                            {page}
                                        </ToggleButton>
                                    );
                                }

                                if (page === 4 && !isFirstThreePages) {
                                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                if (page === totalPages - 3 && !isLastThreePages) {
                                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                return null;
                            })}

                            <ToggleButton
                                value="next"
                                onClick={handleNext}
                                disabled={offset + limit >= totalItems}
                            >
                                Next
                            </ToggleButton>
                        </ToggleButtonGroup>

                    </Box>

                </Box>

            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
};

export default SubAggregate;