import React, { useState,useRef, useEffect } from 'react';
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import DynamicTable from './PurchaseReturnTable';
import { useCreatePurchaseReturnMutation,useGetGrnsMutation,useGetGRNForPurchaseReturnDataMutation } from '../../../store/slice/ApiSlice';
const CreatePurchaseReturn = ({setCurrentTab}) => {
  const [getData]=useGetGRNForPurchaseReturnDataMutation()
  const [getGrn,{isLoading}]=useGetGrnsMutation()
  const [CreatePurchaseReturn, { isSuccess, }] = useCreatePurchaseReturnMutation(); // Prepare the add item mutation
  // searchKey, offset, limit
  const [rows, setRows] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue, control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      grnNo:""
    },
  });

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grnData,setGrnData]=useState([])
  const [grnNo,setGrnNo]=useState("")
  const [vendorCode,setVendorCode]=useState("")
  const [vendorName,setVendorName]=useState("")
  const[ headers,setHeaders ]= useState([
    'Parts Code',
    'Description',
    // 'Hsn Code',
    'Available Qty',
    'Return Qty',
    'Cost',
    // 'Discount Amount',
    'CGST',
    'SGST',
    'IGST',
    'Total Amount',
  ])
  const onSubmit = async (data) => {
    console.log("Pay", dynamicTableData)
    console.log(data,"dataa")
    const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

// This arrangement can be altered based on how we want the date's format to appear.
let currentDate = `${day}/${month}/${year}`;
console.log(currentDate); 

    const purchasereturndata={
      grn_no:data.grnNo,
      vendor_code:data.vendorCode,
      vendor_name:data.vendorName,
      purchase_return_date:currentDate
    }
    const formData = dynamicTableData.filter(item=>item["Return Qty"]&& item["Return Qty"]>0).map((obj) => ({
      id:obj.id,
      item_code: obj["Parts Code"],
      item_description: obj.Description,
      quantity: parseFloat(obj["Return Qty"]), // Convert to number if needed
      cost: parseFloat(obj.Cost), // Convert to number if needed
      cgst: obj.CGST,
      sgst: obj.SGST,
      igst: obj.IGST,
      total: parseFloat(obj["Total Amount"]), // Convert to number if needed
    }));
 


    console.log("formdata", formData)


    let response = await CreatePurchaseReturn({purchaseReturnData:purchasereturndata,purchaseReturnPartsData:formData})
    console.log(response,"ress")
    if (response.data && response.data.requestSuccessful) {
      let  message = response.data.message;
        setDataStatusConfirm('Success');
        reset();
        setDynamicTableData([]);
        setGrandTotal(0);
        setRows([])
        setVendorCode("")
        setVendorName("")
        setGrnNo("")
      setSnackBarMessage(message);
      setCurrentTab(1)
    } else {
      let message;
        setDataStatusConfirm('Error');
        // message = response.error.data.validationErrors;
      
      setSnackBarMessage(response.error.data.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  

  const handleDataSubmit = (data) => {
    setDynamicTableData(data);
    calculateGrandTotal(data);
  };

  const calculateGrandTotal = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row['Total Amount'] || 0), 0);
    setGrandTotal(total);
  };

  const handleGrnSearch = async (searchKey) => {
    if(searchKey){
    try {
      const offset = 0;
      const limit = 100; // Adjust as needed
      const response = await getGrn({ searchKey, offset, limit }).unwrap();
      if (response.requestSuccessful) {
      console.log(response,"ress")
      setGrnData(response?.data);
      } // Assuming the response has data in `response.data`
    } catch (error) {
      console.error("Error fetching vendor data", error);
    }
  };
}

const fetchdata=async(id,vendorCode)=>{
  console.log(id,"id")
  let response=await getData({id:id})
  console.log(response,"response")
 if(response.data&&response.data.requestSuccessful&&response?.data?.data){
  setRows(response?.data?.data)
  setVendorCode(vendorCode)
  setVendorName(response.data.vendorName)
 }

}

console.log(rows,"jobcarddata")
  return (
    <Box>

      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
    <Grid container spacing={2}>
       <Grid item xs={4}>
         <Typography variant="h6" sx={{ marginTop: '20px' }}>
            Grand Total: {grandTotal.toFixed(2)}
          </Typography>
       </Grid>
       <Grid item xs={4}>

       </Grid>
       <Grid item xs={4}>
       <Controller
        name="grnNo"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete 
            options={grnData} // Adjust to map the correct field
            getOptionLabel={(option) => option.grn_no ||grnNo}
            value={grnNo}
            onChange={(_, data) => {
              if(data){
                fetchdata(data.id,data.vendor_code)
              }
              if(data?.grn_no&&data?.id&&data.vendor_code){
                onChange(data.grn_no)
                setGrnNo(data.grn_no)
              }
            }

            } // Handle change
            onInputChange={(_, value) => handleGrnSearch(value)} // Fetch vendor data based on input
            filterOptions={(options, { inputValue }) => 
              options.filter(
                (option) => 
                  option.grn_no.toLowerCase().includes(inputValue.toLowerCase()) || 
                  option.invoice_number.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            renderOption={(props, option) => (
              <Box 
                  key={option.grn_no}
                  component="li"
                  {...props}
              >
                  {option.grn_no+" / "+option.invoice_number}
              </Box>
          )}
            loading={isLoading} // Show a loading state while fetching
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grn No or Invoice Number"
                variant="standard"
                fullWidth
              />
            )}
          />
        )}
      />
       </Grid>
     
    </Grid>
    {rows.length>0&&
    <>
    <Grid container spacing={2} sx={{my:"5px"}}>
      <Grid item xs={4}>
      <TextField style={{backgroundColor:"#F0F0F0"}}
                {...register('vendorCode')}
                value={vendorCode}
                fullWidth
                variant="standard"
                label="vendorCode"
                
              />
      </Grid>
      <Grid item xs={4}>
      </Grid>
      <Grid item xs={4}>
      <TextField style={{backgroundColor:"#F0F0F0"}}
                {...register('vendorName')}
                value={vendorName}
                fullWidth
                variant="standard"
                label="vendorName"
              />
      </Grid>
    </Grid>

        
          <DynamicTable headers={headers} onSubmit={handleDataSubmit} 
          rows={rows} setRows={setRows}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <SoftButton type="submit" variant="contained" color="primary">
              {'Submit'}
            </SoftButton>
          </Box> 
          </>
          }
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default CreatePurchaseReturn;
