import Accounts from "../components/accounts/Accounts";
import ActivationLink from "../components/activationLink/ActivationLink";
import CallRequest from "../components/callRequest/CallRequest";
import ContractSale from "../components/contractSale/ContractSale";
import Dashboard from "../components/dashboard/Dashboard";
import BatteryOem from "../components/fitMaster/BatteryOem";
import CheckListType from "../components/fitMaster/CheckListType";
import ClickinPartName from "../components/fitMaster/ClickinPartName";
import DockAbuseField from "../components/fitMaster/DockAbuseField";
import DockField from "../components/fitMaster/DockField";
import InspectionCheckList from "../components/fitMaster/InspectionCheckList";
import InspectionRatingReason from "../components/fitMaster/InspectionRatingReason";
import InspectionSubsystem from "../components/fitMaster/InspectionSubsystem";
import InventoryChecklist from "../components/fitMaster/InventoryChecklist";
import InventoryPhotoCategory from "../components/fitMaster/InventoryPhotoCategory";
import ParamAlertSchedule from "../components/fitMaster/ParamAlertSchedule";
import PickupType from "../components/fitMaster/PickupType";
import TyreOem from "../components/fitMaster/TyreOem";
import TyreSize from "../components/fitMaster/TyreSize";
import VehicleInventory from "../components/fitMaster/VehicleInventory";
import VehiclePredelivery from "../components/fitMaster/VehiclePredelivery";
import DashboardLogin from "../components/login/Login";
import AuditLog from "../components/logs/AuditLog";
import UserLog from "../components/logs/UserLog";
import BinLocation from "../components/master/binLocation/BinLocation";
import Customer from "../components/master/customer/Customer";
import Disposition from "../components/master/disposition/Disposition";
import DsaAgent from "../components/master/dsaAgent/DsaAgent";
import Employee from "../components/master/employee/Employee";
import EmployeeRole from "../components/master/employeeRole/EmployeeRole";
import Insurance from "../components/master/insurance/Insurance";
import ItemGroup from "../components/master/itemGroup/ItemGroupTab";
import ItemMaster from "../components/master/itemMaster/ItemMaster";
import LabourSchedule from "../components/master/labourSchedule/LabourSchedule";
import Make from "../components/master/make/Make";
import Model from "../components/master/model/Model";
import Oulet from "../components/master/outlet/Oulet";
import RepairType from "../components/master/repairType/RepairType";
import ServiceType from "../components/master/serviceType/ServiceType";
import SourceTab from "../components/master/source/SourceTab";
import SubDisposition from "../components/master/subDisposition/SubDisposition";
import Variant from "../components/master/variant/Variant";
import Vehicle from "../components/master/vehicle/Vehicle";
import Vendor from "../components/master/vendor/Vendor";
import MenuSettingTab from "../components/MenuSettings/MenuSettingTab";
import Receipts from "../components/receipts/Receipts";
import DeliveryReport from "../components/reports/DeliveryReport";
import JobCardBillSummary from "../components/reports/JobCardBillSummary";
import JobCardStatus from "../components/reports/JobCardStatus";
import JobCardWorkInprogress from "../components/reports/JobCardWorkInprogress";
import ServiceBookingReport from "../components/reports/ServiceBookingReport";
import WorkOrderStatement from "../components/reports/WorkOrderStatement";
import BillTab from "../components/transactions/bill/BillTab";
import CashBillViewPage from "../components/transactions/bill/CashBillViewPage";
import CreditBillViewPage from "../components/transactions/bill/CreditBillViewPage";
import AjcInsurance from "../components/transactions/createJobcard/AjcInsurance";
import CreateJobcard from "../components/transactions/createJobcard/CreateJobcard";
import JobCardViewPage from "../components/transactions/createJobcard/JobCardViewPage";
import MechanicMapping from "../components/transactions/createJobcard/MechanicMapping";
import GatePass from "../components/transactions/gatePass/GatePass";
import GatePassViewPage from "../components/transactions/gatePass/GatePassViewPage";
import ServiceBooking from "../components/transactions/serviceBooking/ServiceBooking";
import ServiceEstimate from "../components/transactions/serviceEstimate/ServiceEstimate";
import UserCreation from "../components/UserCreation/UserCreation";
import ProfileSetting from "../layout/Settings/ProfileSetting";
import ChittaStatement from "../components/reports/ChittaStatement";
import ItemUploads from "../components/BulkUploads/ItemUploads";
import LabourUploads from "../components/BulkUploads/LabourUploads";
import CustomerUploads from "../components/BulkUploads/CustomerUploads";
import VehicleUploads from "../components/BulkUploads/VehicleUploads";
import MakeUploads from "../components/BulkUploads/MakeUploads";
import ModelUploads from "../components/BulkUploads/ModelUploads";
import RepairOrderStatement from "../components/reports/RepairOrderStatement";

import GRN from "../components/part/grn/GRN"
import SpareIssue from "../components/part/SpareIssue/SpareIssue";
import SpareReturn from "../components/part/SpareReturn/SpareReturn";
import PurchaseOrder from "../components/part/purchaseOrder/PurchaseOrder"
import PurchaseReturn from "../components/part/purchaseReturn/PurchaseReturn";
import QuickItemSearch from "../components/part/QuickItemSearch/QuickitemSearch";
import CounterSale from "../components/part/counterSales/CounterSale";
import CSR from "../components/part/counterSaleReturn/CSR";
import PurchaseReport from "../components/part/Report/PurchaseReport";
import PurchaseAxaptaReport from "../components/part/Report/PurchaseAxaptaReport";
export default [
  {
    isProtected: true,
    children: [
      {
        path: "/",
        component: DashboardLogin,
      },
      {
        path: "/dashboard",
        component: Dashboard,
      },
      {
        path: "/itemMaster",
        component: ItemMaster,
      },

      {
        path: "/itemGroup",
        component: ItemGroup,
      },
      {
        path: "/make",
        component: Make,
      },
      {
        path: "/serviceType",
        component: ServiceType,
      },
      {
        path: "/repairType",
        component: RepairType,
      },
      {
        path: "/source",
        component: SourceTab,
      },
      {
        path: "/model",
        component: Model,
      },
      {
        path: "/variant",
        component: Variant,
      },

      {
        path: "/userActivation",
        component: ActivationLink,
      },
      {
        path: "/vendor",
        component: Vendor,
      },
      {
        path: "/auditLog",
        component: AuditLog,
      },
      {
        path: "/userLogs",
        component: UserLog,
      },
      {
        path: "/disposition",
        component: Disposition,
      },
      {
        path: "/subDisposition",
        component: SubDisposition,
      },
      {
        path: "/labourSchedule",
        component: LabourSchedule,
      },
      {
        path: "/dsaAgent",
        component: DsaAgent,
      },
      {
        path: "/outlet",
        component: Oulet,
      },
      {
        path: "/binLocation",
        component: BinLocation,
      },
      {
        path: "/employee",
        component: Employee,
      },
      {
        path: "/employeeRole",
        component: EmployeeRole,
      },
      {
        path: "/customer",
        component: Customer,
      },
      {
        path: "/vehicle",
        component: Vehicle,
      },
      {
        path: "/accounts",
        component: Accounts,
      },
      {
        path: "/contractSale",
        component: ContractSale,
      },
      {
        path: "/receipts",
        component: Receipts,
      },
      {
        path: "/callRequest",
        component: CallRequest,
      },
      {
        path: "/insurance",
        component: Insurance,
      },
      {
        path: "/createJobcard",
        component: CreateJobcard,
      },
      {
        path: "/serviceEstimate",
        component: ServiceEstimate,
      },
      {
        path: "/serviceBooking",
        component: ServiceBooking,
      },
      {
        path: "/tyreSize",
        component: TyreSize,
      },

      {
        path: "/tyreOem",
        component: TyreOem,
      },

      {
        path: "/batteryOem",
        component: BatteryOem,
      },
      {
        path: "/pickupType",
        component: PickupType,
      },
      {
        path: "/vehiclePredelivery",
        component: VehiclePredelivery,
      },
      {
        path: "/clickinPartname",
        component: ClickinPartName,
      },
      {
        path: "/checkListType",
        component: CheckListType,
      },
      {
        path: "/inspectionSubsystem",
        component: InspectionSubsystem,
      },
      {
        path: "/inspectionCheckList",
        component: InspectionCheckList,
      },
      {
        path: "/inspectionRating",
        component: InspectionRatingReason,
      },
      {
        path: "/dockField",
        component: DockField,
      },
      {
        path: "/dockAbuseField",
        component: DockAbuseField,
      },
      {
        path: "/vehicleInventory",
        component: VehicleInventory,
      },
      {
        path: "/inventoryChecklist",
        component: InventoryChecklist,
      },
      {
        path: "/paramAlertSchedule",
        component: ParamAlertSchedule,
      },
      {
        path: "/createUser",
        component: UserCreation,
      },
      {
        path: "/inventoryPhotoCategory",
        component: InventoryPhotoCategory,
      },
      {
        path: "/serviceBookingReport",
        component: ServiceBookingReport,
      },
      {
        path: "/createJobcard/jobCardViewPage",
        component: JobCardViewPage,
      },
      {
        path: "/createJobcard/mechanicMapping",
        component: MechanicMapping,
      },
      {
        path: "/bill",
        component: BillTab,
      },
      {
        path: "/bill/cashBillViewPage",
        component: CashBillViewPage,
      },
      {
        path: "/gatePass",
        component: GatePass,
      },
      {
        path: "/gatePass/gatePassViewPage",
        component: GatePassViewPage,
      },
      {
        path: "/purchaseOrder",
        component: PurchaseOrder,
      },
      {
        path: "/grn",
        component: GRN,
      },

      {
        path: "/spareIssue",
        component: SpareIssue,
      },

      {
        path: "/createJobcard/insurance",
        component: AjcInsurance,
      },
      {
        path: "/setting/profileSetting",
        component: ProfileSetting,
      },
      {
        path: "/jobCardStatus",
        component: JobCardStatus,
      },
      {
        path: "/jobCardWorkInProgress",
        component: JobCardWorkInprogress,
      },
      {
        path: "/JobCardBillSummary",
        component: JobCardBillSummary,
      },
      {
        path: "/deliveryReport",
        component: DeliveryReport,
      },
      {
        path: "/menuSettings",
        component: MenuSettingTab,
      },
      {
        path: "/workOrderStatement",
        component: WorkOrderStatement,
      },
      {
        path: "/bill/creditBillViewPage",
        component: CreditBillViewPage,
      },
      {
        path: "/chittaStatement",
        component: ChittaStatement,
      },
      {
        path: "/spareReturn",
        component: SpareReturn
      },
      {
        path:"/purchaseReturn",
        component:PurchaseReturn
      },
      {
        path:"/quickItemSearch",
        component:QuickItemSearch
      },
      {
        path:"/labourBulkUpload",
        component:LabourUploads
      },
      {
        path:"/itemsBulkUpload",
        component:ItemUploads
      },
      {
        path:"/customerBulkUpload",
        component:CustomerUploads
      },
      {
        path:"/vehicleBulkUpload",
        component:VehicleUploads
      },
      {
        path:"/makeBulkUpload",
        component:MakeUploads
      },
      {
        path:"/modelBulkUpload",
        component:ModelUploads
      },



      {
        path:"/counterSale",
        component:CounterSale
      },
      {
        path:"/counterSaleReturn",
        component:CSR
      },
      {
        path:"/purchaseReport",
        component:PurchaseReport
      },
      {
        path:"/purchaseAxaptaReport",
        component:PurchaseAxaptaReport
      },
      {
        path:"/repairOrderStatement",
        component:RepairOrderStatement
      }

    ],
  },

];
