import React, { useState, useEffect,useRef,useMemo } from "react";
import { Typography, Box, Grid, TextField,Tab,Tabs,Select,MenuItem,IconButton } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { StyledPaperShadow, SoftButton } from "../../../theme/StyledElement";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import { useNavigate } from 'react-router-dom';
import { useGetGrnsMutation,useGetGrnPdfDataMutation } from "../../../store/slice/ApiSlice";
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import 'html2canvas'; // Import html2canvas
import { IoSearchSharp } from "react-icons/io5";
import InputAdornment from '@mui/material/InputAdornment';
import AddGRN from "./AddGRN";
import kilogo from "../../../assets/images/kilogo.png"
import mytvslogo from "../../../assets/images/tvslogo.png"


const GRN = () => {
      

  const [getGrnGrid,{ data }] = useGetGrnsMutation({ refetchOnMountOrArgChange: true });
  const [getGrnPdfData,{ data:pdfData }] = useGetGrnPdfDataMutation({ refetchOnMountOrArgChange: true });

  const [limit,setLimit] = useState(25); // Number of records per page
  const [offset, setOffset] = useState(0); // Current page offset
  const [currentTab, setCurrentTab] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  

  useEffect(() => {
    if(currentTab==1){
      fetchItems(offset, searchValue);
    }
}, [offset, limit,currentTab]);

const fetchItems = async (newOffset, searchKey) => {
    const payload = {
        limit,
        offset: newOffset,
        searchKey,
    };
    const response = await getGrnGrid(payload);
    if(response?.data?.requestSuccessful){
      setTotalItems(response?.data?.data?.length);

    } 
};


  const handleNext = () => {
    const newOffset = offset + limit;
    if (newOffset < totalItems) {
      setOffset(newOffset);
    }
  };

  const handlePrevious = () => {
    const newOffset = offset - limit;
    if (newOffset >= 0) {
      setOffset(newOffset);
    }
  };



  const handleEditClick = async(row) => {
   const response =await getGrnPdfData({id:row.id})
   if(response?.data?.requestSuccessful){
   console.log(response,"ressponse")
   const outletdetails=response.data.outlet_details
   const vendordatails=response.data.data[0].grnvendormap
   const grndetails=response.data.data[0]
   const partsdetails=response.data.data[0].grnparts
   
   function numberToWordsIndian(num) {
    if (num === 0) return 'zero';

    const belowTwenty = [
        'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
        'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const tens = [
        'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    const scales = [
        '', 'thousand', 'lakh', 'crore'
    ];

    function helper(n) {
        if (n === 0) return '';
        if (n < 20) return belowTwenty[n - 1] + ' ';
        if (n < 100) return tens[Math.floor(n / 10) - 2] + ' ' + helper(n % 10);
        if (n < 1000) return belowTwenty[Math.floor(n / 100) - 1] + ' hundred ' + (n % 100 !== 0 ? 'and ' : '') + helper(n % 100);
    }

    function convertWholeNumber(n) {
        let word = '';
        let scaleIndex = 0;

        while (n > 0) {
            let chunk;
            if (scaleIndex === 0) {
                // First segment (thousands), handle 3 digits
                chunk = n % 1000;
                n = Math.floor(n / 1000);
            } else {
                // Lakhs and Crores, handle 2 digits
                chunk = n % 100;
                n = Math.floor(n / 100);
            }

            if (chunk !== 0) {
                word = helper(chunk) + scales[scaleIndex] + ' ' + word;
            }

            scaleIndex++;
        }

        return word.trim();
    }

    // Separate the rupees and paisa
    const rupees = Math.floor(num);
    const paisa = Math.round((num - rupees) * 100);

    let result = '';
    if (rupees > 0) {
        result += convertWholeNumber(rupees) + ' rupees ';
    }

    if (paisa > 0) {
        result += 'and ' + convertWholeNumber(paisa) + ' paisa';
    }

    return result.trim();
}


const amountInWords = numberToWordsIndian(grndetails.pdf_total+grndetails.frieght_charges+grndetails.mis_charges);


    const doc = new jsPDF();
    const pageHeight = 841.89;
    const htmlstring=`
    <div style="color: black;font-family:Times New Roman;text-align:justify;line-height:1.2;letter-spacing: 0.01px;">
    <div style="display: flex;flex-direction: row;justify-content:space-between;align-item:flex-start;">
    <div style="flex:1">
    <img src="${kilogo}" style="width: 120px; height: auto;"/>
    </div>
    <div style="flex:5;margin-top:20px">
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_name}
    </p>
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_address1}
    </p>
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_address2}
    </p>
    <p style="font-size:16px;font-weight:500;margin:0">
    ${outletdetails.outlet_city}
    </p>
    </div>
    <div style="flex:4;display: flex; justify-content: flex-end;margin-top:20px">
    <img src="${mytvslogo}" style="width: 150px; height: 40px;"/>
    </div>
    </div>
    <div style="display:flex;flex-direction:row;border-bottom:1px solid black;border-top:1px solid black;line-height:0.5;margin-top:5px">
    <p>
    DEALER GSTIN : 
    </p>
    <p>
     ${outletdetails.outlet_gst}
    </p>
    </div>
    <div style="border-bottom:1px solid black;">
    <p style="color:#aa2e2b; font-size:22px;font-weight:bold;line-height:0.8;text-align:center">
    SPARE PURCHASE RECEIPT
    </p>
    </div>
    <div style="display: flex; justify-content: space-between; gap: 20px;">
    <div style="flex: 1;">
        <div style="display: flex; align-items: flex-start; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">SUPPLIER CODE</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${row.vendor_code}</span>
        </div>
        <div style="display: flex; align-items: flex-start; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">SUPPLIER ADDRESS</span>
            <span style="margin: 0 10px;">:</span>
            <div style="flex: 2">
            <p style="text-align: left;margin:0;">${vendordatails.address1}</p>
            <p style="text-align: left;margin:0">${vendordatails.address2}</p>
            <p style="text-align: left;margin:0">${vendordatails.city},${vendordatails.pincode}</p>
            <p style="text-align: left;margin:0">Mobile Number: ${vendordatails.mobileNumber}</p>
            </div>
        </div>
        <div style="display: flex; align-items: flex-start; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">SUPPLIER INVOICE NUMBER</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${row.invoice_number}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">TIN No</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">0</span>
        </div>
    </div>
    <div style="flex: 1;">
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">DOCUMENT</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${row.grn_no}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">DOC DATE</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${row.invoice_date}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">BRANCH</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${outletdetails.branch}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">E-SUGAM/ROAD PERMIT NO</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${grndetails.e_sugam_no}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">LR NUMBER</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${grndetails.lr_number}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">TRANSPORTER NAME</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;"> ${grndetails.transport_name}</span>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 2px;">
            <span style="font-weight: bold; flex: 1;">LR DATE</span>
            <span style="margin: 0 10px;">:</span>
            <span style="flex: 2; text-align: left;">${grndetails.lr_date}</span>
        </div>
    </div>
</div>

    <table style="border-collapse: collapse;margin-top:10px; width: 100%;">
    <tr>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">SNO</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">CODE</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">NAME</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">Po Number</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">LOCATION</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">QTY</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">Cost</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">DISC</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">TAX</td>
        <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">AMOUNT</td>
    </tr>
    ${partsdetails.map((item, index) => `
      <tr>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${index + 1}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.item_code}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;max-width:100pt;">${item.item_description}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.po_number || ''}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.binlocation}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.quantity}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: center; color: black;">${item.cost }</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: right; color: black;">${item.discount}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: right; color: black;">${item.tax}</td>
          <td style="border: 1pt solid black; padding-top: 3px; padding-bottom: 3px; text-align: right; color: black;">${item.total}</td>
      </tr>
      `).join('')}
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="5">SUB TOTAL</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${grndetails.total_quantity}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: center; color: black;">${grndetails.total_cost}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.total_discount}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.total_tax}</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.pdf_total}</td>
    </tr>
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="9">Freight Charges</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.frieght_charges}</td>
    </tr>
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="9">Mis Charges</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.mis_charges}</td>
    </tr>
    <tr>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;" colspan="9">Net Amount</td>
        <td style="border: 1pt solid black; padding-top: 2px; padding-bottom: 2px; text-align: right; color: black;">${grndetails.pdf_total+grndetails.frieght_charges+grndetails.mis_charges}</td>
    </tr>
</table>
<div style="display: flex; justify-content:flex-start;">
<p style="font-weight: bold;margin-right:10pt">
AMOUNT IN WORDS: 
</P>
<p style="font-weight:700">
Rupees ${amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)} only
</p>
</div>
<div style="margin-top:50pt">
<p style="text-align:right;font-weight:700">
For  ${outletdetails.outlet_name}
</p>
</div>
<div style="display: flex; justify-content:space-between;margin-top:40pt">
<div style="border-top: 2px solid #a2a0a0;padding-right:30pt">
<p style="font-weight:700;margin:0">
Customer Signature   
</p>
</div>
<div style="border-top: 2px solid #a2a0a0;padding-left:30pt">
<p style="font-weight:700;margin:0">
Authorized Signature
</p>
</div>
</div>
<div style="position:absolute; top:${pageHeight+50}pt;border-top:1px solid black;width:100%">
<p style="text-align:center;margin:0">
Regd. Off. No. 10, Jawahar Road, Madurai – 625 002
</p>
<p style="text-align:center;margin:0">
Mail id: enquiry@tvs.in, Website: www.tvsautomobilesolutions.com
</p>
<p style="text-align:center;margin:0">
ki Mobility Solutions Private Limited (formerly Peninsula Auto Parts Private Limited)
</p>
</div>
</div>
`

    doc.html(htmlstring, {
        callback: function (pdf) {
            pdf.save('SparePurchaseReceipt.pdf');
        },
        x: 10,
        y: 0,
        width: 210, // Width in mm
        windowWidth: 800, // Adjusts the window width for rendering
        margin: [0, 0, 0, 0], // Top, Left, Bottom, Right margins
        autoPaging: 'text',
        // html2canvas: { scale: 0.2 }
    });
  }
  else{

  }
  };
console.log(data,"data")

const handleTabChange = (event, newValue) => {
  setCurrentTab(newValue);
};

const hasCreatePermission = useMemo(() => {
  const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
  const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
  return buttonList.split(',').includes("1");
}, [menuList, pathname]);

const hasUpdatePermission = useMemo(() => {
  const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
  const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
  return buttonList.split(',').includes("3");
}, [menuList, pathname]);

const handleLimitChange = (event) => {
  setLimit(event.target.value);
  setOffset(0); // Reset offset when changing the limit
};
const handleSearchClick = () => {
  setOffset(0);
  fetchItems(0, searchValue);
};
  return (
    <>
    <Box>
      <StyledBreadcrumb mainDiv="Parts" subDiv="GRN" />
      <StyledPaperShadow
        elevation={0}
        sx={{ padding: "1px 20px 20px 20px", display: "inline-block", width: "100%" }}
      >

<Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                    <Tabs value={currentTab} onChange={handleTabChange}
                        sx={{ '&  .MuiTabs-indicator': { backgroundColor: "#e96b20" } }}
                    >
                        {hasCreatePermission ? (
                            <Tab label="Create GRN" />
                        ) : (isEditing && !hasCreatePermission) ? (
                            <Tab label="Update GRN" />
                        ) : null}

                        {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="GRN" />)}

                    </Tabs>
                </Box>

                {currentTab === 0 && (
                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                     <AddGRN setCurrentTab={setCurrentTab} />
                    </StyledPaperShadow>
                  )}

{currentTab === 1 && ( <Box>
  <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
                                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                    <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {totalItems}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                                    <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                                    <Select
                                        value={limit}
                                        onChange={handleLimitChange}
                                        sx={{
                                            marginLeft: 1,

                                            '& .MuiSelect-select': {
                                                paddingTop: "1px !important",
                                                paddingBottom: "1px !important"
                                            },
                                        }}

                                    >
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <TextField
                                variant="standard"
                                label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSearchClick}>
                                                <IoSearchSharp />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Box>
          { data?.data?.length > 0 && (
            <ItemGroupDatagrid
              data={data?.data} 
              columns={[...Object.keys(data?.data[0]).filter((item)=>item!="id").map((key) => ({
                field: key,
                headerName: key.replace(/_/g, ' ')  
                  .replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase(),
                  flex:1,
                  minWidth: 100,
              })),
              {
                field: 'Action',
                headerName: 'Action',
                minWidth: 100,
                flex:1,
                renderCell: (params) => (
                    <>
                      <Tooltip title="Print">  
                      <PrintIcon
                       onClick={() => handleEditClick(params.row)} 
                       style={{ fontSize: '1.1rem', cursor: 'pointer', marginRight: '10px' }} />
                       </Tooltip>
          
                    </>
                  ),
              }
            ]}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <SoftButton variant="contained" color="primary" onClick={handlePrevious} disabled={offset === 0}>
              Previous
            </SoftButton>
            <SoftButton
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={offset + limit >= totalItems}
            >
              Next
            </SoftButton>
          </Box>
        </Box>)}
      </StyledPaperShadow>
    </Box>
    </>
  );
};

export default GRN;
