import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,Grid,
  TextField,Autocomplete,Popper
} from "@mui/material";

import StyledSnackBar from '../../../Utils/StyledSnackBar';
import { useGetAllRepairTypeMutation } from "../../../store/slice/ApiSlice";
const TableDynamic = ({ headers, onSubmit,rows,setRows }) => {
  const [open, setOpen] = useState(false);
  const [getAllRepairTypeData, { data }] = useGetAllRepairTypeMutation();

 useEffect(()=>{
getAllRepairTypeData()
 },[])
  const handleInputChange = (rowIndex, header, value) => {
    
    const row = rows[rowIndex];

    if (header === "Return Qty") {
      if (parseInt(value)>row["Issued Qty"] ||parseInt(value)<=0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
  

    let updatedRows = [...rows];
     const initSelect =  updatedRows.map((item,index) => {
      if(index==rowIndex){
        const totalbeforetax1=parseFloat(item["Rate"])*parseFloat(value)-parseFloat(item["Discount Amount"]||0) ||0
        const taxpercentage=parseFloat(item["CGST"])+parseFloat(item["SGST"])+parseFloat(item["IGST"])

        if(header=="Return Qty"){
          return({
            ...item,
            [header]:value,
            "Total Amount":(totalbeforetax1+parseFloat((totalbeforetax1*taxpercentage)/100)).toFixed(2)
          })
        }
        else{
        return(
          {...item,[header]:value}
        )
      }
      }
      else{
        return item
      }
      });
     
    setRows(initSelect);
    onSubmit(initSelect); // Send updated rows back to the parent

  };

  return (
    <Box sx={{ marginTop: "30px", maxHeight: "320px", overflowY: "auto" }}>
  {rows.map((row, rowIndex) => (
    <Grid container spacing={2} key={row.id} sx={{ mb: "40px", alignItems: "center" }}>
      
      {headers.map((header, colIndex) => (
        <Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST" ? 0.5:1.1} key={colIndex}>
          {header === "Return Qty" ? (
            <TextField
              required
              type="number"
              label="Return Qty"
              value={row[header] || ""}
              onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
              InputProps={{
                inputProps: { min: 1, max: row["Issued Qty"] },
              }}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+"||e.key===".") {
                  e.preventDefault()
                }
              }}
              variant="standard"
              size="small"
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  backgroundColor: "inherit",
                },
                "& input.Mui-disabled": {
                  backgroundColor: "#F0F0F0",
                },
              }}
            />
          ) : (
            <TextField 
            focused={header=="Total Amount"&&row[header]}
              label={header}
              value={row[header]}
              onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
              variant="standard"
              size="small"
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "inherit",
                },
                "& input.Mui-disabled": {
                  backgroundColor: "#F0F0F0",
                },
              }}
              InputProps={{
                readOnly:  true
              }}
            />
          )}
          
        </Grid>
      ))}

    </Grid>
  ))}

  {open && (
    <StyledSnackBar open={true} message={"Return Quantity cannot be more than Issued Quantity and should be greater than 0"} status={"Error"} />
  )}
</Box>

  );
};

export default TableDynamic;
