import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import Aggregate from "./Aggregate";
import Hsn from "./Hsn";
import ItemCategory from "./ItemCategory";
import ItemGroupSubMenu from "./ItemGroup";
import SubAggregate from "./SubAggregate";
import UOM from "./Uom";

const tabComponents = {
  "Item Group": ItemGroupSubMenu,
  "Item Category": ItemCategory,
  "UOM": UOM,
  "HSN": Hsn,
  "Aggregate": Aggregate,
  "Sub Aggregate": SubAggregate
};

const ItemGroup = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuTabs, setMenuTabs] = useState([]);

  useEffect(() => {
    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const mastersMenu = menuList.find(menu => menu.title === "Masters");
    const itemSubMasterMenu = mastersMenu?.submenu.find(sub => sub.title === "Item SubMaster");
    setMenuTabs(itemSubMasterMenu?.menuTab || []);
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Master"
        subDiv="Item SubMaster"
      ></StyledBreadcrumb>

      <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
        <Tabs value={activeTab} onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
            maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
            overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
          }}
        >
          {menuTabs.map((tab, index) => (
            <Tab key={index} label={tab.tab_name} />
          ))}
        </Tabs>
        {menuTabs.map((tab, index) => {
          const Component = tabComponents[tab.tab_name];
          return (
            activeTab === index && (
              <Box key={index} sx={{ p: 3 }}>
                <Component buttons={tab.buttons} />
              </Box>
            )
          );
        })}
      </StyledPaperShadow>
    </Box>
  );
}

export default ItemGroup;
