import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField, Autocomplete, Popper, Grid,IconButton,CardMedia,Typography
} from "@mui/material";
import { SoftButton } from "../../../theme/StyledElement";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useGetsearchPartsDetailsMutation, useGetAllPartsDetailsMutation } from '../../../store/slice/ApiSlice';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import MdDelete from "../../../../src/assets/images/del.svg";
import { StyledPaperShadow } from "../../../theme/StyledElement";
import { MdAdd, MdEdit, } from 'react-icons/md';
const DynamicTable = ({ headers, onSubmit, vendorState, vendorCodeRef, rows, setRows }) => {
  const [rowIdCounter, setRowIdCounter] = useState(1);
  const [partsOptionData, setPartsOptionData] = useState([]);
  const [searchPartsData, { data: SearchParts, isLoading: loading }] = useGetsearchPartsDetailsMutation();
  const [getAllPartsData, { data: PartsDetails }] = useGetAllPartsDetailsMutation();
  const [open, setOpen] = useState(false);
  const [mesaage,setMessage]=useState("")

  const addRow = () => {
    const newRow = { id: rowIdCounter, itemid: 0 };
    headers.forEach((header) => (newRow[header] = ""));
    setRows((prevRows) => [...prevRows, newRow]);
    setRowIdCounter((prev) => prev + 1);
  };
  console.log(partsOptionData, "partsdataS")
  const handleInputChange = (rowIndex, header, value) => {

    if (header === "Discount Amount" || header=="Sup. inv qty"|| header=="Received Qty") {
      if (isNaN(value)||parseInt(value)<0) {
        // If the entered value exceeds the available stock quantity, show the message and stop further updates
        setMessage(`${header} should not be non numeric`)
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return; // Exit the function early, preventing state update
      }
    }
    const updatedRows = [...rows];
    updatedRows[rowIndex][header] = value;
    if (header == "Received Qty" && updatedRows[rowIndex]["Cost"]) {
      const totalbeforetax=parseFloat(updatedRows[rowIndex]["Cost"]) * parseFloat(value)-parseFloat(updatedRows[rowIndex]["Discount Amount"]||0)
      const taxpercentage=parseFloat(updatedRows[rowIndex]["CGST"])+parseFloat(updatedRows[rowIndex]["SGST"])+parseFloat(updatedRows[rowIndex]["IGST"])
      if(value){
      updatedRows[rowIndex]["Total Amount"] = (totalbeforetax+parseFloat((totalbeforetax*taxpercentage)/100)).toFixed(2)
      }
      else{
          updatedRows[rowIndex]["Total Amount"]=0
      }
    }
    if (header == "Discount Amount" && updatedRows[rowIndex]["Cost"]&&updatedRows[rowIndex]["Received Qty"]) {
      const totalbeforetax=parseFloat(updatedRows[rowIndex]["Cost"]) *parseFloat(updatedRows[rowIndex]["Received Qty"])- parseFloat(value||0)
      const taxpercentage=parseFloat(updatedRows[rowIndex]["CGST"])+parseFloat(updatedRows[rowIndex]["SGST"])+parseFloat(updatedRows[rowIndex]["IGST"])
      updatedRows[rowIndex]["Total Amount"] = (totalbeforetax+parseFloat((totalbeforetax*taxpercentage)/100)).toFixed(2)
    }
    setRows(updatedRows);
    onSubmit(updatedRows); // Send updated rows back to the parent

  };

  const handlePartsChange = async (rowIndex, header, value) => {
    const payload = {
      itemCode: value ? value.itemCode : "",
      modelSegment: "",
      customerState: vendorState,
    };
    const response = await getAllPartsData(payload);
    if (response?.data && response?.data?.requestSuccessful && response?.data?.itemsData.length > 0) {
      const { taxPercentage, itemName, hsnCode, sgst, cgst, igst, itemDescription, list, cost, mrp, id } = response?.data.itemsData[0];

      const updatedRows = [...rows];
      updatedRows[rowIndex]["Parts Code"] = value.itemCode
      updatedRows[rowIndex]["Description"] = itemDescription;
      updatedRows[rowIndex]["Cost"] = cost;
      updatedRows[rowIndex]["Rate"] = list;
      updatedRows[rowIndex]["MRP"] = mrp;
      updatedRows[rowIndex]["CGST"] = cgst;
      updatedRows[rowIndex]["SGST"] = sgst;
      updatedRows[rowIndex]["IGST"] = igst;
      updatedRows[rowIndex]["itemid"] = id;
      setRows(updatedRows);
      onSubmit(updatedRows); // Send updated rows back to the parent
    }
  };

  const handleDeleteRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
    onSubmit(updatedRows); // Send updated rows back to the parent
  };
  const handleSearchParts = async (e, itemCode) => {
    console.log(e, "e")
    if (e != null && e.type === "change") {
      if (itemCode.length >= 3) {
        const payload = {
          itemCode,
        };
        const response = await searchPartsData(payload);
        if (response.data.requestSuccessful) {
          setPartsOptionData(response?.data?.itemSearchData);
        }
      }
    }
  };

  return (
    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "0px", marginLeft: "10px" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}> Parts details</Typography>
        <SoftButton variant="contained"
        color="primary"
        sx={{
          marginTop: "10px",
          float: "right",
          minWidth: "200px",
          width: "200px",
          height: "40px",
          padding: 0,
          cursor: "pointer", // Default cursor for enabled state
          "&.Mui-disabled": { // Target the disabled state
            pointerEvents: "all", // Ensure the element still responds to pointer events
            cursor: "not-allowed", // Force "not-allowed" cursor on disabled button

          },

        }}
         disabled={!vendorState} onClick={addRow} startIcon={<MdAdd />}>
            Add Parts Details
        </SoftButton>
    </Box>
    <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto" }}>
      {rows.map((row, rowIndex) => (
        <Grid container spacing={2} key={row.id} sx={{ marginBottom: "10px", alignItems: "center" }}>

          {/* Parts Code Autocomplete */}
          <Grid item xs={2}>
            <Autocomplete
              value={row["Parts Code"]}
              onChange={(_, newValue) => handlePartsChange(rowIndex, "Parts Code", newValue)}
              options={partsOptionData}
              getOptionLabel={(option) => option.itemCode || row["Parts Code"]}
              filterOptions={(options, { inputValue }) =>
                options.filter(option =>
                  option.itemCode.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.itemName.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box key={option.itemCode} component="li" {...props}>
                  {option.displayText}
                </Box>
              )}
              PopperComponent={(popperProps) => (
                <Popper {...popperProps} style={{ width: '300px' }} /> // Set your desired dropdown width
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parts Code"
                  variant="standard"
                  required
                  size="small"
                />
              )}
              onInputChange={(e, value) => handleSearchParts(e, value)}
            />
          </Grid>

          {/* Other Fields as TextFields */}
          {headers.map((header, colIndex) => (
              header === "Parts Code" ? null :
              header=="Discount Amount" || header=="Received Qty" || header=="Sup. inv qty" ?(
              <Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST" ? 1 :2} key={colIndex}>
              <TextField 
                label={header}
                value={row[header]}
                onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                required={header !== "Discount Amount"}
                size="small"
                fullWidth
                variant="standard"
                InputProps={{
                  readOnly: !row["Parts Code"]
                }}
              /> 
              </Grid>
              )
              :
              (
              <Grid item xs={header=="CGST"|| header=="SGST" || header=="IGST" ? 1 :2} key={colIndex}>
              <TextField focused={header=="Total Amount"}
                label={header}
                value={row[header]}
                onChange={(e) => handleInputChange(rowIndex, header, e.target.value)}
                required={header !== "Select Bin Location"}
                size="small"
                fullWidth
                variant="standard"
                InputProps={{
                  readOnly:  header !== "Select Bin Location" ? true:false
                }}
              />
            </Grid>)
            
          ))}

          {/* Action Button */}
          <Grid item xs={1}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton  onClick={() => handleDeleteRow(row.id)}>
                <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}

     
      {open && (
        <StyledSnackBar open={true} message={mesaage} status={"Error"} />
      )}
    </Box>
    </StyledPaperShadow>

  );
};

export default DynamicTable;
