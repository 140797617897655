import {
  Avatar, Box, CardMedia,
  Divider,
  IconButton, ListItem,
  ListItemAvatar, ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu, MenuItem, Stack, useTheme
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FaRegUser, FaWhatsapp } from "react-icons/fa";
import { HiOutlineMenu } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import company from "../../../src/assets/images/companylogo.png";
import notification from "../../../src/assets/images/NotificationIcon.svg";
import { useLogOutMutation } from '../../store/slice/ApiSlice';
import { logout } from "../../store/slice/ControlsSlice";
import { useGetConfigurationDataMutation } from '../../store/slice/ApiSlice';

function Header({ onToggleSidebar }) {
  const theme = useTheme({});
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userLogout] = useLogOutMutation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 900);

  const employeeCode = useSelector((state) => state.controls.employeeCode);

  const [getConfigurationData, { data: ConfigData }] = useGetConfigurationDataMutation();

  const config = async () => {
    await getConfigurationData()
  }
  useEffect(() => {
    config();
  }, [])

  const phone = ConfigData?.roleList?.find((item) => item.ref_name === "phone_number")?.ref_value || '';
  const whatsapp = ConfigData?.roleList?.find((item) => item.ref_name === "whatsapp_number")?.ref_value || '';
  const email = ConfigData?.roleList?.find((item) => item.ref_name === "support_email")?.ref_value || ''

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    console.log("log")
    setAnchorEl(null);
    try {
      const response = await userLogout().unwrap();;
      if (response) {
        dispatch(logout());
        navigate(`/`);
      }
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppBar position="fixed" elevation={0} sx={{ p: 0, zIndex: theme.zIndex.drawer + 1, border: 0, backgroundColor: theme.header.backgroundColor }}>
      <Toolbar sx={{ px: '8px !important' }}>
        <Box sx={{ width: '100%' }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
            <IconButton size='large' onClick={onToggleSidebar}>
              <HiOutlineMenu />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <CardMedia src={company} component={'img'} sx={{ height: '38px', width: 'auto' }} />
            </Box>
            <Box>
              <Stack direction={'row'} alignItems={'center'} columnGap={1.2}>
                {isSidebarOpen && (
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                      <IconButton size='small'>
                        <IoIosCall style={{ color: theme.icon.iconColor }} />
                      </IconButton>
                      <Typography sx={{ color: "black", alignSelf: "center" }}>{whatsapp}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginLeft: "5px" }}>
                      <IconButton size='small'>
                        <FaWhatsapp style={{ color: theme.icon.iconColor }} />
                      </IconButton>
                      <Typography sx={{ color: "black", alignSelf: "center" }}>{phone}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginLeft: "5px" }}>
                      <IconButton size='small'>
                        <MdEmail style={{ color: theme.icon.iconColor }} />
                      </IconButton>
                      <Typography sx={{ color: "black", alignSelf: "center" }}>{email}</Typography>
                    </Box>
                  </Box>
                )}
                <IconButton size='small'>
                  <img src={notification} alt="notification icon" />
                </IconButton>

                <ListItem
                  sx={{ padding: "1px" }}
                  onClick={handleMenuOpen}
                >
                  <ListItemButton sx={{ padding: "1px" }} >
                    <ListItemAvatar sx={{ minWidth: 'auto', pl: 1 }}>
                      <Avatar src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww' sizes='small' sx={{ width: '30px', height: '30px' }} />
                    </ListItemAvatar>
                  </ListItemButton>
                </ListItem>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      width: "350px",
                      borderRadius: "12px",
                      padding: "8px",
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Typography sx={{ padding: "5px", textAlign: "center", fontWeight: "700" }}>{employeeCode}</Typography>

                  <Divider sx={{ mb: .8 }}></Divider>
                  <MenuItem onClick={() => navigate("/setting/profileSetting")}>
                    <ListItemIcon sx={{ minWidth: "40px", fontSize: "22px", color: theme.palette.text.primary }}>
                      <IoSettingsOutline />
                    </ListItemIcon>
                    <ListItemText primary={'Profile Settings'} />
                  </MenuItem>

                  <MenuItem >
                    <ListItemIcon sx={{ minWidth: "40px", fontSize: "22px", color: theme.palette.text.primary }}>
                      <FaRegUser />
                    </ListItemIcon>

                    <ListItemText primary={'Account Settings'} />
                  </MenuItem>
                  <Divider sx={{ mb: .8 }}></Divider>

                  <MenuItem onClick={() => {
                    handleMenuClose(); 
                    handleLogout(); 
                  }}>
                    <ListItemIcon sx={{ minWidth: "40px", fontSize: "22px", color: theme.palette.text.primary }}>
                      <TbLogout2 />
                    </ListItemIcon>

                    <ListItemText primary={'Logout'} />

                  </MenuItem>
                </Menu>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;


