import React, { useState,useRef } from 'react';
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import DynamicTable from './DynamicTable';
import { useGetVendorDatagridMutation } from '../../../store/slice/ApiSlice';
import { useGetGrnDocumentsQuery,useCreateGrnMutation} from '../../../store/slice/ApiSlice'
import { useNavigate } from 'react-router-dom';

const AddGRN = ({setCurrentTab}) => {
  const navigate=useNavigate()
  const { data, error } = useGetGrnDocumentsQuery();
  const [addGRN, { isSuccess }] = useCreateGrnMutation(); // Prepare the add item mutation
  // searchKey, offset, limit
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendorState,setVendorState]=useState("")
  const [fetchVendors, { data: vendorData, isLoading }] = useGetVendorDatagridMutation();
  const [rows, setRows] = useState([]);
  const vendorCodeRef = useRef(null); // Create a ref for vendorCode input

  const handleVendorSearch = async (searchKey) => {
    if(searchKey){
    try {
      const offset = 0;
      const limit = 10; // Adjust as needed
      const response = await fetchVendors({ searchKey, offset, limit }).unwrap();
      if (response.requestSuccessful) {

      setVendorOptions(response?.vendorData?.data);
      } // Assuming the response has data in `response.data`
    } catch (error) {
      console.error("Error fetching vendor data", error);
    }
  };
}


  const {
    register,
    handleSubmit,
    reset,
    setValue, control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      status: 0,
      snv: '',
      venderCode: '',
      supplierInvoiceNumber: '',
      invoiceDate: '',
      invoiceAmount: '',
      eSugamPermitNumber: '',
      lrNumber: '',
      lrDate: '',
      transportName: '',
      freightCharge: '',
      miscellaneousCharge: '',
    },
  });

  const [status, setStatus] = useState(1);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const[ headers,setHeaders ]= useState([
    'Parts Code',
    'Description',
    // 'PO Number',
    'Sup. inv qty',
    'Received Qty',
    'Rate',
    'Cost',
    'MRP',
    'Discount Amount',
    'Select Bin Location',
    'CGST',
    'SGST',
    'IGST',
    'Total Amount',
  ])
  const onSubmit = async (data) => {
    if (dynamicTableData.length === 0) {
      setSnackBarMessage('Please add at least one row in the table.');
      setDataStatusConfirm('Error');
      setOpen(true);
      setTimeout(()=>{
        setOpen(false)
        setSnackBarMessage("")
  },3000)
      return;
    }
    const transformedData = dynamicTableData.map((obj) => ({
      item_id: obj.itemid,
      item_code: obj["Parts Code"],
      item_description: obj.Description,
      sup_invoice_quantity: parseFloat(obj["Sup. inv qty"]), // Convert to number if needed
      quantity: parseFloat(obj["Received Qty"]), // Convert to number if needed
      rate: parseFloat(obj.Rate), // Convert to number if needed
      cost: parseFloat(obj.Cost), // Convert to number if needed
      mrp: parseFloat(obj.MRP), // Convert to number if needed
      discount:  isNaN(parseFloat(obj["Discount Amount"])) ? 0 :parseFloat(obj["Discount Amount"]) , // Convert to number if needed
      binlocation: isNaN(parseInt(obj["Select Bin Location"])) ? 0 : parseInt(obj["Select Bin Location"], 10), // Convert to number if needed
      cgst: obj.CGST,
      sgst: obj.SGST,
      igst: obj.IGST,
      total: parseFloat(obj["Total Amount"]), // Convert to number if needed
    }));
    const formData = {

      grndata:{
        document_type:data.snv,
        invoice_number:data.supplierInvoiceNumber,
        invoice_date:data.invoiceDate,
        vendor_id:data.venderCode.id,
        vendor_code:data.venderCode.vendorCode,
        e_sugam_no:data.eSugamPermitNumber,
        lr_number:data.lrNumber,
        lr_date:data.lrDate,
        transport_name:data.transportName,
        frieght_charges :data.freightCharge||0,
        mis_charges:data.miscellaneousCharge||0,
        status:1,

      },
      // status: status ? 1 : 0,
      // companyId: data.companyId.map((company) => company.id),
      grnparts:transformedData
      
    };

    let response = await addGRN(formData)
    if (response.data && response.data.requestSuccessful) {
    
      let  message = response.data.message;
        setDataStatusConfirm('Success');
        reset();
        setDynamicTableData([]);
        setGrandTotal(0);
        setVendorState("")
        setRows([])
      setSnackBarMessage(message);
      setCurrentTab(1)

    } else {
      let message;
        setDataStatusConfirm('Error');
        // message = response.error.data.validationErrors;
      
      setSnackBarMessage(response.error.data.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  

  const handleDataSubmit = (data) => {
    setDynamicTableData(data);
    calculateGrandTotal(data);
  };

  const calculateGrandTotal = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row['Total Amount'] || 0), 0);
    setGrandTotal(total);
  };

  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="snv"
                control={control}
                defaultValue=""
                rules={{ required: 'Document is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Autocomplete
                   disablePortal
                    options={data ? data?.data?.map((item)=>item.name) : []} // Add your options here
                    getOptionLabel={(option) => option}
                    value={value}
                    onChange={(_, data) => onChange(data)} // Handle change
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Document Type"
                        variant="standard"
                        error={!!error}
                        helperText={error ? error.message : ''}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
      <Controller
        name="venderCode"
        control={control}
        defaultValue=""
        rules={{ required: 'Vendor Code is required' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete 
          freeSolo
            disablePortal
            options={vendorOptions} // Adjust to map the correct field
            getOptionLabel={(option) =>option ? option.vendorCode :""}
            value={value}
            onChange={(_, data) => {
              setVendorState(data?.state)
              onChange(data)
            }

            } // Handle change
            onInputChange={(_, value) => handleVendorSearch(value)} // Fetch vendor data based on input
            loading={isLoading} // Show a loading state while fetching
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendor Code"
                variant="standard"
                error={!!error}
                helperText={error ? error.message : ''}
                fullWidth
                inputRef={vendorCodeRef}
              />
            )}
          />
        )}
      />
    </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('supplierInvoiceNumber', { required: 'Supplier Invoice Number is required' })}
                fullWidth
                variant="standard"
                label="Supplier Invoice Number"
                error={!!errors.supplierInvoiceNumber}
                helperText={errors.supplierInvoiceNumber ? errors.supplierInvoiceNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField type='date'
                {...register('invoiceDate', { required: 'Invoice Date is required' })}
                fullWidth
                variant="standard"
                label="Invoice Date"
                InputLabelProps={{ shrink: true }}
                error={!!errors.invoiceDate}
                helperText={errors.invoiceDate ? errors.invoiceDate.message : ''}
              />
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                {...register('invoiceAmount', { required: 'Invoice Amount is required' })}
                fullWidth
                variant="standard"
                label="Invoice Amount"
                error={!!errors.invoiceAmount}
                helperText={errors.invoiceAmount ? errors.invoiceAmount.message : ''}
              />
            </Grid> */}
            <Grid item xs={3}>
              <TextField
                {...register('eSugamPermitNumber')}
                fullWidth
                variant="standard"
                label="E Sugam Road Permit Number"
                error={!!errors.eSugamPermitNumber}
                helperText={errors.eSugamPermitNumber ? errors.eSugamPermitNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('lrNumber', )}
                fullWidth
                variant="standard"
                label="LR Number"
                error={!!errors.lrNumber}
                helperText={errors.lrNumber ? errors.lrNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField type='date'
                {...register('lrDate', )}
                fullWidth
                variant="standard"
                label="LR Date"
                InputLabelProps={{ shrink: true }}
                error={!!errors.lrDate}
                helperText={errors.lrDate ? errors.lrDate.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('transportName', )}
                fullWidth
                variant="standard"
                label="Transport Name"
                error={!!errors.transportName}
                helperText={errors.transportName ? errors.transportName.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('freightCharge', )}
                fullWidth
                variant="standard"
                label="Freight Charge"
                error={!!errors.freightCharge}
                helperText={errors.freightCharge ? errors.freightCharge.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('miscellaneousCharge',)}
                fullWidth
                variant="standard"
                label="Miscellaneous Charge"
                error={!!errors.miscellaneousCharge}
                helperText={errors.miscellaneousCharge ? errors.miscellaneousCharge.message : ''}
              />
            </Grid>
            <Grid item xs={2}>
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'right' }}>
            Grand Total: {grandTotal.toFixed(2)}
          </Typography>

          <DynamicTable headers={headers} onSubmit={handleDataSubmit} 
          vendorState={vendorState} vendorCodeRef={vendorCodeRef}  
          rows={rows} setRows={setRows}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <SoftButton type="submit" variant="contained" color="primary">
              {'Submit'}
            </SoftButton>
          </Box>
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default AddGRN;
