import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoSearchSharp } from "react-icons/io5";
import { useGetUserLogMutation } from "../../store/slice/ApiSlice";
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const { RangePicker } = DatePicker;
const UserLog = () => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      access: "",
    },
  });

  const [getUserLogDatagrid, { data: UserLogDatagrid }] =
    useGetUserLogMutation();

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [access, setAccess] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    let startDate = dateRange[0]
      ? moment(dateRange[0].$d).format("YYYY-MM-DD")
      : "";
    let endDate = dateRange[1]
      ? moment(dateRange[1].$d).format("YYYY-MM-DD")
      : "";
    fetchItems(offset, access, startDate, endDate);
  }, [offset, limit]);

  const fetchItems = async (
    newOffset,
    access,
    startDate,
    endDate,
    searchKey = ""
  ) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey: searchKey,
      access: access,
      startDate: startDate,
      endDate: endDate,
    };
    const response = await getUserLogDatagrid(payload);
    setTotalItems(response?.data?.data?.count);
  };

  const handleSearchClick = () => {
    setOffset(0);
    let startDate = "";
    let endDate = "";
    if (dateRange !== null) {
      startDate = dateRange[0]
        ? moment(dateRange[0].$d).format("YYYY-MM-DD")
        : "";
      endDate = dateRange[1]
        ? moment(dateRange[1].$d).format("YYYY-MM-DD")
        : "";
    }
    fetchItems(0, access, startDate, endDate, searchValue);
  };

  const onSubmit = async (data) => {
    let accessData = data.access === null ? "" : data.access;
    setAccess(accessData);
    let startDate = "";
    let endDate = "";
    if (dateRange !== null) {
      startDate = dateRange[0]
        ? moment(dateRange[0].$d).format("YYYY-MM-DD")
        : "";
      endDate = dateRange[1]
        ? moment(dateRange[1].$d).format("YYYY-MM-DD")
        : "";
    }
    setOffset(0);
    fetchItems(0, accessData, startDate, endDate, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };
  const columns = useMemo(
    () => [
      {
        field: "username",
        headerName: "User Name",
        width: 200,
      },
      {
        field: "login_time",
        headerName: "Login Time",
        width: 200,
      },
      {
        field: "logout_time",
        headerName: "Logout Time",
        width: 200,
      },
      {
        field: "time_duration",
        headerName: "Duration(Sec)",
        width: 200,
      },
      {
        field: "access",
        headerName: "Access",
        width: 200,
      },
    ],
    []
  );

  const accessOptions = ["Portal", "Mobile"];

  return (
    <Box>
      <Typography
        sx={{ fontSize: 24, color: "#464B7C", fontWeight: 700 }}
        variant="h6"
      >
        UserLog
      </Typography>
      <StyledPaperShadow
        elevation={0}
        sx={{ display: "inline-block", width: "100%" }}
      >
        <Typography
          sx={{ fontSize: 20, color: "#464B7C", fontWeight: 700 }}
          variant="h6"
        >
          Filter
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box sx={{ marginTop: "17px" }}>
                <RangePicker
                  onChange={(dates) => setDateRange(dates)}
                  style={{ width: "100%" }}
                  sx={{
                    "& .ant-picker-outlined ": { marginTop: "17px" },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Controller
                name="access"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={accessOptions}
                    getOptionLabel={(option) => option.toString()}
                    onChange={(e, value) => field.onChange(value)}
                    value={field.value || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Access"
                        variant="standard"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <SoftButton type="submit" variant="contained" color="primary">
                  Submit
                </SoftButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </StyledPaperShadow>
      <StyledPaperShadow
        elevation={0}
        sx={{
          padding: "1px 20px 20px 20px",
          marginTop: "30px",
          display: "inline-block",
          width: "100%",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                backgroundColor: "#11164b",
                borderRadius: "5px",
              }}
            >
              <Box sx={{ alignSelf: "center", margin: "5px" }}>
                <Typography
                  sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}
                >
                  Total Records : {UserLogDatagrid?.data?.count}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "1px",
                  backgroundColor: "#ffffff",
                  paddingLeft: "10px",
                }}
              >
                <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  sx={{
                    marginLeft: 1,

                    "& .MuiSelect-select": {
                      paddingTop: "1px !important",
                      paddingBottom: "1px !important",
                    },
                  }}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Box>
            </Box>
            <TextField
              variant="standard"
              label="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchClick}>
                      <IoSearchSharp />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ItemGroupDatagrid
            data={UserLogDatagrid?.data?.rows || []}
            columns={columns}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <ToggleButtonGroup
              exclusive
              sx={{
                display: "flex",
                alignItems: "center",

                "& .MuiToggleButtonGroup-middleButton": {
                  marginRight: "0px !important",
                  borderLeft: "1px solid #e5e5e5",
                },
                "& .MuiToggleButtonGroup-root": {
                  border: "1px solid #e5e5e5",
                },
              }}
            >
              <ToggleButton
                value="previous"
                onClick={handlePrevious}
                disabled={offset === 0}
              >
                Previous
              </ToggleButton>

              {Array.from({ length: totalPages }, (_, index) => {
                const page = index + 1;
                const isFirstThreePages = page <= 3;
                const isLastThreePages = page > totalPages - 3;
                const isCurrentPage = offset / limit === index;

                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                  return (
                    <ToggleButton
                      key={page}
                      value={`page-${page}`}
                      onClick={() => handlePageClick(page)}
                      sx={{ margin: "0 5px" }}
                      selected={isCurrentPage}
                    >
                      {page}
                    </ToggleButton>
                  );
                }

                if (page === 4 && !isFirstThreePages) {
                  return (
                    <Typography
                      key="dots-before"
                      sx={{ margin: "0 5px", userSelect: "none" }}
                    >
                      ...
                    </Typography>
                  );
                }

                if (page === totalPages - 3 && !isLastThreePages) {
                  return (
                    <Typography
                      key="dots-after"
                      sx={{ margin: "0 5px", userSelect: "none" }}
                    >
                      ...
                    </Typography>
                  );
                }

                return null;
              })}
              <ToggleButton
                value="next"
                onClick={handleNext}
                disabled={offset + limit >= totalItems}
              >
                Next
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
};
export default UserLog;
