import { Autocomplete, Box, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { FaSearchPlus } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import { MdAdd, MdEdit, } from 'react-icons/md';
import ScaleLoader from "react-spinners/ScaleLoader";
import check from "../../../../src/assets/images/check.svg";
import MdDelete from "../../../../src/assets/images/del.svg";
import { useEditServiceEstimateMutation, useGetAllMakesMutation, useGetAllModelMutation, useGetAllPartsDetailsMutation, useGetAllPincodeMutation, useGetAllRepairTypeMutation, useGetAllServiceTypeMutation, useGetAllSourceMutation, useGetAllVechileDetailsMutation, useGetAllVedorMutation, useGetLabourDetailsMutation, useGetOslLabourDetailsMutation, useGetServiceEstimateDataMutation, useGetServiceEstimateDatagridMutation, useGetopenServiceBookMutation, useGetsearchLabourDetailsMutation, useGetsearchOslLabourDetailsMutation, useGetsearchPartsDetailsMutation, useLazyGetPdfDownloadQuery, useSubmitSourceMutation, useSubmitopenServiceBookMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";
import ViewPage from "../serviceEstimate/ViewPage";

const ServiceEstimate = () => {
    const { register, handleSubmit, reset, setValue, setError, formState: { errors }, clearErrors, control, getValues, trigger } = useForm({
        defaultValues: {
            registrationNumber: "",
        }
    });

    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const pathname = window.location.pathname;

    const [addSeriveEstimateData, { isSuccess: ServiceEstimateSuccess }] = useGetServiceEstimateDataMutation();
    const [getServiceEstimateDatagrid, { data: ServiceEstimateDatagrid }] = useGetServiceEstimateDatagridMutation();
    const [editServiceEstimateData, { isSuccess: editServiceEstimateSuccess }] = useEditServiceEstimateMutation();
    const [getMakeData, { data: AllMakeData }] = useGetAllMakesMutation();
    const [getModalData, { data: getAllModalData }] = useGetAllModelMutation();
    const [getAllPincodeData,] = useGetAllPincodeMutation();
    const [getAllVehicleData,] = useGetAllVechileDetailsMutation();
    const [getAllsourceData, { data: sourceData }] = useGetAllSourceMutation();
    const [submitSource, { data: submitSourceData }] = useSubmitSourceMutation();
    const [getAllServiceTypeData, { data: serviceTypeData }] = useGetAllServiceTypeMutation();
    const [getAllRepairTypeData, { data: repairTypeData }] = useGetAllRepairTypeMutation();
    const [getAllVendorData, { data: allVendorData }] = useGetAllVedorMutation();
    const [getOpenServiceBookingData, { data: allBookingsData }] = useGetopenServiceBookMutation();
    const [SubmitOpenServiceBooking, { data: SubmitBookingData, isSuccess: BookingSuccess }] = useSubmitopenServiceBookMutation();

    const [getAllLabourDetailsData, { data: LabourDetails }] = useGetLabourDetailsMutation();
    const [searchLabourData] = useGetsearchLabourDetailsMutation();
    const [getAllOslLabourDetailsData, { data: OslLabourDetails }] = useGetOslLabourDetailsMutation();
    const [searchOslLabourData] = useGetsearchOslLabourDetailsMutation();
    const [getAllPartsData, { data: PartsDetails }] = useGetAllPartsDetailsMutation();
    const [searchPartsData] = useGetsearchPartsDetailsMutation();
    const [getPdfDetails] = useLazyGetPdfDownloadQuery();
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [isCreating, setIsCreating] = useState(true);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [VehicleIdData, setVehicleIdData] = useState('');
    const [customerNameData, setCustomerName] = useState('');
    const [customerMobileNumberData, setcustomerMobileNumber] = useState('');
    const [customerAddressData, setCustomerAddress] = useState('');
    const [pincodeData, setPincodeData] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [vehicleNumber, setVehicleNumber] = useState("");

    const makes = AllMakeData?.MakeData || [];
    const model = getAllModalData?.ModelData || [];
    const sources = sourceData?.sourceData || [];
    const sourceType = submitSourceData?.sourceTypes || [];
    const serviceTypes = serviceTypeData?.serviceTypeData || [];
    const repairTypes = repairTypeData?.repairTypeData || [];
    const vendors = allVendorData?.vendorData || [];
    const Bookings = allBookingsData?.ServiceBookingData || [];

    const [optionData, setOptionData] = useState([]);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [oslOptionData, setOslOptionData] = useState([]);
    const [OslcurrentRowIndex, setOslCurrentRowIndex] = useState(null);
    const [partsOptionData, setPartsOptionData] = useState([]);
    const [PartsCurrentRowIndex, setPartsCurrentRowIndex] = useState(null);
    const [viewPageData, setViewPageData] = useState(null); // State to manage view page data
    const [showViewPage, setShowViewPage] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [backDropOpen, setBackDropOpen] = useState(false);  //null
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedSourceType, setSelectedSourceType] = useState(null);
    const [customerNameReadOnly, setCustomerNameReadOnly] = useState(false);
    const [customerAddressReadOnly, setCustomerAddressReadOnly] = useState(false);
    const [mobileNoReadOnly, setMobileNoReadOnly] = useState(false);
    const [pincodeReadOnly, setPincodeReadOnly] = useState(false);
    const [makeDisabled, setMakeDisabled] = useState(false);
    const [modelDisabled, setModelDisabled] = useState(false);

    useEffect(() => {
        getAllsourceData();
        getAllServiceTypeData();
        getAllRepairTypeData();
        getAllVendorData();
        getMakeData();
    }, [])

    useEffect(() => {
        updateFormData();
    }, [BookingSuccess]);

    const updateFormData = async () => {
        const BookingData = SubmitBookingData?.BookingData;
        if (BookingData !== undefined) {
            handleVehicleData(BookingData.registrationNumber)
            setValue('registrationNumber', BookingData.registrationNumber);
            setValue('pincode', BookingData.pincode);
            setSelectedCity(BookingData.customerCity);
            setSelectedState(BookingData.customerState);
            setValue('customerMobileNumber', BookingData.customerMobileNumber);
            setValue('customerName', BookingData.customerName);
            setValue('customerAddress', BookingData.customerAddress);
            setValue('odometer', BookingData.odometer);


            const selectedSourceData = sources?.find(source => source.id === BookingData.dmsSourceId);
            if (selectedSourceData) {
                setSelectedSource(selectedSourceData);
                setValue('sourceId', selectedSourceData);

                const payload = { sourceId: selectedSourceData.id };
                const sourceResponse = await submitSource(payload);

                if (sourceResponse?.data?.requestSuccessful) {
                    const sourceType = sourceResponse?.data?.sourceTypes;
                    const selectedSourceType = sourceType?.find(sourceType => sourceType.id === BookingData.dmsSourceTypeId);

                    if (selectedSourceType) {
                        setSelectedSourceType(selectedSourceType);
                        setValue('sourceTypeId', selectedSourceType);
                    }
                } else {
                    console.log('Failed to fetch model data');
                }
            }


            const selectedMake = makes?.find(make => make?.id === BookingData?.make?.id);

            if (selectedMake) {
                setSelectedMake(selectedMake);
                setValue('makeId', selectedMake.id);

                const payload = { makeId: BookingData?.make?.id };
                const modelResponse = await getModalData(payload);

                if (modelResponse?.data?.requestSuccessful) {
                    const models = modelResponse.data.ModelData;
                    const selectedModel = models?.find(model => model?.id === BookingData?.model?.id);

                    if (selectedModel) {
                        setSelectedModel(selectedModel);
                        setValue('modelId', selectedModel.id);
                    }
                } else {
                    console.log('Failed to fetch model data');
                }
            }
        }
    }

    const handleOpenPopup = () => {
        setOpenPopup(true);
        getOpenServiceBookingData()
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handlePopupSubmit = async () => {
        const selectedServiceBooking = getValues("openServiceBooking");
        handleClosePopup();
        setBackDropOpen(true)
        if (selectedServiceBooking) {
            const payload = {
                selectedServiceBooking
            };

            try {
                const response = await SubmitOpenServiceBooking(payload);

                if (response && response?.data?.requestSuccessful === true) {
                    setCurrentTab(0);
                    setBackDropOpen(false)
                }
            } catch (error) {
                console.error("Error submitting service estimate:", error);
            }
        } else {
            console.error("No service estimate selected");
        }
        setVehicleNumber("");

    };

    const handleServiceEstimateChange = (event, value) => {
        if (value) {
            setVehicleNumber(value.registrationNumber);
        } else {
            setVehicleNumber("");
        }
    };

    const setSelectedValues = () => {
        if (isEditing) {
            if (getAllModalData !== undefined) {
                const selectedModel = model.find(model => model.id === selectedRowData.vehicleModelId);
                setSelectedModel(selectedModel);
                setValue('modelId', selectedModel);
            }
        }
    };
    useEffect(() => {
        setSelectedValues()
    }, [getAllModalData])

    //labour details
    const { fields, append, remove } = useFieldArray({
        control,
        name: "laborEstimate",
    });

    useEffect(() => {
        if (fields.length === 0) {
            append({ laborCode: "", laborDescription: "", quantity: "", rate: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", });

        }

    }, [fields, append]);

    //OSL labour details
    const { fields: oslFields, append: appendOsl, remove: removeOsl } = useFieldArray({
        control,
        name: "oslLaborEstimate",
    });

    useEffect(() => {
        if (oslFields.length === 0) {
            appendOsl({ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" });
        }
    }, [oslFields, appendOsl]);

    //Parts details
    const { fields: partsFields, append: appendParts, remove: removeParts } = useFieldArray({
        control,
        name: "partsEstimate",
    });

    useEffect(() => {
        if (partsFields.length === 0) {
            appendParts({ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", partTotal: "" });
        }
    }, [partsFields, appendParts]);

    useEffect(() => {
        if (vendors.length > 0) {
            setValue(`oslLaborEstimate[${0}].supplierCode`, vendors[0])
        }

    }, [vendors])

    const handleSearch = async (laborCode, index) => {
        if (laborCode <= 2) {
            setOptionData([]);
            setValue(`laborEstimate[${index}].laborDescription`, '');
        }
        if (laborCode.length >= 3) {
            const payload = {
                laborCode,
            };
            const response = await searchLabourData(payload);
            if (response.data.requestSuccessful) {
                setOptionData(response?.data?.labourSearchData);
                setCurrentRowIndex(index);
            }
        }
    };

    const handleSearchOsl = async (laborCode, index) => {
        if (laborCode <= 2) {
            setOslOptionData([]);
            setValue(`oslLaborEstimate[${index}].laborDescription`, '');
        }
        if (laborCode.length >= 3) {
            const payload = {
                laborCode,
            };
            const response = await searchOslLabourData(payload);
            if (response.data.requestSuccessful) {
                setOslOptionData(response?.data?.oslLabourSearchData);
                setOslCurrentRowIndex(index);
            }
        }
    };

    const handleSearchParts = async (itemCode, index) => {
        if (itemCode.length >= 3) {
            const payload = {
                itemCode,
            };
            const response = await searchPartsData(payload);
            if (response.data.requestSuccessful) {
                setPartsOptionData(response?.data?.itemSearchData);
                console.log("response?.data?.itemSearchData", response?.data?.itemSearchData)
                setPartsCurrentRowIndex(index);
                // setValue(`parts[${index}].rate`, labor.amount);
            }
        }
    };

    useEffect(() => {
        fetchItems(offset, searchValue);
        setBackDropOpen(true)
    }, [offset, limit, ServiceEstimateSuccess, editServiceEstimateSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey
        };
        const response = await getServiceEstimateDatagrid(payload);
        if (response?.data?.requestSuccessful === true) {
            setBackDropOpen(false)
        }
        setTotalItems(response?.data?.ServiceEstimateData?.totalItems);
    };

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };
    const totalPages = Math.ceil(totalItems / limit);

    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };
    useEffect(() => {
        if (LabourDetails?.requestSuccessful == true && LabourDetails.laborScheduleData.length > 0 && currentRowIndex !== null) {
            const { sacCode, laborDescription, sgst, cgst, igst, singleAmount, taxPercentage } = LabourDetails.laborScheduleData[0];
            setValue(`laborEstimate[${currentRowIndex}].laborDescription`, laborDescription);
            setValue(`laborEstimate[${currentRowIndex}].cgst`, cgst);
            setValue(`laborEstimate[${currentRowIndex}].sgst`, sgst);
            setValue(`laborEstimate[${currentRowIndex}].igst`, igst);
            setValue(`laborEstimate[${currentRowIndex}].singleAmount`, singleAmount);
            setValue(`laborEstimate[${currentRowIndex}].taxPercentage`, taxPercentage);
            setValue(`laborEstimate[${currentRowIndex}].quantity`, 1);
            setValue(`laborEstimate[${currentRowIndex}].sacCode`, sacCode);

            const rate = (1) * singleAmount;
            setValue(`laborEstimate[${currentRowIndex}].rate`, rate);
            const labourTotal = ((rate * taxPercentage) / 100) + rate;
            setValue(`laborEstimate[${currentRowIndex}].laborTotal`, labourTotal);
        }
        else if (LabourDetails?.requestSuccessful === false) {
            setDataStatusConfirm("Error");
            let message = LabourDetails?.validationErrors;
            setSnackBarMessage(message);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [LabourDetails, currentRowIndex, setValue]);

    useEffect(() => {
        if (OslLabourDetails?.requestSuccessful === true && OslLabourDetails.laborScheduleData.length > 0 && OslcurrentRowIndex !== null) {
            const { sacCode, taxPercentage, laborDescription, sgst, cgst, igst } = OslLabourDetails.laborScheduleData[0];
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].laborDescription`, laborDescription);
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].cgst`, cgst);
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].sgst`, sgst);
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].igst`, igst);
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].quantity`, 1);
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].sacCode`, sacCode);
            setValue(`oslLaborEstimate[${OslcurrentRowIndex}].taxPercentage`, taxPercentage);

        }
        else if (OslLabourDetails?.requestSuccessful === false) {
            setDataStatusConfirm("Error");
            let message = OslLabourDetails?.validationErrors;
            setSnackBarMessage(message);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [OslLabourDetails, OslcurrentRowIndex, setValue]);

    useEffect(() => {
        if (PartsDetails?.requestSuccessful && PartsDetails.itemsData.length > 0 && PartsCurrentRowIndex !== null) {
            const { taxPercentage, itemName, hsnCode, sgst, cgst, igst, list } = PartsDetails.itemsData[0];
            setValue(`partsEstimate[${PartsCurrentRowIndex}].partDescription`, itemName);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].cgst`, cgst);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].hsnCode`, hsnCode);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].sgst`, sgst);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].igst`, igst);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].rate`, list);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].quantity`, 1);
            setValue(`partsEstimate[${PartsCurrentRowIndex}].taxPercentage`, taxPercentage);
        }
        else if (PartsDetails?.requestSuccessful === false) {
            setDataStatusConfirm("Error");
            let message = PartsDetails?.validationErrors;
            setSnackBarMessage(message);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [PartsDetails, PartsCurrentRowIndex, setValue]);

    const handleChange = (index) => {
        const quantity = parseFloat(getValues(`laborEstimate[${index}].quantity`)) || 1;
        const additionalMargin = parseFloat(getValues(`laborEstimate[${index}].additionalMargin`)) || 0;
        const discountAmount = parseFloat(getValues(`laborEstimate[${index}].discountAmount`)) || 0;
        const amount = parseFloat(getValues(`laborEstimate[${index}].singleAmount`)) || 0;
        const tax = parseFloat(getValues(`laborEstimate[${index}].taxPercentage`)) || 0;

        let rate = quantity * amount;

        if (additionalMargin) {
            rate += additionalMargin;
        }

        if (discountAmount >= rate) {
            setError(`laborEstimate[${index}].discountAmount`, {
                type: 'manual',
                message: 'Discount amount cannot be greater than total rate'
            });
        } else {
            clearErrors(`laborEstimate[${index}].discountAmount`);
            rate -= discountAmount;
        }

        const labourTotal = (((rate * tax) / 100) + rate).toFixed(2);
        setValue(`laborEstimate[${index}].laborTotal`, parseFloat(labourTotal));
    };


    const watchFields = useWatch({ control, name: "oslLaborEstimate" });

    const memoizedOslFields = useMemo(() => oslFields, [oslFields]);
    const memoizedWatchFields = useMemo(() => JSON.stringify(watchFields), [watchFields]);

    useEffect(() => {
        memoizedOslFields.forEach((field, index) => {
            if (watchFields && watchFields[index]) {
                const quantity = parseFloat(watchFields[index]?.quantity) || 0;
                const rate = parseFloat(watchFields[index]?.rate) || 0;
                const oslTax = parseFloat(watchFields[index]?.taxPercentage) || 0;
                let newTotal = quantity * rate;

                const additionalMargin = parseFloat(watchFields[index]?.additionalMargin) || 0;
                const discountAmount = parseFloat(watchFields[index]?.discountAmount) || 0;

                if (additionalMargin) {
                    newTotal += additionalMargin;
                }

                if (discountAmount) {
                    if (discountAmount >= newTotal) {
                        setError(`oslLaborEstimate[${index}].discountAmount`, {
                            type: 'manual',
                            message: 'please enter value less than rate'
                        });
                    } else {
                        clearErrors(`oslLaborEstimate[${index}].discountAmount`);
                        newTotal -= discountAmount;
                    }
                }

                const labourTotal = (((newTotal * oslTax) / 100) + newTotal).toFixed(2);
                setValue(`oslLaborEstimate[${index}].laborTotal`, parseFloat(labourTotal));
            }
        });
    }, [memoizedWatchFields, memoizedOslFields, setValue, setError, clearErrors]);

    const watchPartsFields = useWatch({ control, name: "partsEstimate" });

    useEffect(() => {
        partsFields.forEach((field, index) => {
            // Check if watchFields[index] is defined
            if (watchPartsFields && watchPartsFields[index]) {
                const quantity = parseFloat(watchPartsFields[index]?.quantity) || 0;
                const rate = parseFloat(watchPartsFields[index]?.rate) || 0;
                const PartsTax = parseFloat(watchPartsFields[index]?.taxPercentage) || 0;

                let newTotal = quantity * rate;

                const additionalMargin = parseFloat(watchPartsFields[index]?.additionalMargin) || 0;
                const discountAmount = parseFloat(watchPartsFields[index]?.discountAmount) || 0;

                // Add additionalMargin if provided
                if (additionalMargin) {
                    newTotal += additionalMargin;
                }

                // Subtract discountAmount if provided
                if (discountAmount) {
                    if (discountAmount >= newTotal) {
                        setError(`partsEstimate[${index}].discountAmount`, {
                            type: 'manual',
                            message: 'please enter value less than rate'
                        });
                    } else {
                        clearErrors(`partsEstimate[${index}].discountAmount`);
                        newTotal -= discountAmount;
                    }
                }

                const labourTotal = (((newTotal * PartsTax) / 100) + newTotal).toFixed(2);
                setValue(`partsEstimate[${index}].partTotal`, parseFloat(labourTotal));
            }
        });
    }, [JSON.stringify(watchPartsFields), setValue, setError, clearErrors, partsFields]);

    const onSubmit = async (data) => {

        const laborEstimateWithIds = data.laborEstimate.map(item => ({
            ...item,
            laborCode: item.laborCode.laborCode,
            laborId: item.laborCode.id,
            sacCode: item.sacCode,
            repairType: item.repairType || repairTypes[0],
        }));
        const PartsNoWithIds = data.partsEstimate.map(item => ({
            ...item,
            partNo: item.partNo.itemCode,
            partId: item.partNo.id,
        }));
        const oslLaborEstimateWithIds = data.oslLaborEstimate.map(item => ({
            ...item,
            laborCode: item.laborCode.laborCode,
            laborId: item.laborCode.id,
            sacCode: item.sacCode,
            supplierCode: item.supplierCode || vendors[0]
        }));

        const formData = {
            ...data,
            customerId: VehicleIdData.customeId,
            vehicleId: VehicleIdData.vehicleId,
            customerStatus: VehicleIdData.customerStatus,
            customerState: selectedState,
            customerCity: selectedCity,
            pincode: data.pincode,
            makeId: selectedMake?.id || null,
            modelId: selectedModel?.id || null,
            sourceId: data?.sourceId.sourceName,
            sourceTypeId: data?.sourceTypeId.sourceTypeName,
            serviceTypeId: data?.serviceTypeId.serviceTypeName,
            repairTypeId: data?.repairTypeId.repairTypeName,
            jobType: data?.jobType.value,
            laborEstimate: laborEstimateWithIds,
            partsEstimate: PartsNoWithIds,
            oslLaborEstimate: oslLaborEstimateWithIds,
            serviceBookingId: SubmitBookingData?.BookingData?.serviceBookingNumber ? SubmitBookingData?.BookingData?.serviceBookingNumber : 0
        };

        let response;
        if (isEditing) {
            response = await editServiceEstimateData({ id: currentItemId, ...formData });
            if (response && response.data.requestSuccessful === true) {
                setIsEditing(false);
                setCurrentItemId(null);
            }
        } else {
            response = await addSeriveEstimateData(formData);
        }
        if (response && response.data) {
            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                resetForm();
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                resetForm();
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };

    const resetForm = () => {
        reset({
            registrationNumber: "",
            jobType: "",
            serviceEngineerRemarks: "",
            customerVoice: "",
            driverName: "",
            odometer: "",
            customerMobileNumber: "",
            customerName: "",
            customerAddress: "",
            pincode: "",
            laborEstimate: [{ laborCode: "", repairType: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" }],
            oslLaborEstimate: [{ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" }],
            partsEstimate: [{ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", partTotal: "" }]
        });

        setIsEditing(false);
        setSelectedCity("");
        setSelectedState("");
        setSelectedMake(null);
        setSelectedModel(null);
        setCurrentTab(1);
        setSelectedSourceType(null)
        setSelectedSource(null)
    };


    const handleEditClick = async (row) => {
        setSelectedRowData(row)
        setValue('registrationNumber', row.registrationNumber);
        handleVehicleData(row.registrationNumber)
        setValue('odometer', row.odometer);
        setValue('customerMobileNumber', row.customerMobileNumber);
        setValue('customerName', row.customerName);
        setValue('customerAddress', row.customerAddress);
        setValue('pincode', row.customerPincode);
        setValue('customerVoice', row.customerVoice);
        setValue('driverName', row.driverName);
        setValue('driverMobileNumber', row.driverMobileNumber);
        setValue('serviceEngineerRemarks', row.serviceEngineerRemarks);
        setSelectedCity(row.customerCity);
        setSelectedState(row.customerState);

        const selectedJobType = jobTypeOptions.find(option => option.value === row.jobType);
        setValue('jobType', selectedJobType || null);

        const selectedSourceData = sources?.find(source => source.sourceName === row.source);
        setSelectedSource(selectedSourceData)
        setValue('sourceId', selectedSourceData || null);

        if (selectedSourceData) {
            setSelectedSourceType(selectedSourceData);
            setValue('sourceId', selectedSourceData);

            const payload = { sourceId: selectedSourceData.id };
            const sourceResponse = await submitSource(payload);

            if (sourceResponse?.data?.requestSuccessful) {
                const sourceType = sourceResponse?.data?.sourceTypes;
                const selectedSourceType = sourceType?.find(sourceType => sourceType.sourceTypeName === row.sourceType);

                if (selectedSourceType) {
                    setSelectedSourceType(selectedSourceType);
                    setValue('sourceTypeId', selectedSourceType);
                }
            } else {
                console.log('Failed to fetch model data');
            }
        }

        const selectedServiceType = serviceTypes?.find(serviceType => serviceType.serviceTypeName === row.serviceType);
        setValue('serviceTypeId', selectedServiceType || null);

        const selectedRepairType = repairTypes?.find(repairType => repairType.repairTypeName === row.repairType);
        setValue('repairTypeId', selectedRepairType || null);

        const selectedMake = makes?.find(make => make.id === row.vehicleMakeId);
        setValue('makeId', selectedMake || null);
        setSelectedMake(selectedMake);
        if (selectedMake) {
            setSelectedMake(selectedMake);
            setValue('makeId', selectedMake.id);

            const payload = { makeId: selectedMake.id };
            const modelResponse = await getModalData(payload);

            if (modelResponse?.data?.requestSuccessful) {
                const models = modelResponse.data.ModelData;
                const selectedModel = models?.find(model => model?.id === row.vehicleModelId);

                if (selectedModel) {
                    setSelectedModel(selectedModel);
                    setValue('modelId', selectedModel.id);
                }
            } else {
                console.log('Failed to fetch model data');
            }
        }


        row.labourEstimate.forEach((labor, index) => {
            if (fields.length < row.labourEstimate.length) {
                append({
                    laborCode: "",
                    laborDescription: "",
                    quantity: "",
                    rate: "",
                    additionalMargin: "",
                    discountAmount: "",
                    sgst: "",
                    cgst: "",
                    igst: "",
                    laborTotal: "",
                    repairType: "",
                });
            }
            setValue(`laborEstimate[${index}].laborCode`, { laborCode: labor.laborCode, id: labor.laborId });
            setValue(`laborEstimate[${index}].laborDescription`, labor.laborDescription);
            const repairType = repairTypes.find(rt => rt.id === labor.repairTypeId) || repairTypes[0];
            setValue(`laborEstimate[${index}].repairType`, repairType);
            setValue(`laborEstimate[${index}].quantity`, labor.quantity);
            setValue(`laborEstimate[${index}].rate`, labor.rate);
            setValue(`laborEstimate[${index}].additionalMargin`, labor.additionalMargin);
            setValue(`laborEstimate[${index}].discountAmount`, labor.discountAmount);
            setValue(`laborEstimate[${index}].singleAmount`, labor.singleAmount);
            setValue(`laborEstimate[${index}].sgst`, labor.sgst);
            setValue(`laborEstimate[${index}].cgst`, labor.cgst);
            setValue(`laborEstimate[${index}].igst`, labor.igst);
            setValue(`laborEstimate[${index}].laborTotal`, labor.laborTotal);
            setValue(`laborEstimate[${index}].sacCode`, labor.sacCode);
            const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;
            setValue(`laborEstimate[${index}].taxPercentage`, taxPercentage);
        });

        row?.oslLabourEstimate?.forEach((labor, index) => {
            if (oslFields.length < row.oslLabourEstimate.length) {
                appendOsl({
                    supplierCode: "",
                    laborCode: "",
                    laborDescription: "",
                    quantity: "",
                    additionalMargin: "",
                    discountAmount: "",
                    sgst: "",
                    cgst: "",
                    igst: "",
                    laborTotal: "",
                    rate: ""
                })
            }
            const supplier = vendors.find(rt => rt.id === labor.vendorId) || vendors[0]
            setValue(`oslLaborEstimate[${index}].supplierCode`, supplier);
            setValue(`oslLaborEstimate[${index}].laborCode`, { laborCode: labor.laborCode, id: labor.laborId });
            setValue(`oslLaborEstimate[${index}].laborDescription`, labor.laborDescription);
            setValue(`oslLaborEstimate[${index}].quantity`, labor.quantity);
            setValue(`oslLaborEstimate[${index}].rate`, labor.rate);
            setValue(`oslLaborEstimate[${index}].additionalMargin`, labor.additionalMargin);
            setValue(`oslLaborEstimate[${index}].discountAmount`, labor.discountAmount);
            setValue(`oslLaborEstimate[${index}].sgst`, labor.sgst);
            setValue(`oslLaborEstimate[${index}].cgst`, labor.cgst);
            setValue(`oslLaborEstimate[${index}].igst`, labor.igst);
            setValue(`oslLaborEstimate[${index}].laborTotal`, labor.laborTotal);
            setValue(`oslLaborEstimate[${index}].sacCode`, labor.sacCode);
            const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;
            setValue(`oslLaborEstimate[${index}].taxPercentage`, taxPercentage);
        });

        row.partEstimate.forEach((labor, index) => {
            if (partsFields.length < row.partEstimate.length) {
                appendParts({
                    partNo: "",
                    partDescription: "",
                    hsnCode: "",
                    quantity: "",
                    rate: "",
                    additionalMargin: "",
                    discountAmount: "",
                    sgst: "",
                    cgst: "",
                    igst: "",
                    partTotal: ""
                })
            }

            setValue(`partsEstimate[${index}].partNo`, { itemCode: labor.partNo, id: labor.partId });
            setValue(`partsEstimate[${index}].partDescription`, labor.partDescription);
            setValue(`partsEstimate[${index}].hsnCode`, labor.hsnCode);
            setValue(`partsEstimate[${index}].quantity`, labor.requestedQuantity);
            setValue(`partsEstimate[${index}].rate`, labor.rate);
            setValue(`partsEstimate[${index}].additionalMargin`, labor.additionalMargin);
            setValue(`partsEstimate[${index}].discountAmount`, labor.discountAmount);
            setValue(`partsEstimate[${index}].sgst`, labor.sgst);
            setValue(`partsEstimate[${index}].cgst`, labor.cgst);
            setValue(`partsEstimate[${index}].igst`, labor.igst);
            setValue(`partsEstimate[${index}].partTotal`, labor.partTotal);
            const taxPercentage = labor.igst !== 0 ? labor.igst : labor.sgst + labor.cgst;
            setValue(`partsEstimate[${index}].taxPercentage`, taxPercentage);
        });

        setIsEditing(true);
        setCurrentTab(0);
        setCurrentItemId(row.id);
        clearErrors(['pincode', 'odometer', 'customerMobileNumber', 'customerName', 'customerAddress', 'customerVoice', 'registrationNumber', 'serviceEngineerRemarks']);
    };

    const handlePincodeChange = async (event) => {
        let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
        if (pincode.length === 6) {
            const payLoad = {
                pinCode: pincode,
            };
            const response = await getAllPincodeData(payLoad);
            if (response?.data?.requestSuccessful) {
                setSelectedState(response.data.pincodeData.state);
                setSelectedCity(response.data.pincodeData.city);
            }
        }
    };
    const handleVehicleData = async (regNumber) => {
        const payLoad = {
            registrationNumber: regNumber
        };
        const response = await getAllVehicleData(payLoad);

        if (response?.data?.requestSuccessful) {
            const vehicleData = response.data.vehicleData[0];
            const CustomerMessage = vehicleData?.message

            setVehicleIdData(vehicleData);

            const selectedMake = makes?.find(make => make?.id === vehicleData?.makeId);

            if (selectedMake) {
                setSelectedMake(selectedMake);
                setValue('makeId', selectedMake.id);

                const payload = { makeId: vehicleData.makeId };
                const modelResponse = await getModalData(payload);

                if (modelResponse?.data?.requestSuccessful) {
                    const models = modelResponse.data.ModelData;
                    const selectedModel = models?.find(model => model?.id === vehicleData?.modelId);

                    if (selectedModel) {
                        setSelectedModel(selectedModel);
                        setValue('modelId', selectedModel.id);
                    } else {
                        // Handle case where model is not found
                        setSelectedModel("");
                        setValue('modelId', "");
                    }
                } else {
                    console.log('Failed to fetch model data');
                    setSelectedModel("");
                    setValue('modelId', "");
                }
            } else {
                setSelectedMake(null);
                setSelectedModel(null);
                setValue('makeId', "");
                setValue('modelId', "");
            }

            // Set customer details if available
            if (vehicleData.customerName && vehicleData.customerMobileNumber && vehicleData.customerAddress) {
                setValue('customerName', vehicleData.customerName);
                setCustomerName(vehicleData.customerName);

                setValue('customerMobileNumber', vehicleData.customerMobileNumber);
                setcustomerMobileNumber(vehicleData.customerMobileNumber);

                setValue('customerAddress', vehicleData.customerAddress);
                setCustomerAddress(vehicleData.customerAddress);
            } else {
                setValue('customerName', "");
                setCustomerName("");

                setValue('customerMobileNumber', "");
                setcustomerMobileNumber("");

                setValue('customerAddress', "");
                setCustomerAddress("");
            }

            // Handle pincode, state, and city
            if (vehicleData.pincode) {
                setPincodeData(vehicleData.pincode);
                setValue('pincode', vehicleData.pincode);
                setSelectedState(vehicleData.customerState);
                setSelectedCity(vehicleData.customerCity);
            } else {
                setPincodeData("");
                setValue('pincode', "");
                setSelectedState("");
                setSelectedCity("");
            }

            // Check if the customer is an existing customer
            if (CustomerMessage === "Existing customer") {
                setCustomerFieldsReadOnly(true);
            } else {
                setCustomerFieldsReadOnly(false);
            }

            // Show success message
            setDataStatusConfirm("Success");
            setSnackBarMessage(vehicleData?.message);
        } else {
            // Handle error message
            setDataStatusConfirm("Error");
            setSnackBarMessage("Vehicle data could not be found");
        }

        // Show snackbar message
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 5000);
    };

    // A function to handle read-only state for customer fields
    const setCustomerFieldsReadOnly = (isReadOnly) => {
        setCustomerNameReadOnly(isReadOnly);
        setCustomerAddressReadOnly(isReadOnly);
        setMobileNoReadOnly(isReadOnly);
        setMakeDisabled(isReadOnly);
        setModelDisabled(isReadOnly);
        setPincodeReadOnly(isReadOnly)
    };

    const handleRegisterNumberChange = async (event) => {
        const regNumber = event.target.value.toUpperCase().slice(0, 10);
        setValue('registrationNumber', regNumber);

        if (regNumber.length === 10) {
            await handleVehicleData(regNumber);
        }
    };


    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };
    const columns = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        const hasUpdatePermission = buttonList.split(',').includes("3");
        const baseColumns = [
            {
                field: 'serviceEstimateNumber',
                headerName: 'ServiceEstimate Number',
                flex: 1,
            },
            {
                field: 'registrationNumber',
                headerName: 'Registration Number',
                flex: 1,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params) => {
                    let background = '';
                    let statusText;

                    switch (params.value) {
                        case 1:
                            statusText = 'Open';
                            background = '#ff0000';
                            break;
                        case 2:
                            statusText = 'Jc Created';
                            background = '#1b8c0a';
                            break;
                        default:
                            statusText = '-';
                            background = 'gray';
                    }

                    return (
                        <Chip
                            label={
                                <>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '8px',
                                            color: "#ffffd6 !important"
                                        }}
                                    />
                                    {statusText}
                                </>
                            }
                            style={{
                                textTransform: 'capitalize',
                                backgroundColor: background,
                                color: '#fff', // Ensure text contrast is readable
                                width: "150px",
                            }}
                        />
                    );
                },
            }

        ];
        if (hasUpdatePermission) {
            baseColumns.push({
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    <div style={{ display: 'flex' }}>

                        {params?.row?.status === 1 && (
                            <Tooltip title="Edit">
                                <span>
                                    <MdEdit onClick={() => handleEditClick(params.row)} style={{ fontSize: '1.1rem', cursor: 'pointer', marginRight: '10px' }} />
                                </span>
                            </Tooltip>
                        )}

                        <Tooltip title="Pdf">
                            <span>
                                <FaFilePdf onClick={() => handlePdfClick(params.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="View">
                            <span>
                                <FaSearchPlus onClick={() => handleViewClick(params.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
                            </span>
                        </Tooltip>
                    </div>
                ),
                cellClassName: 'sticky-action-column',
                headerClassName: 'sticky-action-column-header',
            });
        }

        return baseColumns;
    }, [menuList, pathname]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            setIsCreating(true);
        } else {
            setIsCreating(false);
            resetForm();
        }
        setCurrentTab(newValue);
    };

    const hasCreatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("1");
    }, [menuList, pathname]);

    const hasUpdatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("3");
    }, [menuList, pathname]);

    const jobTypeOptions = [
        { label: 'SQRT', value: 'SQRT' },
        { label: 'ASQRT', value: 'ASQRT' },
    ];
    const handleViewClick = (rowData) => {
        setViewPageData(rowData);
        setShowViewPage(true);
    };

    const handlePdfClick = async (rowData) => {
        const id = rowData.id;
        try {
            const pdfBlob = await getPdfDetails(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `ServiceEstimate_${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const handleBackClick = () => {
        setShowViewPage(false);
    };
    const Cancelled = () => {
            reset({
                registrationNumber: "",
                jobType: "",
                serviceEngineerRemarks: "",
                customerVoice: "",
                driverName: "",
                odometer: "",
                customerMobileNumber: "",
                customerName: "",
                customerAddress: "",
                pincode: "",
                laborEstimate: [{ laborCode: "", repairType: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" }],
                oslLaborEstimate: [{ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" }],
                partsEstimate: [{ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", partTotal: "" }]

            });
            setSelectedCity("")
            setSelectedState("")
            setSelectedMake(null);
            setSelectedModel(null);
            setSelectedSourceType(null)
            setSelectedSource(null)
            setIsEditing(false);
    }

    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Transaction"
                subDiv="Service Estimate"
            ></StyledBreadcrumb>
            {showViewPage ? (
                <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                    <ViewPage
                        data={viewPageData}
                        onBackClick={handleBackClick}
                    />
                </StyledPaperShadow>
            ) : (
                <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                        <Tabs value={currentTab} onChange={handleTabChange}
                            sx={{ '&  .MuiTabs-indicator': { backgroundColor: "#e96b20" } }}
                        >
                            {hasCreatePermission ? (
                                <Tab label="Create Service Estimate" />
                            ) : (isEditing && !hasCreatePermission) ? (
                                <Tab label="Update Service Estimate" />
                            ) : null}
                            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Service Estimate" />)}

                        </Tabs>
                    </Box>
                    {currentTab === 0 && (
                        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('registrationNumber', {
                                                required: 'Registration Number is required',
                                                maxLength: {
                                                    value: 10,
                                                    message: 'Registration Number should not exceed 10 characters'
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9]*$/,
                                                    message: 'Registration Number should only contain uppercase letters and numbers'
                                                }
                                            })}
                                            fullWidth
                                            variant="standard"
                                            label="Registration Number"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.registrationNumber}
                                            helperText={errors.registrationNumber ? errors.registrationNumber.message : ''}
                                            onChange={handleRegisterNumberChange}
                                            inputProps={{ maxLength: 10, readOnly: isEditing }}
                                            onInput={handleInputChange}

                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="jobType"
                                            control={control}
                                            rules={{ required: 'JobType is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    options={jobTypeOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                    value={field.value || null}
                                                    onChange={(event, value) => field.onChange(value)}
                                                    disabled={isEditing}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Job Type"
                                                            variant="standard"
                                                            error={!!errors.jobType}
                                                            helperText={errors.jobType ? "Job Type is required" : ""}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="makeId"
                                            control={control}
                                            rules={{ required: 'Make is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={makes}
                                                    getOptionLabel={(option) => option.makeName}
                                                    disabled={isEditing || makeDisabled}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Make"
                                                            variant="standard"
                                                            error={!!errors.makeId}
                                                            helperText={errors.makeId ? errors.makeId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedMake}
                                                    onChange={(event, value, reason) => {
                                                        if (value !== selectedMake) {
                                                            setSelectedModel(null);
                                                            setValue('modelId', null);
                                                        }
                                                        setSelectedMake(value);
                                                        field.onChange(value ? value.id : null);

                                                        if (value) {
                                                            const payload = { makeId: value.id };
                                                            getModalData(payload);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            name="modelId"
                                            control={control}
                                            rules={{ required: 'Model is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={model}
                                                    getOptionLabel={(option) => option.modelName}
                                                    disabled={isEditing || modelDisabled}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Model"
                                                            variant="standard"
                                                            error={!!errors.modelId}
                                                            helperText={errors.modelId ? errors.modelId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedModel}
                                                    onChange={(event, value) => {
                                                        setSelectedModel(value);
                                                        field.onChange(value ? value.id : null);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField {...register('odometer', { required: 'odometer is required' })}
                                            fullWidth variant="standard" label="Odometer"
                                            type='number'
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.odometer}
                                            helperText={errors.odometer ? errors.odometer.message : ''}
                                            onInput={handleInputChange}
                                            InputProps={{ readOnly: isEditing }} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField {...register('customerName', { required: 'Customer Name is required' })}
                                            fullWidth variant="standard" label="Customer Name"
                                            InputLabelProps={(isEditing || customerNameData) ? { shrink: true } : {}}
                                            error={!!errors.customerName}
                                            helperText={errors.customerName ? errors.customerName.message : ''}
                                            onInput={handleInputChange}
                                            InputProps={{ readOnly: isEditing || customerNameReadOnly }} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField {...register('customerAddress', { required: 'Customer Address is required' })}
                                            fullWidth variant="standard" label="Customer Address"
                                            InputLabelProps={(isEditing || customerAddressData) ? { shrink: true } : {}}
                                            error={!!errors.customerAddress}
                                            helperText={errors.customerAddress ? errors.customerAddress.message : ''}
                                            onInput={handleInputChange}
                                            InputProps={{ readOnly: isEditing || customerAddressReadOnly }} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('customerMobileNumber', {
                                                required: 'Mobile Number is required',
                                                validate: (value) =>
                                                    value.length === 10 || 'Mobile Number must be exactly 10 digits'
                                            })}
                                            fullWidth
                                            variant="standard"
                                            label="Mobile Number"
                                            error={!!errors.customerMobileNumber}
                                            type="number"
                                            InputProps={{ readOnly: isEditing || mobileNoReadOnly }}
                                            InputLabelProps={(isEditing || customerMobileNumberData) ? { shrink: true } : {}}
                                            helperText={errors.customerMobileNumber ? errors.customerMobileNumber.message : ''}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (value.length > 10) {
                                                    e.target.value = value.slice(0, 10);
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 10,
                                                min: 0,
                                                pattern: "[0-9]*",
                                                inputMode: "numeric"
                                            }}
                                            onBlur={() => {
                                                trigger('customerMobileNumber'); // Trigger validation on blur
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('pincode', {
                                                required: 'Pincode is required',
                                                minLength: { value: 6, message: 'Pincode must be 6 digits' },
                                                maxLength: { value: 6, message: 'Pincode must be 6 digits' }
                                            })}
                                            fullWidth
                                            variant="standard"
                                            type="tel"
                                            label="Pincode"
                                            error={!!errors.pincode}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                            InputLabelProps={(isEditing || pincodeData) ? { shrink: true } : {}}
                                            helperText={errors.pincode ? errors.pincode.message : ''}
                                            onChange={handlePincodeChange}
                                            InputProps={{ readOnly: isEditing || pincodeReadOnly }}
                                        />

                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="State"
                                            value={selectedState}
                                            InputLabelProps={selectedState ? { shrink: true } : {}}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="City"
                                            value={selectedCity}
                                            InputLabelProps={selectedCity ? { shrink: true } : {}}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            name="serviceTypeId"
                                            control={control}
                                            rules={{ required: 'serviceType is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={serviceTypes}
                                                    getOptionLabel={(option) => option.serviceTypeName}
                                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Service Type"
                                                            variant="standard"
                                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                                            error={!!errors.serviceTypeId}
                                                            helperText={errors.serviceTypeId ? errors.serviceTypeId.message : ''}
                                                        />
                                                    )}
                                                    value={field.value || null}
                                                    onChange={(event, value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="repairTypeId"
                                            control={control}
                                            rules={{ required: 'repairType is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={repairTypes}
                                                    getOptionLabel={(option) => option.repairTypeName}
                                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Repair Type"
                                                            variant="standard"
                                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                                            error={!!errors.repairTypeId}
                                                            helperText={errors.repairTypeId ? errors.repairTypeId.message : ''}
                                                        />
                                                    )}
                                                    value={field.value || null}
                                                    onChange={(event, value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="sourceId"
                                            control={control}
                                            rules={{ required: 'sourceId is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={sources}
                                                    getOptionLabel={(option) => option.sourceName}
                                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Source"
                                                            variant="standard"
                                                            error={!!errors.sourceId}
                                                            helperText={errors.sourceId ? errors.sourceId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedSource}
                                                    onChange={(event, value, reason) => {
                                                        if (value !== selectedSource) {
                                                            setSelectedSourceType(null);
                                                            setValue('sourceTypeId', null);
                                                        }

                                                        setSelectedSource(value);
                                                        field.onChange(value ? value.id : null);
                                                        setValue('sourceId', value);

                                                        if (value) {
                                                            const payload = { sourceId: value.id };
                                                            submitSource(payload);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="sourceTypeId"
                                            control={control}
                                            rules={{ required: ' Source Type is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={sourceType}
                                                    getOptionLabel={(option) => option.sourceTypeName}
                                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Source Type"
                                                            variant="standard"
                                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                                            error={!!errors.sourceTypeId}
                                                            helperText={errors.sourceTypeId ? errors.sourceTypeId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedSourceType}
                                                    onChange={(event, value) => {
                                                        setSelectedSourceType(value);
                                                        setValue('sourceTypeId', value);
                                                        field.onChange(value ? value : null);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('customerVoice')}
                                            fullWidth
                                            variant="standard"
                                            label="Customer Voice"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.customerVoice}
                                            helperText={errors.customerVoice ? errors.customerVoice.message : ''}
                                            onInput={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('serviceEngineerRemarks')}
                                            fullWidth
                                            variant="standard"
                                            label="Service Engineer Remarks"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            onInput={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('driverName')}
                                            fullWidth
                                            variant="standard"
                                            label="Driver Name"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            onInput={handleInputChange}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('driverMobileNumber', {
                                                maxLength: {
                                                    value: 10,
                                                    message: 'Driver Mobile Number cannot exceed 10 digits'
                                                }
                                            })}
                                            fullWidth
                                            variant="standard"
                                            label="Driver Mobile Number"
                                            inputProps={{
                                                maxLength: 10,
                                                min: 0,
                                                pattern: "[0-9]*",
                                                inputMode: "numeric"
                                            }}
                                            type="number"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (value.length > 10) {
                                                    e.target.value = value.slice(0, 10);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "50px", marginLeft: "10px" }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}> Labour details</Typography>
                                            <SoftButton sx={{ width: "200px" }} onClick={() => append({ laborCode: "", repairType: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" })} startIcon={<MdAdd />}>
                                                Add Labour Details
                                            </SoftButton>
                                        </Box>

                                        <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
                                            {fields.map((item, index) => (
                                                <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px" }}>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`laborEstimate.${index}.laborCode`}
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    freeSolo
                                                                    options={optionData}
                                                                    getOptionLabel={(option) => option.laborCode || ''}
                                                                    filterOptions={(options, state) => {
                                                                        const searchTerm = state.inputValue.toLowerCase();
                                                                        return options.filter(
                                                                            (option) =>
                                                                                option.laborCode.toLowerCase().includes(searchTerm) ||
                                                                                option.laborDescription.toLowerCase().includes(searchTerm)
                                                                        );
                                                                    }}
                                                                    onChange={async (event, value) => {
                                                                        field.onChange(value);
                                                                        const payload = {
                                                                            laborCode: value ? value.laborCode : "",
                                                                            modelSegment: VehicleIdData ? VehicleIdData.modelSegment : "",
                                                                            customerState: VehicleIdData ? VehicleIdData.customerState : "",
                                                                        };
                                                                        await getAllLabourDetailsData(payload);
                                                                    }}
                                                                    value={field.value}
                                                                    renderOption={(props, option) => (
                                                                        <Box
                                                                            key={option.laborCode}
                                                                            component="li"
                                                                            {...props}
                                                                        >
                                                                            {option.laborCode} | {option.laborDescription}
                                                                        </Box>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            size="small"
                                                                            fullWidth
                                                                            label="Labour Code"
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                    onInputChange={(e, value) => {
                                                                        handleSearch(value, index);
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].laborDescription`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    label="Labour Description"
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].repairType`}
                                                            control={control}
                                                            defaultValue={repairTypes[0]}
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    options={repairTypes}
                                                                    getOptionLabel={(option) => option.repairTypeName}
                                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                    onChange={(_, data) => field.onChange(data)}
                                                                    value={field.value || repairTypes[0]}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            size="small"
                                                                            label="Repair Type"
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].quantity`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Quantity"
                                                                    variant="standard"
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        field.onChange(e.target.value);
                                                                        handleChange(index);
                                                                    }}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        pattern: "[0-9]*",
                                                                        inputMode: "numeric"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].rate`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label="Rate"
                                                                variant="standard"
                                                                type="number"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                                disabled
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{ display: 'none' }}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].singleAmount`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Single Amount"
                                                                    variant="standard"
                                                                    type="hidden"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{ display: 'none' }}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].taxPercentage`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="taxPercentage"
                                                                    variant="standard"
                                                                    type="hidden"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{ display: 'none' }}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].sacCode`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="SacCode"
                                                                    variant="standard"
                                                                    type="hidden"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].additionalMargin`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label=" Additional Margin"
                                                                variant="standard"
                                                                type="number"
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value);
                                                                    handleChange(index);
                                                                }}
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`laborEstimate[${index}].discountAmount`}
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Discount Amount"
                                                                    variant="standard"
                                                                    type="number"
                                                                    error={!!error}
                                                                    helperText={error ? error.message : ''}
                                                                    onChange={(e) => {
                                                                        field.onChange(e.target.value);
                                                                        handleChange(index);
                                                                    }}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        pattern: "[0-9]*",
                                                                        inputMode: "numeric"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                            <IconButton onClick={() => remove(index)}>
                                                                <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                                                <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                    <Grid sx={{ margin: "20px 0px", backgroundColor: "#eef3ff", width: "100%", marginLeft: "16px" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Box sx={{ display: "flex", columnGap: "30px" }} >

                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`laborEstimate[${index}].sgst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" SGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`laborEstimate[${index}].cgst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" CGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`laborEstimate[${index}].igst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" IGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Box>
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                                                <Box>
                                                                    <Typography>Labour Total</Typography>
                                                                </Box>
                                                                <Grid item xs={2} sx={{ display: "flex", flexDirection: "row" }}>

                                                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                                        <Controller
                                                                            name={`laborEstimate[${index}].laborTotal`}
                                                                            control={control}
                                                                            variant="standard"
                                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                                type="number"
                                                                                variant="standard"
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiInputBase-input': {
                                                                                        textAlign: 'center',
                                                                                        fontWeight: 700,
                                                                                    }
                                                                                }}
                                                                            />}
                                                                        />

                                                                    </Box>

                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Box>
                                    </StyledPaperShadow>

                                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "50px", marginLeft: "10px" }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Osl Labour details</Typography>
                                            <SoftButton sx={{ width: "200px" }} onClick={() => appendOsl({ supplierCode: "", laborCode: "", laborDescription: "", quantity: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", laborTotal: "", rate: "" })} startIcon={<MdAdd />}>
                                                Add Osl Labour
                                            </SoftButton>
                                        </Box>

                                        <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
                                            {oslFields.map((item, index) => (
                                                <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px" }}>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`oslLaborEstimate.${index}.laborCode`}
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    freeSolo
                                                                    options={oslOptionData}
                                                                    getOptionLabel={(option) => option.laborCode || ''}
                                                                    filterOptions={(options, state) => {
                                                                        const searchTerm = state.inputValue.toLowerCase();
                                                                        return options.filter(
                                                                            (option) =>
                                                                                option.laborCode.toLowerCase().includes(searchTerm) ||
                                                                                option.laborDescription.toLowerCase().includes(searchTerm)
                                                                        );
                                                                    }}
                                                                    onChange={async (event, value) => {
                                                                        field.onChange(value);
                                                                        const payload = {
                                                                            laborCode: value ? value.laborCode : "",
                                                                            modelSegment: VehicleIdData ? VehicleIdData.modelSegment : "",
                                                                            customerState: VehicleIdData ? VehicleIdData.customerState : "",
                                                                        };
                                                                        await getAllOslLabourDetailsData(payload);
                                                                    }}
                                                                    value={field.value}
                                                                    renderOption={(props, option) => (
                                                                        <Box
                                                                            key={option.laborCode}
                                                                            component="li"
                                                                            {...props}
                                                                        >
                                                                            {option.laborCode} | {option.laborDescription}
                                                                        </Box>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            size="small"
                                                                            fullWidth
                                                                            label="Labour Code"
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                    onInputChange={(e, value) => {
                                                                        handleSearchOsl(value, index);
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].supplierCode`}
                                                            control={control}
                                                            defaultValue={vendors[0]}
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    options={vendors}
                                                                    getOptionLabel={(option) => option.vendorCode}
                                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                    onChange={(_, data) => field.onChange(data)}
                                                                    value={field.value || vendors[0]}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            size="small"
                                                                            label="Supplier Code"
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].laborDescription`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    label="Labour Description"
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={1}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].quantity`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label=" Quantity"
                                                                variant="standard"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                            />}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].rate`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label="Rate"
                                                                variant="standard"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}

                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{ display: 'none' }}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].sacCode`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="SacCode"
                                                                    variant="standard"
                                                                    type="hidden"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{ display: 'none' }}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].taxPercentage`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="taxPercentage"
                                                                    variant="standard"
                                                                    type="hidden"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].additionalMargin`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label=" Additional Margin"
                                                                variant="standard"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`oslLaborEstimate[${index}].discountAmount`}
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Discount Amount"
                                                                    variant="standard"
                                                                    type="number"
                                                                    error={!!error}
                                                                    helperText={error ? error.message : ''}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        pattern: "[0-9]*",
                                                                        inputMode: "numeric"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                            <IconButton onClick={() => removeOsl(index)}>
                                                                <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                                                <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                    <Grid sx={{ margin: "20px 0px", backgroundColor: "#eef3ff", width: "100%", marginLeft: "16px" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Box sx={{ display: "flex", columnGap: "30px" }} >

                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`oslLaborEstimate[${index}].sgst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" SGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`oslLaborEstimate[${index}].cgst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" CGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`oslLaborEstimate[${index}].igst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" IGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Box>
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                                                <Box>
                                                                    <Typography>OslLabour Total</Typography>
                                                                </Box>
                                                                <Grid item xs={2} sx={{ display: "flex", flexDirection: "row" }}>

                                                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                                        <Controller
                                                                            name={`oslLaborEstimate[${index}].laborTotal`}
                                                                            control={control}
                                                                            variant="standard"
                                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiInputBase-input': {
                                                                                        textAlign: 'center',
                                                                                        fontWeight: 700,
                                                                                    }
                                                                                }}

                                                                            />}
                                                                        />

                                                                    </Box>

                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Box>
                                    </StyledPaperShadow>

                                    <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "50px", marginLeft: "10px" }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Parts details</Typography>
                                            <SoftButton sx={{ width: "200px" }} onClick={() => appendParts({ partNo: "", partDescription: "", hsnCode: "", quantity: "", rate: "", additionalMargin: "", discountAmount: "", sgst: "", cgst: "", igst: "", partTotal: "" })} startIcon={<MdAdd />}>
                                                Add Parts Details
                                            </SoftButton>
                                        </Box>

                                        <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
                                            {partsFields.map((item, index) => (
                                                <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px" }}>

                                                    <Grid item xs={2}>
                                                        <Controller
                                                            name={`partsEstimate.${index}.partNo`}
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    freeSolo
                                                                    options={partsOptionData}
                                                                    onChange={async (event, value) => {
                                                                        field.onChange(value);
                                                                        const payload = {
                                                                            itemCode: value ? value.itemCode : "",
                                                                            modelSegment: VehicleIdData ? VehicleIdData.modelSegment : "",
                                                                            customerState: VehicleIdData ? VehicleIdData.customerState : "",
                                                                        };
                                                                        await getAllPartsData(payload);
                                                                    }}
                                                                    value={field.value}
                                                                    getOptionLabel={(option) => option.itemCode || ''}
                                                                    filterOptions={(options, state) => {
                                                                        const searchTerm = state.inputValue.toLowerCase();
                                                                        return options.filter(
                                                                            (option) =>
                                                                                option.itemCode.toLowerCase().includes(searchTerm) ||
                                                                                option.itemName.toLowerCase().includes(searchTerm)
                                                                        );
                                                                    }}
                                                                    renderOption={(props, option) => (
                                                                        <Box
                                                                            key={option.itemCode}
                                                                            component="li"
                                                                            {...props}
                                                                        >
                                                                            {option.itemCode} | {option.itemName}
                                                                        </Box>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            size="small"
                                                                            fullWidth
                                                                            label="part No"
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                    onInputChange={(e, value) => {
                                                                        handleSearchParts(value, index);
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].partDescription`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    label="Parts Name"
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].hsnCode`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    label="Hsn Code"
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={1}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].quantity`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label=" Quantity"
                                                                variant="standard"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                            />}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].rate`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label="Rate"
                                                                variant="standard"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{ display: 'none' }}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].taxPercentage`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="taxPercentage"
                                                                    variant="standard"
                                                                    type="hidden"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].additionalMargin`}
                                                            control={control}
                                                            variant="standard"
                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                label=" Additional Margin"
                                                                variant="standard"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    pattern: "[0-9]*",
                                                                    inputMode: "numeric"
                                                                }}
                                                            />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Controller
                                                            name={`partsEstimate[${index}].discountAmount`}
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Discount Amount"
                                                                    variant="standard"
                                                                    type="number"
                                                                    error={!!error}
                                                                    helperText={error ? error.message : ''}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        pattern: "[0-9]*",
                                                                        inputMode: "numeric"
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                            <IconButton onClick={() => removeParts(index)}>
                                                                <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px", }} />
                                                                <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                    <Grid sx={{ margin: "20px 0px", backgroundColor: "#eef3ff", width: "100%", marginLeft: "16px" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Box sx={{ display: "flex", columnGap: "30px" }} >

                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`partsEstimate[${index}].sgst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" SGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`partsEstimate[${index}].cgst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" CGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={1.5}>
                                                                    <Controller
                                                                        name={`partsEstimate[${index}].igst`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                fullWidth
                                                                                size='small'
                                                                                label=" IGST%"
                                                                                variant="standard"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Box>
                                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                                                <Box>
                                                                    <Typography>Parts Total</Typography>
                                                                </Box>
                                                                <Grid item xs={2} sx={{ display: "flex", flexDirection: "row" }}>

                                                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                                        <Controller
                                                                            name={`partsEstimate[${index}].partTotal`}
                                                                            control={control}
                                                                            variant="standard"
                                                                            render={({ field }) => <TextField {...field} fullWidth size='small'
                                                                                variant="standard"
                                                                                type="number"
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    pattern: "[0-9]*",
                                                                                    inputMode: "numeric"
                                                                                }}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiInputBase-input': {
                                                                                        textAlign: 'center',
                                                                                        fontWeight: 700,
                                                                                    }
                                                                                }}
                                                                            />}
                                                                        />

                                                                    </Box>

                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Box>
                                    </StyledPaperShadow>

                                    <Grid item xs={12}>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                                <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{background:"red !important"}} >
                                                    Cancel
                                                </SoftButton>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                                <SoftButton type="submit" variant="contained" color="primary" >
                                                    {isEditing ? 'Update' : 'Submit'}
                                                </SoftButton>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>


                            </form>
                        </StyledPaperShadow>
                    )}
                    {currentTab === 1 && (
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
                                    <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                        <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {ServiceEstimateDatagrid?.ServiceEstimateData?.totalItems}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                                        <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                                        <Select
                                            value={limit}
                                            onChange={handleLimitChange}
                                            sx={{
                                                marginLeft: 1,

                                                '& .MuiSelect-select': {
                                                    paddingTop: "1px !important",
                                                    paddingBottom: "1px !important"
                                                },
                                            }}

                                        >
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={75}>75</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Box sx={{ marginRight: "30px" }}>
                                        <SoftButton
                                            variant="contained"
                                            color="primary"
                                            sx={{ width: "220px" }}
                                            onClick={handleOpenPopup}
                                        >
                                            Open Service Booking
                                        </SoftButton>
                                    </Box>
                                    <Box>
                                        <TextField
                                            variant="standard"
                                            label="Search"
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={handleSearchClick}>
                                                            <IoSearchSharp />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>

                            </Box>
                            <ItemGroupDatagrid data={ServiceEstimateDatagrid?.ServiceEstimateData?.data || []} columns={columns} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                                <ToggleButtonGroup
                                    exclusive
                                    sx={{
                                        display: 'flex', alignItems: 'center',

                                        '& .MuiToggleButtonGroup-middleButton': {
                                            marginRight: "0px !important",
                                            borderLeft: "1px solid #e5e5e5"
                                        },
                                        '& .MuiToggleButtonGroup-root': {
                                            border: "1px solid #e5e5e5",
                                        },
                                    }}
                                >
                                    <ToggleButton
                                        value="previous"
                                        onClick={handlePrevious}
                                        disabled={offset === 0}
                                    >
                                        Previous
                                    </ToggleButton>

                                    {Array.from({ length: totalPages }, (_, index) => {
                                        const page = index + 1;
                                        const isFirstThreePages = page <= 3;
                                        const isLastThreePages = page > totalPages - 3;
                                        const isCurrentPage = offset / limit === index;

                                        if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                            return (
                                                <ToggleButton
                                                    key={page}
                                                    value={`page-${page}`}
                                                    onClick={() => handlePageClick(page)}
                                                    sx={{ margin: '0 5px' }}
                                                    selected={isCurrentPage}
                                                >
                                                    {page}
                                                </ToggleButton>
                                            );
                                        }

                                        if (page === 4 && !isFirstThreePages) {
                                            return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                        }

                                        if (page === totalPages - 3 && !isLastThreePages) {
                                            return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                        }

                                        return null;
                                    })}
                                    <ToggleButton
                                        value="next"
                                        onClick={handleNext}
                                        disabled={offset + limit >= totalItems}
                                    >
                                        Next
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>

                        </Box>

                    )}
                    {open && (
                        <StyledSnackBar
                            open={true}
                            message={snackBarMessage}
                            status={dataStatusConfirm}
                        />
                    )}
                    <Dialog open={openPopup} onClose={handleClosePopup} >
                        <DialogTitle sx={{ width: "600px", textAlign: "center", backgroundColor: "#f6f6f6" }}>
                            Service Estimate
                            <IconButton
                                aria-label="close"
                                onClick={handleClosePopup}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <IoClose />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} sx={{ justifyContent: "center", paddingTop: "20px !important" }}>
                                <Grid item xs={9}>
                                    <Controller
                                        name="openServiceBooking"
                                        control={control}
                                        rules={{ required: 'ServiceBooking is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={Bookings}
                                                getOptionLabel={(option) => option.serviceBookingNumber}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select ServiceBooking"
                                                        variant="standard"
                                                        InputLabelProps={isEditing ? { shrink: true } : {}}
                                                        error={!!errors.openServiceBooking}
                                                        helperText={errors.openServiceBooking ? errors.openServiceBooking.message : ''}
                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, value) => {
                                                    field.onChange(value);
                                                    handleServiceEstimateChange(event, value);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="Vehicle Number"
                                        value={vehicleNumber}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <SoftButton
                                variant="contained"
                                color="primary"
                                onClick={handlePopupSubmit}
                            >
                                Submit
                            </SoftButton>

                        </DialogActions>
                    </Dialog>
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={backDropOpen}
                    >
                        <ScaleLoader color="#11164b" />
                    </Backdrop>
                </StyledPaperShadow>
            )}
        </Box>

    )
}

export default ServiceEstimate
