import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import ActivationLink from "../components/activationLink/ActivationLink";
import LoginPage from "../components/login/Login";
import IsPrivateLayout from "./IsPrivateLayout/IsPrivateLayout";
import IsPublicLayout from "./IsPublicLayout/IsPublicLayout";
import PageNotFound from "./page-not-found/PageNotFound";
import routes from "./routes";

const RouteView = () => {
  const renderRoutes = (routes) => {
    return routes.map((route, index) => {
      if (route.children) {
        return route.children.map((child, childIndex) => (
          <Route
            key={`${index}-${childIndex}`}
            path={child.path}
            element={<child.component />}
          />
        ));
      }
      return null;
    });
  };

  return (
    <Routes>
      <Route path="/" element={<IsPublicLayout />}>
        <Route index element={<LoginPage />} />
        <Route path="userActivation" element={<ActivationLink />} />
      </Route>
      
      <Route  element={<IsPrivateLayout />}>
        {renderRoutes(routes)}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteView;
