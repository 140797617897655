import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customizer:{
        mode:'light',
        themeView:'default',
        fontFamily:'inter',
        themeCustom:true,
        themePallete:null,
        header:{
            theme:'default',
            primary:null,
            secondary:null,
        },
        sidebar:{
            theme:'default',
            primary:null,
            secondary:null,
            backgroundImg:'none'
        },
       
    },
    authToken:null,
    refToken:null,
    isloading:false,
    employeeCode:null,
    roleId:null,
}


export const Controls = createSlice({
    name:'SiteControls',
    initialState,
    reducers:{
        getAuth:(state, payload) =>{
            state.authToken = payload.payload;
         },
         getEmployeeCode:(state, payload) =>{
            state.employeeCode = payload.payload;
         },
         getRoleId:(state, payload) =>{
            state.roleId = payload.payload;
         },
         getLoginData:(state, payload) =>{
            state.loginData = payload.payload;
         },
         logout:(state) => {
            window.localStorage.removeItem("jwToken");
            window.localStorage.removeItem("menuList");
            window.localStorage.removeItem("employeeCode");
            window.localStorage.removeItem("roleId");
            window.localStorage.removeItem("loginData");
            return initialState;
        },

        }
})

export const { getAuth,logout,getEmployeeCode,getRoleId,getLoginData} = Controls.actions;
export default Controls.reducer