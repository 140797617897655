import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useEditLabourMutation, useGetAllHsnsDataMutation, useGetCompanyQuery, useGetHsnQuery, useGetLabourDataMutation, useGetLabourDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const LabourSchedule = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors, isValid }, clearErrors, control } = useForm({
    defaultValues: {
      laborCode: "",
      laborDescription: "",
      status: 0,
      companyId: [],
    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addLabourData, { isSuccess: LabourSuccess }] = useGetLabourDataMutation();
  const [getLabourDatagrid, { data: LabourDatagrid }] = useGetLabourDatagridMutation();
  const [editLabourData, { isSuccess: editLabourSuccess }] = useEditLabourMutation();
  const [getHsnTaxData, { data: getAllHsnTaxData, isLoading: getHsnTaxDataLoading, isSuccess: getHsnTaxDataSuccess }] = useGetAllHsnsDataMutation();


  const { data: itemCompanyData } = useGetCompanyQuery();
  const { data: itemHsnData } = useGetHsnQuery();

  const [status, setStatus] = useState(1);
  const [osl, setOsl] = useState(0);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const companies = itemCompanyData?.companyData || [];
  const hsn = itemHsnData?.hsnData || [];
  const [searchValue, setSearchValue] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [isCreating, setIsCreating] = useState(true);

  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, LabourSuccess, editLabourSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey
    };
    const response = await getLabourDatagrid(payload);
    setTotalItems(response?.data?.laborScheduleData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };
  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
      osl: osl ? 1 : 0,
      companyId: data.companyId,
      sacCode: data?.sacCode?.hsnCode !== undefined || null ? data?.sacCode?.hsnCode : data?.sacCode
    };

    let response;
    if (isEditing) {
      response = await editLabourData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
        setCurrentItemId(null);
      }
    } else {
      response = await addLabourData(formData);
    }
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        resetForm();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);

  };

  const resetForm = () => {
    reset({
      laborDescription: "",
      laborCode: "",
      stdhrsA: "",
      stdhrsB: "",
      stdhrsC: "",
      stdhrsD: "",
      stdhrsE: "",
      citySegmentA: "",
      citySegmentB: "",
      citySegmentC: "",
      citySegmentD: "",
    });

    setIsEditing(false);
    setStatus(0);
    setOsl(0)
    setCurrentTab(1);
  };


  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };
  const handleOslChange = (event) => {
    setOsl(event.target.checked ? 1 : 0);
  };
  const handleEditClick = (row) => {
    setValue('laborDescription', row.laborDescription);
    setValue('laborCode', row.laborCode);
    setValue('stdhrsA', row.stdhrsA);
    setValue('stdhrsB', row.stdhrsB);
    setValue('stdhrsC', row.stdhrsC);
    setValue('stdhrsD', row.stdhrsD);
    setValue('stdhrsE', row.stdhrsE);
    setValue('citySegmentA', row.citySegmentA);
    setValue('citySegmentB', row.citySegmentB);
    setValue('citySegmentC', row.citySegmentC);
    setValue('citySegmentD', row.citySegmentD);
    setValue('taxPercentage', row.taxPercentage);

    const selectedCompanyNames = row.companies.split(',').map(companyName => companyName.trim());
    const selectedCompanies = companies.filter(company => selectedCompanyNames.includes(company.name));
    setValue('companyId', selectedCompanies);

    const selectedHsn = hsn?.find(hsn => hsn.hsnCode === row.sacCode);
    setValue('sacCode', selectedHsn);

    setStatus(row.status ? 1 : 0);
    setOsl(row.osl ? 1 : 0);
    setIsEditing(true);
    setCurrentTab(0);
    setCurrentItemId(row.id);
    clearErrors(['laborCode', 'taxPercentage', 'laborDescription', 'stdhrsA', 'stdhrsB', 'stdhrsC', 'stdhrsD']);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };
  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [

      {
        field: 'laborCode',
        headerName: 'Labour Code',
        flex: 1,
      },
      {
        field: 'laborDescription',
        headerName: 'Labour Description',
        flex: 1,
      },
      {
        field: 'companies',
        headerName: 'Company',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
      {
        field: 'osl',
        headerName: 'OSL',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params.row)}
              style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
              sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
            >
              <MdEdit />
            </IconButton>
          </Tooltip>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsCreating(true);
    } else {
      setIsCreating(false);
      resetForm();
    }
    setCurrentTab(newValue);
  };

  // Filter out selected companies from options
  const selectedCompanies = useWatch({ name: 'companyId', control }) || [];
  const filteredOptions = companies.filter(
    (company) => !selectedCompanies?.some((selectedCompany) => selectedCompany.id === company.id)
  );

  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const handleCancel = () => {
    reset({
      laborDescription: "",
      laborCode: "",
      stdhrsA: "",
      stdhrsB: "",
      stdhrsC: "",
      stdhrsD: "",
      stdhrsE: "",
      citySegmentA: "",
      citySegmentB: "",
      citySegmentC: "",
      citySegmentD: "",
      taxPercentage: "",
      companyId: []
    })
    setStatus(1);
    setOsl(0);
    setIsEditing(false);
  };

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Master"
        subDiv="LabourSchedule"
      ></StyledBreadcrumb>

      <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
          <Tabs value={currentTab} onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
              maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
              overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
            }}
          >
            {hasCreatePermission ? (
              <Tab label="Create LabourSchedule" />
            ) : (isEditing && !hasCreatePermission) ? (
              <Tab label="Update LabourSchedule" />
            ) : null}
            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="LabourSchedule" />)}

          </Tabs>
        </Box>
        {currentTab === 0 && (
          <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('laborCode', { required: 'laborCode is required' })}
                    fullWidth variant="standard" label="Labour Code"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.laborCode}
                    helperText={errors.laborCode ? errors.laborCode.message : ''}
                    onInput={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('laborDescription', { required: 'laborDescription is required' })}
                    fullWidth variant="standard" label="Labour Description "
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.laborDescription}
                    helperText={errors.laborDescription ? errors.laborDescription.message : ''}
                    multiline
                    onInput={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="sacCode"
                    control={control}
                    rules={{ required: 'HsnCode is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        // {...field}
                        options={hsn}
                        getOptionLabel={(option) => option?.hsnCode}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="HSN Code"
                            variant="standard"
                            error={!!errors.sacCode}
                            helperText={errors.sacCode ? errors.sacCode.message : ''}
                          />
                        )}
                        // value={hsn.find((item) => item.hsnCode === field.value) || null}
                        // onChange={(event, value) => {
                        //   field.onChange(value ? value.hsnCode : null);
                        //   setValue('taxPercentage', value ? value.tax : '');
                        // }}
                        value={field.value || null}
                        onChange={(event, value) => {
                          field.onChange(value)
                          setValue('taxPercentage', value ? value.tax : '');
                        }}
                      />
                    )}
                  />

                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="taxPercentage"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="standard"
                        label="Tax Percentage"
                        type="number"
                        InputLabelProps={field.value ? { shrink: true } : {}}
                        disabled
                        inputProps={{
                          min: 0,
                          pattern: "[0-9]*",
                          inputMode: "numeric"
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('stdhrsA', { required: 'stdhrsA is required' })}
                    fullWidth variant="standard" label="stdhrsA"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.stdhrsA}
                    type="number"
                    helperText={errors.stdhrsA ? errors.stdhrsA.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('stdhrsB', { required: 'stdhrsB is required' })}
                    fullWidth variant="standard" label="stdhrsB"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.stdhrsB}
                    type="number"
                    helperText={errors.stdhrsB ? errors.stdhrsB.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('stdhrsC', { required: 'stdhrsC is required' })}
                    fullWidth variant="standard" label="stdhrsC"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.stdhrsC}
                    type="number"
                    helperText={errors.stdhrsC ? errors.stdhrsC.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('stdhrsD', { required: 'stdhrsD is required' })}
                    fullWidth variant="standard" label="stdhrsD"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.stdhrsD}
                    type="number"
                    helperText={errors.stdhrsD ? errors.stdhrsD.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('stdhrsE', { required: 'stdhrsE is required' })}
                    fullWidth variant="standard" label="stdhrsE"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.stdhrsE}
                    type="number"
                    helperText={errors.stdhrsE ? errors.stdhrsE.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('citySegmentA', { required: 'citySegmentA is required' })}
                    fullWidth variant="standard" label="citySegmentA"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.citySegmentA}
                    type="number"
                    helperText={errors.citySegmentA ? errors.citySegmentA.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('citySegmentB', { required: 'citySegmentB is required' })}
                    fullWidth variant="standard" label="citySegmentB"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.citySegmentB}
                    type="number"
                    helperText={errors.citySegmentB ? errors.citySegmentB.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('citySegmentC', { required: 'citySegmentC is required' })}
                    fullWidth variant="standard" label="citySegmentC"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.citySegmentC}
                    type="number"
                    helperText={errors.citySegmentC ? errors.citySegmentC.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('citySegmentD', { required: 'citySegmentD is required' })}
                    fullWidth variant="standard" label="citySegmentD"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.citySegmentD}
                    type="number"
                    helperText={errors.citySegmentD ? errors.citySegmentD.message : ''}
                    inputProps={{
                      min: 0,
                      pattern: "[0-9]*",
                      step: "0.01",
                      inputMode: "numeric"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyId"
                    control={control}
                    rules={{ required: 'Company is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={filteredOptions}
                        getOptionLabel={(option) => option.name || ""}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company"
                            variant="standard"
                            error={!!errors.companyId}
                            sx={{
                              '& .MuiChip-root': { height: "25px" }
                            }}
                            helperText={errors.companyId ? errors.companyId.message : ''}
                          />
                        )}
                        value={field.value}
                        onChange={(event, value) => field.onChange(value)}
                        />
                    )}
                  />
                </Grid>

                <Grid item xs={6} sm={3} md={1.5}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                    <Box>
                      <FormLabel>OSL</FormLabel>
                    </Box>
                    <Box>
                      <Switch checked={osl === 1} onChange={handleOslChange} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={1.5}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                    <Box>
                      <FormLabel>Status</FormLabel>
                    </Box>
                    <Box>
                      <Switch checked={status === 1} onChange={handleStatusChange} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                  <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                    Cancel
                  </SoftButton>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                  <SoftButton type="submit" variant="contained" color="primary" >
                    {isEditing ? 'Update' : 'Add'}
                  </SoftButton>
                </Box>
              </Grid>
            </form>
          </StyledPaperShadow>
        )}
        {currentTab === 1 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                flexWrap: "wrap",
                rowGap: "10px",
                columnGap: "30px",
                '@media (max-width: 700px)': {
                  flexDirection: "column",
                  columnGap: "10px",
                }
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  backgroundColor: "#11164b",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "auto",
                  '@media (max-width: 700px)': {
                    flexDirection: "column",
                    columnGap: "5px",
                    padding: "5px",
                    width: "100%",
                  },
                }}
              >
                <Box sx={{ alignSelf: "center", margin: "5px" }}>
                  <Typography
                    sx={{
                      alignSelf: "center",
                      color: "#d9d9d9 !important",
                      fontSize: "1rem",
                      '@media (max-width: 700px)': {
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    Total Records: {LabourDatagrid?.laborScheduleData?.totalItems}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "1px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "10px",
                    '@media (max-width: 700px)': {
                      flexDirection: "column",
                      paddingLeft: "5px",
                      width: "100%",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: "center",
                      fontSize: "1rem",
                      '@media (max-width: 700px)': {
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    Show:
                  </Typography>
                  <Select
                    value={limit}
                    onChange={handleLimitChange}
                    sx={{
                      marginLeft: 1,
                      '& .MuiSelect-select': {
                        paddingTop: "1px !important",
                        paddingBottom: "1px !important",
                      },
                      '@media (max-width: 700px)': {
                        marginLeft: 0,
                        width: "100%",
                      },
                    }}
                  >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={75}>75</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "auto",
                  '@media (max-width: 700px)': {
                    width: "100%",
                    marginTop: "10px",
                  },
                }}
              >
                <TextField
                  variant="standard"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSearchClick}>
                          <IoSearchSharp />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "auto",
                    '@media (max-width: 700px)': {
                      width: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
            <ItemGroupDatagrid data={LabourDatagrid?.laborScheduleData?.data || []} columns={columns} />
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
              <ToggleButtonGroup
                exclusive
                sx={{
                  display: 'flex', alignItems: 'center',

                  '& .MuiToggleButtonGroup-middleButton': {
                    marginRight: "0px !important",
                    borderLeft: "1px solid #e5e5e5"
                  },
                  '& .MuiToggleButtonGroup-root': {
                    border: "1px solid #e5e5e5",
                  },
                }}
              >
                <ToggleButton
                  value="previous"
                  onClick={handlePrevious}
                  disabled={offset === 0}
                >
                  Previous
                </ToggleButton>

                {Array.from({ length: totalPages }, (_, index) => {
                  const page = index + 1;
                  const isFirstThreePages = page <= 3;
                  const isLastThreePages = page > totalPages - 3;
                  const isCurrentPage = offset / limit === index;

                  if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                    return (
                      <ToggleButton
                        key={page}
                        value={`page-${page}`}
                        onClick={() => handlePageClick(page)}
                        sx={{ margin: '0 5px' }}
                        selected={isCurrentPage}
                      >
                        {page}
                      </ToggleButton>
                    );
                  }

                  if (page === 4 && !isFirstThreePages) {
                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  if (page === totalPages - 3 && !isLastThreePages) {
                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  return null;
                })}
                <ToggleButton
                  value="next"
                  onClick={handleNext}
                  disabled={offset + limit >= totalItems}
                >
                  Next
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

          </Box>

        )}
        {open && (
          <StyledSnackBar
            open={true}
            message={snackBarMessage}
            status={dataStatusConfirm}
          />
        )}
      </StyledPaperShadow>

    </Box>

  )
}

export default LabourSchedule