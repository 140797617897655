import { Box, Chip, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowCircleRight } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyInsuranceInvoicePdfQuery, useLazyJobCardInvoicePdfQuery, useLazyPreInvoicePdfQuery, useLazyJobCardPdfQuery, useLazyLabourInsurancePdfQuery, useLazyLabourPdfQuery, useLazyPartsPdfQuery, useLazyWorOrderPdfQuery } from '../../../store/slice/ApiSlice';
import { SoftButton } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";

const GatePassViewPage = () => {
    const { formState: { errors, isValid } } = useForm({
        defaultValues: {

        }
    });
    const navigate = useNavigate();

    const location = useLocation();
    const data = location?.state?.rowData;

    const [LabourPdf] = useLazyLabourPdfQuery();
    const [PartsPdf] = useLazyPartsPdfQuery();
    const [LabourInsurancePdf] = useLazyLabourInsurancePdfQuery();
    const [getWorkOrderPdf] = useLazyWorOrderPdfQuery();
    const [getJobCardPdfDetails] = useLazyJobCardPdfQuery();
    const [getPreInvoicePdf] = useLazyPreInvoicePdfQuery();
    const [JobCardInvoicePdf] = useLazyJobCardInvoicePdfQuery();
    const [InsuranceInvoicePdf] = useLazyInsuranceInvoicePdfQuery();

    const [jobCard, setJobCard] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);

    useEffect(() => {
        setJobCard(data);
    }, [data]);


    // Columns for the vehicle details
    const vehicleColumns = [
        { field: 'reg_no', headerName: 'Vehicle Reg No', flex: 1, },
        { field: 'job_card_no', headerName: 'JobCard No', flex: 1, },
        { field: 'odometer', headerName: 'Odometer', flex: 1, },
        { field: 'work_end_date_time', headerName: 'Work Enddate', flex: 1, },
        { field: 'customer_arrived_date', headerName: 'Customer ArrivedDate', flex: 1, },
        { field: 'service_estimate_code', headerName: 'Service Estimate Number', flex: 1, },
        {
            field: 'status', headerName: 'Status', flex: 1,
            renderCell: (params) => {
                let background = '';
                let statusText;

                switch (params.value) {
                    case 1:
                        statusText = 'Open';
                        background = '#ff0000';
                        break;
                    case 2:
                        statusText = 'Work In Progress';
                        background = '#e7c018';
                        break;
                    case 3:
                        statusText = 'Ready For Billing';
                        background = '#1b8c0a';
                        break;
                    case 4:
                        statusText = 'Billing';
                        background = '#1b8c0a';
                        break;
                    case 5:
                        statusText = 'Deliverd';
                        background = '#1b8c0a';
                        break;
                    default:
                        statusText = '-';
                        background = 'gray';
                }

                return (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '8px',
                                        color: "#ffffd6 !important"
                                    }}
                                />
                                {statusText}
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            backgroundColor: background,
                            color: '#fff', // Ensure text contrast is readable
                            width: "150px",
                        }}
                    />
                );
            },
        },
        { field: 'customer_name', headerName: 'Customer Name', flex: 1, },
        { field: 'customer_mobileNumber', headerName: 'Customer Mobile Number', flex: 1, },

    ];

    // Columns for the labour schedules
    const labourColumns = [
        { field: 'rot_code', headerName: 'LaborCode', width: 150 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discount_percentage', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'laborTotal', headerName: 'Total Amount', width: 150 },
    ];

    //Osl Columns for the labour schedules
    const oslLabourColumns = [
        { field: 'rot_code', headerName: 'LaborCode', width: 150 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discount_percentage', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'laborTotal', headerName: 'Total Amount', width: 150 },
    ];

    //parts Columns for the labour schedules
    const partsColumns = [
        { field: 'item_code', headerName: 'PartNo', width: 150 },
        { field: 'item_name', headerName: 'Part Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'rate', headerName: 'Amount', width: 100 },
        { field: 'discount', headerName: 'Discount', width: 100 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'total', headerName: 'Total Amount', width: 150 },
    ];

    // Rows for the vehicle details DataGridtransactionData
    const vehicleRows = data ? [{ id: data.id, ...data }] : [];

    // Rows for the labour schedules DataGrid
    const labourRows = data.schedules ? data.schedules.map((item, index) => ({ id: index, ...item })) : [];
    const oslLabourRows = data.oslSchedules ? data.oslSchedules.map((item, index) => ({ id: index, ...item })) : [];
    const partsRows = data.partsIssue ? data.partsIssue.map((item, index) => ({ id: index, ...item })) : [];

    // Calculate grand total for laborTotal
    const grandTotal = labourRows.reduce((total, row) => total + parseFloat(row.laborTotal || 0), 0);
    const oslGrandTotal = oslLabourRows.reduce((total, row) => total + parseFloat(row.laborTotal || 0), 0);
    const partsTotal = partsRows.reduce((total, row) => total + parseFloat(row.total || 0), 0);

    const onBackClick = () => {
        navigate('/gatePass');
    };
    const PartsPdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await PartsPdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `Parts${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const LabourPdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await LabourPdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `Labour${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };
    const LabourInsurancePdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await LabourInsurancePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `LabourInsurance${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const workOrderPdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await getWorkOrderPdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `WorkOrder${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };
    const JobCardPdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await getJobCardPdfDetails(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `JobCard${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const preInvoiceClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await getPreInvoicePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `PreInvoice${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const JobCardInvoiceClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await JobCardInvoicePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `JobCardInvoice${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const InsuranceInvoicePdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await InsuranceInvoicePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `InsuranceInvoice${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="GatePass"
                subDiv="GatePass ViewPage"
            ></StyledBreadcrumb>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                {/* {data?.status === 5 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={LabourPdfClick}>
                            Labour Bill <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {(data?.status === 5 && data?.document_type === "AJC") && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "250px" }} onClick={LabourInsurancePdfClick}>
                            Labour Insurance Bill<FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {data?.status === 5 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={PartsPdfClick}>
                            Parts Bill <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )} */}
                {/* {data?.status === 5 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={JobCardPdfClick}>
                            Job Card <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {data?.status === 5 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "250px" }} onClick={preInvoiceClick}>
                            JobCard PreInvoice<FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )} */}

                {(data?.status === 5 && data?.document_type === "AJC") && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "250px" }} onClick={InsuranceInvoicePdfClick}>
                            Insurance Invoice<FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {data?.status === 5 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={JobCardInvoiceClick}>
                            JobCard Invoice <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {data?.status === 5 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={workOrderPdfClick}>
                            Work Order <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                <SoftButton variant="contained" sx={{ width: "200px" }} onClick={onBackClick}>
                    GatePass <FaArrowCircleRight style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                </SoftButton>
            </Box>
            <Grid>
                <Box sx={{ height: 'auto', width: '100%' }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Vehicle Details</Typography>
                    <DataGrid rows={vehicleRows} columns={vehicleColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },

                        }}
                    />
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Labour Schedules</Typography>
                    <DataGrid rows={labourRows} columns={labourColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {grandTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Osl Labour scheduless</Typography>
                    <DataGrid rows={oslLabourRows} columns={oslLabourColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {oslGrandTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Parts Details</Typography>
                    <DataGrid rows={partsRows} columns={partsColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {partsTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>

            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
}

export default GatePassViewPage