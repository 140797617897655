import { Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useAllMenuListMutation, useAllSubMenuListMutation, useCreateTabListMutation, useGetTabListMutation, useGetroleIdMutation, useUpdateTabListsMutation } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const RoleMenuTabSetting = () => {
    const { register, handleSubmit, reset, control, setValue, formState: { errors }, clearErrors } = useForm({
        defaultValues: {
            title: "",
        }
    });

    const [addTabSettingData, {isSuccess: TabSettingSuccess }] = useCreateTabListMutation();
    const [getTabSettingDatagrid, { data: TabSettingDatagrid }] = useGetTabListMutation();
    const [editTabSettingData, { isSuccess: editTabSettingSuccess }] = useUpdateTabListsMutation();
    const [getRoles, { data: getRolesData }] = useGetroleIdMutation();
    const [getSubMenuList] = useAllSubMenuListMutation();
    const [getAllMenuList] = useAllMenuListMutation();
    const roleData = getRolesData?.menuList;

    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [subMenuList, setSubMenuList] = useState();
    const [menuList, setMenuList] = useState();
    const [isSubMenu, setIsSubMenu] = useState(false);
    const [selectedOperations, setSelectedOperations] = useState([]);

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0);
    };

    const onSubmit = async (data) => {
        let selectedOptions = selectedOperations
            .map(item => item.trim())
            .filter(item => !isNaN(item) && item !== '');
        const filteredResult = selectedOptions.join(',');
        const formData = await {
            roleId: data?.role?.id,
            menuId: data?.menuId?.id ? data?.menuId?.id : 0,
            submenuId: data?.submenuId?.id ? data?.submenuId?.id : 0,
            tabName: data?.tabName,
            tabMenuOperations: filteredResult
        };

        try {
            let response;
            if (isEditing) {
                response = await editTabSettingData({ id: currentItemId, ...formData });
                if (response?.data?.requestSuccessful) {
                    setIsEditing(false);
                }
                setCurrentItemId(null);
            } else {
                response = await addTabSettingData(formData);
            }

            const message = response?.data?.message || 'Success';
            setDataStatusConfirm('Success');
            reset();
            setSelectedOperations([]);
            setSnackBarMessage(message);
        } catch (error) {
            const message = error?.data?.validationErrors || error?.data?.message || 'Error';
            setDataStatusConfirm('Error');
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => setOpen(false), 3000);
    };

    const handleEditClick = async (row) => {
        reset();
        if (row) {
            try {
                const data = await getRoles();
                if (data) {
                    const selectedRole = data.data.menuList?.find(role => role?.roleName === row?.roleName);
                    setValue('role', selectedRole);
                    const payload = { roleId: selectedRole?.id };
                    const responseMenu = await getAllMenuList(payload);
                    const MenuData = responseMenu?.data?.data || [];
                    setMenuList(MenuData);

                    if (responseMenu) {
                        const response = await getSubMenuList(payload);
                        const SubMenuData = response?.data?.submenudData || [];
                        setSubMenuList(SubMenuData);

                        if (row.menuTitle && MenuData) {
                            const selectedMenu = MenuData?.find(menu => menu?.title === row?.menuTitle);
                            setValue('menuId', selectedMenu);
                            setValue('submenuId', null);
                            setValue('tabName', row.tab_name);
                            setIsSubMenu(false);
                            setSelectedOperations(row.tab_menu_operation.split(',').map(op => op.trim()));
                        } else if (row.subMenuTitle && SubMenuData) {
                            await setIsSubMenu(true);
                            const selectedSubMenu = SubMenuData?.find(submenu => submenu?.title === row?.subMenuTitle);
                            setValue('submenuId', selectedSubMenu);
                            setValue('menuId', null);
                            setValue('tabName', row.tab_name);
                            setSelectedOperations(row.tab_menu_operation.split(',').map(op => op.trim()));
                        }

                        setIsEditing(true);
                        setCurrentItemId(row.id);
                        clearErrors([]);
                    }
                }
            } catch (error) {
                console.error("Error during handleEditClick:", error);
            }
        }
    };


    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };
    const columns = useMemo(() => [
        {
            field: 'roleName',
            headerName: 'Role Name',
            flex: 1,
        },
        {
            field: 'tab_name',
            headerName: 'Tab Name',
            flex: 1,
        },
        {
            field: 'menuTitle',
            headerName: 'Menu Title',
            flex: 1,
        },
        {
            field: 'subMenuTitle',
            headerName: 'Sub Menu Title',
            flex: 1,
        },
        {
            field: 'tab_menu_operation',
            headerName: 'Tab Menu Operation',
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Edit">
        <IconButton
          onClick={() => handleEditClick(params.row)}
          style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
          sx={{ '&:hover': { backgroundColor: '#e0e0e0' }}}
        >
          <MdEdit />
      </IconButton>
      </Tooltip>
            ),
        },

    ], []);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };
    const selectedInputType = useWatch({
        control,
        name: 'role',
    });

    const getroleaction = async () => {
        try {
           await getRoles();
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    };

    useEffect(() => {
        getroleaction();
    }, []);

    useEffect(() => {
        fetchItems(offset, searchValue);
    }, [offset, limit, TabSettingSuccess, editTabSettingSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey,
        };
        const response = await getTabSettingDatagrid(payload);
        setTotalItems(response?.data?.totalItems);
    };

    const fetchMenuList = async () => {
        if (selectedInputType !== undefined) {
            try {
                const payload = { roleId: selectedInputType?.id };
                const responseMenu = await getAllMenuList(payload);
                const MenuData = responseMenu?.data?.data || [];
                setMenuList(MenuData)

            } catch (error) {
                console.error("Error fetching menu list:", error);
            }
        }
    };

    const fetchSubMenuList = async () => {
        if (selectedInputType !== undefined) {
            try {
                const payload = { roleId: selectedInputType?.id };
                const response = await getSubMenuList(payload);

                const SubMenuData = response?.data?.submenudData || [];
                setSubMenuList(SubMenuData)


            } catch (error) {
                console.error("Error fetching menu list:", error);
            }
        }
    };

    useEffect(() => {
        fetchMenuList();
        fetchSubMenuList();
    }, [selectedInputType]);

    const handleSwitchChange = (event) => {
        setIsSubMenu(event.target.checked);

        if (event.target.checked) {
            setValue('menuId', null);
        } else {
            setValue('submenuId', null);
        }
    };

    const handleCheckboxChange = (operation) => {
        setSelectedOperations((prev) => {
            if (prev.includes(operation)) {
                return prev.filter((item) => item !== operation);
            } else {
                return [...prev, operation];
            }
        });
    };

    return (
        <Box>

            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Controller
                                name="role"
                                control={control}
                                rules={{ required: 'Role is required' }}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={roleData || []}
                                        getOptionLabel={(option) => option.roleName || ''}
                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Roles"
                                                variant="standard"
                                                error={!!errors.role}
                                                helperText={errors.role ? errors.role.message : ''}
                                            />
                                        )}
                                        value={field.value || null}
                                        onChange={(event, value) => field.onChange(value)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ paddingTop: "30px !important" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Typography variant="body1" style={{ marginRight: 8 }}>
                                    Menu
                                </Typography>
                                <Switch
                                    checked={isSubMenu}
                                    onChange={handleSwitchChange}
                                    color="primary"
                                />
                                <Typography variant="body1" style={{ marginLeft: 8 }}>
                                    Submenu
                                </Typography>
                            </div>
                        </Grid>

                        {!isSubMenu ? (
                            <Grid item xs={3}>
                                <Controller
                                    name="menuId"
                                    control={control}
                                    rules={{ required: 'menuId is required' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={menuList || []}
                                            getOptionLabel={(option) => option.title}
                                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Menu"
                                                    variant="standard"

                                                />
                                            )}
                                            value={field.value || null}
                                            onChange={(event, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </Grid>
                        ) : null}

                        {
                            isSubMenu ? (
                                <Grid item xs={3}>
                                    <Controller
                                        name="submenuId"
                                        control={control}
                                        rules={{ required: 'submenuId is required' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={subMenuList || []}
                                                getOptionLabel={(option) => option.title}
                                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="SubMenu"
                                                        variant="standard"

                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, value) => field.onChange(value)}
                                            />
                                        )}
                                    />
                                </Grid>
                            ) : null
                        }

                        <Grid item xs={3}>
                            <TextField {...register('tabName', { required: 'tabName is required' })}
                                fullWidth variant="standard" label="Tab Name"
                                InputLabelProps={isEditing ? { shrink: true } : {}}
                                error={!!errors.tabName}
                                helperText={errors.tabName ? errors.tabName.message : ''}
                                onInput={handleInputChange} />
                        </Grid>
                        <Grid item xs={3}>
                            <Box>Button Operation</Box>
                            <FormGroup row>
                                {['Create', 'Read', 'Update', 'Delete'].map((operation, index) => {
                                    const operationValue = index + 1; // Create=1, Read=2, Update=3, Delete=4
                                    return (
                                        <FormControlLabel
                                            key={operation}
                                            control={
                                                <Controller
                                                    name={`${operation}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            checked={selectedOperations.includes(operationValue.toString())}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.checked);
                                                                handleCheckboxChange(operationValue.toString());
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={operation}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </Grid>


                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                <SoftButton type="submit" variant="contained" color="primary">
                                    {isEditing ? 'Update' : 'Add'}
                                </SoftButton>
                            </Box>
                        </Grid>
                    </Grid>


                </form>
            </StyledPaperShadow>

            <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
                            <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {TabSettingDatagrid?.data?.totalItems}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                                <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                                <Select
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{
                                        marginLeft: 1,

                                        '& .MuiSelect-select': {
                                            paddingTop: "1px !important",
                                            paddingBottom: "1px !important"
                                        },
                                    }}

                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                        <TextField
                            variant="standard"
                            label="Search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleSearchClick}>
                                            <IoSearchSharp />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Box>
                    <ItemGroupDatagrid data={TabSettingDatagrid?.data?.data || []} columns={columns} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                        <ToggleButtonGroup
                            exclusive
                            sx={{
                                display: 'flex', alignItems: 'center',

                                '& .MuiToggleButtonGroup-middleButton': {
                                    marginRight: "0px !important",
                                    borderLeft: "1px solid #e5e5e5"
                                },
                                '& .MuiToggleButtonGroup-root': {
                                    border: "1px solid #e5e5e5",
                                },
                            }}
                        >
                            <ToggleButton
                                value="previous"
                                onClick={handlePrevious}
                                disabled={offset === 0}
                            >
                                Previous
                            </ToggleButton>

                            {Array.from({ length: totalPages }, (_, index) => {
                                const page = index + 1;
                                const isFirstThreePages = page <= 3;
                                const isLastThreePages = page > totalPages - 3;
                                const isCurrentPage = offset / limit === index;

                                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                    return (
                                        <ToggleButton
                                            key={page}
                                            value={`page-${page}`}
                                            onClick={() => handlePageClick(page)}
                                            sx={{ margin: '0 5px' }}
                                            selected={isCurrentPage}
                                        >
                                            {page}
                                        </ToggleButton>
                                    );
                                }

                                if (page === 4 && !isFirstThreePages) {
                                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                if (page === totalPages - 3 && !isLastThreePages) {
                                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                return null;
                            })}
                            <ToggleButton
                                value="next"
                                onClick={handleNext}
                                disabled={offset + limit >= totalItems}
                            >
                                Next
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                </Box>

            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    )
}


export default RoleMenuTabSetting;