import { Autocomplete, Box, CardMedia, Grid, IconButton, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { FaArrowCircleRight } from "react-icons/fa";
import { MdAdd, } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import check from "../../../../src/assets/images/check.svg";
import MdDelete from "../../../../src/assets/images/del.svg";
import { useAllMechanicMappingsMutation, useMappingLaboursMutation, useMappingMechanicMutation, useSubmitMechanicMappingMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";

const MechanicMapping = () => {
  const workPercantageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '75', value: 75 },
    { label: '100', value: 100 },
  ];
  const statusOptions = [
    { label: "Completed", value: 1 },
    { label: "Work In Progress", value: 2 },
    { label: "Pause", value: 3 },
  ];

  const { handleSubmit, reset, setValue, formState: { errors, isValid }, control, getValues } = useForm({
    defaultValues: {
      labor: [{
        labour_code: "",
        stdHrs: "",
        laborDescription: "",
        mechanic: "",
        percentage: workPercantageOptions[0],
        start_time: "",
        end_time: "",
        mechanicHrs: "",
        reason: "",
        status: statusOptions[0]
      }],
    }

  });
  const navigate = useNavigate();

  const location = useLocation();
  const rowData = location?.state?.rowData;

  const [GetLabourDetails, { data: allLaboursData }] = useMappingLaboursMutation();
  const [GetMechanicDetails, { data: allMechanicsData }] = useMappingMechanicMutation();
  const [GetAllMechanicMappingData, { data: MechanicMappingData, isSuccess: MechanicMappingSuccess }] = useAllMechanicMappingsMutation();
  const [SubmitMechanicMapping] = useSubmitMechanicMappingMutation();
  const LabourData = allLaboursData?.JobCardData || [];
  const MechanicData = allMechanicsData?.employeeData?.data || [];

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [dateOpen1, setDateOpen1] = useState(false);

  useEffect(() => {
    const payload = {
      transaction_id: rowData.id,
    }
    GetLabourDetails(payload)
  }, [])

  useEffect(() => {
    const payload = {
      transaction_id: rowData.id,
    }
    GetAllMechanicMappingData(payload)
  }, [])

  useEffect(() => {
    const payload = {
      id: rowData.id,
      outletId: rowData.outlet_id
    }
    GetMechanicDetails(payload)
  }, [])

  useEffect(() => {
    const MappingData = MechanicMappingData?.JobCardData;
    if (MappingData !== undefined) {
      MappingData.forEach((labor, index) => {
        if (fields.length < MappingData?.length) {
          append({
            labour_code: "",
            stdHrs: "",
            laborDescription: "",
            mechanic: "",
            percentage: workPercantageOptions[0],
            start_time: "",
            end_time: "",
            mechanicHrs: "",
            reason: "",
            status: statusOptions[0]
          });
        }

        const selectedLabour = LabourData?.find(option => option.rot_id === labor?.schedule_id);
        setValue(`labor[${index}].labour_code`, selectedLabour || null);

        const selectedMechanic = MechanicData?.find(option => option.employeeName === labor?.mechanic_name);
        setValue(`labor[${index}].mechanic`, selectedMechanic || null);

        setValue(`labor[${index}].stdHrs`, labor.stdhrs);

        const selectedPercentage = workPercantageOptions?.find(option => option.value === labor?.percentage);
        setValue(`labor[${index}].percentage`, selectedPercentage || null);

        setValue(`labor[${index}].mechanicHrs`, labor.mechanic_hrs);
        setValue(`labor[${index}].start_time`, labor.start_time ? dayjs(labor.start_time) : null);
        setValue(`labor[${index}].end_time`, labor.end_time ? dayjs(labor.end_time) : null);
        setValue(`labor[${index}].reason`, labor.reason);

        const selectedStatus = statusOptions?.find(option => option.value === labor?.status);
        setValue(`labor[${index}].status`, selectedStatus || null);
      });
    }
  }, [MechanicMappingSuccess])

  const { fields, append, remove } = useFieldArray({
    control,
    name: "labor",
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ labour_code: "", stdHrs: "", laborDescription: "", mechanic: "", percentage: "", start_time: "", end_time: "", mechanicHrs: "", reason: "", status: "" });

    }

  }, [fields, append]);

  const resetForm = () => {
    reset({
      labor: [{ labour_code: "", stdHrs: "", laborDescription: "", mechanic: "", percentage: "", start_time: "", end_time: "", mechanicHrs: "", reason: "", status: "" }],
    });
  };

  const onSubmit = async (data) => {

    const laborSchedules = data.labor.map(item => ({
      labour_code: item.labour_code.description,
      schedule_id: item.labour_code.rot_id,
      mechanic_id: item.mechanic.id,
      mechanic_name: item.mechanic.employeeName,
      percentage: item.percentage.value,
      start_time: dayjs(item.start_time).format('YYYY-MM-DD HH:mm:ss'),
      end_time: dayjs(item.end_time).format('YYYY-MM-DD HH:mm:ss'),
      status: item.status.value,
      mechanic_hrs: item.mechanicHrs,
      reason: item.reason,
      stdhrs: item.stdHrs
    }));
    const formData = {
      transaction_id: rowData.id,
      mechanics: laborSchedules
    }

    let response = await SubmitMechanicMapping(formData)

    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        navigate("/createJobcard")
        resetForm();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  }

  const onBackClick = () => {
    navigate('/createJobcard');
  };

  const calculateMechanicHrs = (startTime, endTime) => {
    if (startTime && endTime) {
      const duration = dayjs(endTime).diff(dayjs(startTime));
      const hours = Math.floor(duration / (1000 * 60 * 60));
      const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
      return { hours, minutes };
    }
    return { hours: 0, minutes: 0 };
  };
  const watchFields = useWatch({ control, name: "labor" });

  const checkPercentageOverflow = () => {
    const labourMechanicMap = {};

    for (const field of watchFields) {
      const { labour_code, mechanic, percentage } = field;

      if (labour_code && mechanic) {
        const key = `${labour_code.laborCode}-${mechanic.employeeName}`;
        if (!labourMechanicMap[key]) {
          labourMechanicMap[key] = 0;
        }
        labourMechanicMap[key] += percentage?.value || 0;
      }
    }

    for (const [key, totalPercentage] of Object.entries(labourMechanicMap)) {
      if (totalPercentage > 100) {
        return `Total percentage for ${key} cannot exceed 100%`;
      }
    }

    return null;
  };

  useEffect(() => {
    const message = checkPercentageOverflow();
    if (message) {
      setSnackBarMessage(message);
      setDataStatusConfirm("Error");
      setOpen(true);
    } else {
      setOpen(false); // Close the snackbar when the percentage is valid
    }
  }, [watchFields]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <StyledBreadcrumb
          mainDiv="Create Jobcard"
          subDiv="Mechanic Mapping"
        ></StyledBreadcrumb>

        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "10px", marginLeft: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}> Labour & Mechanic Details</Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", columnGap: "20px" }}>

              <SoftButton sx={{ width: "200px" }} onClick={() => append({ labour_code: "", stdHrs: "", laborDescription: "", mechanic: "", percentage: "", start_time: "", end_time: "", mechanicHrs: "", reason: "", status: "" })} startIcon={<MdAdd />}>
                Add Labour Details
              </SoftButton>

              <SoftButton variant="contained" sx={{ width: "200px" }} onClick={onBackClick}>
                Jobcard <FaArrowCircleRight style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
              </SoftButton>

            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: "10px", maxHeight: "320px", overflowY: "auto", overflowX: "none" }}>
              {fields.map((item, index) => (
                <Grid container spacing={2} key={item.id} sx={{ marginBottom: "10px", paddingTop: "10px" }}>
                  <Grid item xs={1.2}>
                    <Controller
                      name={`labor[${index}].labour_code`}
                      control={control}
                      rules={{ required: 'labours is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={LabourData}
                          getOptionLabel={(option) => option.laborCode}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Labours"
                              variant="standard"
                              error={!!errors.labour_code}
                              helperText={errors.labour_code ? errors.labour_code.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => {
                            field.onChange(value);
                            if (value) {
                              // Set stdHrs based on the selected labour's stdhrsA
                              setValue(`labor[${index}].stdHrs`, value.stdhrsA || '');
                            } else {
                              // Clear stdHrs if no labour is selected
                              setValue(`labor[${index}].stdHrs`, '');
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Controller
                      name={`labor[${index}].stdHrs`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          InputProps={{
                            readOnly: true,
                          }}
                          label="STD Hrs"
                          variant="standard"
                          disabled
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1.2}>
                    <Controller
                      name={`labor[${index}].mechanic`}
                      control={control}
                      rules={{ required: 'mechanic is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          options={MechanicData}
                          getOptionLabel={(option) => option.employeeName}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="mechanic"
                              variant="standard"
                              error={!!errors.mechanic}
                              helperText={errors.mechanic ? errors.mechanic.message : ''}
                            />
                          )}
                          value={field.value || null}
                          onChange={(event, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1.2}>
                    <Controller
                      name={`labor[${index}].percentage`}
                      control={control}
                      rules={{ required: 'percentage is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={workPercantageOptions}
                          getOptionLabel={(option) => option?.label ?? ''}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          onChange={(event, value) => field.onChange(value || workPercantageOptions[0])} // Fallback to default value
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Work Allocated Percentage"
                              variant="standard"
                              error={!!errors.percentage}
                              helperText={errors.percentage ? "percentage is required" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={1.5}>
                    <Controller
                      name={`labor[${index}].start_time`}
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          label="Start Date Time"
                          value={field.value ? dayjs(field.value) : null}
                          open={dateOpen}
                          onClose={() => setDateOpen(false)}
                          slotProps={{
                            textField: {
                              variant: 'standard',
                              fullWidth: true,
                              readOnly: true,
                              onClick: () => setDateOpen(true),
                            },
                            openPickerButton: {
                              onClick: () => setDateOpen(true)
                          }
                          }}
                          minDateTime={dayjs()}
                          onChange={(newValue) => {
                            const startTime = dayjs(newValue);
                            field.onChange(startTime);
                            if (getValues(`labor[${index}].end_time`)) {
                              const endTime = dayjs(getValues(`labor[${index}].end_time`));
                              const { hours, minutes } = calculateMechanicHrs(startTime, endTime);
                              setValue(`labor[${index}].mechanicHrs`, `${hours}h ${minutes}m`);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          format='DD/MM/YYYY HH:mm A'
                        />
                      )}
                    />

                  </Grid>

                  <Grid item xs={1.5}>
                    <Controller
                      name={`labor[${index}].end_time`}
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          label="End Date Time"
                          value={field.value ? dayjs(field.value) : null}
                          open={dateOpen1}
                          onClose={() => setDateOpen1(false)}
                          slotProps={{
                            textField: {
                              variant: 'standard',
                              fullWidth: true,
                              readOnly: true,
                              onClick: () => setDateOpen1(true),
                            },
                            openPickerButton: {
                              onClick: () => setDateOpen1(true)
                          }
                          }}
                          onChange={(newValue) => {
                            const endTime = dayjs(newValue);
                            field.onChange(endTime);
                            if (getValues(`labor[${index}].start_time`)) {
                              const startTime = dayjs(getValues(`labor[${index}].start_time`));
                              const { hours, minutes } = calculateMechanicHrs(startTime, endTime);
                              setValue(`labor[${index}].mechanicHrs`, `${hours}h ${minutes}m`);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          format='DD/MM/YYYY HH:mm A'
                          minDateTime={getValues(`labor[${index}].start_time`) ? dayjs(getValues(`labor[${index}].start_time`)) : null}
                        />
                      )}
                    />


                  </Grid>


                  <Grid item xs={0.8}>
                    <Controller
                      name={`labor[${index}].mechanicHrs`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Mechanic Hrs"
                          variant="standard"
                          disabled
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={1.5}>
                    <Controller
                      name={`labor[${index}].reason`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Reason"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <Controller
                      name={`labor[${index}].status`}
                      control={control}
                      rules={{ required: 'status is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={statusOptions}
                          getOptionLabel={(option) => option?.label ?? ''}
                          isOptionEqualToValue={(option, value) => (option.id === value.id)}
                          onChange={(event, value) => field.onChange(value || statusOptions[0])} // Fallback to default value
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="standard"
                              error={!!errors.status}
                              helperText={errors.status ? "status is required" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <IconButton onClick={() => remove(index)}>
                        <CardMedia src={check} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                        <CardMedia src={MdDelete} component={'img'} sx={{ height: '27px', width: 'auto', marginRight: "10px" }} />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                  <SoftButton type="submit" variant="contained" color="primary">
                    Submit
                  </SoftButton>
                </Box>
              </Grid>
            </Box>
          </form>
        </StyledPaperShadow>
        {open && (
          <StyledSnackBar
            open={true}
            message={snackBarMessage}
            status={dataStatusConfirm}
          />
        )}
      </Box>

    </LocalizationProvider>
  )
}

export default MechanicMapping