import { Autocomplete, Box, Chip, Grid, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaArrowCircleRight } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetTransactionSubQuery, useLazyJobCardInvoiceInsurancePdfQuery, useLazyJobCardInvoicePdfQuery, useLazyJobCardPdfQuery, useLazyPreInvoicePdfQuery, useTransactionStatusMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";

const CashBillViewPage = () => {
    const { handleSubmit, setValue, formState: { errors }, control } = useForm({
        defaultValues: {

        }
    });
    const navigate = useNavigate();

    const location = useLocation();
    const data = location?.state?.rowData;

    const { data: allTransactionData } = useGetTransactionSubQuery();
    const [cashBillData] = useTransactionStatusMutation();
    const [JobCardInvoicePdf] = useLazyJobCardInvoicePdfQuery();
    const [JobCardInvoiceInsurancePdf] = useLazyJobCardInvoiceInsurancePdfQuery();
    const [getJobCardPdfDetails] = useLazyJobCardPdfQuery();
    const [getPreInvoicePdf] = useLazyPreInvoicePdfQuery();

    const TransactionSubStatus = allTransactionData?.TransactionSubstatusData || [];
    const [jobCard, setJobCard] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);

    useEffect(() => {
        setJobCard(data);
    }, [data]);

    useEffect(() => {
        if (jobCard) {
            const selectedSubStatus = TransactionSubStatus?.find(subStatus => subStatus.title === jobCard?.sub_status);
            setValue('subStatus', selectedSubStatus || null);
        }
    }, [jobCard, TransactionSubStatus, setValue]);

    const JobCardInvoiceClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await JobCardInvoicePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `JobCardInvoice${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };
    const JobCardInvoiceInsuranceClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await JobCardInvoiceInsurancePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `JobCardInvoiceInsurancePdf${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const JobCardPdfClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await getJobCardPdfDetails(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `JobCard${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };

    const preInvoiceClick = async () => {
        const id = data?.id;
        try {
            const pdfBlob = await getPreInvoicePdf(id)

            if (pdfBlob?.data !== undefined) {
                const pdfUrl = window.URL.createObjectURL(pdfBlob?.data);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `PreInvoice${id}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
                setDataStatusConfirm("Success");
                const message = "pdf is downloaded";
                setSnackBarMessage(message);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching PDF details:', error);
        }

    };
    // Columns for the vehicle details
    const vehicleColumns = [
        { field: 'reg_no', headerName: 'Vehicle Reg No', flex: 1, },
        { field: 'job_card_no', headerName: 'JobCard No', flex: 1, },
        { field: 'odometer', headerName: 'Odometer', flex: 1, },
        { field: 'work_end_date_time', headerName: 'Work Enddate', flex: 1, },
        { field: 'customer_arrived_date', headerName: 'Customer ArrivedDate', flex: 1, },
        { field: 'service_estimate_code', headerName: 'Service Estimate Number', flex: 1, },
        {
            field: 'status', headerName: 'Status', flex: 1,
            renderCell: (params) => {
                let background = '';
                let statusText;

                switch (params.value) {
                    case 1:
                        statusText = 'Open';
                        background = '#ff0000';
                        break;
                    case 2:
                        statusText = 'Work In Progress';
                        background = '#e7c018';
                        break;
                    case 3:
                        statusText = 'Ready For Billing';
                        background = '#1b8c0a';
                        break;
                    case 4:
                        statusText = 'Billing';
                        background = '#1b8c0a';
                        break;
                    case 5:
                        statusText = 'Deliverd';
                        background = '#1b8c0a';
                        break;
                    default:
                        statusText = '-';
                        background = 'gray';
                }

                return (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '8px',
                                        color: "#ffffd6 !important"
                                    }}
                                />
                                {statusText}
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            backgroundColor: background,
                            color: '#fff', // Ensure text contrast is readable
                            width: "150px",
                        }}
                    />
                );
            },
        },
        { field: 'customer_name', headerName: 'Customer Name', flex: 1, },
        { field: 'customer_mobileNumber', headerName: 'Customer Mobile Number', flex: 1, },

    ];

    // Columns for the labour schedules
    const labourColumns = [
        { field: 'rot_code', headerName: 'LaborCode', width: 150 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discount_percentage', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'laborTotal', headerName: 'Total Amount', width: 150 },
    ];

    //Osl Columns for the labour schedules
    const oslLabourColumns = [
        { field: 'rot_code', headerName: 'LaborCode', width: 150 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'additionalMargin', headerName: 'Margin', width: 100 },
        { field: 'discount_percentage', headerName: 'Discount Amount', width: 150 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'laborTotal', headerName: 'Total Amount', width: 150 },
    ];

    //parts Columns for the labour schedules
    const partsColumns = [
        { field: 'item_code', headerName: 'PartNo', width: 150 },
        { field: 'item_name', headerName: 'Part Description', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        { field: 'rate', headerName: 'Amount', width: 100 },
        { field: 'discount', headerName: 'Discount', width: 100 },
        { field: 'cgst', headerName: 'CGST %', width: 100 },
        { field: 'sgst', headerName: 'SGST %', width: 100 },
        { field: 'igst', headerName: 'IGST %', width: 100 },
        { field: 'total', headerName: 'Total Amount', width: 150 },
    ];

    // Rows for the vehicle details DataGridtransactionData
    const vehicleRows = data ? [{ id: data.id, ...data }] : [];

    // Rows for the labour schedules DataGrid
    const labourRows = data.schedules ? data.schedules.map((item, index) => ({ id: index, ...item })) : [];
    const oslLabourRows = data.oslSchedules ? data.oslSchedules.map((item, index) => ({ id: index, ...item })) : [];
    const partsRows = data.partsIssue ? data.partsIssue.map((item, index) => ({ id: index, ...item })) : [];

    // Calculate grand total for laborTotal
    const grandTotal = labourRows.reduce((total, row) => total + parseFloat(row.laborTotal || 0), 0);
    const oslGrandTotal = oslLabourRows.reduce((total, row) => total + parseFloat(row.laborTotal || 0), 0);
    const partsTotal = partsRows.reduce((total, row) => total + parseFloat(row.total || 0), 0);
    const Total = (grandTotal + oslGrandTotal + partsTotal)

    const onSubmit = async (data) => {
        try {
            const payload = {
                id: jobCard.id,
                jobCardNo: jobCard.job_card_no,
                billType: "cash",
                status: data?.status?.value,
                subStatus: data?.subStatus?.title,
            };
            const response = await cashBillData(payload);

            if (response && response.data) {
                let message;
                if (response.data.validationErrors) {
                    message = response.data.validationErrors;
                    setDataStatusConfirm("Error");
                } else {
                    message = response.data.message;
                    setDataStatusConfirm("Success");
                    navigate("/bill")
                }
                setSnackBarMessage(message);
            } else {
                let message;
                if (response?.error?.data?.validationErrors) {
                    setDataStatusConfirm("Error");
                    message = response.error.data.validationErrors;
                } else {
                    setDataStatusConfirm("Success");
                    message = response?.error?.data.message;
                }
                setSnackBarMessage(message);
            }
        } catch (error) {
            console.error("Transaction update failed", error);
        }
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };

    const statusOptions = [
        { label: 'Billing', value: 4 },
        { label: 'Ready For Billing', value: 3 },
        { label: 'Work In Progress', value: 2 },
    ];

    const onBackClick = () => {
        navigate('/bill');
    };
    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Cash Bill"
                subDiv="CashBill ViewPage"
            ></StyledBreadcrumb>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                {data?.status === 4 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={JobCardPdfClick}>
                            JobCard<FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {data?.status === 3 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "250px" }} onClick={preInvoiceClick}>
                            JobCard PreInvoice<FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {data?.status === 4 && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "200px" }} onClick={JobCardInvoiceClick}>
                            JobCard Invoice <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}
                {(data?.status === 4 && data?.document_type === "AJC") && (
                    <Box sx={{ marginRight: "10px" }}>
                        <SoftButton variant="contained" sx={{ width: "250px" }} onClick={JobCardInvoiceInsuranceClick}>
                            Ajc JobCard Invoice <FaFilePdf style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                        </SoftButton>
                    </Box>
                )}

                <SoftButton variant="contained" sx={{ width: "200px" }} onClick={onBackClick}>
                    Bill <FaArrowCircleRight style={{ fontSize: '1.0rem', cursor: 'pointer', marginLeft: '10px' }} />
                </SoftButton>
            </Box>
            <Grid>
                <Box sx={{ height: 'auto', width: '100%' }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Vehicle Details</Typography>
                    <DataGrid rows={vehicleRows} columns={vehicleColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },

                        }}
                    />
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Labour Schedules</Typography>
                    <DataGrid rows={labourRows} columns={labourColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {grandTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Osl Labour scheduless</Typography>
                    <DataGrid rows={oslLabourRows} columns={oslLabourColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {oslGrandTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={{ marginTop: "10px" }}>
                <Box sx={{ height: "auto", width: '100%', }}>
                    <Typography variant="body1" sx={{ fontWeight: "700", color: '#464B7C', fontSize: 20, }}>Parts Details</Typography>
                    <DataGrid rows={partsRows} columns={partsColumns}
                        sx={{
                            '& .MuiDataGrid-cell': { fontSize: '12px', fontWeight: '500' },
                            '& .MuiDataGrid-columnHeaderTitleContainer': { fontSize: '14px', fontWeight: '700' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected': { outline: 'none' },
                            '& .MuiDataGrid-row.Mui-selected:focus-within': { outline: 'none' },
                            '& .MuiTablePagination-toolbar': { display: 'none' },
                            '& .MuiDataGrid-footerContainer': { display: 'none' },
                            '& .css-tgsonj ': { display: 'none' },
                        }}
                    />
                    <Typography variant="body1" sx={{ backgroundColor: "#d8e4fd", fontWeight: "700", color: '#464B7C', fontSize: 16, textAlign: 'right', marginTop: '10px' }}>
                        Grand Total: {partsTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>

            <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%", marginTop: "30px" }}>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={3}>
                        <TextField fullWidth size='small'
                            variant="standard"
                            type="number"
                            label="Labour Amount"
                            value={grandTotal}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth size='small'
                            variant="standard"
                            type="number"
                            label="Osl Labour Amount"
                            value={oslGrandTotal}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth size='small'
                            variant="standard"
                            type="number"
                            label="Parts Amount"
                            value={partsTotal}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth size='small'
                            variant="standard"
                            type="number"
                            label="Grant Amount"
                            value={Total}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>

                        <Grid item xs={3}>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={statusOptions[0]}
                                rules={{ required: 'status is required' }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={statusOptions}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, value) => field.onChange(value)}
                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Status"
                                                variant="standard"
                                                error={!!errors.status}
                                                helperText={errors.status ? "Document Type is required" : ""}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                name="subStatus"
                                control={control}
                                rules={{ required: 'TransactionSubStatus is required' }}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={TransactionSubStatus}
                                        getOptionLabel={(option) => option.title}
                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Substatus"
                                                variant="standard"
                                                error={!!errors.subStatus}
                                                helperText={errors.subStatus ? errors.subStatus.message : ''}
                                            />
                                        )}
                                        value={field.value || null}
                                        onChange={(event, value) => field.onChange(value)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                <SoftButton type="submit" variant="contained" color="primary" >
                                    Submit
                                </SoftButton>
                            </Box>
                        </Grid>

                    </Grid>
                </form>
            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
}
export default CashBillViewPage