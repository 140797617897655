import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Header from '../layout/Header/Header';
import Main from '../layout/Main/Main';
import Sidebar from '../layout/Sidebar/SideBar';

function Wrapper({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 900);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          height: 'calc(100vh - 0px)',
          position: 'relative',
          background: '#ffffff',
        }}
      >
        <Header onToggleSidebar={handleToggleSidebar} />
        <Sidebar isOpen={isSidebarOpen} />
        <Main page={children} isOpen={isSidebarOpen} />
      </Box>
    </Box>
  );
}

export default Wrapper;
