import { Box, Chip, IconButton, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSearchPlus } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useCashBillMutation } from '../../../store/slice/ApiSlice';
import { StyledPaperShadow } from "../../../theme/StyledElement";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const CashBill = ({ buttons }) => {
  const { formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: {
    }
  });
  const navigate = useNavigate();

  const [getCreditDatagrid, { data: CreditDatagrid }] = useCashBillMutation();

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [backDropOpen, setBackDropOpen] = useState(false); //null

  useEffect(() => {
    fetchItems(offset, searchValue);
    setBackDropOpen(true)
  }, [offset, limit]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey,
    };
    const response = await getCreditDatagrid(payload);
    if (response?.data?.requestSuccessful === true) {
      setBackDropOpen(false)
    }
    setTotalItems(response?.data?.JobCardData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };
  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };

  const columns = useMemo(() => [
    {
      field: 'reg_no',
      headerName: 'Registration No',
      flex: 1,
    },
    {
      field: 'job_card_no',
      headerName: 'JobCard No',
      flex: 1,
    },
    {
      field: 'status_value',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        let background = '';

        switch (params.value) {
          case 'Open':
            background = '#ff0000';
            break;
          case 'Ready For Billing':
            background = '#1b8c0a';
            break;
          case 'Billing':
            background = '#1b8c0a';
            break;
          case 'Work In Progress':
            background = '#e7c018';
            break;
          default:
            background = 'gray';
        }

        return (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: '8px',
                    color: "#ffffd6 !important"
                  }}
                />
                {params.value}
              </>
            }
            style={{
              textTransform: 'capitalize',
              backgroundColor: background,
              color: '#fff', // Ensure text contrast is readable
              width: "150px",
            }}
          />
        );
      },
    },

    ...(buttons.includes(3) ? [{
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
          <Tooltip title="Edit">
            <>
            <FaSearchPlus onClick={() => handleViewClick(params.row)} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '10px' }} />
            </>
          </Tooltip>
      ),
    }] : []),
  ], [buttons]);

  const handleViewClick = (rowData) => {
    navigate('/bill/cashBillViewPage', { state: { rowData } });
  };
  const calcRecords = () => {
    let total = 0;
    CreditDatagrid?.JobCardData?.data.forEach((item) => {
      if(item.billing?.bill_type === "cash" || item.billing?.bill_type === null || item.billing === null){
        total++;
      }
    });
    return total;
  }
  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
              <Box sx={{ alignSelf: "center", margin: "5px" }}>
                <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {calcRecords()}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  sx={{
                    marginLeft: 1,

                    '& .MuiSelect-select': {
                      paddingTop: "1px !important",
                      paddingBottom: "1px !important"
                    },
                  }}

                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Box>
            </Box>
            <TextField
              variant="standard"
              label="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchClick}>
                      <IoSearchSharp />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </Box>
          <ItemGroupDatagrid data={CreditDatagrid?.JobCardData?.data.filter((item) => item.billing?.bill_type === "cash" || item.billing?.bill_type === null || item.billing === null) || []} columns={columns} />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
            <ToggleButtonGroup
              exclusive
              sx={{
                display: 'flex', alignItems: 'center',

                '& .MuiToggleButtonGroup-middleButton': {
                  marginRight: "0px !important",
                  borderLeft: "1px solid #e5e5e5"
                },
                '& .MuiToggleButtonGroup-root': {
                  border: "1px solid #e5e5e5",
                },
              }}
            >
              <ToggleButton
                value="previous"
                onClick={handlePrevious}
                disabled={offset === 0}
              >
                Previous
              </ToggleButton>

              {Array.from({ length: totalPages }, (_, index) => {
                const page = index + 1;
                const isFirstThreePages = page <= 3;
                const isLastThreePages = page > totalPages - 3;
                const isCurrentPage = offset / limit === index;

                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                  return (
                    <ToggleButton
                      key={page}
                      value={`page-${page}`}
                      onClick={() => handlePageClick(page)}
                      sx={{ margin: '0 5px' }}
                      selected={isCurrentPage}
                    >
                      {page}
                    </ToggleButton>
                  );
                }

                if (page === 4 && !isFirstThreePages) {
                  return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                }

                if (page === totalPages - 3 && !isLastThreePages) {
                  return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                }

                return null;
              })}
              <ToggleButton
                value="next"
                onClick={handleNext}
                disabled={offset + limit >= totalItems}
              >
                Next
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

        </Box>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={backDropOpen}
      >
        <ScaleLoader color="#11164b" />
      </Backdrop>
    </Box>
  )
}

export default CashBill