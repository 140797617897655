import React, { useState,useRef, useEffect } from 'react';
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import DynamicTable from './CounterSaleReturnTable';
import { useGetCountersaleForReturnMutation } from '../../../store/slice/ApiSlice';
import { useCreateCountersaleReturnMutation} from '../../../store/slice/ApiSlice'

const CounterSaleReturn = ({setCurrentTab,id}) => {
  const [createCounterSalereturn, { isSuccess }] = useCreateCountersaleReturnMutation(); // Prepare the add item mutation
const [getCountersale]=useGetCountersaleForReturnMutation()
  // searchKey, offset, limit
  const [rows, setRows] = useState([]);
  const [customerCode,setCustomerCode]=useState("");
  const [source,setSource]=useState("")
  const [souceType,setSourceType]=useState("")
  const [customerType,setCustomerType]=useState("")
  const [customerGSTIN,setCustomerGSTIN]=useState("")
  const [customerAddress,setCustomerAddress]=useState("")
  const [customerId,setCustomerId]=useState()
  const [documentType,setDocumentType]=useState("")
  const [invoiceNumber,setInvoiceNumber]=useState("")

useEffect(()=>{
    const fetchdata=async()=>{
        if(id){
          let response=await getCountersale({id:id})
         if(response.data&&response.data.requestSuccessful&&response?.data?.data&&response?.data?.parts){
          setRows(response?.data?.parts)
          const countersaledata=response.data.data[0]
          setDocumentType(countersaledata.document_type)
          setCustomerCode(countersaledata.customer_code)
          setSource(countersaledata.source)
          setSourceType(countersaledata.source_type)
          setCustomerType(countersaledata.customer_type)
          setCustomerGSTIN(countersaledata.customer_gstin)
          setCustomerAddress(countersaledata.customer_address)
          setCustomerId(countersaledata.customer_id)
          setInvoiceNumber(countersaledata.invoice_number)
         }
        }
      }
      fetchdata()
},[])




  const {
    register,
    handleSubmit,
    reset,
    setValue, control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      status: 0,
      document: '',
      customerCode: '',
      source: '',
      sourceType: '',
      customerType: '',
      customerGSTIN: '',
      CustomerAddress: '',
    },
  });

  const [status, setStatus] = useState(1);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const[ headers,setHeaders ]= useState([
    'Parts Code',
    'Description',
    'Issued Qty',
    "Return Qty",
    'Rate',
    'Cost',
    'MRP',
    'Discount Amount',
    'CGST',
    'SGST',
    'IGST',
    'Total Amount',
  ])
  const onSubmit = async (data) => {
    if (dynamicTableData.length === 0) {
      setSnackBarMessage('Please add at least one row in the table.');
      setDataStatusConfirm('Error');
      setOpen(true);
      setTimeout(()=>{
            setOpen(false)
            setSnackBarMessage("")
      },3000)
      return;
    }
    const transformedData = dynamicTableData.map((obj) => ({
      item_id: obj.item_id,
      item_code: obj["Parts Code"],
      item_description: obj.Description,
      quantity: parseFloat(obj["Return Qty"]), // Convert to number if needed
      rate: parseFloat(obj.Rate), // Convert to number if needed
      cost: parseFloat(obj.Cost), // Convert to number if needed
      mrp: parseFloat(obj.MRP), // Convert to number if needed
      discount:  isNaN(parseFloat(obj["Discount Amount"])) ? 0 :parseFloat(obj["Discount Amount"]) , // Convert to number if needed
      cgst: obj.CGST,
      sgst: obj.SGST,
      igst: obj.IGST,
      total: parseFloat(obj["Total Amount"]), // Convert to number if needed
      counter_sale_id:id,
      countersale_part_id:obj.id
    }));
    const countersaledata={
     counter_sale_invoice_number:invoiceNumber,
     counter_sale_id:id,
     customer_code:customerCode,
     customer_id:customerId,
     grand_total:grandTotal
    }
    let response = await createCounterSalereturn({counterSale:countersaledata,counterSalePart:transformedData})
    if (response.data && response.data.requestSuccessful) {
    
      let  message = response.data.message;
        setDataStatusConfirm('Success');
        reset();
        setDynamicTableData([]);
        setGrandTotal(0);
        setRows([])
      setSnackBarMessage(message);
      setCurrentTab(1)

    } else {
      let message;
        setDataStatusConfirm('Error');
        // message = response.error.data.validationErrors;
      
      setSnackBarMessage(response.error.data.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  

  const handleDataSubmit = (data) => {
    setDynamicTableData(data);
    calculateGrandTotal(data);
  };

  const calculateGrandTotal = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row['Total Amount'] || 0), 0);
    setGrandTotal(total);
  };

  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container sx={{alignItems:"flex-end"}} spacing={2}>
            <Grid item xs={3}>
            <TextField
                {...register('document')}
                fullWidth
                variant="standard"
                value={documentType}
                label="Document Type"
                error={!!errors.document}
                helperText={errors.document ? errors.document.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
            <TextField
                {...register('customerCode')}
                fullWidth
                variant="standard"
                value={customerCode}
                label="Document Type"
                error={!!errors.customerCode}
                helperText={errors.customerCode ? errors.customerCode.message : ''}
              />
            </Grid>

    
         <Grid item xs={3}>
              <TextField
                {...register('source')}
                fullWidth
                variant="standard"
                value={source}
                label="Source"
                error={!!errors.source}
                helperText={errors.source ? errors.source.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField 
                {...register('sourceType')}
                fullWidth
                variant="standard"
                value={souceType}
                label="Source Type"
                error={!!errors.sourceType}
                helperText={errors.sourceType ? errors.sourceType.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('customerType')}
                fullWidth
                variant="standard"
                value={customerType}
                label="Customer Type"
                error={!!errors.customerType}
                helperText={errors.customerType ? errors.customerType.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('customerGSTIN', )}
                fullWidth
                variant="standard"
                value={customerGSTIN}
                label="Customer GSTIN"
                error={!!errors.customerGSTIN}
                helperText={errors.customerGSTIN ? errors.customerGSTIN.message : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField  focused
                {...register('CustomerAddress', )}
                
                fullWidth
                InputProps={{
                  multiline: true,
                  inputComponent: 'textarea'
              }}
                variant="standard"
                value={customerAddress}
                label="Customer Address"
                error={!!errors.CustomerAddress}
                helperText={errors.CustomerAddress ? errors.CustomerAddress.message : ''}
              />
            </Grid>
   
          </Grid>

          <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'right' }}>
            Grand Total: {grandTotal.toFixed(2)}
          </Typography>

          <DynamicTable headers={headers} onSubmit={handleDataSubmit} 
          rows={rows} setRows={setRows}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <SoftButton type="submit" variant="contained" color="primary">
              {'Submit'}
            </SoftButton>
          </Box>
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default CounterSaleReturn;
