import { Autocomplete, Box, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useAllMenuListMutation, useAllSubMenuListMutation, useGetroleIdMutation, useMenuListByRoleMutation, useSubmitMenuSettingMutation } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";

const RoleMenuSettings = () => {
  const {handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {},
  });
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [SubMenuList, setSubMenuList] = useState([]);

  const [getRoles, { data: getRolesData }] = useGetroleIdMutation();
  const [getAllMenuList, { data: getAllMenuListData }] = useAllMenuListMutation();
  const [getSubMenuList] = useAllSubMenuListMutation();
  const [menuList] = useMenuListByRoleMutation();
  const [SubmitRoleSettingData,] = useSubmitMenuSettingMutation();

  const roleData = getRolesData?.menuList;
  const AllMenuList = getAllMenuListData?.data;

  useEffect(() => {
    getRoles();
    getAllMenuList();
  }, []);


  const onSubmit = async (data) => {
    const roleMenuSetting = AllMenuList?.map((menuItem, index) => {
      const buttonOperations = [];
      if (data[`Create-${menuItem.id}`]) buttonOperations.push(1);
      if (data[`Read-${menuItem.id}`]) buttonOperations.push(2);
      if (data[`Update-${menuItem.id}`]) buttonOperations.push(3);
      if (data[`Delete-${menuItem.id}`]) buttonOperations.push(4);

      const selectedSubMenus = data[`subMenu-${index}`] || [];
      const subMenuIds = selectedSubMenus.map((subMenu) => subMenu.id).join(',');

      return {
        menu: {
          id: menuItem.id,
          title: menuItem.title,
        },
        SubMenu: subMenuIds,
        button_operation: buttonOperations.join(','),
        status: data[`assign-${menuItem.id}`] ? true : false,
      };
    });

    const formData = {
      role: data.role,
      roleMenuSetting,
    };

    let response = await SubmitRoleSettingData(formData)
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        // reset();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        // reset();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const selectedInputType = useWatch({
    control,
    name: 'role',
  });

  useEffect(() => {
    const fetchMenuList = async () => {
      if (selectedInputType !== undefined) {
        try {
          const payload = { roleId: selectedInputType?.id };
          const response = await getSubMenuList(payload);

          const SubMenuData = response?.data?.submenudData || [];
          setSubMenuList(SubMenuData)

        } catch (error) {
          console.error("Error fetching menu list:", error);
        }
      }
    };

    fetchMenuList();
  }, [selectedInputType]);

  useEffect(() => {
    const fetchMenuList = async () => {
      if (selectedInputType !== undefined) {
        try {
          const payload = { roleId: selectedInputType?.id };
          const response = await menuList(payload);
          const menuResponse = response?.data?.menuList || [];

          menuResponse.forEach((menuItem) => {
            const matchedMenu = AllMenuList?.find((menu) => menu.title === menuItem.title);

            if (matchedMenu) {
              const statusIsActive = menuItem.status === "Active";
            setValue(`assign-${matchedMenu.id}`, statusIsActive);

              const buttonOperations = menuItem.buttons.split(',').map(Number);
              buttonOperations.forEach((operation) => {
                if (operation === 1) setValue(`Create-${matchedMenu.id}`, true);
                if (operation === 2) setValue(`Read-${matchedMenu.id}`, true);
                if (operation === 3) setValue(`Update-${matchedMenu.id}`, true);
                if (operation === 4) setValue(`Delete-${matchedMenu.id}`, true);
              });

              const subMenuIds = menuItem?.submenu?.map((sub) => sub.title);
              const matchedSubMenus = SubMenuList?.filter((subMenu) =>
                subMenuIds?.includes(subMenu.title)
              );
              setValue(`subMenu-${AllMenuList.indexOf(matchedMenu)}`, matchedSubMenus);
            }
          });
        } catch (error) {
          console.error("Error fetching menu list:", error);
        }
      }
    };

    fetchMenuList();
  }, [selectedInputType,SubMenuList]);

  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ marginBottom: '50px' }}>
            {/* Role Selection */}
            <Grid item xs={3}>
              <Controller
                name="role"
                control={control}
                rules={{ required: 'Role is required' }}
                render={({ field }) => (
                  <Autocomplete
                    options={roleData || []}
                    getOptionLabel={(option) => option.roleName || ''}
                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Roles"
                        variant="standard"
                        error={!!errors.role}
                        helperText={errors.role ? errors.role.message : ''}
                      />
                    )}
                    value={field.value || null}
                    onChange={(event, value) => field.onChange(value)}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mb: 1.5, margin: "50px 5px" }}></Divider>
          <Grid container spacing={2}>
            {AllMenuList?.map((item, index) => (
              <React.Fragment key={item.id}>
                <Grid item xs={3}>
                  <TextField
                    label="Menu"
                    value={item.title}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`subMenu-${index}`}
                    control={control}
                    render={({ field }) => {
                      const selectedSubMenus = watch(`subMenu-${index}`) || [];
                      const filteredOptions = SubMenuList.filter(
                        (subMenu) => !selectedSubMenus.some((selected) => selected.title === subMenu.title)
                      );
                      return (
                        <Autocomplete
                          multiple
                          options={filteredOptions || []}
                          getOptionLabel={(option) => option.title || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="SubMenu"
                              variant="standard"
                              fullWidth
                            />
                          )}
                          value={field.value || []}
                          onChange={(event, value) => field.onChange(value)}
                          renderTags={(selected, getTagProps) =>
                            selected.map((option, index) => (
                              <Chip key={index} label={option.title} {...getTagProps({ index })} />
                            ))
                          }
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Box>Button Operation</Box>
                  <FormGroup row>
                    {['Create', 'Read', 'Update', 'Delete'].map((operation) => (
                      <FormControlLabel
                        key={operation}
                        control={
                          <Controller
                            name={`${operation}-${item.id}`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={field.value || false}
                                onChange={(e) => field.onChange(e.target.checked)}
                              />
                            )}
                          />
                        }
                        label={operation}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={2} sx={{ alignSelf: 'center', marginTop: '20px' }}>
                  <Controller
                    name={`assign-${item.id}`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label="Status"
                        control={<Switch {...field} checked={field.value || false} />}
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <SoftButton type="submit" variant="contained" color="primary">
                  Submit
                </SoftButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
};

export default RoleMenuSettings;
