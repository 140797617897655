import React, { useState,useRef } from 'react';
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import DynamicTable from './CounterSaleTable';
import { useGetAllCustomerMutation,useGetCustomerDetailsMutation } from '../../../store/slice/ApiSlice';
import { useCreateCounterSaleMutation} from '../../../store/slice/ApiSlice'

const CreateCounterSale = ({setCurrentTab}) => {
  const [createCounterSale, { isSuccess }] = useCreateCounterSaleMutation(); // Prepare the add item mutation
  const [fetchVendors, { data: vendorData, isLoading }] = useGetAllCustomerMutation();
  const [getData]=useGetCustomerDetailsMutation()

  // searchKey, offset, limit
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendorState,setVendorState]=useState("")
  const [rows, setRows] = useState([]);
  const [customerCode,setCustomerCode]=useState("");
  const [source,setSource]=useState("")
  const [souceType,setSourceType]=useState("")
  const [customerType,setCustomerType]=useState("")
  const [customerGSTIN,setCustomerGSTIN]=useState("")
  const [customerAddress,setCustomerAddress]=useState("")
  const [customerId,setCustomerId]=useState()
  const [customerName,setCustomerName]=useState("")
  const [customerAddress1,setCustomerAddress1]=useState("")
  const [customerCity,setCustomerCity]=useState("")
  const [customerState,setCustomerState]=useState("")


  const fetchdata=async(id)=>{
    console.log(id,"id")
    let response=await getData({customerCode:id})
    console.log(response,"response")
   if(response.data&&response?.data?.requestSuccessful&&response?.data?.data){
    const datas=response.data.data
    let fulladdress=datas.firstName+" "+datas.lastName+", "+datas.address1+", "+datas.address2+", "+
    datas.city+", "+datas.state+", "+datas.pinCode+", mobile: "+datas.mobileNumber
    setSource(datas.source?.sourceName)
    setSourceType(datas.sourceType?.sourceTypeName)
    setCustomerType(datas.customerType)
    setCustomerGSTIN(datas.gstinNumber)
    setCustomerAddress(fulladdress)
    setVendorState(datas.state)
    setCustomerId(datas.id)
    setCustomerName(datas.firstName+" "+datas.lastName)
    setCustomerAddress1(datas.address1)
    setCustomerCity(datas.city)
    setCustomerState(datas.state)
   }
  
  }
  const handleVendorSearch = async (searchKey) => {
    if(searchKey){
    try {
      
      const offset = 0;
      const limit = 10; // Adjust as needed
      const response = await fetchVendors({ searchKey, offset, limit,customerId:0 }).unwrap();
      if (response.requestSuccessful) {

      setVendorOptions(response?.customerData);
      } // Assuming the response has data in `response.data`
    } catch (error) {
      console.error("Error fetching vendor data", error);
    }
  };
}


  const {
    register,
    handleSubmit,
    reset,
    setValue, control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      status: 0,
      document: '',
      customerCode: '',
      source: '',
      sourceType: '',
      customerType: '',
      customerGSTIN: '',
      CustomerAddress: '',
    },
  });

  const [status, setStatus] = useState(1);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const[ headers,setHeaders ]= useState([
    'Parts Code',
    'Description',
    'Available Qty',
    'Issued Qty',
    'Rate',
    'Cost',
    'MRP',
    'Discount Amount',
    'CGST',
    'SGST',
    'IGST',
    'Total Amount',
  ])
  const onSubmit = async (data) => {
    if (dynamicTableData.length === 0) {
      setSnackBarMessage('Please add at least one row in the table.');
      setDataStatusConfirm('Error');
      setOpen(true);
      return;
    }
    const transformedData = dynamicTableData.map((obj) => ({
      item_id: obj.itemid,
      item_code: obj["Parts Code"],
      item_description: obj.Description,
      quantity: parseFloat(obj["Issued Qty"]), // Convert to number if needed
      rate: parseFloat(obj.Rate), // Convert to number if needed
      cost: parseFloat(obj.Cost), // Convert to number if needed
      mrp: parseFloat(obj.MRP), // Convert to number if needed
      discount:  isNaN(parseFloat(obj["Discount Amount"])) ? 0 :parseFloat(obj["Discount Amount"]) , // Convert to number if needed
      cgst: obj.CGST,
      sgst: obj.SGST,
      igst: obj.IGST,
      total: parseFloat(obj["Total Amount"]), // Convert to number if needed
    }));

    const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

// This arrangement can be altered based on how we want the date's format to appear.
let currentDate = `${day}/${month}/${year}`;
    const formData = {

      countersale:{
        document_type:data.document,
        customer_id:customerId,
        customer_code:customerCode,
        customer_name:customerName,
        customer_address:customerAddress,
        customer_state:customerState,
        customer_city:customerCity,
        customer_gstin:customerGSTIN,
        source:source,
        source_type:souceType,
        grand_total:parseFloat(grandTotal.toFixed(2)),
        customer_type:customerType,
        invoice_date:currentDate
      },
      // status: status ? 1 : 0,
      // companyId: data.companyId.map((company) => company.id),
      countersalepart:transformedData
      
    };



    console.log("Pay", formData)

    let response = await createCounterSale(formData)
    console.log(response,"ress")
    if (response.data && response.data.requestSuccessful) {
    
      let  message = response.data.message;
        setDataStatusConfirm('Success');
        reset();
        setDynamicTableData([]);
        setGrandTotal(0);
        setVendorState("")
        setRows([])
      setSnackBarMessage(message);
      setCurrentTab(1)

    } else {
      let message;
        setDataStatusConfirm('Error');
        // message = response.error.data.validationErrors;
      
      setSnackBarMessage(response.error.data.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  

  const handleDataSubmit = (data) => {
    setDynamicTableData(data);
    calculateGrandTotal(data);
  };

  const calculateGrandTotal = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row['Total Amount'] || 0), 0);
    setGrandTotal(total);
  };

  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container sx={{alignItems:"flex-end"}} spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="document"
                control={control}
                defaultValue=""
                rules={{ required: 'Document is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Autocomplete
                    options={ ["CSC","CSCR"]} // Add your options here
                    getOptionLabel={(option) => option}
                    value={value}
                    onChange={(_, data) => onChange(data)} // Handle change
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Document Type"
                        variant="standard"
                        error={!!error}
                        helperText={error ? error.message : ''}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
      <Controller
        name="customerCode"
        control={control}
        defaultValue=""
        rules={{ required: 'Customer Code is required' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete 
            options={vendorOptions} // Adjust to map the correct field
            getOptionLabel={(option) =>option ? option.customerCode :customerCode}
            value={value}
            onChange={(_, data) => {
              console.log(data,"data")
              if(data&&data.customerCode){
                setCustomerCode(data.customerCode)
                onChange(data)
                fetchdata(data.customerCode)
              }
              
            }

            } // Handle change
            onInputChange={(_, value) => handleVendorSearch(value)} // Fetch vendor data based on input
            loading={isLoading} // Show a loading state while fetching
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer Code"
                variant="standard"
                error={!!error}
                helperText={error ? error.message : ''}
                fullWidth
              />
            )}
          />
        )}
      />
    </Grid>

      {source&& 
      <>
         <Grid item xs={3}>
              <TextField
                {...register('source')}
                fullWidth
                variant="standard"
                value={source}
                label="Source"
                error={!!errors.source}
                helperText={errors.source ? errors.source.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField 
                {...register('sourceType')}
                fullWidth
                variant="standard"
                value={souceType}
                label="Source Type"
                error={!!errors.sourceType}
                helperText={errors.sourceType ? errors.sourceType.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('customerType')}
                fullWidth
                variant="standard"
                value={customerType}
                label="Customer Type"
                error={!!errors.customerType}
                helperText={errors.customerType ? errors.customerType.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('customerGSTIN', )}
                fullWidth
                variant="standard"
                value={customerGSTIN}
                label="Customer GSTIN"
                error={!!errors.customerGSTIN}
                helperText={errors.customerGSTIN ? errors.customerGSTIN.message : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField  focused
                {...register('CustomerAddress', )}
                
                fullWidth
                InputProps={{
                  multiline: true,
                  inputComponent: 'textarea'
              }}
                variant="standard"
                value={customerAddress}
                label="Customer Address"
                error={!!errors.CustomerAddress}
                helperText={errors.CustomerAddress ? errors.CustomerAddress.message : ''}
              />
            </Grid>
            </>
      }
          </Grid>

          <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'right' }}>
            Grand Total: {grandTotal.toFixed(2)}
          </Typography>

          <DynamicTable headers={headers} onSubmit={handleDataSubmit} 
          vendorState={vendorState}   
          rows={rows} setRows={setRows}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <SoftButton type="submit" variant="contained" color="primary">
              {'Submit'}
            </SoftButton>
          </Box>
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default CreateCounterSale;
