import React, { useState,useRef } from 'react';
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { StyledPaperShadow, SoftButton } from '../../../theme/StyledElement';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import StyledBreadcrumb from '../../../Utils/StyledBreadcrumb';
import { useGetPurchaseAxaptaReportMutation } from '../../../store/slice/ApiSlice';
import { useNavigate } from 'react-router-dom';

const PurchaseAxaptaReport = ({setCurrentTab}) => {
  const [GetPurchaseAxaptaReport, { isSuccess }] = useGetPurchaseAxaptaReportMutation(); // Prepare the add item mutation
  const {
    register,
    handleSubmit,
    reset,
    setValue, control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
     
      fromDate: '',
      toDate:""
      
    },
  });

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState('');
  const [dynamicTableData, setDynamicTableData] = useState([]);
  const[ headers,setHeaders ]= useState([
    'Parts Code',
    'Description',
    // 'PO Number',
    'Sup. inv qty',
    'Received Qty',
    'Rate',
    'Cost',
    'MRP',
    'Discount Amount',
    'Select Bin Location',
    'CGST',
    'SGST',
    'IGST',
    'Total Amount',
  ])
  const onSubmit = async (data) => {
    console.log(data,"data")
   
   const formData={
    fromDate:data.fromDate,
    toDate:data.toDate,
   }




    console.log("Pay", formData)

    let blob = await GetPurchaseAxaptaReport(formData)
    if (blob.data) {
      const url = window.URL.createObjectURL(blob.data);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PurchaseReport.xlsx'); // Set the file name
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      let  message = "Purchase Report Downloaded Successfully"
        setDataStatusConfirm('Success');
        reset();
       
      setSnackBarMessage(message);

    } else {
      let message;
        setDataStatusConfirm('Error');
        // message = response.error.data.validationErrors;
      
      setSnackBarMessage("Server Error");
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  




  return (
    <Box>
      <StyledPaperShadow elevation={0} sx={{ display: 'inline-block', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" sx={{ marginTop: '10px',marginBottom:"20px" }}>
            Purchase Axapta Statement
          </Typography>
          <Grid container spacing={2}>
           
            <Grid item xs={3}>
              <TextField type='date'
                {...register('fromDate', { required: 'From Date is required' })}
                fullWidth
                variant="standard"
                label="From Date"
                InputLabelProps={{ shrink: true }}
                error={!!errors.fromDate}
                helperText={errors.fromDate ? errors.fromDate.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField type='date'
                {...register('toDate', { required: 'To Date is required' })}
                fullWidth
                variant="standard"
                label="To Date"
                InputLabelProps={{ shrink: true }}
                error={!!errors.toDate}
                helperText={errors.toDate ? errors.toDate.message : ''}
              />
            </Grid>
            <Grid item xs={3}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
            <SoftButton type="submit" variant="contained" color="primary">
              {'Submit'}
            </SoftButton>
          </Box>
          </Grid>
          </Grid>

          
         
        </form>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar open={true} message={snackBarMessage} status={dataStatusConfirm} />
      )}
    </Box>
  );
};

export default PurchaseAxaptaReport;
