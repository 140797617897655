import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useEditBinLocationMutation, useGetAllOuletQuery, useGetBinLocationDataMutation, useGetBinLocationDatagridMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const BinLocation = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      binLocation: "",
      binLocationDescription: "",
      status: 0,
    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addBinLocationData, { isSuccess: BinLocationDataSuccess }] = useGetBinLocationDataMutation();
  const [getBinLocationGrid, { data: BinLocationGrid }] = useGetBinLocationDatagridMutation();
  const [editBinLocationData, { isSuccess: editBinLocationSuccess }] = useEditBinLocationMutation();
  const { data: AllOutlet } = useGetAllOuletQuery();

  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'binLocation',
        headerName: 'Bin Location',
        flex: 1,
      },
      {
        field: 'binLocationDescription',
        headerName: 'Bin Location Description',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title="Edit">
        <IconButton
          onClick={() => handleEditClick(params.row)}
          style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
          sx={{ '&:hover': { backgroundColor: '#e0e0e0' }}}
        >
          <MdEdit />
      </IconButton>
      </Tooltip>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const outlet = AllOutlet?.OutletData || [];


  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, BinLocationDataSuccess, editBinLocationSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey,
    };
    const response = await getBinLocationGrid(payload);
    setTotalItems(response?.data?.BinLocationsData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };
  const onSubmit = async (data) => {

    const formData = {
      ...data,
      status: status ? 1 : 0,
      outletCode: data.outletCode.outletCode
    };

    let response;
    if (isEditing) {
      response = await editBinLocationData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
      }
    } else {
      response = await addBinLocationData(formData);
    }

    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response.data.message;
        reset();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    } else {

      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        reset();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);

  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };

  const handleEditClick = (row) => {
    setValue('binLocation', row.binLocation);
    setValue('binLocationDescription', row.binLocationDescription);

    const selectedOutlet = outlet.find(d => d.outletCode === row.outletCode) || null;
    setValue("outletCode", selectedOutlet)

    setStatus(row.status ? 1 : 0);
    setIsEditing(true);
    setCurrentItemId(row.id);
    clearErrors(['binLocation', 'binLocationDescription']);
  };


  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };


  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const handleCancel = () => {
    reset({
      binLocation: "",
      binLocationDescription: "",
      outletCode: "",
    })
    setStatus(1)
    setIsEditing(false);
  };
  
  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Master"
        subDiv="Bin Location"
      ></StyledBreadcrumb>

      {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (
        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('binLocation', { required: 'Bin Location is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label="Bin Location"
                  error={!!errors.binLocation}
                  helperText={errors.binLocation ? errors.binLocation.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('binLocationDescription', { required: 'BinLocation Description is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label="Bin Location Description"
                  error={!!errors.binLocationDescription}
                  helperText={errors.binLocationDescription ? errors.binLocationDescription.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="outletCode"
                  control={control}
                  rules={{ required: 'Outlet is required' }}
                  render={({ field }) => (
                    <Autocomplete
                      options={outlet || []}
                      getOptionLabel={(option) => option.outletCode || ''}
                      isOptionEqualToValue={(option, value) => (option.outletCode === value.outletCode)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Outlet"
                          variant="standard"
                          error={!!errors.outletCode}
                          helperText={errors.outletCode ? errors.outletCode.message : ''}
                        />
                      )}
                      value={field.value || null}
                      onChange={(event, value) => field.onChange(value)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={1} sm={1} md={1}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                  <Box>
                    <FormLabel>Status</FormLabel>
                  </Box>
                  <Box>
                    <Switch checked={status === 1} onChange={handleStatusChange} />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{background:"red !important"}} >
                      Cancel
                    </SoftButton>
                  </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                  <SoftButton type="submit" variant="contained" color="primary" >
                    {isEditing ? 'Update' : 'Add'}
                  </SoftButton>
                </Box>
              </Grid>
          </form>
        </StyledPaperShadow>
      )}
      <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
              <Box sx={{ alignSelf: "center", margin: "5px" }}>
                <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {BinLocationGrid?.BinLocationsData?.totalItems}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  sx={{
                    marginLeft: 1,

                    '& .MuiSelect-select': {
                      paddingTop: "1px !important",
                      paddingBottom: "1px !important"
                    },
                  }}

                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Box>
            </Box>
            <TextField
              variant="standard"
              label="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchClick}>
                      <IoSearchSharp />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </Box>
          <ItemGroupDatagrid data={BinLocationGrid?.BinLocationsData?.data || []} columns={columns} />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
            <ToggleButtonGroup
              exclusive
              sx={{
                display: 'flex', alignItems: 'center',

                '& .MuiToggleButtonGroup-middleButton': {
                  marginRight: "0px !important",
                },
              }}
            >
              <ToggleButton
                value="previous"
                onClick={handlePrevious}
                disabled={offset === 0}
              >
                Previous
              </ToggleButton>

              {Array.from({ length: totalPages }, (_, index) => (
                <ToggleButton
                  key={index + 1}
                  value={`page-${index + 1}`}
                  onClick={() => handlePageClick(index + 1)}
                  sx={{ margin: '0 5px' }}
                  selected={offset / limit === index}
                >
                  {index + 1}
                </ToggleButton>
              ))}

              <ToggleButton
                value="next"
                onClick={handleNext}
                disabled={offset + limit >= totalItems}
              >
                Next
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
};

export default BinLocation