import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { dashboardApi } from "./slice/ApiSlice";
import ControlsSlice from "./slice/ControlsSlice";

const reducers = combineReducers({
    controls:ControlsSlice,
    [dashboardApi?.reducerPath] : dashboardApi?.reducer
  });
  
  const persistConfig = {
      key: 'root',
      storage
  };
  
  const persistedReducer = persistReducer(persistConfig, reducers);

export const NewStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }).concat(dashboardApi?.middleware)
})