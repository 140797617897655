import { Autocomplete, Box, Chip, Divider, Grid, IconButton, MenuItem, Select, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useEditServiceBookingMutation, useGetAllDispositionMutation, useGetAllMakesMutation, useGetAllModelMutation, useGetAllPincodeMutation, useGetAllSourceMutation, useGetAllVechileDetailsMutation, useGetServiceBookingDataMutation, useGetServiceBookingDatagridMutation, useSubmitSourceMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const ServiceBooking = () => {
    const { register, handleSubmit, reset, setValue, watch, formState: { errors }, clearErrors, control, trigger } = useForm({
        defaultValues: {
            status: 0,
        }
    });

    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const pathname = window.location.pathname;

    const [addSeriveBookingData, { isSuccess: ServiceBookingSuccess }] = useGetServiceBookingDataMutation();
    const [getServiceBookingDatagrid, { data: ServiceEstimateDatagrid }] = useGetServiceBookingDatagridMutation();
    const [editServiceBookingData, { isSuccess: editServiceBookingSuccess }] = useEditServiceBookingMutation();
    const [getAllPincodeData,] = useGetAllPincodeMutation();
    const [getAllVehicleData,] = useGetAllVechileDetailsMutation();
    const [getMakeData, { data: AllMakeData }] = useGetAllMakesMutation();
    const [getModalData, { data: getAllModalData }] = useGetAllModelMutation();


    const [getAllDisposition, { data: getAllDispositionData }] = useGetAllDispositionMutation();
    const [getAllsourceData, { data: sourceData }] = useGetAllSourceMutation();
    const [submitSource, { data: submitSourceData }] = useSubmitSourceMutation();

    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [currentTab, setCurrentTab] = useState(1);
    const [isCreating, setIsCreating] = useState(true);
    const [VehicleIdData, setVehicleIdData] = useState('');
    const [pincodeData, setPincodeData] = useState('');
    const [customerNameData, setCustomerName] = useState('');
    const [customerMobileNumberData, setcustomerMobileNumber] = useState('');
    const [customerAddressData, setCustomerAddress] = useState('');
    const [searchValue, setSearchValue] = useState("");
    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [backDropOpen, setBackDropOpen] = useState(false);  //null
    const [dateOpen, setDateOpen] = useState(false);
    const [dateOpen1, setDateOpen1] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedSourceType, setSelectedSourceType] = useState(null);
    const [customerNameReadOnly, setCustomerNameReadOnly] = useState(false);
    const [customerAddressReadOnly, setCustomerAddressReadOnly] = useState(false);
    const [mobileNoReadOnly, setMobileNoReadOnly] = useState(false);
    const [pincodeReadOnly, setPincodeReadOnly] = useState(false);
    const [makeDisabled, setMakeDisabled] = useState(false);
    const [modelDisabled, setModelDisabled] = useState(false);

    const makes = AllMakeData?.MakeData || [];
    const model = getAllModalData?.ModelData || [];
    const disposition = getAllDispositionData?.DisPositionsData || []
    const sources = sourceData?.sourceData || [];
    const sourceType = submitSourceData?.sourceTypes || [];
    const currentDate = dayjs().format('DD-MM-YYYY HH:mm:ss');
    const futureDate = dayjs().add(15, 'day').format('DD-MM-YYYY HH:mm:ss');

    const setSelectedValues = () => {
        if (isEditing) {
            if (getAllModalData !== undefined) {
                const selectedModel = model.find(model => model.modelName === selectedRowData.model);
                setSelectedModel(selectedModel);
                setValue('modelId', selectedModel);
            }
        }
    };
    useEffect(() => {
        setSelectedValues()
    }, [getAllModalData, isEditing])

    useEffect(() => {
        getAllsourceData();
        getAllDisposition();
        getMakeData();
    }, [])

    const watchDisposition = useWatch({
        control,
        name: 'dispositionId',
    });

    useEffect(() => {
        fetchItems(offset, searchValue);
        setBackDropOpen(true)
    }, [offset, limit, ServiceBookingSuccess, editServiceBookingSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey
        };
        const response = await getServiceBookingDatagrid(payload);
        if (response?.data?.requestSuccessful === true) {
            setBackDropOpen(false)
        }
        setTotalItems(response?.data?.ServiceBookingData?.totalItems);
    };


    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0); // Reset offset when changing the limit
    };

    useEffect(() => {
        if (!watchDisposition) {
            setValue('appointmentStatus', '');
        } else {
            const statusMap = {
                'Appointment Booked': 'Inprogress',
                'Appointment Cancelled': 'Cancelled',
                'Appointment Rescheduled': 'Inprogress',
                'Duplicate': 'Cancelled',
                'Call Back/Follow Up': 'Inprogress',
                'Hung Up/Refuse to Speak': 'Inprogress',
                'Dissatisfied': 'Completed',
                'Not contactable': 'Completed',
                'Wrong Number': 'Completed',
                'Already Servicing Done': 'Completed',
                'Do Not Have Car/Outlet/Service': 'Completed',
                'Service Not required': 'Completed',
                'Vehicle Sold': 'Completed',
                'Refuse to visit due to Distance': 'Completed',
                'Offers Price high when compared': 'Completed',
                'Query': 'Completed',
            };
            const newStatus = statusMap[watchDisposition.title] || '';
            setValue('appointmentStatus', newStatus);
        }
    }, [watchDisposition, setValue]);


    const onSubmit = async (data) => {
        const currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss');
        const futureDate = dayjs().add(15, 'day').format('YYYY-MM-DD HH:mm:ss');

        const appointmentDate = data.appointmentDate ? dayjs(data.appointmentDate).format('YYYY-MM-DD HH:mm:ss') : null;
        const nextFollowupDate = data.nextFollowupDate ? dayjs(data.nextFollowupDate).format('YYYY-MM-DD HH:mm:ss') : null;

        let statusFlag;
        switch (data.appointmentStatus) {
            case 'Inprogress':
                statusFlag = 2;
                break;
            case 'Completed':
                statusFlag = 3;
                break;
            case 'Open':
                statusFlag = 1;
                break;
            case 'Cancelled':
                statusFlag = 4;
                break;
            default:
                statusFlag = null;
        }

        const formData = {
            ...data,
            customeId: VehicleIdData.customeId,
            vehicleId: VehicleIdData.vehicleId,
            customerStatus: VehicleIdData.customerStatus,
            customerState: selectedState,
            customerCity: selectedCity,
            pincode: data.pincode,
            makeId: selectedMake?.id || null,
            modelId: selectedModel?.id || null,
            dispositionId: data.dispositionId.id,
            status: data.appointmentStatus,
            dmsSourceId: data?.sourceId,
            dmsSourceTypeId: data?.sourceTypeId,
            serviceType: data.serviceType?.value,
            scheduledStartDate: currentDate,
            scheduledEndDate: futureDate,
            appointmentDate,
            nextFollowupDate,
            statusFlag
        };
        delete formData.appointmentStatus;

        let response;
        if (isEditing) {
            response = await editServiceBookingData({ id: currentItemId, ...formData });
            if (response && response.data.requestSuccessful === true) {
                setIsEditing(false);
                setCurrentItemId(null);
            }
        } else {
            response = await addSeriveBookingData(formData);
        }
        if (response && response.data) {
            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                resetForm();
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                resetForm();
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };

    const resetForm = () => {
        reset({
            appointmentDate: null,
            nextFollowupDate: null,
            registrationNumber: "",
            odometer: "",
            customerName: "",
            customerAddress: "",
            customerMobileNumber: "",
            pincode: "",
            source: "",
        });

        setIsEditing(false);
        setSelectedCity("")
        setSelectedState("")
        setSelectedMake(null);
        setSelectedModel(null);
        setCurrentTab(1);
        setSelectedSourceType(null)
        setSelectedSource(null)
    };
    const handlePincodeChange = async (event) => {
        let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
        if (pincode.length === 6) {
            const payLoad = {
                pinCode: pincode,
            };
            const response = await getAllPincodeData(payLoad);
            if (response?.data?.requestSuccessful) {
                setSelectedState(response.data.pincodeData.state);
                setSelectedCity(response.data.pincodeData.city);
            }
        }
    };

    const serviceTypeOptions = [
        { label: 'Service', value: 'Service' },
        { label: 'Scheme and Offers', value: 'Scheme and Offers' },
        { label: 'Accidental Service', value: 'Accidental Service' }
    ];


    const handleEditClick = async (row) => {
        setSelectedRowData(row)
        setValue('registrationNumber', row.registrationNumber);
        setValue('odometer', row.odometer);
        setValue('phoneCallNotes', row.phoneCallNotes);
        setValue('source', row.source);
        setValue('customerMobileNumber', row.customerMobileNumber);
        setValue('customerName', row.customerName);
        setValue('customerAddress', row.customerAddress);
        setValue('pincode', row.pincode);
        setValue('serviceType', row.serviceType);
        setValue('appointmentDate', row.appointmentDate ? dayjs(row.appointmentDate) : null);
        setValue('nextFollowupDate', row.nextFollowupDate ? dayjs(row.nextFollowupDate) : null);
        setSelectedCity(row.customerCity);
        setSelectedState(row.customerState);

        const selectedSourceData = sources?.find(source => source.id === row.dmsSourceId);
        setSelectedSource(selectedSourceData)
        setValue('sourceId', selectedSourceData || null);

        if (selectedSourceData) {
            setSelectedSourceType(selectedSourceData);
            setValue('sourceId', selectedSourceData.id);

            const payload = { sourceId: selectedSourceData.id };
            const sourceResponse = await submitSource(payload);

            if (sourceResponse?.data?.requestSuccessful) {
                const sourceType = sourceResponse?.data?.sourceTypes;
                const selectedSourceType = sourceType?.find(sourceType => sourceType.id === row.dmsSourceTypeId);

                if (selectedSourceType) {
                    setSelectedSourceType(selectedSourceType);
                    setValue('sourceTypeId', selectedSourceType.id);
                }
            } else {
                console.log('Failed to fetch model data');
            }
        }

        const selectedDisposition = disposition.find(d => d.title === row.disposition) || null;
        setValue("dispositionId", selectedDisposition)

        const selectedServiceType = serviceTypeOptions.find(d => d.label === row.serviceType) || null;
        setValue('serviceType', selectedServiceType);

        const selectedMake = makes?.find(make => make.makeName === row.make);
        setValue('makeId', selectedMake || null);
        setSelectedMake(selectedMake);
        if (selectedMake) {
            setSelectedMake(selectedMake);
            setValue('makeId', selectedMake.id);

            const payload = { makeId: selectedMake.id };
            const modelResponse = await getModalData(payload);

            if (modelResponse?.data?.requestSuccessful) {
                const models = modelResponse.data.ModelData;
                const selectedModel = models?.find(model => model?.id === row.model);

                if (selectedModel) {
                    setSelectedModel(selectedModel);
                    setValue('modelId', selectedModel.id);
                }
            } else {
                console.log('Failed to fetch model data');
            }
        }

        setIsEditing(true);
        setCurrentTab(0);
        setCurrentItemId(row.id);
        clearErrors(['registrationNumber']);
    };

    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };
    const columns = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        const hasUpdatePermission = buttonList.split(',').includes("3");
        const baseColumns = [
            {
                field: 'serviceBookingNumber',
                headerName: 'Booking Number ',
                flex: 1,
            },
            {
                field: 'customerName',
                headerName: 'Customer Name ',
                flex: 1,
            },

            {
                field: 'customerMobileNumber',
                headerName: 'Customer Mobile Number',
                flex: 1,
            },

            {
                field: 'registrationNumber',
                headerName: 'Registration Number ',
                flex: 1,
            },
            {
                field: 'make',
                headerName: 'Make ',
                flex: 1,
            },
            {
                field: 'model',
                headerName: 'Model ',
                flex: 1,
            },
            {
                field: 'disposition',
                headerName: 'Disposition ',
                flex: 1,
            },
            {
                field: 'status',
                headerName: 'Appointment Status ',
                flex: 1,
                renderCell: (params) => {
                    let background = '';
                    let statusText;

                    switch (params.value) {
                        case "Inprogress":
                            statusText = 'In Progress';
                            background = '#e7c018';
                            break;
                        case "Completed":
                            statusText = 'Completed';
                            background = '#1b8c0a';
                            break;
                        case "Cancelled":
                            statusText = 'Cancelled';
                            background = '#ff0000';
                            break;

                        default:
                            statusText = '-';
                            background = 'gray';
                    }

                    return (
                        <Chip
                            label={
                                <>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '8px',
                                            color: "#ffffd6 !important"
                                        }}
                                    />
                                    {statusText}
                                </>
                            }
                            style={{
                                textTransform: 'capitalize',
                                backgroundColor: background,
                                color: '#fff', // Ensure text contrast is readable
                                width: "150px",
                            }}
                        />
                    );
                },
            },


        ];

        if (hasUpdatePermission) {
            baseColumns.push({
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    <>
                        {
                            params?.row?.status === "Completed" ? "" :
                                <Tooltip title="Edit">
                                    <><MdEdit onClick={() => handleEditClick(params.row)} style={{ cursor: 'pointer', marginRight: '10px' }} /> </>
                                </Tooltip>
                        }
                    </>
                ),
                cellClassName: 'sticky-action-column',
                headerClassName: 'sticky-action-column-header',
            });
        }

        return baseColumns;
    }, [menuList, pathname]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };

    const handleRegisterNumberChange = async (event) => {
        const regNumber = event.target.value.toUpperCase().slice(0, 10);
        setValue('registrationNumber', regNumber);

        if (regNumber.length === 10) {
            const payLoad = {
                registrationNumber: regNumber
            };
            const response = await getAllVehicleData(payLoad);

            if (response?.data?.requestSuccessful) {
                const vehicleData = response.data.vehicleData[0];
                const CustomerMessage = vehicleData?.message
                setVehicleIdData(vehicleData);
                const selectedMake = makes?.find(make => make?.id === vehicleData?.makeId);

                if (selectedMake) {
                    setSelectedMake(selectedMake);
                    setValue('makeId', selectedMake.id);

                    const payload = { makeId: vehicleData.makeId };
                    const modelResponse = await getModalData(payload);

                    if (modelResponse?.data?.requestSuccessful) {
                        const models = modelResponse.data.ModelData;
                        const selectedModel = models?.find(model => model?.id === vehicleData?.modelId);

                        if (selectedModel) {
                            setSelectedModel(selectedModel);
                            setValue('modelId', selectedModel.id);
                        }
                    } else {
                        console.log('Failed to fetch model data');
                    }
                } else {
                    setSelectedMake(null);
                    setSelectedModel(null);
                    setValue('makeId', "");
                    setValue('modelId', "");
                }
                if (vehicleData.customerName && vehicleData.customerMobileNumber && vehicleData.customerAddress) {
                    setValue('customerName', vehicleData.customerName);
                    setCustomerName(vehicleData.customerName);

                    setValue('customerMobileNumber', vehicleData.customerMobileNumber);
                    setcustomerMobileNumber(vehicleData.customerMobileNumber);

                    setValue('customerAddress', vehicleData.customerAddress);
                    setCustomerAddress(vehicleData.customerAddress);
                } else {
                    setValue('customerName', "");
                    setCustomerName("");

                    setValue('customerMobileNumber', "");
                    setcustomerMobileNumber("");

                    setValue('customerAddress', "");
                    setCustomerAddress("");
                }

                // Handle pincode, state, and city
                if (vehicleData.pincode) {
                    setPincodeData(vehicleData.pincode);
                    setValue('pincode', vehicleData.pincode);
                    setSelectedState(vehicleData.customerState);
                    setSelectedCity(vehicleData.customerCity);
                } else {
                    setPincodeData("");
                    setValue('pincode', "");
                    setSelectedState("");
                    setSelectedCity("");
                }

                // Check if the customer is an existing customer
                if (CustomerMessage === "Existing customer") {
                    setCustomerFieldsReadOnly(true);
                } else {
                    setCustomerFieldsReadOnly(false);
                }

                // Show success message
                setDataStatusConfirm("Success");
                setSnackBarMessage(vehicleData?.message);
            } else {
                // Handle error message
                setDataStatusConfirm("Error");
                setSnackBarMessage("Vehicle data could not be found");
            }

            // Show snackbar message
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 5000);
        }
    };

    const setCustomerFieldsReadOnly = (isReadOnly) => {
        setCustomerNameReadOnly(isReadOnly);
        setCustomerAddressReadOnly(isReadOnly);
        setMobileNoReadOnly(isReadOnly);
        setMakeDisabled(isReadOnly);
        setModelDisabled(isReadOnly);
        setPincodeReadOnly(isReadOnly)
    };

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            setIsCreating(true);
        } else {
            setIsCreating(false);
            resetForm();
        }
        setCurrentTab(newValue);
    };

    const hasCreatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("1");
    }, [menuList, pathname]);

    const hasUpdatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("3");
    }, [menuList, pathname]);

    const Cancelled = () => {
        reset({
            appointmentDate: null,
            nextFollowupDate: null,
            registrationNumber: "",
            odometer: "",
            customerName: "",
            customerAddress: "",
            customerMobileNumber: "",
            pincode: "",
            source: "",

        });
        setSelectedCity("")
        setSelectedState("")
        setSelectedMake(null);
        setSelectedModel(null);
        setSelectedSourceType(null)
        setSelectedSource(null)
        setIsEditing(false);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <StyledBreadcrumb
                    mainDiv="Transaction"
                    subDiv="Service Booking"
                ></StyledBreadcrumb>

                <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
                        <Tabs value={currentTab} onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            sx={{
                              '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
                              maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
                              overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
                            }}
                        >
                            {hasCreatePermission ? (
                                <Tab label="Create Service Booking" />
                            ) : (isEditing && !hasCreatePermission) ? (
                                <Tab label="Update Service Booking" />
                            ) : null}
                            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Service Booking" />)}

                        </Tabs>
                    </Box>
                    {currentTab === 0 && (
                        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            {...register('registrationNumber', {
                                                required: 'Registration Number is required',
                                                maxLength: {
                                                    value: 10,
                                                    message: 'Registration Number should not exceed 10 characters'
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9]*$/,
                                                    message: 'Registration Number should only contain uppercase letters and numbers'
                                                }
                                            })}
                                            fullWidth
                                            variant="standard"
                                            label={<span>Registration Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.registrationNumber}
                                            helperText={errors.registrationNumber ? errors.registrationNumber.message : ''}
                                            onInput={handleRegisterNumberChange}
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.toUpperCase().slice(0, 10);
                                            }}


                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controller
                                            name="makeId"
                                            control={control}
                                            rules={{ required: 'Make is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={makes}
                                                    getOptionLabel={(option) => option.makeName}
                                                    disabled={isEditing || makeDisabled}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={<span>Make <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                            variant="standard"
                                                            error={!!errors.makeId}
                                                            helperText={errors.makeId ? errors.makeId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedMake}
                                                    onChange={(event, value, reason) => {
                                                        if (value !== selectedMake) {
                                                            setSelectedModel(null);
                                                            setValue('modelId', null);
                                                        }
                                                        setSelectedMake(value);
                                                        field.onChange(value ? value.id : null);

                                                        if (value) {
                                                            const payload = { makeId: value.id };
                                                            getModalData(payload);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controller
                                            name="modelId"
                                            control={control}
                                            rules={{ required: 'Model is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={model}
                                                    disabled={isEditing || modelDisabled}
                                                    getOptionLabel={(option) => option.modelName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={<span>Model <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                            variant="standard"
                                                            error={!!errors.modelId}
                                                            helperText={errors.modelId ? errors.modelId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedModel}
                                                    onChange={(event, value) => {
                                                        setSelectedModel(value);
                                                        field.onChange(value ? value.id : null);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField {...register('odometer', { required: 'odometer is required' })}
                                            fullWidth variant="standard"
                                            label={<span>Odometer <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            type='number'
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.odometer}
                                            helperText={errors.odometer ? errors.odometer.message : ''}
                                            onInput={handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField {...register('customerName', { required: 'Customer Name is required' })}
                                            fullWidth variant="standard"
                                            label={<span>Customer Name <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            InputLabelProps={(isEditing || customerNameData) ? { shrink: true } : {}}
                                            error={!!errors.customerName}
                                            helperText={errors.customerName ? errors.customerName.message : ''}
                                            InputProps={{ readOnly: isEditing || customerNameReadOnly }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField {...register('customerAddress', { required: 'Customer Address is required' })}
                                            fullWidth variant="standard"
                                            label={<span>Customer Address <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            InputLabelProps={(isEditing || customerAddressData) ? { shrink: true } : {}}
                                            error={!!errors.customerAddress}
                                            helperText={errors.customerAddress ? errors.customerAddress.message : ''}
                                            InputProps={{ readOnly: isEditing || customerAddressReadOnly }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            {...register('customerMobileNumber', {
                                                required: 'Mobile Number is required',
                                                validate: (value) =>
                                                    value.length === 10 || 'Mobile Number must be exactly 10 digits'
                                            })}
                                            fullWidth
                                            variant="standard"
                                            label={<span>Mobile Number <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            error={!!errors.customerMobileNumber}
                                            type="number"
                                            InputProps={{ readOnly: isEditing || mobileNoReadOnly }}
                                            InputLabelProps={(isEditing || customerMobileNumberData) ? { shrink: true } : {}}
                                            helperText={errors.customerMobileNumber ? errors.customerMobileNumber.message : ''}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (value.length > 10) {
                                                    e.target.value = value.slice(0, 10);
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 10,
                                                min: 0,
                                                pattern: "[0-9]*",
                                                inputMode: "numeric"
                                            }}
                                            onBlur={() => {
                                                trigger('customerMobileNumber'); // Trigger validation on blur
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            {...register('pincode', {
                                                required: 'Pincode is required',
                                                minLength: { value: 6, message: 'Pincode must be 6 digits' },
                                                maxLength: { value: 6, message: 'Pincode must be 6 digits' }
                                            })}
                                            fullWidth
                                            variant="standard"
                                            type="tel"
                                            label={<span>Pincode <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            error={!!errors.pincode}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                            InputLabelProps={(isEditing || pincodeData) ? { shrink: true } : {}}
                                            helperText={errors.pincode ? errors.pincode.message : ''}
                                            onChange={handlePincodeChange}
                                            InputProps={{ readOnly: isEditing || pincodeReadOnly }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="State"
                                            value={selectedState}
                                            InputLabelProps={selectedState ? { shrink: true } : {}}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="City"
                                            value={selectedCity}
                                            InputLabelProps={selectedCity ? { shrink: true } : {}}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField {...register('source', { required: 'source is required' })}
                                            fullWidth variant="standard"
                                            label={<span>Source <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.source}
                                            helperText={errors.source ? errors.source.message : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controller
                                            name="sourceId"
                                            control={control}
                                            rules={{ required: 'sourceId is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={sources}
                                                    getOptionLabel={(option) => option.sourceName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={<span>DmsSource <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                            variant="standard"
                                                            error={!!errors.sourceId}
                                                            helperText={errors.sourceId ? errors.sourceId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedSource}
                                                    onChange={(event, value, reason) => {
                                                        if (value !== selectedSource) {
                                                            setSelectedSourceType(null);
                                                            setValue('sourceTypeId', null);
                                                        }
                                                        setSelectedSource(value);
                                                        field.onChange(value ? value.id : null);

                                                        if (value) {
                                                            const payload = { sourceId: value.id };
                                                            submitSource(payload);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controller
                                            name="sourceTypeId"
                                            control={control}
                                            rules={{ required: ' Source Type is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={sourceType}
                                                    getOptionLabel={(option) => option.sourceTypeName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={<span>DmsSource Type <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                            variant="standard"
                                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                                            error={!!errors.sourceTypeId}
                                                            helperText={errors.sourceTypeId ? errors.sourceTypeId.message : ''}
                                                        />
                                                    )}
                                                    value={selectedSourceType}
                                                    onChange={(event, value) => {
                                                        setSelectedSourceType(value);
                                                        field.onChange(value ? value.id : null);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ margin: "20px 0px", fontWeight: "700", fontSize: "18px", color: '#464B7C' }}>Lead Management</Typography>
                                        <Divider sx={{ mb: .8 }}></Divider>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Scheduled Start Date"
                                            value={currentDate}
                                            InputProps={{
                                                readOnly: true,
                                                sx: {
                                                    backgroundColor: '#eeeeee',
                                                },
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Scheduled End Date"
                                            value={futureDate}
                                            InputProps={{
                                                readOnly: true,
                                                sx: {
                                                    backgroundColor: '#eeeeee',
                                                },
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Appointment Status"
                                            {...register('appointmentStatus')}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{ shrink: !!watch('appointmentStatus') }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controller
                                            name="dispositionId"
                                            control={control}
                                            rules={{ required: 'Disposition is required' }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={disposition || []}
                                                    getOptionLabel={(option) => option.title || ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={<span>Select Disposition <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                            variant="standard"
                                                            error={!!errors.dispositionId}
                                                            helperText={errors.dispositionId ? errors.dispositionId.message : ''}
                                                        />
                                                    )}
                                                    value={field.value || null}
                                                    onChange={(event, value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {watchDisposition?.title === 'Appointment Booked' && (
                                        <>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Controller
                                                    name="appointmentDate"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DateTimePicker
                                                            label="Appointment Date"
                                                            open={dateOpen}
                                                            onClose={() => setDateOpen(false)}
                                                            slotProps={{
                                                                textField: {
                                                                    variant: 'standard',
                                                                    fullWidth: true,
                                                                    readOnly: true,
                                                                    onClick: () => setDateOpen(true),
                                                                },
                                                                openPickerButton: {
                                                                    onClick: () => setDateOpen(true)
                                                                }
                                                            }}
                                                            format='DD/MM/YYYY HH:mm A'
                                                            renderInput={(params) => <TextField {...params} variant="standard" />}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Controller
                                                    name="serviceType"
                                                    control={control}
                                                    rules={{ required: 'Service Type is required' }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            options={serviceTypeOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={<span>Service Type <span style={{ color: '#D32F2F' }}>*</span></span>}
                                                                    variant="standard"
                                                                    error={!!errors.serviceType}
                                                                    helperText={errors.serviceType ? errors.serviceType.message : ''}
                                                                />
                                                            )}
                                                            value={field.value || null}
                                                            onChange={(event, value) => field.onChange(value)}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    {watchDisposition?.title === 'Appointment Rescheduled' && (
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controller
                                                name="nextFollowupDate"
                                                control={control}
                                                render={({ field }) => (
                                                    <DateTimePicker
                                                        label="Next Follow-up Date"
                                                        open={dateOpen1}
                                                        onClose={() => setDateOpen1(false)}
                                                        slotProps={{
                                                            textField: {
                                                                variant: 'standard',
                                                                fullWidth: true,
                                                                readOnly: true,
                                                                onClick: () => setDateOpen1(true),
                                                            },
                                                            openPickerButton: {
                                                                onClick: () => setDateOpen1(true)
                                                            }
                                                        }}
                                                        format='DD/MM/YYYY HH:mm A'
                                                        renderInput={(params) => <TextField {...params} variant="standard" />}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}


                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField {...register('phoneCallNotes', { required: 'Phone Call Notes is required' })}
                                            fullWidth variant="standard"
                                            label={<span>Phone Call Notes <span style={{ color: '#D32F2F' }}>*</span></span>}
                                            multiline
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            error={!!errors.phoneCallNotes}
                                            helperText={errors.phoneCallNotes ? errors.phoneCallNotes.message : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                                <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{ background: "red !important" }} >
                                                    Cancel
                                                </SoftButton>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                                <SoftButton type="submit" variant="contained" color="primary" >
                                                    {isEditing ? 'Update' : 'Add'}
                                                </SoftButton>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>


                            </form>
                        </StyledPaperShadow>
                    )}
                    {currentTab === 1 && (
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                    flexWrap: "wrap",
                                    rowGap: "10px",
                                    columnGap: "30px",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column",
                                        columnGap: "10px",
                                    }
                                }}
                            >

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: "10px",
                                        backgroundColor: "#11164b",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        width: "auto",
                                        '@media (max-width: 700px)': {
                                            flexDirection: "column",
                                            columnGap: "5px",
                                            padding: "5px",
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                        <Typography
                                            sx={{
                                                alignSelf: "center",
                                                color: "#d9d9d9 !important",
                                                fontSize: "1rem",
                                                '@media (max-width: 700px)': {
                                                    fontSize: "0.8rem",
                                                },
                                            }}
                                        >
                                            Total Records: {ServiceEstimateDatagrid?.ServiceBookingData?.totalItems}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            columnGap: "1px",
                                            backgroundColor: "#ffffff",
                                            paddingLeft: "10px",
                                            '@media (max-width: 700px)': {
                                                flexDirection: "column",
                                                paddingLeft: "5px",
                                                width: "100%",
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                alignSelf: "center",
                                                fontSize: "1rem",
                                                '@media (max-width: 700px)': {
                                                    fontSize: "0.8rem",
                                                },
                                            }}
                                        >
                                            Show:
                                        </Typography>
                                        <Select
                                            value={limit}
                                            onChange={handleLimitChange}
                                            sx={{
                                                marginLeft: 1,
                                                '& .MuiSelect-select': {
                                                    paddingTop: "1px !important",
                                                    paddingBottom: "1px !important",
                                                },
                                                '@media (max-width: 700px)': {
                                                    marginLeft: 0,
                                                    width: "100%",
                                                },
                                            }}
                                        >
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={75}>75</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        width: "auto",
                                        '@media (max-width: 700px)': {
                                            width: "100%",
                                            marginTop: "10px",
                                        },
                                    }}
                                >
                                    <TextField
                                        variant="standard"
                                        label="Search"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={handleSearchClick}>
                                                        <IoSearchSharp />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            width: "auto",
                                            '@media (max-width: 700px)': {
                                                width: "100%",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>

                            <ItemGroupDatagrid data={ServiceEstimateDatagrid?.ServiceBookingData?.data || []} columns={columns} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                                <ToggleButtonGroup
                                    exclusive
                                    sx={{
                                        display: 'flex', alignItems: 'center',

                                        '& .MuiToggleButtonGroup-middleButton': {
                                            marginRight: "0px !important",
                                            borderLeft: "1px solid #e5e5e5"
                                        },
                                        '& .MuiToggleButtonGroup-root': {
                                            border: "1px solid #e5e5e5",
                                        },
                                    }}
                                >
                                    <ToggleButton
                                        value="previous"
                                        onClick={handlePrevious}
                                        disabled={offset === 0}
                                    >
                                        Previous
                                    </ToggleButton>

                                    {Array.from({ length: totalPages }, (_, index) => {
                                        const page = index + 1;
                                        const isFirstThreePages = page <= 3;
                                        const isLastThreePages = page > totalPages - 3;
                                        const isCurrentPage = offset / limit === index;

                                        if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                            return (
                                                <ToggleButton
                                                    key={page}
                                                    value={`page-${page}`}
                                                    onClick={() => handlePageClick(page)}
                                                    sx={{ margin: '0 5px' }}
                                                    selected={isCurrentPage}
                                                >
                                                    {page}
                                                </ToggleButton>
                                            );
                                        }

                                        if (page === 4 && !isFirstThreePages) {
                                            return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                        }

                                        if (page === totalPages - 3 && !isLastThreePages) {
                                            return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                        }

                                        return null;
                                    })}
                                    <ToggleButton
                                        value="next"
                                        onClick={handleNext}
                                        disabled={offset + limit >= totalItems}
                                    >
                                        Next
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>

                        </Box>

                    )}
                    {open && (
                        <StyledSnackBar
                            open={true}
                            message={snackBarMessage}
                            status={dataStatusConfirm}
                        />
                    )}
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={backDropOpen}
                    >
                        <ScaleLoader color="#11164b" />
                    </Backdrop>
                </StyledPaperShadow>

            </Box>
        </LocalizationProvider>
    )
}
export default ServiceBooking