import { Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdDelete, MdEdit } from 'react-icons/md';
import { useDeletePartNameMutation, useEditPartNameMutation, useGetPartNameDataMutation, useGetPartNameDatagridMutation, } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const ClickinPartName = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues: {
      clickinPartName: "",
      status: 0,
    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addPartNameData, { isSuccess: PartNameDataSuccess }] = useGetPartNameDataMutation();
  const [getPartNameGrid, { data: PartNameGrid, }] = useGetPartNameDatagridMutation();
  const [editPartNameData, { isSuccess: editPartNameSuccess }] = useEditPartNameMutation();
  const [deletePartNameData, { isSuccess: deletePartNameSuccess }] = useDeletePartNameMutation();

  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'CLICKINS_NAME',
        headerName: 'Clickin PartName',
        flex: 1,
      },
      {
        field: 'PANEL_NAME',
        headerName: 'Panel Name',
        flex: 1,
      },
      {
        field: 'STATUS',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditClick(params.row)}>
                <MdEdit style={{ fontSize: '1.1rem', cursor: 'pointer', color: '#5d6294' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="delete">
              <IconButton
                onClick={() => handleDeleteClick(params.row)}
                style={{ padding: '5px', fontSize: '1.1rem', color: 'red' }}
                sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
              >
                <MdDelete />
              </IconButton>
            </Tooltip>
          </>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, PartNameDataSuccess, editPartNameSuccess, deletePartNameSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey
    };
    const response = await getPartNameGrid(payload);
    setTotalItems(response?.data?.PartsData?.totalItems);
  };
  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
    };

    let response;
    if (isEditing) {
      response = await editPartNameData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
      }
    } else {
      response = await addPartNameData(formData);
    }

    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        reset();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        reset();
        setCurrentItemId(null);
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);

  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };

  const handleEditClick = (row) => {
    setValue('clickinPartName', row.CLICKINS_NAME);
    setValue('panelName', row.PANEL_NAME);


    setStatus(row.STATUS ? 1 : 0);
    setIsEditing(true);
    setCurrentItemId(row.ID);
    clearErrors(['clickinPartName', 'panelName']);
  };

  const handleDeleteClick = async (row) => {
    const payload = {
      id: row.ID
    }
    let response;
    response = await deletePartNameData(payload)
    if (response && response.data) {
      setDataStatusConfirm("Success");
      setSnackBarMessage(response?.data?.message);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);


  const Cancelled = () => {
    reset({
      clickinPartName: "",
      panelName: "",
    })
    setStatus(1)
    setIsEditing(false);
  }

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Fit Master"
        subDiv="Clickin PartName"
      ></StyledBreadcrumb>

      {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (
        <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('clickinPartName', { required: 'Clickin PartName is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label="Clickin PartName"
                  error={!!errors.clickinPartName}
                  helperText={errors.clickinPartName ? errors.clickinPartName.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  {...register('panelName', { required: 'Panel Name is required' })}
                  InputLabelProps={isEditing ? { shrink: true } : {}}
                  fullWidth variant="standard"
                  label="Panel Name"
                  error={!!errors.panelName}
                  helperText={errors.panelName ? errors.panelName.message : ''}
                  onInput={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                  <Box>
                    <FormLabel>Status</FormLabel>
                  </Box>
                  <Box>
                    <Switch checked={status === 1} onChange={handleStatusChange} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton onClick={() => Cancelled()} variant="contained" color="primary" sx={{ background: "red !important" }} >
                      Cancel
                    </SoftButton>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton type="submit" variant="contained" color="primary" >
                      {isEditing ? 'Update' : 'Submit'}
                    </SoftButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </StyledPaperShadow>
      )}
      <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", backgroundColor: "#11164b", borderRadius: "5px" }}>
              <Box sx={{ alignSelf: "center", margin: "5px" }}>
                <Typography sx={{ alignSelf: "center", color: "#d9d9d9 !important" }}>Total Records : {PartNameGrid?.PartsData?.totalItems}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", columnGap: "1px", backgroundColor: "#ffffff", paddingLeft: "10px" }}>
                <Typography sx={{ alignSelf: "center" }}>Show:</Typography>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  sx={{
                    marginLeft: 1,

                    '& .MuiSelect-select': {
                      paddingTop: "1px !important",
                      paddingBottom: "1px !important"
                    },
                  }}

                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Box>
            </Box>
            <TextField
              variant="standard"
              label="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchClick}>
                      <IoSearchSharp />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </Box>
          <ItemGroupDatagrid data={PartNameGrid?.PartsData?.data || []} columns={columns} />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
            <ToggleButtonGroup
              exclusive
              sx={{
                display: 'flex', alignItems: 'center',

                '& .MuiToggleButtonGroup-middleButton': {
                  marginRight: "0px !important",
                  borderLeft: "1px solid #e5e5e5"
                },
                '& .MuiToggleButtonGroup-root': {
                  border: "1px solid #e5e5e5",
                },
              }}
            >
              <ToggleButton
                value="previous"
                onClick={handlePrevious}
                disabled={offset === 0}
              >
                Previous
              </ToggleButton>

              {Array.from({ length: totalPages }, (_, index) => {
                const page = index + 1;
                const isFirstThreePages = page <= 3;
                const isLastThreePages = page > totalPages - 3;
                const isCurrentPage = offset / limit === index;

                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                  return (
                    <ToggleButton
                      key={page}
                      value={`page-${page}`}
                      onClick={() => handlePageClick(page)}
                      sx={{ margin: '0 5px' }}
                      selected={isCurrentPage}
                    >
                      {page}
                    </ToggleButton>
                  );
                }

                if (page === 4 && !isFirstThreePages) {
                  return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                }

                if (page === totalPages - 3 && !isLastThreePages) {
                  return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                }

                return null;
              })}
              <ToggleButton
                value="next"
                onClick={handleNext}
                disabled={offset + limit >= totalItems}
              >
                Next
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

        </Box>

      </StyledPaperShadow>
      {open && (
        <StyledSnackBar
          open={true}
          message={snackBarMessage}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
};


export default ClickinPartName