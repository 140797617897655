import { Breadcrumbs, Stack, Typography, } from "@mui/material";
import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";

const StyledBreadcrumb = ({ mainDiv, subDiv}) => {
  return (
    <Stack direction="row" alignItems="center" sx={{marginBottom:"10px",marginLeft:"5px"}}>
        <Breadcrumbs aria-label="breadcrumb" separator={<HiArrowLongRight />} >
          <Typography variant="body1" sx={{fontWeight:"700",color: '#464B7C',fontSize: 20,}}>{mainDiv}</Typography>
          <Typography variant="body1" sx={{fontWeight:"700",color: '#464B7C',fontSize: 20,}}>{subDiv}</Typography>
        </Breadcrumbs>
        
      </Stack>
  )
}

export default StyledBreadcrumb;