import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const ItemGroupDatagrid = ({ data, columns }) => {
  const rows = data.map((row, index) => ({ id: index, ...row }));

  return (
    <Box sx={{ marginTop: '30px', display: 'inline-block', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        getRowId={(row) => row.id}
        pagination
        editable={false}
        sx={{
         '& .MuiDataGrid-cell': {
            fontSize: '12px',
             fontWeight:'500'
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            fontSize: '14px',
            fontWeight:'700'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            outline: 'none',
          },
          '& .MuiDataGrid-row.Mui-selected:focus-within': {
            outline: 'none',
          },
          '& .MuiTablePagination-toolbar': {
            display: 'none',
          },
          // Sticky column styling
          '& .sticky-action-column': {
            position: 'sticky',
            right: 0,
            backgroundColor: 'white',
            zIndex: 1,
          },
          '& .sticky-action-column-header': {
            position: 'sticky',
            right: 0,
            backgroundColor: '#f6f6f6',
            zIndex: 2,
          },
          '& .MuiDataGrid-columnHeaders': {
            position: 'sticky',
            top: 0,
            zIndex: 3,
            backgroundColor: '#f6f6f6',
          },
           // Apply horizontal scrolling for screen widths <= 1920px
           '@media (max-width: 1920px)': {
            '& .MuiDataGrid-root': {
              overflowX: 'auto',
            },
          },
          
        }}
      />
    </Box>
  );
};

export default ItemGroupDatagrid;
