import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import CashBill from "../bill/CashBill";
import CreditBill from "../bill/CreditBill";
const tabComponents = {
    "CashBill": CashBill,
    "CreditBill": CreditBill,
  };

const BillTab = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [menuTabs, setMenuTabs] = useState([]);
  
    useEffect(() => {
      const menuList = JSON.parse(localStorage.getItem("menuList"));
      const mastersMenu = menuList.find(menu => menu.title === "Transactions");
      const itemSourceMenu = mastersMenu?.submenu.find(sub => sub.title === "Bill");
      setMenuTabs(itemSourceMenu?.menuTab || []);
    }, []);
  
    const handleChange = (event, newValue) => {
      setActiveTab(newValue);
    };

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Transaction"
        subDiv="Credit Bill / Cash Bill"
      ></StyledBreadcrumb>
      <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
        <Tabs value={activeTab} onChange={handleChange}
          sx={{ '& .MuiTabs-indicator': { backgroundColor: "#e96b20" } }}
        >
          {menuTabs.map((tab, index) => (
            <Tab key={index} label={tab.tab_name} />
          ))}
        </Tabs>
        {menuTabs.map((tab, index) => {
          const Component = tabComponents[tab.tab_name];
          return (
            activeTab === index && (
              <Box key={index} sx={{ p: 3 }}>
                <Component buttons={tab.buttons} />
              </Box>
            )
          );
        })}
      </StyledPaperShadow>
    </Box>
  );
}
export default BillTab