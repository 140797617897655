import { ErrorBoundary } from 'react-error-boundary';
import './App.css';
import RouterView from "./Route/RouterView";
import React, { useEffect } from 'react';

function App() {

  // useEffect(() => {
  //   document.addEventListener('contextmenu', (event) => {
  //     event.preventDefault();
  //   });
  //   const disableDevTools = (event) => {
  //     if (
  //       event.keyCode === 123 || // F12
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 67) || // Ctrl+Shift+C
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 74) // Ctrl+Shift+J
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener('keydown', disableDevTools);
  //   return () => {
  //     document.removeEventListener('contextmenu', (event) => {
  //       event.preventDefault();
  //     });
  //     document.removeEventListener('keydown', disableDevTools);
  //   };
  // }, []);

  useEffect(() => {
    const setZoomLevel = () => {
      if (window.innerWidth <= 1466 && window.innerHeight <= 768) {
        document.body.style.zoom = '90%';
      } else {
        document.body.style.zoom = '100%';
      }
    };

    setZoomLevel();

    window.addEventListener('resize', setZoomLevel);
    return () => window.removeEventListener('resize', setZoomLevel);
  }, []);
  return (
    <ErrorBoundary>
      <RouterView />
    </ErrorBoundary>
  );
}

export default App;
