import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit } from 'react-icons/md';
import { useEditVendorMutation, useGetAllPincodeMutation, useGetCompanyQuery, useGetVendorDatagridMutation, useGetVendorDataMutation, useItemGroupQuery } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const Vendor = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
    defaultValues: {
      vendorCode: "",
      vendorName: "",
      gstin: "",
      panNumber: "",
      address1: "",
      address2: "",
      mobileNumber: "",
      contactPerson: "",
      contactPersonMobileNo: "",
      vendorType: "",
      marginPercentage: "",
      status: 0,
    }
  });

  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const pathname = window.location.pathname;

  const [addVendorData, { isSuccess: VendorSuccess }] = useGetVendorDataMutation();
  const [getVendorDatagrid, { data: VendorDatagrid }] = useGetVendorDatagridMutation();
  const [editVendorData, { isSuccess: editVendorSuccess }] = useEditVendorMutation();
  const [getAllPincodeData, { data: AllPincode }] = useGetAllPincodeMutation();


  const { data: itemCompanyData, isLoading: itemCompanyLoading } = useGetCompanyQuery();
  const { data: itemGroupsData, isLoading: itemGroupsLoading } = useItemGroupQuery();

  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [isCreating, setIsCreating] = useState(true);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedArea, setSelectedArea] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const companies = itemCompanyData?.companyData || [];
  const itemGroups = itemGroupsData?.ItemGroupData || [];
  const AreaNames = AllPincode?.pincodeData?.areaNames || []

  useEffect(() => {
    fetchItems(offset, searchValue);
  }, [offset, limit, VendorSuccess, editVendorSuccess]);

  const fetchItems = async (newOffset, searchKey) => {
    const payload = {
      limit,
      offset: newOffset,
      searchKey
    };
    const response = await getVendorDatagrid(payload);
    setTotalItems(response?.data?.vendorData?.totalItems);
  };

  const handleSearchClick = () => {
    setOffset(0);
    fetchItems(0, searchValue);
  };

  const totalPages = Math.ceil(totalItems / limit);
  const handlePageClick = (page) => {
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setOffset(0); // Reset offset when changing the limit
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      status: status ? 1 : 0,
      companyId: data?.companyId,
      state: selectedState,
      city: selectedCity,
      pincode: data.pincode,
      areaName: selectedArea ? selectedArea.area : '',
      itemGroupId: data?.itemGroupId

    };

    let response;
    if (isEditing) {
      response = await editVendorData({ id: currentItemId, ...formData });
      if (response && response.data.requestSuccessful === true) {
        setIsEditing(false);
        setCurrentItemId(null);
      }
    } else {
      response = await addVendorData(formData);
    }
    if (response && response.data) {
      let message;
      if (response.data.validationErrors) {
        message = response.data.validationErrors;
        setDataStatusConfirm("Error");
      } else {
        message = response.data.message;
        setDataStatusConfirm("Success");
        resetForm();
      }
      setSnackBarMessage(message);
    } else {
      let message;
      if (response?.error?.data?.validationErrors) {
        setDataStatusConfirm("Error");
        message = response.error.data.validationErrors;
      } else {
        setDataStatusConfirm("Success");
        message = response?.error?.data.message;
        resetForm();
      }
      setSnackBarMessage(message);
    }

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const resetForm = () => {
    reset({
      vendorCode: "",
      vendorName: "",
      gstin: "",
      panNumber: "",
      address1: "",
      address2: "",
      mobileNumber: "",
      contactPerson: "",
      contactPersonMobileNo: "",
      vendorType: "",
      marginPercentage: "",
      companyId: [],
    });

    setIsEditing(false);
    setStatus(0);
    setSelectedCity("")
    setSelectedArea("")
    setSelectedState("")
    setSelectedItemGroup(null);
    setCurrentTab(1);
  };


  const handleStatusChange = (event) => {
    setStatus(event.target.checked ? 1 : 0);
  };
  const handlePincodeChange = async (event) => {
    let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
    if (pincode.length === 6) {
      const payLoad = {
        pinCode: pincode,
      };
      const response = await getAllPincodeData(payLoad);
      if (response?.data?.requestSuccessful) {
        setSelectedState(response.data.pincodeData.state);
        setSelectedCity(response.data.pincodeData.city);
      }
    }
  };


  const handleEditClick = (row) => {

    setValue('vendorCode', row.vendorCode);
    setValue('vendorName', row.vendorName);
    setValue('gstin', row.gstin);
    setValue('panNumber', row.panNumber);
    setValue('address1', row.address1);
    setValue('address2', row.address2);
    setValue('mobileNumber', row.mobileNumber);
    setValue('contactPerson', row.contactPerson);
    setValue('contactPersonMobileNo', row.contactPersonMobileNo);
    setValue('vendorType', row.vendorType);
    setValue('marginPercentage', row.marginPercentage);
    setValue('pincode', row.pincode);

    const selectedCompanies = companies.filter(company => row.companies.includes(company.name));
    setValue('companyId', selectedCompanies);

    const selectedItemGroups = itemGroups.filter(itemGroup => row.itemgroupId.split(',').includes(itemGroup.itemGroupCode));
    setValue('itemGroupId', selectedItemGroups);

    setSelectedArea({ area: row.areaName });

    setSelectedState(row.state);
    setSelectedCity(row.city);

    setStatus(row.status ? 1 : 0);
    setIsEditing(true);
    setCurrentTab(0);
    setCurrentItemId(row.id);
    clearErrors(['vendorName', 'vendorCode', 'gstin', 'panNumber', 'address1', 'address2', 'mobileNumber', 'contactPerson', 'contactPersonMobileNo']);
  };

  const handleNext = () => {
    if (offset + limit < totalItems) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };
  const columns = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    const hasUpdatePermission = buttonList.split(',').includes("3");
    const baseColumns = [
      {
        field: 'vendorCode',
        headerName: 'Vendor Code',
        flex: 1,
      },
      {
        field: 'vendorName',
        headerName: 'Vendor Name',
        flex: 1,
      },
      {
        field: 'gstin',
        headerName: 'Gstin',
        flex: 1,
      },
      {
        field: 'panNumber',
        headerName: 'Pan Number',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                    marginRight: '8px',
                  }}
                />
                {params.value ? 'Active' : 'Inactive'}
              </>
            }
            style={{
              textTransform: 'capitalize',
              width: "90px",
            }}
          />
        ),
      },
    ];

    if (hasUpdatePermission) {
      baseColumns.push({
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params.row)}
              style={{ padding: '5px', fontSize: '1.1rem', color: '#5d6294' }}
              sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
            >
              <MdEdit />
            </IconButton>
          </Tooltip>
        ),
      });
    }

    return baseColumns;
  }, [menuList, pathname]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.trimStart();
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsCreating(true);
    } else {
      setIsCreating(false);
      resetForm();
    }
    setCurrentTab(newValue);
  };

  // Filter out selected companies from options
  const selectedCompanies = useWatch({ name: 'companyId', control }) || [];
  const filteredOptions = companies.filter(
    (company) => !selectedCompanies?.some((selectedCompany) => selectedCompany.id === company.id)
  );


  const selectedItem = useWatch({ name: 'itemGroupId', control });
  const filteredItemGroupsOptions = itemGroups.filter(
    (itemGroup) => !selectedItem?.some((selectedGroup) => selectedGroup.id === itemGroup.id)
  );

  const vendorType = ["OSL", "Parts"];

  const hasCreatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("1");
  }, [menuList, pathname]);

  const hasUpdatePermission = useMemo(() => {
    const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
    const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
    return buttonList.split(',').includes("3");
  }, [menuList, pathname]);

  const handleCancel = () => {
    reset({
      vendorCode: "",
      vendorName: "",
      gstin: "",
      panNumber: "",
      address1: "",
      address2: "",
      mobileNumber: "",
      contactPerson: "",
      contactPersonMobileNo: "",
      vendorType: "",
      marginPercentage: "",
      pincode: "",
      status: 0,
      companyId: "",
      itemGroupId: []

    })
    setStatus(1)
    setIsEditing(false);
  };

  return (
    <Box>
      <StyledBreadcrumb
        mainDiv="Master"
        subDiv="Vendor"
      ></StyledBreadcrumb>

      <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 0px" }}>
          <Tabs value={currentTab} onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              '& .MuiTabs-indicator': { backgroundColor: "#e96b20" },
              maxWidth: window.innerWidth > 980 ? '100%' : 'auto',
              overflowX: window.innerWidth > 980 ? 'auto' : 'visible'
            }}
          >
            {hasCreatePermission ? (
              <Tab label="Create Vendor" />
            ) : (isEditing && !hasCreatePermission) ? (
              <Tab label="Update Vendor" />
            ) : null}
            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (<Tab label="Vendor " />)}

          </Tabs>
        </Box>
        {currentTab === 0 && (
          <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('vendorCode', { required: 'vendorCode is required' })}
                    fullWidth variant="standard"
                    label={<span>VendorCode<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.vendorCode}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.vendorCode ? errors.vendorCode.message : ''}
                    onInput={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('vendorName', { required: 'vendorName is required' })}
                    fullWidth variant="standard"
                    label={<span>VendorName<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.vendorName}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.vendorName ? errors.vendorName.message : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('gstin', { required: 'gstin is required' })}
                    fullWidth variant="standard"
                    label={<span>Gstin <span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.gstin}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.gstin ? errors.gstin.message : ''}
                    onInput={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('panNumber', { required: 'Pan Number is required' })}
                    fullWidth variant="standard"
                    label={<span>Pan Number<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.panNumber}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.panNumber ? errors.panNumber.message : ''}
                    onInput={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('address1', { required: 'address1 is required' })}
                    fullWidth variant="standard"
                    label={<span>Address1<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.address1}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.address1 ? errors.address1.message : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('address2', { required: 'address2 is required' })}
                    fullWidth variant="standard"
                    label={<span>Address2<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.address2}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.address2 ? errors.address2.message : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('pincode', {
                      required: 'Pincode is required',
                      minLength: { value: 6, message: 'Pincode must be 6 digits' },
                      maxLength: { value: 6, message: 'Pincode must be 6 digits' }
                    })}
                    fullWidth
                    variant="standard"
                    type="tel"
                    label={<span>Pincode<span style={{ color: '#D32F2F' }}>*</span></span>}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    error={!!errors.pincode}
                    helperText={errors.pincode ? errors.pincode.message : ''}
                    onChange={handlePincodeChange} // Implement this function
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label={<span>State<span style={{ color: '#D32F2F' }}>*</span></span>}
                    value={selectedState}
                    InputLabelProps={selectedState ? { shrink: true } : {}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label={<span>City<span style={{ color: '#D32F2F' }}>*</span></span>}
                    value={selectedCity}
                    InputLabelProps={selectedCity ? { shrink: true } : {}}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="areaName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={AreaNames}
                        getOptionLabel={(option) => option.area}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={<span>Area Name<span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.areaName}
                            helperText={errors.areaName ? errors.areaName.message : ''}
                          />
                        )}
                        onChange={(e, value) => setSelectedArea(value)}
                        value={selectedArea}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('mobileNumber', {
                      required: 'Mobile Number is required',
                      maxLength: {
                        value: 10,
                        message: 'Mobile Number cannot exceed 10 digits'
                      }
                    })}
                    fullWidth
                    variant="standard"
                    label={<span>Mobile Number<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.mobileNumber}
                    inputProps={{ maxLength: 10 }}
                    type="tel"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.mobileNumber ? errors.mobileNumber.message : ''}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value.length > 10) {
                        e.target.value = value.slice(0, 10);
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('contactPerson', { required: 'Contact Person is required' })}
                    fullWidth variant="standard"
                    label={<span>Contact Person<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.contactPerson}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.contactPerson ? errors.contactPerson.message : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    {...register('contactPersonMobileNo', {
                      required: 'Contact Person MobileNo is required',
                      maxLength: {
                        value: 10,
                        message: 'Contact Person MobileNo cannot exceed 10 digits'
                      }
                    })}
                    fullWidth
                    variant="standard"
                    label={<span>Contact Person MobileNo<span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.contactPersonMobileNo}
                    type="tel"
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    inputProps={{ maxLength: 10 }}
                    helperText={errors.contactPersonMobileNo ? errors.contactPersonMobileNo.message : ''}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value.length > 10) {
                        e.target.value = value.slice(0, 10);
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyId"
                    control={control}
                    rules={{ required: 'Company is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={filteredOptions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Company<span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.companyId}
                            sx={{
                              '& .MuiChip-root': { height: "25px" }
                            }}
                            helperText={errors.companyId ? errors.companyId.message : ''}
                          />
                        )}
                        value={field.value || []}
                        onChange={(event, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="itemGroupId"
                    control={control}
                    rules={{ required: 'Item Group is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={filteredItemGroupsOptions}

                        getOptionLabel={(option) => option.itemGroupCode}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Item Group <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.itemGroupId}
                            sx={{
                              '& .MuiChip-root': { height: "25px" }
                            }}
                            helperText={errors.itemGroupId ? errors.itemGroupId.message : ''}
                          />
                        )}
                        value={field.value || []}
                        onChange={(event, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="vendorType"
                    control={control}
                    rules={{ required: 'vendorType is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={vendorType}
                        getOptionLabel={(option) => option.toString()}
                        onChange={(e, value) => field.onChange(value)}
                        value={field.value || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<span>Vendor Type <span style={{ color: '#D32F2F' }}>*</span></span>}
                            variant="standard"
                            error={!!errors.vendorType}
                            helperText={errors.vendorType ? errors.vendorType.message : ''}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField {...register('marginPercentage', { required: 'marginPercentage is required' })}
                    fullWidth variant="standard"
                    label={<span>Margin Percentage <span style={{ color: '#D32F2F' }}>*</span></span>}
                    error={!!errors.marginPercentage}
                    InputLabelProps={isEditing ? { shrink: true } : {}}
                    helperText={errors.marginPercentage ? errors.marginPercentage.message : ''}
                    onInput={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                    <Box>
                      <FormLabel>Status</FormLabel>
                    </Box>
                    <Box>
                      <Switch checked={status === 1} onChange={handleStatusChange} />
                    </Box>
                  </Box>
                </Grid>
                <Grid container xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "flex-end", padding: "20px 0px 0px 0px" }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                      Cancel
                    </SoftButton>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                    <SoftButton type="submit" variant="contained" color="primary" >
                      {isEditing ? 'Update' : 'Add'}
                    </SoftButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </StyledPaperShadow>
        )}
        {currentTab === 1 && (
          <Box>
            <Box
              sx={{
                display: "flex", justifyContent: "space-between", marginTop: "10px", flexWrap: "nowrap", rowGap: "10px", columnGap: "30px"
              }}
            >
              <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            flexWrap: "wrap",  
                            rowGap: "10px",
                            columnGap: "30px",
                            '@media (max-width: 700px)': {
                                flexDirection: "column",  
                                columnGap: "10px",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                backgroundColor: "#11164b",
                                borderRadius: "5px",
                                padding: "10px",
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column", 
                                    columnGap: "5px",
                                    padding: "5px",
                                    width: "100%",
                                },
                            }}
                        >
                            <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "#d9d9d9 !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                   Total Records: {VendorDatagrid?.vendorData?.totalItems}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "1px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "10px",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column", 
                                        paddingLeft: "5px",
                                        width: "100%", 
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Show:
                                </Typography>
                                <Select
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{
                                        marginLeft: 1,
                                        '& .MuiSelect-select': {
                                            paddingTop: "1px !important",
                                            paddingBottom: "1px !important",
                                        },
                                        '@media (max-width: 700px)': {
                                            marginLeft: 0,
                                            width: "100%", 
                                        },
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </Box>

              <Box>
                <TextField
                  variant="standard"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleSearchClick}>
                          <IoSearchSharp />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '@media (max-width: 700px)': {
                      width: "100%",
                      marginTop: "10px",
                    },
                  }}
                />
              </Box>
            </Box>
            <ItemGroupDatagrid data={VendorDatagrid?.vendorData?.data || []} columns={columns} />
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
              <ToggleButtonGroup
                exclusive
                sx={{
                  display: 'flex', alignItems: 'center',

                  '& .MuiToggleButtonGroup-middleButton': {
                    marginRight: "0px !important",
                    borderLeft: "1px solid #e5e5e5"
                  },
                  '& .MuiToggleButtonGroup-root': {
                    border: "1px solid #e5e5e5",
                  },
                }}
              >
                <ToggleButton
                  value="previous"
                  onClick={handlePrevious}
                  disabled={offset === 0}
                >
                  Previous
                </ToggleButton>

                {Array.from({ length: totalPages }, (_, index) => {
                  const page = index + 1;
                  const isFirstThreePages = page <= 3;
                  const isLastThreePages = page > totalPages - 3;
                  const isCurrentPage = offset / limit === index;

                  if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                    return (
                      <ToggleButton
                        key={page}
                        value={`page-${page}`}
                        onClick={() => handlePageClick(page)}
                        sx={{ margin: '0 5px' }}
                        selected={isCurrentPage}
                      >
                        {page}
                      </ToggleButton>
                    );
                  }

                  if (page === 4 && !isFirstThreePages) {
                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  if (page === totalPages - 3 && !isLastThreePages) {
                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                  }

                  return null;
                })}
                <ToggleButton
                  value="next"
                  onClick={handleNext}
                  disabled={offset + limit >= totalItems}
                >
                  Next
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

          </Box>

        )}
        {open && (
          <StyledSnackBar
            open={true}
            message={snackBarMessage}
            status={dataStatusConfirm}
          />
        )}
      </StyledPaperShadow>

    </Box>

  )
}

export default Vendor