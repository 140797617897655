import { Autocomplete, Box, Chip, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSearchSharp } from "react-icons/io5";
import { MdDelete, MdEdit } from 'react-icons/md';
import { useDeleteUserCreationMutation, useEditUserCreationMutation, useGetEmployeeDatagridMutation, useGetUserCreationDatagridMutation, useGetroleIdMutation, usePickRolesMutation, useUserCreationMutation } from '../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../theme/StyledElement";
import StyledBreadcrumb from "../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import ItemGroupDatagrid from "../master/itemGroup/itemGroupDatagrid/ItemGroupDatagrid";

const UserCreation = () => {
    const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            status: 0,
            employeeId: null,
            role: null,
            roles: []
        }
    });
    const previousRoleRef = useRef();
    const menuList = JSON.parse(localStorage.getItem("menuList"));
    const pathname = window.location.pathname;

    const [addUserCreationData, { isSuccess: UserCreationDataSuccess }] = useUserCreationMutation();
    const [getUserCreationGrid, { data: EmployeeGrid }] = useGetUserCreationDatagridMutation();
    const [editUserCreationData, { isSuccess: editEmployeeSuccess }] = useEditUserCreationMutation();
    const [deleteUserCreationData, { isSuccess: deleteUserCreationSuccess }] = useDeleteUserCreationMutation();

    const [getRoles, { data: getRolesData }] = useGetroleIdMutation();
    const [getemployee, { data: EmployeeDataGrid, }] = useGetEmployeeDatagridMutation();
    const [pickRolesData, { data: pickRoleGrid, }] = usePickRolesMutation();

    const employeeRoles = EmployeeDataGrid?.employeeData?.data
    const roleData = getRolesData?.menuList
    const pickSecondaryRole = pickRoleGrid?.menuList

    useEffect(() => {
        getRoles()

    }, [])
    useEffect(() => {
        getemployee()

    }, [])

    const [status, setStatus] = useState(1);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        fetchItems(offset, searchValue);
    }, [offset, limit, UserCreationDataSuccess, editEmployeeSuccess, deleteUserCreationSuccess]);

    const fetchItems = async (newOffset, searchKey) => {
        const payload = {
            limit,
            offset: newOffset,
            searchKey,
        };
        const response = await getUserCreationGrid(payload);
        setTotalItems(response?.data?.userdata?.total);
    };
    const handleDeleteClick = async (row) => {
        const payload = {
            id: row.id
        }
        let response;
        response = await deleteUserCreationData(payload)
        if (response && response.data) {
            setDataStatusConfirm("Success");
            setSnackBarMessage(response?.data?.message);
        }
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };

    const handleSearchClick = () => {
        setOffset(0);
        fetchItems(0, searchValue);
    };

    const totalPages = Math.ceil(totalItems / limit);
    const handlePageClick = (page) => {
        const newOffset = (page - 1) * limit;
        setOffset(newOffset);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setOffset(0);
    };

    const onSubmit = async (data) => {
        const formData = {
            ...data,
            status: status ? 1 : 0,
            employeeId: data?.employeeId?.id,
            role: data?.role?.id,
            roleName: data?.role?.roleName
        };

        let response;
        if (isEditing) {
            response = await editUserCreationData({ id: currentItemId, ...formData });
            if (response && response.data.requestSuccessful === true) {
                setIsEditing(false);
            }
        } else {
            response = await addUserCreationData(formData);
        }

        if (response && response.data) {
            let message;
            if (response.data.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response.data.message;
                reset();
                setCurrentItemId(null);
            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                reset();
                setCurrentItemId(null);
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };


    const columns = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        const hasUpdatePermission = buttonList.split(',').includes("3");
        const baseColumns = [
            {
                field: 'user_id',
                headerName: 'UserId',
                flex: 1,
            },
            {
                field: 'employeeName',
                headerName: 'Employee Name',
                flex: 1,
            },
            {
                field: 'roleName',
                headerName: 'Primary Role',
                flex: 1,
            },
            {
                field: 'secondryRoleName',
                headerName: 'Secondary Role',
                flex: 1,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params) => (
                    <Chip
                        label={
                            <>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: params.value ? '#2CA75F' : '#d70f0f',
                                        marginRight: '8px',
                                    }}
                                />
                                {params.value ? 'Active' : 'Inactive'}
                            </>
                        }
                        style={{
                            textTransform: 'capitalize',
                            width: "90px",
                        }}
                    />
                ),
            },
        ];

        if (hasUpdatePermission) {
            baseColumns.push({
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    <>
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => handleEditClick(params.row)}
                                style={{ padding: '5px', fontSize: '1.1rem', color: '#302159' }}
                                sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
                            >
                                <MdEdit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="delete">
                            <IconButton
                                onClick={() => handleDeleteClick(params.row)}
                                style={{ padding: '5px', fontSize: '1.1rem', color: 'red' }}
                                sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
                            >
                                <MdDelete />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
            });
        }

        return baseColumns;
    }, [menuList, pathname]);

    const handleStatusChange = (event) => {
        setStatus(event.target.checked ? 1 : 0);
    };

    const [rolesSet, setRolesSet] = useState(false);
    
    const pickRoles = useWatch({ name: 'role', control });
    
    useEffect(() => {
        const currentRoleName = pickRoles?.roleName;
        const previousRoleName = previousRoleRef.current?.roleName;
    
        if (currentRoleName && currentRoleName !== previousRoleName) {
            if (!rolesSet) {
                pickRolesData(pickRoles);
                setValue('roles', []);
            }
            pickRolesData(pickRoles);
            previousRoleRef.current = pickRoles;
        }
    }, [pickRoles, rolesSet]);
    
    const handleRolesUpdate = (newRoles) => {
        setRolesSet(true);
        setValue("roles", newRoles);
    };

    const handleEditClick = (row) => {
        setSelectedRow(row);
        setValue('user_id', row.user_id);
        setValue('password', row.employeeCode);
    
        const selectedEmployee = employeeRoles.find(d => d.employeeName === row.employeeName) || null;
        setValue("employeeId", selectedEmployee);
    
        const selectedRole = roleData.find(d => d.roleName === row.roleName) || null;
        setValue("role", selectedRole);
    
        if (row?.secondryRoleName.includes(',')) {
            const secondaryRole = row?.secondryRoleName?.split(',');
            const secondaryRoleName = roleData?.filter(list => secondaryRole?.includes(list.roleName));
            handleRolesUpdate(secondaryRoleName);
        } else {
            const secondaryRole = row?.secondryRoleName?.split(',');
            const secondaryRoleName = roleData?.filter(list => secondaryRole?.includes(list.roleName));
            handleRolesUpdate(secondaryRoleName);
        }
        setStatus(row.status ? 1 : 0);
        setIsEditing(true);
        setCurrentItemId(row.id);
        clearErrors(['employeeName', 'mobileNumber', 'email', 'role', 'roles']);
    };


    const handleNext = () => {
        if (offset + limit < totalItems) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handlePrevious = () => {
        if (offset - limit >= 0) {
            setOffset(prevOffset => prevOffset - limit);
        }
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        event.target.value = inputValue.trimStart();
    };


    const hasCreatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("1");
    }, [menuList, pathname]);

    const hasUpdatePermission = useMemo(() => {
        const menu = menuList.find(item => item.path === pathname || item.submenu?.some(sub => sub.path === pathname));
        const buttonList = menu?.submenu?.find(sub => sub.path === pathname)?.buttons || "";
        return buttonList.split(',').includes("3");
    }, [menuList, pathname]);

    const selectedRoles = useWatch({ name: 'roles', control });

    const filteredOptions = pickSecondaryRole?.filter(
        (role) => !selectedRoles?.some((select) => select.id === role.id)
    );

    // const pickRoles = useWatch({ name: 'role', control });

    // useEffect(() => {
    //     const currentRoleName = pickRoles?.roleName;
    //     const previousRoleName = previousRoleRef.current?.roleName;

    //     if (
    //         currentRoleName &&
    //         currentRoleName !== previousRoleName
    //     ) {
    //         pickRolesData(pickRoles);
    //         setValue('roles', []);
    //         previousRoleRef.current = pickRoles;
    //     }
    // }, [pickRoles]);

    const handleCancel = () => {
        reset({
            employeeId: "",
            user_id: "",
            role: "",
            roles: [],
            password: "",
        })
        setStatus(1);
        setIsEditing(false);
    };

    return (
        <Box>
            <StyledBreadcrumb
                mainDiv="Master"
                subDiv="Users"
            ></StyledBreadcrumb>

            {(hasCreatePermission || (hasUpdatePermission && isEditing)) && (
                <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Controller
                                    name="employeeId"
                                    control={control}
                                    rules={{ required: 'Employee is required' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={employeeRoles || []}
                                            getOptionLabel={(option) => option.employeeName || ''}
                                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<span>Employee<span style={{ color: '#D32F2F' }}>*</span></span>}
                                                    variant="standard"
                                                    error={!!errors.employeeId}
                                                    helperText={errors.employeeId ? errors.employeeId.message : ''}
                                                />
                                            )}
                                            value={field.value || null}
                                            onChange={(event, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    {...register('user_id', { required: 'User Name is required' })}
                                    fullWidth variant="standard"
                                    label={<span>User Name<span style={{ color: '#D32F2F' }}>*</span></span>}
                                    InputLabelProps={isEditing ? { shrink: true } : {}}
                                    error={!!errors.user_id}
                                    helperText={errors.user_id ? errors.user_id.message : ''}
                                    onInput={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Controller
                                    name="role"
                                    control={control}
                                    rules={{ required: 'Role is required' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={roleData || []}
                                            getOptionLabel={(option) => option.roleName || ''}
                                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<span>Primary Role<span style={{ color: '#D32F2F' }}>*</span></span>}
                                                    variant="standard"
                                                    error={!!errors.role}
                                                    helperText={errors.role ? errors.role.message : ''}
                                                />
                                            )}
                                            value={field.value || null}
                                            onChange={(event, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Controller
                                    name="roles"
                                    control={control}
                                    rules={{ required: 'roles  is required' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            multiple
                                            options={filteredOptions || []}
                                            getOptionLabel={(option) => option.roleName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<span>Secondary Role<span style={{ color: '#D32F2F' }}>*</span></span>}
                                                    variant="standard"
                                                    error={!!errors.roles}
                                                    sx={{
                                                        '& .MuiChip-root': { height: "25px" },
                                                        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                                            minWidth: '1px !important',
                                                        },

                                                    }}
                                                    helperText={errors.roles ? errors.roles.message : ''}
                                                />
                                            )}
                                            value={field.value} // Use useWatch to reflect form state
                                            onChange={(event, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    {...register('password', { required: 'password is required' })}
                                    fullWidth variant="standard"
                                    label={<span>Password <span style={{ color: '#D32F2F' }}>*</span></span>}
                                    error={!!errors.password}
                                    helperText={errors.password ? errors.password.message : ''}
                                    onInput={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "15px" }}>
                                    <Box>
                                        <FormLabel>Status</FormLabel>
                                    </Box>
                                    <Box>
                                        <Switch checked={status === 1} onChange={handleStatusChange} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                        <SoftButton onClick={handleCancel} variant="contained" color="primary" sx={{ background: "red !important" }} >
                                            Cancel
                                        </SoftButton>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                        <SoftButton type="submit" variant="contained" color="primary" >
                                            {isEditing ? 'Update' : 'Add'}
                                        </SoftButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </StyledPaperShadow>
            )}
            <StyledPaperShadow elevation={0} sx={{ padding: "1px 20px 20px 20px", marginTop: "30px", display: "inline-block", width: "100%" }}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            flexWrap: "wrap",  
                            rowGap: "10px",
                            columnGap: "30px",
                            '@media (max-width: 700px)': {
                                flexDirection: "column",  
                                columnGap: "10px",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                backgroundColor: "#11164b",
                                borderRadius: "5px",
                                padding: "10px",
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    flexDirection: "column", 
                                    columnGap: "5px",
                                    padding: "5px",
                                    width: "100%",
                                },
                            }}
                        >
                            <Box sx={{ alignSelf: "center", margin: "5px" }}>
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        color: "#d9d9d9 !important",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Total Records: {EmployeeGrid?.userdata?.total}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "1px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "10px",
                                    '@media (max-width: 700px)': {
                                        flexDirection: "column", 
                                        paddingLeft: "5px",
                                        width: "100%", 
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        alignSelf: "center",
                                        fontSize: "1rem",
                                        '@media (max-width: 700px)': {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    Show:
                                </Typography>
                                <Select
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{
                                        marginLeft: 1,
                                        '& .MuiSelect-select': {
                                            paddingTop: "1px !important",
                                            paddingBottom: "1px !important",
                                        },
                                        '@media (max-width: 700px)': {
                                            marginLeft: 0,
                                            width: "100%", 
                                        },
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width: "auto",
                                '@media (max-width: 700px)': {
                                    width: "100%", 
                                    marginTop: "10px",
                                },
                            }}
                        >
                            <TextField
                                variant="standard"
                                label="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSearchClick}>
                                                <IoSearchSharp />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "auto",  
                                    '@media (max-width: 700px)': {
                                        width: "100%", 
                                    },
                                }}
                            />
                        </Box>
                    </Box>

                    <ItemGroupDatagrid data={EmployeeGrid?.userdata?.result || []} columns={columns} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                        <ToggleButtonGroup
                            exclusive
                            sx={{
                                display: 'flex', alignItems: 'center',

                                '& .MuiToggleButtonGroup-middleButton': {
                                    marginRight: "0px !important",
                                    borderLeft: "1px solid #e5e5e5"
                                },
                                '& .MuiToggleButtonGroup-root': {
                                    border: "1px solid #e5e5e5",
                                },
                            }}
                        >
                            <ToggleButton
                                value="previous"
                                onClick={handlePrevious}
                                disabled={offset === 0}
                            >
                                Previous
                            </ToggleButton>

                            {Array.from({ length: totalPages }, (_, index) => {
                                const page = index + 1;
                                const isFirstThreePages = page <= 3;
                                const isLastThreePages = page > totalPages - 3;
                                const isCurrentPage = offset / limit === index;

                                if (isFirstThreePages || isLastThreePages || isCurrentPage) {
                                    return (
                                        <ToggleButton
                                            key={page}
                                            value={`page-${page}`}
                                            onClick={() => handlePageClick(page)}
                                            sx={{ margin: '0 5px' }}
                                            selected={isCurrentPage}
                                        >
                                            {page}
                                        </ToggleButton>
                                    );
                                }

                                if (page === 4 && !isFirstThreePages) {
                                    return <Typography key="dots-before" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                if (page === totalPages - 3 && !isLastThreePages) {
                                    return <Typography key="dots-after" sx={{ margin: '0 5px', userSelect: 'none' }}>...</Typography>;
                                }

                                return null;
                            })}
                            <ToggleButton
                                value="next"
                                onClick={handleNext}
                                disabled={offset + limit >= totalItems}
                            >
                                Next
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                </Box>
            </StyledPaperShadow>
            {open && (
                <StyledSnackBar
                    open={true}
                    message={snackBarMessage}
                    status={dataStatusConfirm}
                />
            )}
        </Box>
    );
};

export default UserCreation