import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "../../store/slice/ControlsSlice";
import LabourUploads from "../../components/BulkUploads/LabourUploads";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_UI_BASE_URL,

  prepareHeaders: (headers, { getState }) => {
    let auth = getState().controls.authToken;
    if (auth) {
      headers.set("Authorization", auth);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }

  return result;
};

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: baseQueryWithReauth,

  //add endpoints
  endpoints: (builder) => ({

    saveLogin: builder.mutation({
      query: (body) => ({
        url: "/users/login",
        method: "POST",
        body: body,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (body) => ({
        url: "/users/forgetPassword",
        method: "POST",
        body: body,
      }),
    }),
    menuList: builder.mutation({
      query: (body) => ({
        url: "/users/menuList",
        method: "POST",
        body: body,
      }),
    }),
    itemMasterDatagrid: builder.mutation({
      query: (body) => ({
        url: "/items/getItems",
        method: "POST",
        body: body,
      }),
    }),
    addItemMaster: builder.mutation({
      query: (body) => ({
        url: "/items/createItem",
        method: "POST",
        body: body,
      }),
    }),
    editItemMaster: builder.mutation({
      query: (body) => ({
        url: "/items/updateItem",
        method: "POST",
        body: body,
      }),
    }),
    itemGroup: builder.query({
      query: () => ({
        url: "/itemgroups/getItemGroups",
        method: "GET",
      }),
    }),
    getitemCategory: builder.query({
      query: () => ({
        url: "/itemcategories/getAllItemCategory",
        method: "GET",
      }),
    }),
    getHsn: builder.query({
      query: () => ({
        url: "hsns/getAllHsn",
        method: "GET",
      }),
    }),
    getUom: builder.query({
      query: () => ({
        url: "uom/listUom",
        method: "GET",
      }),
    }),
    AllUom: builder.query({
      query: () => ({
        url: "uom/listUom",
        method: "GET",
      }),
    }),
    getCompany: builder.query({
      query: () => ({
        url: "companies/getAllCompany",
        method: "GET",
      }),
    }),
    getUomData: builder.mutation({
      query: (body) => ({
        url: "/uom/addUom",
        method: "POST",
        body: body,
      }),
    }),
    getUomDatagrid: builder.mutation({
      query: (body) => ({
        url: "/uom/getUomList",
        method: "POST",
        body: body,
      }),
    }),
    editUom: builder.mutation({
      query: (body) => ({
        url: "/uom/editUom",
        method: "POST",
        body: body,
      }),
    }),
    getItemGroupData: builder.mutation({
      query: (body) => ({
        url: "/itemgroups/create",
        method: "POST",
        body: body,
      }),
    }),
    getItemGroupDatagrid: builder.mutation({
      query: (body) => ({
        url: "/itemgroups/listItemGroups",
        method: "POST",
        body: body,
      }),
    }),
    editItemGroup: builder.mutation({
      query: (body) => ({
        url: "/itemgroups/editItemGroup",
        method: "POST",
        body: body,
      }),
    }),
    getItemCategory: builder.mutation({
      query: (body) => ({
        url: "/itemcategories/createItemCategory",
        method: "POST",
        body: body,
      }),
    }),
    getItemCategoryDatagrid: builder.mutation({
      query: (body) => ({
        url: "/itemcategories/getItemCategoryList",
        method: "POST",
        body: body,
      }),
    }),
    editgetItemCategory: builder.mutation({
      query: (body) => ({
        url: "/itemcategories/updateItemCategory",
        method: "POST",
        body: body,
      }),
    }),
    getHsnsData: builder.mutation({
      query: (body) => ({
        url: "/hsns/create",
        method: "POST",
        body: body,
      }),
    }),
    getHsnsDatagrid: builder.mutation({
      query: (body) => ({
        url: "/hsns/listHsn",
        method: "POST",
        body: body,
      }),
    }),
    editHsns: builder.mutation({
      query: (body) => ({
        url: "/hsns/editHsn",
        method: "POST",
        body: body,
      }),
    }),
    getAggregateData: builder.mutation({
      query: (body) => ({
        url: "/aggregates/create",
        method: "POST",
        body: body,
      }),
    }),
    getAggregateDatagrid: builder.mutation({
      query: (body) => ({
        url: "/aggregates/listAggregates",
        method: "POST",
        body: body,
      }),
    }),
    editAggregate: builder.mutation({
      query: (body) => ({
        url: "/aggregates/editAggregate",
        method: "POST",
        body: body,
      }),
    }),
    getSubAggregateData: builder.mutation({
      query: (body) => ({
        url: "/subaggregates/create",
        method: "POST",
        body: body,
      }),
    }),
    getSubAggregateDatagrid: builder.mutation({
      query: (body) => ({
        url: "/subaggregates/listSubaggregates",
        method: "POST",
        body: body,
      }),
    }),
    editSubAggregate: builder.mutation({
      query: (body) => ({
        url: "/subaggregates/editSubAggregate",
        method: "POST",
        body: body,
      }),
    }),
    getAllAggregate: builder.query({
      query: () => ({
        url: "aggregates/allAggregates",
        method: "GET",
      }),
    }),
    getAllSubAggregate: builder.mutation({
      query: (body) => ({
        url: "/subaggregates/getSubaggregates",
        method: "POST",
        body: body,
      }),
    }),
    getMakeData: builder.mutation({
      query: (body) => ({
        url: "/makes/create",
        method: "POST",
        body: body,
      }),
    }),
    getMakeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/makes/getAllMakes",
        method: "POST",
        body: body,
      }),
    }),
    editMake: builder.mutation({
      query: (body) => ({
        url: "/makes/editMake",
        method: "POST",
        body: body,
      }),
    }),
    getVariantData: builder.mutation({
      query: (body) => ({
        url: "/varient/createVarient",
        method: "POST",
        body: body,
      }),
    }),
    getVariantDatagrid: builder.mutation({
      query: (body) => ({
        url: "/varient/listVarients",
        method: "POST",
        body: body,
      }),
    }),
    editVariant: builder.mutation({
      query: (body) => ({
        url: "/varient/editVarient",
        method: "POST",
        body: body,
      }),
    }),
    getModelData: builder.mutation({
      query: (body) => ({
        url: "/models/createmodel",
        method: "POST",
        body: body,
      }),
    }),
    getModelDatagrid: builder.mutation({
      query: (body) => ({
        url: "/models/getModelList",
        method: "POST",
        body: body,
      }),
    }),
    editModel: builder.mutation({
      query: (body) => ({
        url: "/models/updateModel",
        method: "POST",
        body: body,
      }),
    }),
    getAllMakes: builder.mutation({
      query: (body) => ({
        url: "makes/listMakes",
        method: "POST",
        body: body,
      }),
    }),
    getAllVaraint: builder.query({
      query: () => ({
        url: "varient/getAllVariants",
        method: "GET",
      }),
    }),
    getServiceTypeData: builder.mutation({
      query: (body) => ({
        url: "/servicetypes/createServiceType",
        method: "POST",
        body: body,
      }),
    }),
    getServiceTypeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/servicetypes/listServiceTypes",
        method: "POST",
        body: body,
      }),
    }),
    editServiceType: builder.mutation({
      query: (body) => ({
        url: "/servicetypes/editServiceType",
        method: "POST",
        body: body,
      }),
    }),
    getRepairTypeData: builder.mutation({
      query: (body) => ({
        url: "/repairtypes/createRepairType",
        method: "POST",
        body: body,
      }),
    }),
    getRepairTypeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/repairtypes/listRepairTypes",
        method: "POST",
        body: body,
      }),
    }),
    editRepairType: builder.mutation({
      query: (body) => ({
        url: "/repairtypes/editRepairType",
        method: "POST",
        body: body,
      }),
    }),
    getSourceData: builder.mutation({
      query: (body) => ({
        url: "/sources/createSource",
        method: "POST",
        body: body,
      }),
    }),
    getSourceDatagrid: builder.mutation({
      query: (body) => ({
        url: "/sources/listSource",
        method: "POST",
        body: body,
      }),
    }),
    editSource: builder.mutation({
      query: (body) => ({
        url: "/sources/editSource",
        method: "POST",
        body: body,
      }),
    }),
    getAllModel: builder.mutation({
      query: (body) => ({
        url: "/models/getModelsByMake",
        method: "POST",
        body: body,
      }),
    }),
    getSourceTypeData: builder.mutation({
      query: (body) => ({
        url: "/sourcetypes/createSourceType",
        method: "POST",
        body: body,
      }),
    }),
    getSourceTypeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/sourcetypes/listSourceTypes",
        method: "POST",
        body: body,
      }),
    }),
    editSourceType: builder.mutation({
      query: (body) => ({
        url: "/sourcetypes/editSourceType",
        method: "POST",
        body: body,
      }),
    }),
    getAllSource: builder.mutation({
      query: (body) => ({
        url: "sources/getAllSources",
        method: "POST",
        body: body,
      }),
    }),
    getVendorData: builder.mutation({
      query: (body) => ({
        url: "/vendors/createVendor",
        method: "POST",
        body: body,
      }),
    }),
    getVendorDatagrid: builder.mutation({
      query: (body) => ({
        url: "/vendors/listVendors",
        method: "POST",
        body: body,
      }),
    }),
    editVendor: builder.mutation({
      query: (body) => ({
        url: "/vendors/editVendor",
        method: "POST",
        body: body,
      }),
    }),
    getAllPincode: builder.mutation({
      query: (body) => ({
        url: "/vendors/getPincodeData",
        method: "POST",
        body: body,
      }),
    }),
    getAuditLog: builder.mutation({
      query: (body) => ({
        url: "/logs/getAuditLog",
        method: "POST",
        body: body,
      }),
    }),
    getUserLog: builder.mutation({
      query: (body) => ({
        url: "/users/getUserLogs",
        method: "POST",
        body: body,
      }),
    }),
    LogOut: builder.mutation({
      query: (body) => ({
        url: "/users/logout",
        method: "POST",
        body: body,
      }),
    }),
    getDispositionData: builder.mutation({
      query: (body) => ({
        url: "/disposition/createDisposition",
        method: "POST",
        body: body,
      }),
    }),
    getDispositionDatagrid: builder.mutation({
      query: (body) => ({
        url: "/disposition/listDispositions",
        method: "POST",
        body: body,
      }),
    }),
    editDisposition: builder.mutation({
      query: (body) => ({
        url: "/disposition/editDisposition",
        method: "POST",
        body: body,
      }),
    }),
    getSubDispositionData: builder.mutation({
      query: (body) => ({
        url: "/subdisposition/createSubDisPosition",
        method: "POST",
        body: body,
      }),
    }),
    getSubDispositionDatagrid: builder.mutation({
      query: (body) => ({
        url: "/subdisposition/listSubDisPositions",
        method: "POST",
        body: body,
      }),
    }),
    editSubDisposition: builder.mutation({
      query: (body) => ({
        url: "/subdisposition/editSubDisPosition",
        method: "POST",
        body: body,
      }),
    }),
    auditFilter: builder.mutation({
      query: (body) => ({
        url: "/auditLog/filter",
        method: "POST",
        body: body,
      }),
    }),
    userLogFilter: builder.mutation({
      query: (body) => ({
        url: "/userLog/filter",
        method: "POST",
        body: body,
      }),
    }),
    getAllDisposition: builder.mutation({
      query: (body) => ({
        url: "/disposition/getAllDisPositions",
        method: "POST",
        body: body,
      }),
    }),
    getLabourData: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/createLaborSchedule",
        method: "POST",
        body: body,
      }),
    }),
    getLabourDatagrid: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/listLaborSchedule",
        method: "POST",
        body: body,
      }),
    }),
    editLabour: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/editLaborSchedule",
        method: "POST",
        body: body,
      }),
    }),
    getAllHsnsData: builder.mutation({
      query: (body) => ({
        url: "/hsns/getHsn",
        method: "POST",
        body: body,
      }),
    }),
    getDsaAgentData: builder.mutation({
      query: (body) => ({
        url: "/dsaagent/createDsaAgent",
        method: "POST",
        body: body,
      }),
    }),
    getDsaAgentDatagrid: builder.mutation({
      query: (body) => ({
        url: "/dsaagent/listDsaAgents",
        method: "POST",
        body: body,
      }),
    }),
    editDsaAgent: builder.mutation({
      query: (body) => ({
        url: "/dsaagent/editDsaAgent",
        method: "POST",
        body: body,
      }),
    }),
    getAllBank: builder.query({
      query: () => ({
        url: "/dsaagent/getAllBanks",
        method: "GET",
      }),
    }),
    getOutletData: builder.mutation({
      query: (body) => ({
        url: "/outlets/createOutlet",
        method: "POST",
        body: body,
      }),
    }),
    getOutletDatagrid: builder.mutation({
      query: (body) => ({
        url: "/outlets/listOutlets",
        method: "POST",
        body: body,
      }),
    }),
    editOutlet: builder.mutation({
      query: (body) => ({
        url: "/outlets/editOutlet",
        method: "POST",
        body: body,
      }),
    }),
    getBinLocationData: builder.mutation({
      query: (body) => ({
        url: "/binLocations/createBinLocation",
        method: "POST",
        body: body,
      }),
    }),
    getBinLocationDatagrid: builder.mutation({
      query: (body) => ({
        url: "/binLocations/listBinLocations",
        method: "POST",
        body: body,
      }),
    }),
    editBinLocation: builder.mutation({
      query: (body) => ({
        url: "/binLocations/editBinLocation",
        method: "POST",
        body: body,
      }),
    }),
    getAllOulet: builder.query({
      query: () => ({
        url: "/outlets/getallOutlets",
        method: "GET",
      }),
    }),
    getFuel: builder.query({
      query: () => ({
        url: "/varient/getFuelTypes",
        method: "GET",
      }),
    }),
    getEmployeeRoleData: builder.mutation({
      query: (body) => ({
        url: "/employeerole/createEmployeeRole",
        method: "POST",
        body: body,
      }),
    }),
    getEmployeeRoleDatagrid: builder.mutation({
      query: (body) => ({
        url: "/employeerole/listEmployeeRoles",
        method: "POST",
        body: body,
      }),
    }),
    editEmployeeRole: builder.mutation({
      query: (body) => ({
        url: "/employeerole/editEmployeeRole",
        method: "POST",
        body: body,
      }),
    }),
    getEmployeeData: builder.mutation({
      query: (body) => ({
        url: "/employee/createEmployee",
        method: "POST",
        body: body,
      }),
    }),
    getEmployeeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/employee/listEmployee",
        method: "POST",
        body: body,
      }),
    }),
    editEmployee: builder.mutation({
      query: (body) => ({
        url: "/employee/editEmployee",
        method: "POST",
        body: body,
      }),
    }),
    getAllEmpoyeeRoles: builder.query({
      query: () => ({
        url: "/employeerole/getAllEmployeeRoles",
        method: "GET",
      }),
    }),
    getCustomerData: builder.mutation({
      query: (body) => ({
        url: "/customer/createCustomer",
        method: "POST",
        body: body,
      }),
    }),
    getCustomerDatagrid: builder.mutation({
      query: (body) => ({
        url: "/customer/listCustomers",
        method: "POST",
        body: body,
      }),
    }),
    editCustomer: builder.mutation({
      query: (body) => ({
        url: "/customer/editCustomer",
        method: "POST",
        body: body,
      }),
    }),
    getAllSourceType: builder.mutation({
      query: (body) => ({
        url: "/sourcetypes/getAllSourceTypes",
        method: "POST",
        body: body,
      }),
    }),
    getAllBillType: builder.query({
      query: () => ({
        url: "/customer/getAllBilltypes",
        method: "GET",
      }),
    }),
    getAllCustomerCategories: builder.query({
      query: () => ({
        url: "/customer/getAllCustomercategories",
        method: "GET",
      }),
    }),
    getAllCustomerType: builder.query({
      query: () => ({
        url: "/customer/getAllCustomertypes",
        method: "GET",
      }),
    }),
    getAllInsurance: builder.query({
      query: () => ({
        url: "/insurance/getAllInsurances",
        method: "GET",
      }),
    }),
    getAllCustomer: builder.mutation({
      query: (body) => ({
        url: "/customer/getAllCustomers",
        method: "POST",
        body: body,
      }),
    }),
    getAllModels: builder.mutation({
      query: (body) => ({
        url: "/models/getAllModels",
        method: "POST",
        body: body,
      }),
    }),
    getAllVechicleColors: builder.query({
      query: () => ({
        url: "/vehicle/getAllVechicleColors",
        method: "GET",
      }),
    }),
    getVehicleData: builder.mutation({
      query: (body) => ({
        url: "/vehicle/createVehicle",
        method: "POST",
        body: body,
      }),
    }),
    getVehicleDatagrid: builder.mutation({
      query: (body) => ({
        url: "/vehicle/listVehicles",
        method: "POST",
        body: body,
      }),
    }),
    editVehicle: builder.mutation({
      query: (body) => ({
        url: "/vehicle/editVehicle",
        method: "POST",
        body: body,
      }),
    }),
    getInsuranceData: builder.mutation({
      query: (body) => ({
        url: "/insurance/createInsurance",
        method: "POST",
        body: body,
      }),
    }),
    getInsuranceDatagrid: builder.mutation({
      query: (body) => ({
        url: "/insurance/listInsurances",
        method: "POST",
        body: body,
      }),
    }),
    editInsurance: builder.mutation({
      query: (body) => ({
        url: "/insurance/editInsurance",
        method: "POST",
        body: body,
      }),
    }),
    getServiceEstimateData: builder.mutation({
      query: (body) => ({
        url: "/serviceEstimate/createServiceEstimate",
        method: "POST",
        body: body,
      }),
    }),
    getServiceEstimateDatagrid: builder.mutation({
      query: (body) => ({
        url: "/serviceEstimate/listServiceEstimate",
        method: "POST",
        body: body,
      }),
    }),
    editServiceEstimate: builder.mutation({
      query: (body) => ({
        url: "/serviceEstimate/updateServiceEstimate",
        method: "POST",
        body: body,
      }),
    }),
    getServiceBookingData: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/createServiceBooking",
        method: "POST",
        body: body,
      }),
    }),
    getServiceBookingDatagrid: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/listServiceBookings",
        method: "POST",
        body: body,
      }),
    }),
    editServiceBooking: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/editServiceBooking",
        method: "POST",
        body: body,
      }),
    }),
    getAllVechileDetails: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/getVehicleDetails",
        method: "POST",
        body: body,
      }),
    }),
    getAllServiceType: builder.mutation({
      query: (body) => ({
        url: "/servicetypes/getAllServiceTypes",
        method: "POST",
        body: body,
      }),
    }),
    getAllRepairType: builder.mutation({
      query: (body) => ({
        url: "/repairtypes/getAllRepairTypes",
        method: "POST",
        body: body,
      }),
    }),
    getAllVedor: builder.mutation({
      query: (body) => ({
        url: "/vendors/getAllVendors",
        method: "POST",
        body: body,
      }),
    }),
    getLabourDetails: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/getLabourDetails",
        method: "POST",
        body: body,
      }),
    }),
    getOslLabourDetails: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/getOslLabourDetails",
        method: "POST",
        body: body,
      }),
    }),
    getsearchLabourDetails: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/searchLabourDetails",
        method: "POST",
        body: body,
      }),
    }),
    getsearchOslLabourDetails: builder.mutation({
      query: (body) => ({
        url: "/laborSchedules/searchOslLabourDetails",
        method: "POST",
        body: body,
      }),
    }),
    getsearchPartsDetails: builder.mutation({
      query: (body) => ({
        url: "/items/searchItemDetails",
        method: "POST",
        body: body,
      }),
    }),
    getAllPartsDetails: builder.mutation({
      query: (body) => ({
        url: "/items/getItemDetails",
        method: "POST",
        body: body,
      }),
    }),
    getPdfDownload: builder.query({
      query: (id) => ({
        url: `/serviceEstimate/generatePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    //new
    getTyreOemData: builder.mutation({
      query: (body) => ({
        url: "/tyreOem/createTyreOem",
        method: "POST",
        body: body,
      }),
    }),
    getTyreOemDatagrid: builder.mutation({
      query: (body) => ({
        url: "/tyreOem/listTyreOems",
        method: "POST",
        body: body,
      }),
    }),
    editTyreOem: builder.mutation({
      query: (body) => ({
        url: "/tyreOem/updateTyreOem",
        method: "POST",
        body: body,
      }),
    }),
    deleteTyreOem: builder.mutation({
      query: (body) => ({
        url: "/tyreOem/deleteTyreOem",
        method: "POST",
        body: body,
      }),
    }),
    getBatteryOemData: builder.mutation({
      query: (body) => ({
        url: "/batteryOem/createBatteryOem",
        method: "POST",
        body: body,
      }),
    }),
    getBatteryOemDatagrid: builder.mutation({
      query: (body) => ({
        url: "/batteryOem/listBatteryOems",
        method: "POST",
        body: body,
      }),
    }),
    editBatteryOem: builder.mutation({
      query: (body) => ({
        url: "/batteryOem/updateBatteryOem",
        method: "POST",
        body: body,
      }),
    }),
    deleteBatteryOem: builder.mutation({
      query: (body) => ({
        url: "/batteryOem/deleteBatteryOem",
        method: "POST",
        body: body,
      }),
    }),
    getTyreSizeData: builder.mutation({
      query: (body) => ({
        url: "/tyreSize/createTyreSize",
        method: "POST",
        body: body,
      }),
    }),
    getTyreSizeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/tyreSize/listTyreSizes",
        method: "POST",
        body: body,
      }),
    }),
    editTyreSize: builder.mutation({
      query: (body) => ({
        url: "/tyreSize/updateTyreSize",
        method: "POST",
        body: body,
      }),
    }),
    deleteTyreSize: builder.mutation({
      query: (body) => ({
        url: "/tyreSize/deleteTyreSize",
        method: "POST",
        body: body,
      }),
    }),
    getPickupTypeData: builder.mutation({
      query: (body) => ({
        url: "/pickupType/addPickupType",
        method: "POST",
        body: body,
      }),
    }),
    getPickupTypeDatagrid: builder.mutation({
      query: (body) => ({
        url: "/pickupType/listPickupType",
        method: "POST",
        body: body,
      }),
    }),
    editPickupType: builder.mutation({
      query: (body) => ({
        url: "/pickupType/updatePickupType",
        method: "POST",
        body: body,
      }),
    }),
    deletePickupType: builder.mutation({
      query: (body) => ({
        url: "/pickupType/deletePickupType",
        method: "POST",
        body: body,
      }),
    }),
    getPartNameData: builder.mutation({
      query: (body) => ({
        url: "/clickInParts/createPart",
        method: "POST",
        body: body,
      }),
    }),
    getPartNameDatagrid: builder.mutation({
      query: (body) => ({
        url: "/clickInParts/listParts",
        method: "POST",
        body: body,
      }),
    }),
    editPartName: builder.mutation({
      query: (body) => ({
        url: "/clickInParts/updatePart",
        method: "POST",
        body: body,
      }),
    }),
    deletePartName: builder.mutation({
      query: (body) => ({
        url: "/clickInParts/deletePart",
        method: "POST",
        body: body,
      }),
    }),
    getVehiclePredeliveryData: builder.mutation({
      query: (body) => ({
        url: "/vehiclePredeliveryCheckList/createPredeliveryCheck",
        method: "POST",
        body: body,
      }),
    }),
    getVehiclePredeliveryDatagrid: builder.mutation({
      query: (body) => ({
        url: "/vehiclePredeliveryCheckList/listPredeliveryChecks",
        method: "POST",
        body: body,
      }),
    }),
    editVehiclePredelivery: builder.mutation({
      query: (body) => ({
        url: "/vehiclePredeliveryCheckList/updatePredeliveryCheck",
        method: "POST",
        body: body,
      }),
    }),
    deleteVehiclePredelivery: builder.mutation({
      query: (body) => ({
        url: "/vehiclePredeliveryCheckList/deletePredeliveryCheck",
        method: "POST",
        body: body,
      }),
    }),
    getCustomerAccount: builder.query({
      query: () => ({
        url: "/checkListTypes/getCustomerAccountTypes",
        method: "GET",
      }),
    }),
    getCheckListData: builder.mutation({
      query: (body) => ({
        url: "/checkListTypes/createCheckListType",
        method: "POST",
        body: body,
      }),
    }),
    getCheckListDatagrid: builder.mutation({
      query: (body) => ({
        url: "/checkListTypes/listCheckListTypes",
        method: "POST",
        body: body,
      }),
    }),
    editCheckList: builder.mutation({
      query: (body) => ({
        url: "/checkListTypes/updateCheckListType",
        method: "POST",
        body: body,
      }),
    }),
    deleteCheckList: builder.mutation({
      query: (body) => ({
        url: "/checkListTypes/deleteCheckListType",
        method: "POST",
        body: body,
      }),
    }),
    getCheckListCode: builder.query({
      query: () => ({
        url: "/checkListTypes/getCheckListTypes",
        method: "GET",
      }),
    }),
    getInspectionSubsystemData: builder.mutation({
      query: (body) => ({
        url: "/inspectionSubsystemMap/createInspectionSubsystem",
        method: "POST",
        body: body,
      }),
    }),
    getInspectionDatagrid: builder.mutation({
      query: (body) => ({
        url: "/inspectionSubsystemMap/listInspectionSubsystems",
        method: "POST",
        body: body,
      }),
    }),
    editInspection: builder.mutation({
      query: (body) => ({
        url: "/inspectionSubsystemMap/updateInspectionSubsystem",
        method: "POST",
        body: body,
      }),
    }),
    deleteInspection: builder.mutation({
      query: (body) => ({
        url: "/inspectionSubsystemMap/deleteSubsystemMap",
        method: "POST",
        body: body,
      }),
    }),
    getInputTypes: builder.query({
      query: () => ({
        url: "/dockField/listInputTypes",
        method: "GET",
      }),
    }),
    getDockFeildsData: builder.mutation({
      query: (body) => ({
        url: "/dockField/addDockFields",
        method: "POST",
        body: body,
      }),
    }),
    getDockFeildsDatagrid: builder.mutation({
      query: (body) => ({
        url: "/dockField/listDockFields",
        method: "POST",
        body: body,
      }),
    }),
    editDockFeilds: builder.mutation({
      query: (body) => ({
        url: "/dockField/updateDockFields",
        method: "POST",
        body: body,
      }),
    }),
    deleteDockFeilds: builder.mutation({
      query: (body) => ({
        url: "/dockField/deleteDockFields",
        method: "POST",
        body: body,
      }),
    }),
    getDockAbuseFeildsData: builder.mutation({
      query: (body) => ({
        url: "/dockAbuseField/addDockAbuseFields",
        method: "POST",
        body: body,
      }),
    }),
    getDockAbuseFeildsDatagrid: builder.mutation({
      query: (body) => ({
        url: "/dockAbuseField/listDockAbuseFields",
        method: "POST",
        body: body,
      }),
    }),
    editDockAbuseFeilds: builder.mutation({
      query: (body) => ({
        url: "/dockAbuseField/updateDockAbuseFields",
        method: "POST",
        body: body,
      }),
    }),
    deleteDockAbuseFeilds: builder.mutation({
      query: (body) => ({
        url: "/dockAbuseField/deleteDockAbuseFields",
        method: "POST",
        body: body,
      }),
    }),
    getInspectionCheckData: builder.mutation({
      query: (body) => ({
        url: "/inspectionCheckList/createInspectionCheckList",
        method: "POST",
        body: body,
      }),
    }),
    getInspectionCheckDatagrid: builder.mutation({
      query: (body) => ({
        url: "/inspectionCheckList/listInspectionCheckList",
        method: "POST",
        body: body,
      }),
    }),
    editInspectionCheck: builder.mutation({
      query: (body) => ({
        url: "/inspectionCheckList/updateInspectionCheckList",
        method: "POST",
        body: body,
      }),
    }),
    deleteInspectionCheck: builder.mutation({
      query: (body) => ({
        url: "/inspectionCheckList/deleteInspectionCheckList",
        method: "POST",
        body: body,
      }),
    }),
    getSubSystem: builder.query({
      query: () => ({
        url: "/inspectionSubsystemMap/getInspectionSubsystemMaps",
        method: "GET",
      }),
    }),
    getInspectionType: builder.query({
      query: () => ({
        url: "/checkListTypes/getCheckListTypes",
        method: "GET",
      }),
    }),
    getParamCode: builder.query({
      query: () => ({
        url: "/inspectionCheckList/getAllInspectionCheckList",
        method: "GET",
      }),
    }),
    getInspectionRatingData: builder.mutation({
      query: (body) => ({
        url: "/inspectionRatingReason/createInspectionRatingReason",
        method: "POST",
        body: body,
      }),
    }),
    getInspectionRatingDatagrid: builder.mutation({
      query: (body) => ({
        url: "/inspectionRatingReason/listInspectionRatingReasons",
        method: "POST",
        body: body,
      }),
    }),
    editInspectionRating: builder.mutation({
      query: (body) => ({
        url: "/inspectionRatingReason/updateInspectionRatingReason",
        method: "POST",
        body: body,
      }),
    }),
    deleteInspectionRating: builder.mutation({
      query: (body) => ({
        url: "/inspectionRatingReason/deleteInspectionRatingReason",
        method: "POST",
        body: body,
      }),
    }),
    getVehicleInventoryData: builder.mutation({
      query: (body) => ({
        url: "/gateInVehicle/addVehicleInventory",
        method: "POST",
        body: body,
      }),
    }),
    getVehicleInventoryDatagrid: builder.mutation({
      query: (body) => ({
        url: "/gateInVehicle/listGateInVehicleInventory",
        method: "POST",
        body: body,
      }),
    }),
    editVehicleInventory: builder.mutation({
      query: (body) => ({
        url: "/gateInVehicle/updateVehicleInventory",
        method: "POST",
        body: body,
      }),
    }),
    deleteVehicleInventory: builder.mutation({
      query: (body) => ({
        url: "/gateInVehicle/deleteGateInVehicleInventory",
        method: "POST",
        body: body,
      }),
    }),
    getInventoryChecklistData: builder.mutation({
      query: (body) => ({
        url: "/inventoryCheckList/addInventoryCheckList",
        method: "POST",
        body: body,
      }),
    }),
    getInventoryChecklistDatagrid: builder.mutation({
      query: (body) => ({
        url: "/inventoryCheckList/listInventoryCheckList",
        method: "POST",
        body: body,
      }),
    }),
    editInventoryChecklist: builder.mutation({
      query: (body) => ({
        url: "/inventoryCheckList/updateInventoryCheckList",
        method: "POST",
        body: body,
      }),
    }),
    deleteInventoryChecklist: builder.mutation({
      query: (body) => ({
        url: "/inventoryCheckList/deleteInventoryCheckList",
        method: "POST",
        body: body,
      }),
    }),
    getVehicleType: builder.query({
      query: () => ({
        url: "/inventoryCheckList/listVehicleTypes",
        method: "GET",
      }),
    }),
    getOtdFailure: builder.query({
      query: () => ({
        url: "/jobCard/getOTDFailureReasons",
        method: "GET",
      }),
    }),
    getTransactionSub: builder.query({
      query: () => ({
        url: "/jobCard/getTransactionSubstatuses",
        method: "GET",
      }),
    }),
    getAllDsaAgent: builder.mutation({
      query: (body) => ({
        url: "/dsaagent/getAllDsaAgents",
        method: "POST",
        body: body,
      }),
    }),
    getOpenServiceEstimate: builder.mutation({
      query: (body) => ({
        url: "/serviceEstimate/getOpenEstimates",
        method: "POST",
        body: body,
      }),
    }),
    SubmitOpenEstimate: builder.mutation({
      query: (body) => ({
        url: "/serviceEstimate/loadOpenEstimate",
        method: "POST",
        body: body,
      }),
    }),
    getJobCardData: builder.mutation({
      query: (body) => ({
        url: "/jobCard/createJobCard",
        method: "POST",
        body: body,
      }),
    }),
    getJobCardDatagrid: builder.mutation({
      query: (body) => ({
        url: "/jobCard/listJobCards",
        method: "POST",
        body: body,
      }),
    }),
    editJobCard: builder.mutation({
      query: (body) => ({
        url: "/jobCard/updateJobCard",
        method: "POST",
        body: body,
      }),
    }),

    getParamAlertScheduleData: builder.mutation({
      query: (body) => ({
        url: "/paramAlertSchedule/addParamAlertSchedule",
        method: "POST",
        body: body,
      }),
    }),
    getParamAlertScheduleDatagrid: builder.mutation({
      query: (body) => ({
        url: "/paramAlertSchedule/listParamAlertSchedule",
        method: "POST",
        body: body,
      }),
    }),
    editParamAlertSchedule: builder.mutation({
      query: (body) => ({
        url: "/paramAlertSchedule/updateParamAlertSchedule",
        method: "POST",
        body: body,
      }),
    }),
    deleteParamAlertSchedule: builder.mutation({
      query: (body) => ({
        url: "/paramAlertSchedule/deleteParamAlertSchedule",
        method: "POST",
        body: body,
      }),
    }),
    getInspectionList: builder.query({
      query: () => ({
        url: "/checkListTypes/getInspectionTypes",
        method: "GET",
      }),
    }),
    userCreation: builder.mutation({
      query: (body) => ({
        url: "/users/createUser",
        method: "POST",
        body: body,
      }),
    }),
    getUserCreationDatagrid: builder.mutation({
      query: (body) => ({
        url: "/users/listUsers",
        method: "POST",
        body: body,
      }),
    }),

    editUserCreation: builder.mutation({
      query: (body) => ({
        url: "/users/updateUser",
        method: "POST",
        body: body,
      }),
    }),
    deleteUserCreation: builder.mutation({
      query: (body) => ({
        url: "/users/deleteUser",
        method: "POST",
        body: body,
      }),
    }),
    getroleId: builder.mutation({
      query: (body) => ({
        url: "/users/getRoles",
        method: "POST",
        body: body,
      }),
    }),
    createInventoryPhotoCategory: builder.mutation({
      query: (body) => ({
        url: "/inventoryPhotoCategory/createInventoryPhotoCategory",
        method: "POST",
        body: body,
      }),
    }),
    updateInventoryPhotoCategory: builder.mutation({
      query: (body) => ({
        url: "/inventoryPhotoCategory/updateInventoryPhotoCategory",
        method: "POST",
        body: body,
      }),
    }),
    deleteInventoryPhotoCategory: builder.mutation({
      query: (body) => ({
        url: "/inventoryPhotoCategory/deleteInventoryPhotoCategory",
        method: "POST",
        body: body,
      }),
    }),
    listInventoryPhotoCategory: builder.mutation({
      query: (body) => ({
        url: "/inventoryPhotoCategory/listInventoryPhotoCategories",
        method: "POST",
        body: body,
      }),
    }),
    bookingReport: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/loadServiceBookings",
        method: "POST",
        body: body,
      }),
    }),
    serviceBookingExcel: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/exportServiceBookings",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    transactionStatus: builder.mutation({
      query: (body) => ({
        url: "/jobCard/saveBillingDetails",
        method: "POST",
        body: body,
      }),
    }),
    mappingLabours: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getJobcardLabor",
        method: "POST",
        body: body,
      }),
    }),
    mappingMechanic: builder.mutation({
      query: (body) => ({
        url: "/employee/getMechanics",
        method: "POST",
        body: body,
      }),
    }),
    submitMechanicMapping: builder.mutation({
      query: (body) => ({
        url: "/jobCard/createMechanicMapping",
        method: "POST",
        body: body,
      }),
    }),
    cashBill: builder.mutation({
      query: (body) => ({
        url: "/jobCard/listBillJobCards",
        method: "POST",
        body: body,
      }),
    }),
    creditBill: builder.mutation({
      query: (body) => ({
        url: "/jobCard/createMechanicMapping",
        method: "POST",
        body: body,
      }),
    }),
    jobCardPdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateJobCardPDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    workOrder: builder.mutation({
      query: (body) => ({
        url: "/jobCard/oslWorkOrders",
        method: "POST",
        body: body,
      }),
    }),
    worOrderPdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateWorkOrderPDF?billNo=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    allMechanicMappings: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getMechanicMapping",
        method: "POST",
        body: body,
      }),
    }),
    allGatePass: builder.mutation({
      query: (body) => ({
        url: "/jobCard/listGatePassJobCards",
        method: "POST",
        body: body,
      }),
    }),
    submitGatePass: builder.mutation({
      query: (body) => ({
        url: "/jobCard/updateJobcardStatus",
        method: "POST",
        body: body,
      }),
    }),
    preInvoicePdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateJobCardPreInvoicePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    GatePassPdf: builder.query({
      query: (id) => ({
        url: `/jobCard/downloadGatePass?jobcardNo=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    GetJobcardsForOutlet: builder.mutation({
      query: (body) => ({
        url: `/jobCard/getAllJobCardsForOutlets`,
        method: "POST",
        body: body,
      }),
    }),
    GetJobCardDetails: builder.mutation({
      query: (body) => ({
        url: `/jobCard/getJobCardDetails`,
        method: "POST",
        body: body,
      }),
    }),
    CreatePartIssue: builder.mutation({
      query: (body) => ({
        url: `/partissue/createPartIssue`,
        method: "POST",
        body: body,
      }),
    }),
    GetGrnDocuments: builder.query({
      query: () => ({
        url: "/parts/GRNDocuments",
        method: "GET",
      }),
    }),
    CreateGrn: builder.mutation({
      query: (body) => ({
        url: "/parts/createGrn",
        method: "POST",
        body: body,
      }),
    }),
    getopenServiceBook: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/getOpenBookings",
        method: "POST",
        body: body,
      }),
    }),
    GetGrns: builder.mutation({
      query: (body) => ({
        url: "/parts/GRN",
        method: "POST",
        body: body,
      }),
    }),
    submitopenServiceBook: builder.mutation({
      query: (body) => ({
        url: "/serviceBooking/getServiceBookingData",
        method: "POST",
        body: body,
      }),
    }),
    getAddress: builder.mutation({
      query: (body) => ({
        url: "/jobCard/listInsuranceAddresses",
        method: "POST",
        body: body,
      }),
    }),
    submitInsurance: builder.mutation({
      query: (body) => ({
        url: "/jobCard/addInsurance",
        method: "POST",
        body: body,
      }),
    }),
    submitSource: builder.mutation({
      query: (body) => ({
        url: "/sourcetypes/getSourceTypesBySource",
        method: "POST",
        body: body,
      }),
    }),
    getAjcInsurance: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getInsurance",
        method: "POST",
        body: body,
      }),
    }),
    UpdateAjcInsurance: builder.mutation({
      query: (body) => ({
        url: "/jobCard/updateJobCardInsurance",
        method: "POST",
        body: body,
      }),
    }),
    labourPdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateLabourPDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    ajcPreInvoicePdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateJobCardPreInvoiceInsurancePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    pickRoles: builder.mutation({
      query: (body) => ({
        url: "/users/getSecondryRoles",
        method: "POST",
        body: body,
      }),
    }),
    multiUserLogin: builder.mutation({
      query: (body) => ({
        url: "/users/getUserRoles",
        method: "POST",
        body: body,
      }),
    }),
    partsPdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generatePartsPDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    jobCardInvoicePdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateJobCardInvoicePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    jobCardInvoiceInsurancePdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateJobCardInvoiceInsurancePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    labourInsurancePdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateLabourInsurancePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    jobCardStatusReport: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getJobCardStatusReportData",
        method: "POST",
        body: body,
      }),
    }),
    jobCardStatusExcel: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportJobCardStatusReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    deliveryReport: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getJobCardDeliveryReportData",
        method: "POST",
        body: body,
      }),
    }),
    deliveryExcel: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportJobCardDeliveryReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    jobCardWIPReport: builder.mutation({
      query: (body) => ({
        url: "/jobCard/wipGridView",
        method: "POST",
        body: body,
      }),
    }),
    jobCardWIPExcel: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportWipStatusReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    jobCardBillReport: builder.mutation({
      query: (body) => ({
        url: "/jobCard/billGridView",
        method: "POST",
        body: body,
      }),
    }),
    jobCardBillExcel: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportBillReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    getMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getMenuListGrid",
        method: "POST",
        body: body,
      }),
    }),
    createMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/createMenu",
        method: "POST",
        body: body,
      }),
    }),
    UpdateMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/updateMenu",
        method: "POST",
        body: body,
      }),
    }),
    getSubMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getSubMenuListGrid",
        method: "POST",
        body: body,
      }),
    }),
    createSubMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/createSubMenu",
        method: "POST",
        body: body,
      }),
    }),
    UpdateSubMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/updateSubMenu",
        method: "POST",
        body: body,
      }),
    }),
    AllMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getMenuList",
        method: "POST",
        body: body,
      }),
    }),
    AllSubMenuList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getSubmenuData",
        method: "POST",
        body: body,
      }),
    }),
    AllSubMenuData: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getSubMenuList",
        method: "POST",
        body: body,
      }),
    }),
    SubmitMenuSetting: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/createRoleMenu",
        method: "POST",
        body: body,
      }),
    }),
    SubmitSubMenuSetting: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/createRoleSubMenu",
        method: "POST",
        body: body,
      }),
    }),
    WorkOrderStatement: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getWorkOrderReportData",
        method: "POST",
        body: body,
      }),
    }),
    ExportWorkOrderStatement: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportWorkOrderReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    MenuListByRole: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getMenuListByRole",
        method: "POST",
        body: body,
      }),
    }),
    getTabList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/getRoleMenuTabs",
        method: "POST",
        body: body,
      }),
    }),
    createTabList: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/addRoleMenuTab",
        method: "POST",
        body: body,
      }),
    }),
    UpdateTabLists: builder.mutation({
      query: (body) => ({
        url: "/menuSettings/updateRoleMenuTab",
        method: "POST",
        body: body,
      }),
    }),
    GetGrnPdfData: builder.mutation({
      query: (body) => ({
        url: "/parts/Grnpdf",
        method: "POST",
        body: body,
      }),
    }),
    getAllReceipts: builder.mutation({
      query: (body) => ({
        url: "/receipt/createReceipt",
        method: "POST",
        body: body,
      }),
    }),
    GetPartIssue: builder.mutation({
      query: (body) => ({
        url: "/partreturn/getPartIssue",
        method: "POST",
        body: body,
      }),
    }),
    getAllReceiptsDatagrid: builder.mutation({
      query: (body) => ({
        url: "/receipt/listReceipts",
        method: "POST",
        body: body,
      }),
    }),
    createPartReturn: builder.mutation({
      query: (body) => ({
        url: "/partreturn/createPartReturn",
        method: "POST",
        body: body,
      }),
    }),
    getCustomer: builder.mutation({
      query: (body) => ({
        url: "/customer/searchCustomer",
        method: "POST",
        body: body,
      }),
    }),
    getJobCard: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getJobCardData",
        method: "POST",
        body: body,
      }),
    }),
    receiptsDownload: builder.query({
      query: (id) => ({
        url: `/receipt/getReceiptPdf?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/users/resetPassword",
        method: "POST",
        body: body,
      }),
    }),

    getGRNForPurchaseReturnData: builder.mutation({
      query: (body) => ({
        url: "/parts/GetGRNForPurchaseReturn",
        method: "POST",
        body: body,
      }),
    }),
    chittaStatment: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getReceiptReportData",
        method: "POST",
        body: body,
      }),
    }),
    chittaStatmentExcel: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportChittaReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    repairOrderStatment: builder.mutation({
      query: (body) => ({
        url: "/jobCard/getRepairOrderReportData",
        method: "POST",
        body: body,
      }),
    }),
    repairOrderStatmentExcel: builder.mutation({
      query: (body) => ({
        url: "/jobCard/exportRepairOrderReport",
        method: "POST",
        body: body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    CreatePurchaseReturn: builder.mutation({
      query: (body) => ({
        url: "/parts/CreatePurchaseReturn",
        method: "POST",
        body: body,
      }),
    }),
    GetPurchaseReturn: builder.mutation({
      query: (body) => ({
        url: "/parts/GetPurchaseReturn",
        method: "POST",
        body: body,
      }),
    }),
    getConfigurationData: builder.mutation({
      query: (body) => ({
        url: "/users/getAppConfig",
        method: "POST",
        body: body,
      })
    }),
    GetQuickItemSearch: builder.mutation({
      query: (body) => ({
        url: "/parts/GetQuickItemSearch",
        method: "POST",
        body: body,
      }),
    }),
    InsuranceInvoicePdf: builder.query({
      query: (id) => ({
        url: `/jobCard/generateJobCardInvoiceInsurancePDF?id=${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    getCustomerDetails: builder.mutation({
      query: (body) => ({
        url: "/customer/getCustomerData",
        method: "POST",
        body: body,
      }),
    }),
    getCounterSalepart: builder.mutation({
      query: (body) => ({
        url: "/parts/GetCounterSalePart",
        method: "POST",
        body: body,
      }),
    }),
    createCounterSale: builder.mutation({
      query: (body) => ({
        url: "/countersale/createCounterSale",
        method: "POST",
        body: body,
      }),
    }),
    getCounterSale: builder.mutation({
      query: (body) => ({
        url: "/countersale/getCounterSale",
        method: "POST",
        body: body,
      }),
    }),
    LabourUploadsData: builder.mutation({
      query: (body) => ({
        url: "/bulkUpload/bulkLaborSchedule",
        method: "POST",
        body: body,
      })
    }),
    ItemUploadsData: builder.mutation({
      query: (body) => ({
        url: "/bulkUpload/bulkItemMaster",
        method: "POST",
        body: body,
      })
    }),
    CustomerUploadsData: builder.mutation({
      query: (body) => ({
        url: "/customer/createBulkCustomer",
        method: "POST",
        body: body,
      })
    }),
    VehicleUploadsData: builder.mutation({
      query: (body) => ({
        url: "/vehicle/createBulkVehicle",
        method: "POST",
        body: body,
      })
    }),
    MakeUploadsData: builder.mutation({
      query: (body) => ({
        url: "/bulkUpload/bulkMake",
        method: "POST",
        body: body,
      })
    }),
    ModelUploadsData: builder.mutation({
      query: (body) => ({
        url: "/bulkUpload/bulkModel",
        method: "POST",
        body: body,
      })
    }),
  getCounterSalePdf: builder.mutation({
    query: (body) => ({
      url: "/countersale/countersalepdf",
      method: "POST",
      body: body,
    })
  }),
  getCountersaleForReturn: builder.mutation({
    query: (body) => ({
      url: "/countersale/getCountersaleForReturn",
      method: "POST",
      body: body,
    })
  }),
  CreateCountersaleReturn: builder.mutation({
    query: (body) => ({
      url: "/countersale/createCountersaleReturn",
      method: "POST",
      body: body,
    })
  }), 
  GetCountersaleReturn: builder.mutation({
    query: (body) => ({
      url: "/countersale/getCountersaleReturn",
      method: "POST",
      body: body,
    })
  }),
  GetCustomerValidate: builder.mutation({
    query: (body) => ({
      url: "/customer/validateBulkCustomer",
      method: "POST",
      body: body,
    })
  }),
  GetVehicleValidate: builder.mutation({
    query: (body) => ({
      url: "/vehicle/validateBulkVehicle",
      method: "POST",
      body: body,
    })
  }),
  GetMakeValidate: builder.mutation({
    query: (body) => ({
      url: "/bulkUpload/bulkValidateMake",
      method: "POST",
      body: body,
    })
  }),
  GetModelValidate: builder.mutation({
    query: (body) => ({
      url: "/bulkUpload/bulkValidateModel",
      method: "POST",
      body: body,
    })
  }),
  GetItemMasterValidate: builder.mutation({
    query: (body) => ({
      url: "/bulkUpload/validateItemMaster",
      method: "POST",
      body: body,
    })
  }),
  GetLabourValidate: builder.mutation({
    query: (body) => ({
      url: "/bulkUpload/validateLaborSchedule",
      method: "POST",
      body: body,
    })
  }),

  GetPurchaseReport: builder.mutation({
    query: (body) => ({
      url: "/parts/GetPurchaseReport",
      method: "POST",
      body: body,
      responseHandler: (response) => response.blob(),
    })
  }),
  GetPurchaseAxaptaReport: builder.mutation({
    query: (body) => ({
      url: "/parts/GetPurchaseAxaptaReport",
      method: "POST",
      body: body,
      responseHandler: (response) => response.blob(),
    })
  }),
})
})

export const {
  useSaveLoginMutation,
  useForgetPasswordMutation,
  useMenuListMutation,
  useItemMasterDatagridMutation,
  useItemGroupQuery, useGetInspectionSubsystemDataMutation,
  useGetUomDataMutation, useGetInspectionListQuery,
  useGetUomDatagridMutation, useSubmitOpenEstimateMutation,
  useGetCompanyQuery, useGetroleIdMutation,
  useGetitemCategoryQuery, useGetopenServiceBookMutation,
  useGetHsnQuery, useGetOtdFailureQuery, useGetTransactionSubQuery,
  useGetUomQuery, useGetVehicleTypeQuery, useGetOpenServiceEstimateMutation,
  useEditUomMutation, useGetInputTypesQuery, useLazyWorOrderPdfQuery,
  useEditItemGroupMutation, useGetCheckListCodeQuery,
  useGetItemGroupDataMutation, useGetCustomerAccountQuery,
  useGetItemGroupDatagridMutation, useLazyGetPdfDownloadQuery,
  useEditgetItemCategoryMutation, useGetsearchPartsDetailsMutation, useGetAllPartsDetailsMutation,
  useGetItemCategoryDatagridMutation, useAllUomQuery, useGetsearchOslLabourDetailsMutation,
  useGetItemCategoryMutation, useGetsearchLabourDetailsMutation,
  useEditHsnsMutation, useGetLabourDetailsMutation, useGetOslLabourDetailsMutation,
  useGetHsnsDataMutation,
  useGetHsnsDatagridMutation, useGetAllVechileDetailsMutation,
  useEditAggregateMutation, useGetAllCustomerMutation,
  useGetAggregateDataMutation, useGetAllBillTypeQuery, useGetAllCustomerCategoriesQuery,
  useGetAllCustomerTypeQuery, useGetAllInsuranceQuery, useWorkOrderMutation,
  useGetAggregateDatagridMutation,
  useAddItemMasterMutation, useGetAllEmpoyeeRolesQuery, useGetAllVechicleColorsQuery,
  useEditItemMasterMutation, useGetAllBankQuery, useGetAllOuletQuery, useGetFuelQuery,
  useEditSubAggregateMutation, useGetAllHsnsDataMutation,
  useGetSubAggregateDataMutation, useAuditFilterMutation, useUserLogFilterMutation,
  useGetSubAggregateDatagridMutation, useGetUserLogMutation, useLogOutMutation,
  useGetAllAggregateQuery, useGetAllSubAggregateMutation, useGetAuditLogMutation,
  useEditMakeMutation, useGetMakeDataMutation, useGetMakeDatagridMutation,
  useEditVariantMutation, useGetVariantDataMutation, useGetVariantDatagridMutation,
  useEditModelMutation, useGetModelDataMutation, useGetModelDatagridMutation,
  useEditServiceTypeMutation, useGetServiceTypeDataMutation, useGetServiceTypeDatagridMutation,
  useEditRepairTypeMutation, useGetRepairTypeDataMutation, useGetRepairTypeDatagridMutation,
  useEditSourceMutation, useGetSourceDataMutation, useGetSourceDatagridMutation,
  useGetAllVaraintQuery, useGetAllModelMutation,
  useEditSourceTypeMutation, useGetSourceTypeDataMutation, useGetSourceTypeDatagridMutation,
  useEditVendorMutation, useGetVendorDataMutation, useGetVendorDatagridMutation, useGetAllPincodeMutation,
  useEditDispositionMutation, useGetDispositionDataMutation, useGetDispositionDatagridMutation,
  useEditSubDispositionMutation, useGetSubDispositionDataMutation, useGetSubDispositionDatagridMutation,
  useEditLabourMutation, useGetLabourDataMutation, useGetLabourDatagridMutation,
  useEditDsaAgentMutation, useGetDsaAgentDataMutation, useGetDsaAgentDatagridMutation,
  useEditOutletMutation, useGetOutletDataMutation, useGetOutletDatagridMutation,
  useEditBinLocationMutation, useGetBinLocationDataMutation, useGetBinLocationDatagridMutation,
  useEditEmployeeRoleMutation, useGetEmployeeRoleDataMutation, useGetEmployeeRoleDatagridMutation,
  useEditEmployeeMutation, useGetEmployeeDataMutation, useGetEmployeeDatagridMutation,
  useEditCustomerMutation, useGetCustomerDataMutation, useGetCustomerDatagridMutation,
  useEditVehicleMutation, useGetVehicleDataMutation, useGetVehicleDatagridMutation,
  useEditInsuranceMutation, useGetInsuranceDataMutation, useGetInsuranceDatagridMutation,
  useEditServiceEstimateMutation, useGetServiceEstimateDataMutation, useGetServiceEstimateDatagridMutation,
  useEditServiceBookingMutation, useGetServiceBookingDataMutation, useGetServiceBookingDatagridMutation,
  useEditTyreOemMutation, useGetTyreOemDataMutation, useGetTyreOemDatagridMutation, useDeleteTyreOemMutation,
  useEditBatteryOemMutation, useGetBatteryOemDataMutation, useGetBatteryOemDatagridMutation, useDeleteBatteryOemMutation,
  useEditTyreSizeMutation, useGetTyreSizeDataMutation, useGetTyreSizeDatagridMutation, useDeleteTyreSizeMutation,
  useEditPickupTypeMutation, useGetPickupTypeDataMutation, useGetPickupTypeDatagridMutation, useDeletePickupTypeMutation,
  useDeletePartNameMutation, useEditPartNameMutation, useGetPartNameDataMutation, useGetPartNameDatagridMutation,
  useDeleteVehiclePredeliveryMutation, useEditVehiclePredeliveryMutation, useGetVehiclePredeliveryDataMutation, useGetVehiclePredeliveryDatagridMutation,
  useDeleteCheckListMutation, useEditCheckListMutation, useGetCheckListDataMutation, useGetCheckListDatagridMutation,
  useDeleteInspectionMutation, useEditInspectionMutation, useGetInspectionDataMutation, useGetInspectionDatagridMutation,
  useDeleteDockAbuseFeildsMutation, useEditDockAbuseFeildsMutation, useGetDockAbuseFeildsDataMutation, useGetDockAbuseFeildsDatagridMutation,
  useDeleteDockFeildsMutation, useEditDockFeildsMutation, useGetDockFeildsDataMutation, useGetDockFeildsDatagridMutation,
  useDeleteInspectionCheckMutation, useEditInspectionCheckMutation, useGetInspectionCheckDataMutation, useGetInspectionCheckDatagridMutation, useGetInspectionTypeQuery, useGetSubSystemQuery,
  useDeleteInspectionRatingMutation, useEditInspectionRatingMutation, useGetInspectionRatingDataMutation, useGetInspectionRatingDatagridMutation, useGetParamCodeQuery,
  useDeleteVehicleInventoryMutation, useEditVehicleInventoryMutation, useGetVehicleInventoryDataMutation, useGetVehicleInventoryDatagridMutation,
  useDeleteInventoryChecklistMutation, useEditInventoryChecklistMutation, useGetInventoryChecklistDataMutation, useGetInventoryChecklistDatagridMutation,
  useEditJobCardMutation, useGetJobCardDataMutation, useGetJobCardDatagridMutation,
  useDeleteParamAlertScheduleMutation, useEditParamAlertScheduleMutation, useGetParamAlertScheduleDataMutation, useGetParamAlertScheduleDatagridMutation,
  useUserCreationMutation, useGetUserCreationDatagridMutation, useDeleteUserCreationMutation, useEditUserCreationMutation, useCreateInventoryPhotoCategoryMutation, useDeleteInventoryPhotoCategoryMutation, useUpdateInventoryPhotoCategoryMutation, useListInventoryPhotoCategoryMutation,
  useBookingReportMutation, useServiceBookingExcelMutation, useTransactionStatusMutation,
  useMappingLaboursMutation, useMappingMechanicMutation, useSubmitMechanicMappingMutation,
  useCashBillMutation, useCreditBillMutation, useLazyJobCardPdfQuery, useAllMechanicMappingsMutation, useLazyPreInvoicePdfQuery,
  useGetAllSourceMutation, useGetAllSourceTypeMutation, useGetAllServiceTypeMutation, useGetAllRepairTypeMutation,
  useGetAllVedorMutation, useGetAllDispositionMutation, useGetAllDsaAgentMutation, useGetAllMakesMutation, useGetAllModelsMutation,
  useAllGatePassMutation, useSubmitGatePassMutation, useLazyGatePassPdfQuery, useGetJobcardsForOutletMutation, useGetJobCardDetailsMutation,
  useCreatePartIssueMutation, useGetGrnDocumentsQuery, useCreateGrnMutation, useGetGrnsMutation,
  useSubmitopenServiceBookMutation,
  useGetAddressMutation, useSubmitInsuranceMutation, useSubmitSourceMutation, useGetAjcInsuranceMutation, useUpdateAjcInsuranceMutation,
  useLazyLabourPdfQuery, useLazyAjcPreInvoicePdfQuery, usePickRolesMutation, useMultiUserLoginMutation, useLazyPartsPdfQuery,
  useLazyJobCardInvoicePdfQuery, useLazyJobCardInvoiceInsurancePdfQuery, useLazyLabourInsurancePdfQuery,
  useJobCardStatusReportMutation, useJobCardStatusExcelMutation, useDeliveryReportMutation, useDeliveryExcelMutation,
  useJobCardWIPExcelMutation, useJobCardWIPReportMutation, useJobCardBillReportMutation, useJobCardBillExcelMutation,
  useGetMenuListMutation, useCreateMenuListMutation, useUpdateMenuListMutation,
  useGetSubMenuListMutation, useCreateSubMenuListMutation, useUpdateSubMenuListMutation, useResetPasswordMutation,
  useAllMenuListMutation, useAllSubMenuListMutation, useSubmitMenuSettingMutation, useSubmitSubMenuSettingMutation,
  useExportWorkOrderStatementMutation, useWorkOrderStatementMutation, useMenuListByRoleMutation, useAllSubMenuDataMutation,
  useCreateTabListMutation, useGetTabListMutation, useUpdateTabListsMutation, useLazyReceiptsDownloadQuery,
  useGetCustomerMutation, useGetJobCardMutation, useGetAllReceiptsDatagridMutation, useGetAllReceiptsMutation,
  useChittaStatmentMutation, useChittaStatmentExcelMutation, useGetConfigurationDataMutation,
  useGetGrnPdfDataMutation, useGetPartIssueMutation,
  useCreatePartReturnMutation, useGetGRNForPurchaseReturnDataMutation, useCreatePurchaseReturnMutation,
  useGetPurchaseReturnMutation, useGetQuickItemSearchMutation, useLazyInsuranceInvoicePdfQuery,
  useItemUploadsDataMutation, useLabourUploadsDataMutation, useCustomerUploadsDataMutation, useVehicleUploadsDataMutation,
  useGetCustomerDetailsMutation, useGetCounterSalepartMutation, useCreateCounterSaleMutation, useGetCounterSaleMutation, useGetCounterSalePdfMutation,
  useMakeUploadsDataMutation, useModelUploadsDataMutation,useGetCountersaleForReturnMutation,useCreateCountersaleReturnMutation,
  useGetCountersaleReturnMutation,useGetCustomerValidateMutation,useGetVehicleValidateMutation,
  useGetPurchaseReportMutation,useGetPurchaseAxaptaReportMutation,useGetMakeValidateMutation,useGetModelValidateMutation,
  useGetItemMasterValidateMutation,useGetLabourValidateMutation,useRepairOrderStatmentExcelMutation,useRepairOrderStatmentMutation





} = dashboardApi;
