import { Box, Toolbar, alpha, useTheme } from '@mui/material';
import React from 'react';

function Main({page,isOpen}) {
    let theme = useTheme();
    const mainContentWidth = isOpen ? 'calc(100% - 215px)' : 'calc(100% - 70px)';

    return (
    <Box sx={{background:'transparent', backgroundColor:"#f2f5fb",background:alpha(theme.palette.background.default,.7), width:mainContentWidth, height:'100%'}}>
        <Toolbar/>
        <Box sx={{
            height:'calc(100% - 45px)', 
            marginBottom:'20px', 
            boxShadow:'0px 0px 15px rgba(0,0,0,.02)',
            margin:"15px",
           
            }}>
            {page}
        </Box>
    </Box>
  )
}

export default Main