
import { ThemeProvider } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import React from "react";
import { themeCreator } from "./themelist";


const ThemeProviderWrapper = (props) => {
  
  const themeMode = "light";
  const theme = themeCreator({themeMode,});
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
