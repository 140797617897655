import { Autocomplete, Box, Checkbox, DialogActions, Divider, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FaAddressCard } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAddressMutation, useGetAllInsuranceQuery, useGetAllPincodeMutation, useSubmitInsuranceMutation, useUpdateAjcInsuranceMutation } from '../../../store/slice/ApiSlice';
import { SoftButton, StyledPaperShadow } from "../../../theme/StyledElement";
import StyledBreadcrumb from "../../../Utils/StyledBreadcrumb";
import StyledSnackBar from "../../../Utils/StyledSnackBar";

const AjcInsurance = () => {
    const { register, handleSubmit, reset, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            address: "",
            newAddress: "",
            pincode: '',
            provider: null,
            gstinNumber: '',
            claimNo: '',
            policyNo: '',
            policyExpDate: '',
            surveyorName: '',
            surveyorMobileNo: '',
            surveyorEmail: '',
            surveyorIntimatedDateTime: '',
            surveyorProposedDateTime: '',
            surveyorVisitedDateTime: '',
            surveyorApprovedDateTime: '',
            estimatedCost: 0
        }
    });
    const [getAllPincodeData] = useGetAllPincodeMutation();
    const [getAddress, { data: getAddressData }] = useGetAddressMutation();
    const [submitInsurance] = useSubmitInsuranceMutation();
    const [UpdateInsurance] = useUpdateAjcInsuranceMutation();
    const { data: allInsurance } = useGetAllInsuranceQuery();

    const [status, setStatus] = useState(0);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedPincode, setSelectedPincode] = useState('');
    const [dateOpen, setDateOpen] = useState(false);
    const [dateOpen1, setDateOpen1] = useState(false);
    const [dateOpen2, setDateOpen2] = useState(false);
    const [dateOpen3, setDateOpen3] = useState(false);
    const [dateOpen4, setDateOpen4] = useState(false);
    const [isNewAddress, setIsNewAddress] = useState(0);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const provider = allInsurance?.InsuracesData || [];
    const Address = getAddressData?.AddressData || [];

    const location = useLocation();
    const insuranceData = location?.state?.insuranceData;
    const navigate = useNavigate();

    const selectedInputType = useWatch({
        control,
        name: 'provider'
    });

    const resetForm = () => {
        reset({
            address: "",
            newAddress: "",
            pincode: '',
            provider: null,
            gstinNumber: '',
            claimNo: '',
            policyNo: '',
            policyExpDate: '',
            surveyorName: '',
            surveyorMobileNo: '',
            surveyorEmail: '',
            surveyorIntimatedDateTime: '',
            surveyorProposedDateTime: '',
            surveyorVisitedDateTime: '',
            surveyorApprovedDateTime: '',
            estimatedCost: 0
        });
    };

    useEffect(() => {
        updateFormData();
    }, [insuranceData])

    const updateFormData = async () => {
        if (insuranceData.status === 0) {
            const selectedProvider = provider?.find(d => d.insuranceName === insuranceData.insurance_provider_name) || null;
            setValue("provider", selectedProvider)
            setIsEditing(true);
            setValue('pincode', insuranceData?.insurance_pincode);
            let addressresponse;
            if (selectedProvider && insuranceData?.insurance_pincode) {
                const payload = {
                    provider: selectedProvider,
                    pincode: insuranceData?.insurance_pincode,
                }
                addressresponse = await getAddress(payload)
                if (addressresponse.data.AddressData.length > 0) {
                    const add = addressresponse.data.AddressData
                    const selectedAddress = add?.find(d => d.address === insuranceData.insurance_address) || null;
                    setValue("address", selectedAddress)
                }

            }
            setSelectedState(insuranceData?.insurance_state);
            setSelectedCity(insuranceData?.insurance_city);
            setValue('gstinNumber', insuranceData?.gstin_number);
            setValue('claimNo', insuranceData?.claim_no);
            setValue('policyNo', insuranceData?.policy_no);
            const selectedPolicyExpDate = insuranceData.policy_exp_date ? dayjs(insuranceData.policy_exp_date) : null;
            setValue('policyExpDate', selectedPolicyExpDate);

            setValue('surveyorName', insuranceData?.surveyor_name);
            setValue('surveyorMobileNo', insuranceData?.surveyor_mob);
            setValue('surveyorEmail', insuranceData?.surveyor_email);
            setValue('estimatedCost', insuranceData?.estimated_cost);
            setValue('surveyorIntimatedDateTime', insuranceData.surveyor_intimate_date ? dayjs(insuranceData.surveyor_intimate_date, "DD-MM-YYYY HH:mm:ss") : null);
            setValue('surveyorProposedDateTime', insuranceData.surveyor_proposed_date ? dayjs(insuranceData.surveyor_proposed_date, "DD-MM-YYYY HH:mm:ss") : null);
            setValue('surveyorVisitedDateTime', insuranceData.surveyor_visited_date ? dayjs(insuranceData.surveyor_visited_date, "DD-MM-YYYY HH:mm:ss") : null);
            setValue('surveyorApprovedDateTime', insuranceData.surveyor_approved_date ? dayjs(insuranceData.surveyor_approved_date, "DD-MM-YYYY HH:mm:ss") : null);
            setCurrentItemId(insuranceData.transaction_id);

        } else if (insuranceData.status === 2) {
            setStatus(1)
        }
    }
    const onSubmit = async (data) => {

        const formData = {
            ...data,
            transactionId: insuranceData?.id,
            status: status ? 1 : 0,
            customerState: selectedState,
            customerCity: selectedCity,
            pincode: data.pincode,
            address: data?.address?.address,
            policyExpDate: data?.policyExpDate ? data?.policyExpDate?.format('YYYY-MM-DD') : "",
            surveyorIntimatedDateTime: data?.surveyorIntimatedDateTime ? dayjs(data?.surveyorIntimatedDateTime).format('YYYY-MM-DD HH:mm:ss') : "",
            surveyorProposedDateTime: data?.surveyorProposedDateTime ? dayjs(data?.surveyorProposedDateTime).format('YYYY-MM-DD HH:mm:ss') : "",
            surveyorVisitedDateTime: data?.surveyorVisitedDateTime ? dayjs(data?.surveyorVisitedDateTime).format('YYYY-MM-DD HH:mm:ss') : "",
            surveyorApprovedDateTime: data?.surveyorApprovedDateTime ? dayjs(data?.surveyorApprovedDateTime).format('YYYY-MM-DD HH:mm:ss') : "",
            isNewAddress: isNewAddress ? isNewAddress : 0
        };

        let response;
        if (insuranceData?.status === 0 || insuranceData?.status === 2) {
            response = await UpdateInsurance({ id: currentItemId, ...formData })
            if (response && response.data.requestSuccessful === true) {
                setCurrentItemId(null);
                setIsEditing(false);
            }
        } else {
            response = await submitInsurance(formData)
        }


        if (response && response.data) {
            let message;
            if (response.data.validationErrors) {
                message = response.data.validationErrors;
                setDataStatusConfirm("Error");
            } else {
                message = response.data.message;
                setDataStatusConfirm("Success");
                navigate("/createJobcard")

            }
            setSnackBarMessage(message);
        } else {
            let message;
            if (response?.error?.data?.validationErrors) {
                setDataStatusConfirm("Error");
                message = response.error.data.validationErrors;
            } else {
                setDataStatusConfirm("Success");
                message = response?.error?.data.message;
                resetForm();
            }
            setSnackBarMessage(message);
        }

        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.checked ? 1 : 0);
    };

    const handlePincodeChange = async (event) => {
        let pincode = event.target.value.replace(/\D/g, '').slice(0, 6);
        if (pincode.length === 6) {
            const payLoad = { pinCode: pincode };
            const response = await getAllPincodeData(payLoad);
            if (response?.data?.requestSuccessful) {
                setSelectedState(response.data.pincodeData.state);
                setSelectedCity(response.data.pincodeData.city);
                setSelectedPincode(response.data.pincodeData.pinCode);
            }
        }
    };

    const handleInputChange = (event) => {
        event.target.value = event.target.value.replace(/\s+/g, '').toUpperCase();
    };

    useEffect(() => {

        if (selectedInputType !== null && selectedPincode) {
            const payload = {
                provider: selectedInputType,
                pincode: selectedPincode,
            }
            getAddress(payload)
            setIsNewAddress(0);
            setValue('address', null);
        }

    }, [selectedInputType, selectedPincode])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <StyledBreadcrumb mainDiv="Create Jobcard" subDiv="Insurance Details" />
                <StyledPaperShadow elevation={0} sx={{ display: "inline-block", width: "100%" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", alignItems: "center", paddingTop: "15px" }}>
                                    <Checkbox checked={status === 1} onChange={handleStatusChange} />
                                    <FormLabel>Direct</FormLabel>
                                </Box>
                            </Grid>

                            {(status === 0) && (
                                <>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="provider"
                                            control={control}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    options={provider}
                                                    getOptionLabel={(option) => option?.insuranceName || ''}
                                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                    renderInput={(params) => <TextField {...params} label="Provider" variant="standard" />}
                                                    value={field.value || null}
                                                    onChange={(event, value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('pincode')}
                                            fullWidth
                                            variant="standard"
                                            label="Pincode"
                                            InputLabelProps={insuranceData.status === 0 ? { shrink: true } : {}}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                            onChange={handlePincodeChange}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="State"
                                            value={selectedState}
                                            InputLabelProps={selectedState ? { shrink: true } : {}}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="City"
                                            value={selectedCity}
                                            InputLabelProps={selectedCity ? { shrink: true } : {}}
                                            disabled
                                        />
                                    </Grid>
                                    {isNewAddress === 0 && (
                                        <Grid item xs={3}>
                                            <Controller
                                                name="address"
                                                control={control}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        options={Address} // Remove 'New Address+' from the options
                                                        getOptionLabel={(option) => option?.address || ''}
                                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Address"
                                                                variant="standard"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <>
                                                                            {params.InputProps.endAdornment}
                                                                            <FaAddressCard
                                                                                variant="outlined"
                                                                                onClick={() => {
                                                                                    setIsNewAddress(1);
                                                                                    setValue('address', null);
                                                                                }}
                                                                                style={{ fontSize: '1.5rem', cursor: 'pointer', }}
                                                                            >

                                                                            </FaAddressCard>
                                                                        </>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                        value={field.value || null}
                                                        onChange={(event, value) => {
                                                            setIsNewAddress(0);  // Existing address selected
                                                            field.onChange(value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                    )}

                                    {isNewAddress === 1 && (
                                        <Grid item xs={3}>
                                            <TextField
                                                {...register('newAddress')}
                                                fullWidth
                                                variant="standard"
                                                label="New Address"
                                                InputLabelProps={insuranceData.status === 0 ? { shrink: true } : {}}
                                            />
                                        </Grid>
                                    )}


                                    <Grid item xs={3}>
                                        <TextField {...register('gstinNumber')}
                                            fullWidth variant="standard" label="Gstin Number"
                                            InputLabelProps={insuranceData.status === 0 ? { shrink: true } : {}}
                                            onInput={handleInputChange} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField {...register('claimNo')}
                                            fullWidth variant="standard" label="Claim No"
                                            InputLabelProps={insuranceData.status === 0 ? { shrink: true } : {}}
                                            onInput={handleInputChange} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField {...register('policyNo')}
                                            fullWidth variant="standard" label="Policy No"
                                            InputLabelProps={insuranceData.status === 0 ? { shrink: true } : {}}
                                            onInput={handleInputChange} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            name="policyExpDate"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    label="Policy Expiry Date"
                                                    open={dateOpen4}
                                                    onClose={() => setDateOpen4(false)}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'standard',
                                                            fullWidth: true,
                                                            readOnly: true,
                                                            onClick: () => setDateOpen4(true),
                                                        },
                                                        openPickerButton: {
                                                            onClick: () => setDateOpen4(true)
                                                        }
                                                    }}
                                                    format='DD/MM/YYYY'
                                                    value={field.value || null}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography sx={{ margin: "20px 0px", fontWeight: "700", fontSize: "18px", color: '#464B7C' }}>Surveyor Details</Typography>
                                        <Divider sx={{ mb: .8 }} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField {...register('surveyorName')}
                                            fullWidth variant="standard" label="Surveyor Name"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('surveyorMobileNo', {
                                                maxLength: {
                                                    value: 10,
                                                    message: 'Mobile Number cannot exceed 10 digits'
                                                }
                                            })}
                                            fullWidth
                                            variant="standard"
                                            label="Surveyor MobileNo"
                                            type="number"
                                            helperText={errors.mobileNumber ? errors.mobileNumber.message : ''}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (value.length > 10) {
                                                    e.target.value = value.slice(0, 10);
                                                }
                                            }}
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                            inputProps={{
                                                maxLength: 10,
                                                min: 0,
                                                pattern: "[0-9]*",
                                                inputMode: "numeric"
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            {...register('surveyorEmail')}
                                            fullWidth
                                            variant="standard"
                                            label="Surveyor Email"
                                            InputLabelProps={isEditing ? { shrink: true } : {}}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField {...register('estimatedCost')} type='number' fullWidth variant="standard" label="Estimated Cost" />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            name="surveyorIntimatedDateTime"
                                            control={control}
                                            render={({ field }) => (
                                                <DateTimePicker
                                                    {...field}
                                                    label="Surveyor Intimated Date"
                                                    open={dateOpen}
                                                    onClose={() => setDateOpen(false)}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'standard',
                                                            fullWidth: true,
                                                            readOnly: true,
                                                            onClick: () => setDateOpen(true),

                                                        },
                                                        openPickerButton: {
                                                            onClick: () => setDateOpen(true)
                                                        }
                                                    }}
                                                    format='DD/MM/YYYY HH:mm A'
                                                    value={field.value || null}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            name="surveyorProposedDateTime"
                                            control={control}
                                            render={({ field }) => (
                                                <DateTimePicker
                                                    {...field}
                                                    label="Surveyor Proposed Date"
                                                    open={dateOpen1}
                                                    onClose={() => setDateOpen1(false)}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'standard',
                                                            fullWidth: true,
                                                            readOnly: true,
                                                            onClick: () => setDateOpen1(true),
                                                        },
                                                        openPickerButton: {
                                                            onClick: () => setDateOpen1(true)
                                                        }
                                                    }}
                                                    format='DD/MM/YYYY HH:mm A'
                                                    value={field.value || null}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            name="surveyorVisitedDateTime"
                                            control={control}
                                            render={({ field }) => (
                                                <DateTimePicker
                                                    {...field}
                                                    label="Surveyor Visited Date"
                                                    open={dateOpen2}
                                                    onClose={() => setDateOpen2(false)}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'standard',
                                                            fullWidth: true,
                                                            readOnly: true,
                                                            onClick: () => setDateOpen2(true),
                                                        },
                                                        openPickerButton: {
                                                            onClick: () => setDateOpen2(true)
                                                        }
                                                    }}
                                                    format='DD/MM/YYYY HH:mm A'
                                                    value={field.value || null}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            name="surveyorApprovedDateTime"
                                            control={control}
                                            render={({ field }) => (
                                                <DateTimePicker
                                                    {...field}
                                                    label="Surveyor Approved Date"
                                                    open={dateOpen3}
                                                    onClose={() => setDateOpen3(false)}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'standard',
                                                            fullWidth: true,
                                                            readOnly: true,
                                                            onClick: () => setDateOpen3(true),
                                                        },
                                                        openPickerButton: {
                                                            onClick: () => setDateOpen3(true)
                                                        }
                                                    }}
                                                    format='DD/MM/YYYY HH:mm A'
                                                    value={field.value || null}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <DialogActions>
                                    <SoftButton type="submit" variant="contained">
                                        {insuranceData?.status === 0 || insuranceData?.status === 2 ? 'Update' : 'Add'}
                                    </SoftButton>
                                </DialogActions>
                            </Grid>

                        </Grid>
                    </form>
                </StyledPaperShadow>
                {open && (
                    <StyledSnackBar
                        open={true}
                        message={snackBarMessage}
                        status={dataStatusConfirm}
                    />
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default AjcInsurance;
