import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Icons imports
import { ReactComponent as User } from '../../assets/images/accounts.svg';
import { ReactComponent as Calendar } from '../../assets/images/Calendar_Active.svg';
import { ReactComponent as Dashboard } from '../../assets/images/Dashboard.svg';
import { ReactComponent as Item } from '../../assets/images/item master_active.svg';
import { ReactComponent as Log } from '../../assets/images/log_Active.svg';
import { ReactComponent as FitMaster, ReactComponent as Master } from '../../assets/images/Master_Active.svg';
import { ReactComponent as Receipts } from '../../assets/images/Messages_Active.svg';
import { ReactComponent as Call } from '../../assets/images/Notification_Active.svg';
import { ReactComponent as Report } from '../../assets/images/Settings_Active.svg';
import { ReactComponent as Transaction } from '../../assets/images/transactions_Active.svg';
import { ReactComponent as Menus } from '../../assets/images/receipt.svg';
import { ReactComponent as Stock } from '../../assets/images/stock.svg';
import { ReactComponent as Sale } from '../../assets/images/sales.svg';
import { ReactComponent as Purchase } from '../../assets/images/purchase.svg';
import { ReactComponent as Bulk } from '../../assets/images/bulkUpload.svg';

// Active icons
import { ReactComponent as UserActive } from '../../assets/images/accounts.svg';
import { ReactComponent as CalendarActive } from '../../assets/images/Calendar_Active.svg';
import { ReactComponent as DashboardActive } from '../../assets/images/Dashboard.svg';
import { ReactComponent as ItemActive } from '../../assets/images/item master_active.svg';
import { ReactComponent as LogActive } from '../../assets/images/log_Active.svg';
import { ReactComponent as FitMasterActive, ReactComponent as MasterActive } from '../../assets/images/Master_Active.svg';
import { ReactComponent as ReceiptsActive } from '../../assets/images/Messages_Active.svg';
import { ReactComponent as CallActive } from '../../assets/images/Notification_Active.svg';
import { ReactComponent as ReportActive } from '../../assets/images/Settings_Active.svg';
import { ReactComponent as TransactionActive } from '../../assets/images/transactions_Active.svg';
import { ReactComponent as MenuActive } from '../../assets/images/receipt.svg';
import { ReactComponent as StockActive } from '../../assets/images/stock.svg';
import { ReactComponent as SaleActive } from '../../assets/images/sales.svg';
import { ReactComponent as PurchaseActive } from '../../assets/images/purchase.svg';
import { ReactComponent as BulkActive } from '../../assets/images/bulkUpload.svg';

const iconMapping = {
  Dashboard,
  Master,
  Transaction,
  Receipts,
  Calendar,
  Report,
  Call,
  Item,
  User,
  Log,
  FitMaster,
  Menus,
  Stock,
  Sale,
  Purchase,
  Bulk
};

const activeIconMapping = {
  DashboardActive,
  UserActive,
  MasterActive,
  TransactionActive,
  ReceiptsActive,
  CalendarActive,
  ReportActive,
  CallActive,
  ItemActive,
  LogActive,
  FitMasterActive,
  MenuActive,
  StockActive,
  SaleActive,
  PurchaseActive,
  BulkActive

};

const ExpandMenuList = () => {
  const [open, setOpen] = useState({});
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const menuList = JSON.parse(localStorage.getItem("menuList"));

  const handleClick = (menuItem) => {
    if (menuItem.submenu) {
      setOpen((prevOpen) => {
        // Collapse all other submenus except the current one
        const newOpenState = Object.keys(prevOpen).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
        return {
          ...newOpenState,
          [menuItem.title]: !prevOpen[menuItem.title],
        };
      });
      handleNavigation(menuItem.path, menuItem.title); // Navigate to main menu path
    } else {
      setOpen({});
      handleNavigation(menuItem.path, menuItem.title);
    }
  };

  const handleNavigation = (path, menuTitle, subMenuTitle = null) => {
    setSelectedMenuItem(menuTitle);
    setSelectedSubMenuItem(subMenuTitle);
    if (path) navigate(path);
  };

  return (
    <Box sx={{ width: '100%', height: '100%', background: 'transparent', backgroundColor: theme.sidebar.background }}>
      <List >
        {menuList.map((menuItem) => {
          const Icon = selectedMenuItem === menuItem.title ? activeIconMapping[menuItem.activeIcon] : iconMapping[menuItem.icon];

          return (
            <React.Fragment key={menuItem.title}>
              <ListItem
                button
                onClick={() => handleClick(menuItem)}
                sx={{
                  // backgroundColor: selectedMenuItem === menuItem.title ? '#E96B20' : theme.sidebar.background,
                  backgroundImage: selectedMenuItem === menuItem.title 
                    ? 'linear-gradient(to right, #E96B20, rgba(255,0,0,0))'
                    : 'none',
                  '&:hover': {
                    // backgroundColor: '#E96B20',
                    backgroundImage: 'linear-gradient(to right, #E96B20, rgba(255,0,0,0))',
                    '& .MuiListItemText-root .MuiTypography-root': {
                      color: '#d9d9d9',
                      fontWeight: 600,
                    },
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#E96B20',
                    backgroundImage: 'linear-gradient(to right, #E96B20, rgba(255,0,0,0))',
                    '& .MuiListItemText-root .MuiTypography-root': {
                      color: '#d9d9d9',
                      fontWeight: 600,
                    },
                  },
                  paddingLeft: "4px !important",
                  paddingRight: 2,
                  '& .MuiListItemText-root .MuiTypography-root': {
                    color: '#d9d9d9',
                    fontWeight: 600,
                  },
                }}
                
              >
                <ListItemIcon sx={{ minWidth: 33 }}>
                  {Icon ? <Icon style={{ width: 24, height: 24, color: theme.icon.iconColor }} /> : null}
                </ListItemIcon>
                <ListItemText primary={menuItem.title} />
                {menuItem.submenu ? (
                  open[menuItem.title] ? (
                    <ExpandLess sx={{ color: '#d9d9d9' }} />
                  ) : (
                    <ExpandMore sx={{ color: '#d9d9d9' }} />
                  )
                ) : null}
              </ListItem>
              {menuItem.submenu && (
                <Collapse in={open[menuItem.title]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ pl: 6, backgroundColor: theme.sidebar.background, paddingLeft: "none !important" }}>
                    {menuItem.submenu.map((subMenuItem) => (
                      <ListItem
                        button
                        onClick={() => handleNavigation(subMenuItem.path, menuItem.title, subMenuItem.title)}
                        sx={{
                          pl: 4,
                          paddingLeft: "33px !important",
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: selectedSubMenuItem === subMenuItem.title ? '#312345' : 'transparent',
                          borderLeft: `4px solid ${selectedSubMenuItem === subMenuItem.title ? '#E96B20' : 'transparent'
                            }`,
                          '&:hover': {
                            backgroundColor: '#312345',
                            // backgroundImage: 'linear-gradient(to right, #E96B20, rgba(255,0,0,0))',
                            borderLeft: '4px solid #E96B20',
                            '& .MuiListItemText-root .MuiTypography-root': {
                              color: '#d9d9d9',
                              fontWeight: 600,
                            },
                          },
                          '& .MuiListItemText-root .MuiTypography-root': {
                            color: '#d9d9d9',
                            fontWeight: 600,
                          },
                        }}
                        key={subMenuItem.title}
                      >
                        <ListItemText primary={subMenuItem.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};

export default ExpandMenuList;
